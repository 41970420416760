<template>
  <div class="flex col gap-0-125">
    <div class="event-card layout-event-card-flex-gap">
      <div class="layout-event-card-flex-gap width-100">
        <div v-if="useEventLogos && event.logoUrl" class="layout-event-card-first-col image-container">
          <img width="120" :src="event.logoUrl" alt="Event logo" />
        </div>
        <router-link v-if="isAdmin" :to="{ name: 'event-details', params: { eventId: event.eventId } }"
          class="event-text grow">
          <event-text :event="event" :size="2" :darkBackground="false" :isUserSpecificView="false"
            :isPaidIfNecessary="false"></event-text>
        </router-link>
        <event-text v-else :event="event" :size="2" :darkBackground="false" :isUserSpecificView="false"
          :isPaidIfNecessary="false"></event-text>
      </div>

      <div v-if="event.popularVoteTournament" class="event-actions flex">
        <div class="upcoming-actions flex row-to-col-1024 gap-0-5">
          <template v-if="!event.published">
            <rebel-button type="primary" color="default" text="Voting opens soon" disabled></rebel-button>
            <rebel-button v-if="event.infoUrl" type="ghosted" color="default" text="Event Info"
              @click="openInNewWindow(event.infoUrl)">
              <template v-slot:icon-leading>
                <arrow-top-right-on-square-icon class="icon-20" />
              </template>
            </rebel-button>
          </template>
          <template v-else-if="!event.participationEnded">
            <rebel-button type="primary" color="default" text="Vote Now" @click="voteNow"></rebel-button>
          </template>
          <template v-else>
            <rebel-button type="ghosted" color="default" text="Results" @click="viewPopularEventResults"></rebel-button>
          </template>
        </div>
      </div>
      <div v-else class="event-actions flex">
        <div class="upcoming-actions flex row-to-col-1024 gap-0-5" v-if="eventStatus == 'upcoming'">
          <rebel-button type="primary" color="default" @click="startLeague(event)" text="Start a League"></rebel-button>
          <rebel-button v-if="!isMemberOfOpenTournament" type="ghosted" color="default"
            @click="joinOpenTournament(event)" text="Join Open Tournament"></rebel-button>
        </div>
        <rebel-button v-else type="ghosted" color="default" text="Results"
          @click="viewEventResults(event)"></rebel-button>
      </div>
    </div>

    <template v-if="event.popularVoteTournament && event.entry != null && event.eventParticipant">
      <vote-card :class="{ 'pointer': event.published }" :entry="event.entry" :event="event" :event-status="eventStatus"
        @click="popularVoteEntryClicked" />
    </template>

    <template v-for="league in event.leagues" :key="league.leagueId">
      <router-link :to="{ name: 'league-details', params: { leagueId: league.leagueId } }">
        <league-card :event="event" :event-status="eventStatus" :league="league" />
      </router-link>
    </template>
  </div>
</template>

<script>
import APP_SETTINGS from '@/appSettings.js'
import { useAuthenticationStore } from '@/stores/authentication'
import emitEvents from '../../utils/emitEvents.js'

import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'
import EventText from '@/components/events/EventText.vue'
import LeagueCard from '@/components/events/entryCard/LeagueCard.vue'
import RebelButton from '@/components/RebelButton.vue'
import VoteCard from '@/components/events/VoteCard.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    ArrowTopRightOnSquareIcon,
    EventText,
    LeagueCard,
    RebelButton,
    VoteCard
  },

  computed: {
    isAdmin() {
      return this.authStore.isAdmin
    },

    isMemberOfOpenTournament() {
      if (this.event == null || this.event.leagues == null || this.event.leagues.length === 0) {
        return false
      }

      return this.event.leagues.some((league) => league.openTournament)
    }
  },

  data() {
    return {
      useEventLogos: APP_SETTINGS.USE_EVENT_LOGOS,
    }
  },

  props: {
    eventStatus: {
      type: String,
      required: true,
      default: ''
    },

    event: {
      type: Object,
      required: true,
    }
  },

  emits: [emitEvents.VIEW_EVENT_RESULTS, emitEvents.VIEW_POPULAR_EVENT_RESULTS, emitEvents.VOTE_NOW, emitEvents.CREATE_LEAGUE, emitEvents.JOIN_OPEN_TOURNAMENT],

  methods: {
    joinOpenTournament() {
      this.$emit(emitEvents.JOIN_OPEN_TOURNAMENT, this.event)
    },

    startLeague() {
      this.$emit(emitEvents.CREATE_LEAGUE, this.event)
    },

    viewEventResults() {
      this.$emit(emitEvents.VIEW_EVENT_RESULTS, this.event)
    },

    viewPopularEventResults() {
      this.$emit(emitEvents.VIEW_POPULAR_EVENT_RESULTS, this.event)
    },

    voteNow() {
      this.$emit(emitEvents.VOTE_NOW, this.event)
    },

    getLeagueNotificationCount(league) {
      return this.eventStatus == 'upcoming' ? league.predictionsRemaining : null
    },

    openInNewWindow(url) {
      window.open(url, '_blank')
    },

    async popularVoteEntryClicked() {
      if (!this.event.published) return

      if (!this.event.participationEnded) {
        this.voteNow()
      } else {
        this.viewPopularEventResults()
      }
    }
  }
}
</script>

<style scoped>
.event-card {
  background: var(--color-white);
  border-radius: 2px;
  padding: 1.25rem;
  padding-bottom: 1rem;
}

.event-actions {
  width: initial;
}

.image-container {
    min-width: 120px;
  }

@media screen and (max-width: 640px) {
  .event-card {
    flex-direction: column;
    gap: 1rem;
  }

  .event-actions {
    width: 100%;
    box-sizing: border-box;
  }

  .upcoming-actions {
    width: 100%;
    box-sizing: border-box;
  }

  .event-actions>button {
    flex-grow: 1;
  }
}

@media screen and (max-width: 432px) {
  .upcoming-actions {
    flex-direction: column;
  }
}

.event-card:has(.unpublished-event) {
  border: 1px solid var(--color-danger);
}
</style>