<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog" aria-labelledby="modalTitle">
        <div class="flex align-center justify-end">
          <x-mark-icon data-testid="close-btn" class="pointer icon-20 gray2" @click="close" />
        </div>
        <div class="modal-body">
          <div class="modal-body-inner">
            <h2 id="modalTitle" class="heading2 text-center white">
              <slot name="modal-title"></slot>
            </h2>
            <div id="modal-contents" class="body2 text-center white">
              <slot name="modal-contents"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
  
<script>
import { XMarkIcon } from '@heroicons/vue/20/solid';
import emitEvents from '../utils/emitEvents.js'

export default {
  components: {
    XMarkIcon
  },

  data() {
    return {
      CLOSE_ME_CLASS: 'close-me-when-clicked'
    }
  },

  emits: [emitEvents.CLOSE],

  methods: {
    close() {
      this.$emit(emitEvents.CLOSE)
    }
  }
}
</script>

<style scoped>
@import '/modal.css';
.modal {
  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 0 .125rem 1.25rem .125rem;
  width: 325px;
}

.modal-body {
  padding: 0 1.25rem;
}

.modal-body-inner {
  background-color: var(--color-light-blue);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: .75rem;
  width: 100%;
}
</style>