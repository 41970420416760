<template>
  <div id="action-bar-container" class="flex col align-center gap-0-75 width-100" :class="styles.getLightDarkClasses(darkBackground)">
    <div v-if="$slots.linkBackTo && title != null" class="link-back-to-slot width-100">
      <slot name="linkBackTo"></slot>
    </div>

    <div class="flex width-100 gap-1 align-center" :class="getOuterResponsiveClass">
      <div id="title-gear" class="flex width-100 row gap-1 align-start space-between">
        <h2 v-if="title != null" class="heading2" :class="styles.getLightDarkClasses(darkBackground)">{{ title }}</h2>
        <div v-if="$slots.linkBackTo && title == null" class="link-back-to-slot">
          <slot name="linkBackTo"></slot>
        </div>
        <slot v-if="isWithinOuterBreakpoint" name="gearFlyOutMenu"></slot>
      </div>

      <rebel-button-grid :breakpointInner="breakpointInner" :breakpointMid="breakpointMid" :breakpointOuter="breakpointOuter" :showPrimaryActionsLastOnDesktop="showPrimaryActionsLastOnDesktop">
        <template v-if="$slots.primaryAction1" v-slot:primaryAction1>
          <slot name="primaryAction1"></slot>
        </template>
        <template v-if="$slots.primaryAction2" v-slot:primaryAction2>
          <slot name="primaryAction2"></slot>
        </template>
        <template v-if="$slots.secondaryAction1" v-slot:secondaryAction1>
          <slot name="secondaryAction1"></slot>
        </template>
        <template v-if="$slots.secondaryAction2" v-slot:secondaryAction2>
          <slot name="secondaryAction2"></slot>
        </template>
        <template v-if="$slots.gearFlyOutMenu" v-slot:gearFlyOutMenuDesktopOnly>
          <slot v-if="!isWithinOuterBreakpoint" name="gearFlyOutMenu"></slot>
        </template>
      </rebel-button-grid>

      <slot v-if="!isWithinOuterBreakpoint" name="gearFlyOutMenu"></slot>
    </div>
  </div>
</template>

<script>
import RebelButtonGrid from '@/components/RebelButtonGrid.vue';
import styles, { validBreakpoints } from '@/utils/styles.js' 

export default {
  setup() {
    return {
      styles
    }
  },
  
  components: {
    RebelButtonGrid
  },

  data() {
    return {
      windowWidth: window.innerWidth
    }
  },


  props: {
    breakpointInner: {
      type: Number,
      default: null,
      validator(value) {
        return value == null || validBreakpoints.includes(value)
      }
    },

    breakpointMid: {
      type: Number,
      default: null,
      validator(value) {
        return value == null || validBreakpoints.includes(value)
      }
    },
    
    breakpointOuter: {
      type: Number,
      default: null,
      validator(value) {
        return value == null || validBreakpoints.includes(value)
      }
    },

    darkBackground: {
      type: Boolean,
      default: false
    },

    showPrimaryActionsLastOnDesktop: {
      type: Boolean,
      default: true
    },

    title: {
      type: String,
      default: null
    }
  },

  computed: {
    isWithinOuterBreakpoint() {
      return this.breakpointOuter != null && this.windowWidth <= this.breakpointOuter
    },

    getOuterResponsiveClass(){
      if(this.breakpointOuter == null) {
        return ''
      }
      return `row-to-col-${this.breakpointOuter}`
    },
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style scoped>
.link-back-to-slot {
  min-height: 1.5rem;
  display: flex;
  align-items: center;
}
</style>