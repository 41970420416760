<template>
  <section class="score-section">
    <div class="score-section-part">
      <h3>Points Won / Possible</h3>
      <div class="flex align-baseline gap-0-25" data-testid="points-won-points-possible">
        <span class="color-action heading3">{{ numberWithCommas(won) }}</span>
        <span class="color-action caption">won</span>
        <span class="gray2 heading3"> / </span>
        <span class="gray2 heading3">{{ numberWithCommas(possible) }}</span>
        <span class="gray2 caption">possible</span>
      </div>
    </div>
    <div class="score-section-gap"></div>
    <div class="score-section-part">
      <h3>League Rank</h3>
      <div class="flex align-baseline gap-0-25" data-testid="league-rank">
        <span class="color-action heading3">{{ numberWithCommas(leagueRank) }}</span>
        <span class="gray2 caption">out of </span>
        <span class="gray2 heading3">{{ numberWithCommas(leagueMemberCount) }}</span>
      </div>
    </div>
    <div class="score-section-gap"></div>
    <div class="score-section-part">
      <h3>MATNESS Rank</h3>
      <div class="flex align-baseline gap-0-25" data-testid="matness-rank">
        <span class="color-action heading3">{{ numberWithCommas(overallRank) }}</span>
        <span class="gray2 caption">out of </span>
        <span class="gray2 heading3">{{ numberWithCommas(overallMemberCount) }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import numberUtils from '../../utils/numberUtils'

export default {
  props: {
    leagueRank: {
      default: "-"
    },

    leagueMemberCount: {
      type: Number,
      default: 0
    },

    overallRank: {
      default: "-"
    },

    overallMemberCount: {
      type: Number,
      default: 0
    },

    possible: {
      type: Number,
      default: 0
    },

    won: {
      type: Number,
      default: 0
    }
  },

  methods: {
    numberWithCommas(x) {
      return numberUtils.numberWithCommas(x)
    }
  }
}
</script>

<style scoped>
.score-section {
  --gap: 8px;

  box-sizing: border-box;
  display: flex;
  min-height: 66px;
  max-width: 652px;
}
.score-section-gap {
  width: var(--gap);
}
.score-section-part {
  background: var(--color-gray0-5);
  padding: 0.75rem 1rem;
  flex-basis: calc(33.3% - var(--gap));
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

@media screen and (max-width: 575px) {
  .score-section {
    flex-wrap: wrap;
  }
  .score-section-part {
    flex-grow: 1;
  }
  .score-section-gap:nth-child(4) {
    display: none;
  }
  .score-section-part:last-child {
    margin-top: var(--gap);
  }
}
</style>