<template>
  <div ref="rebelTable" class="rebel-table overflow-x-auto">
    <table>
      <thead>
        <tr>
          <th v-for="column in columns" :key="column.prop" :class="{ clickable: sortable }" @click="sortColumn(column)">
            <div class="small-caps-1 gray3 column-heading-content">
              {{ column.label }}
              <div class="chevrons" v-if="sortable">
                <i class="fa fa-chevron-up" :class="{ asc: column.sortDirection === 'asc' }"></i>
                <i class="fa fa-chevron-down" :class="{ desc: column.sortDirection === 'desc' }"></i>
              </div>
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in sortedTableDataCurrentPage" :key="row[rowKey] || index">
          <td v-for="column in columns" :key="column.prop" :class="column.cssClasses" :data-testid="`${column.label}-row${index}`">
            <slot :name="column.prop" v-bind="{ row, rowIndex: index, column }">

              <span v-if="column.isHyperlink && row[column.prop]?.href">
                <router-link class="body2 color-action underline" :to="row[column.prop].href" target="_blank">{{
                  row[column.prop].text
                }}</router-link>
              </span>
              <span v-else-if="column.isListOfHyperlinks && row[column.prop]?.length" class="flex col">
                <span v-for="hyperlinkObj in row[column.prop]" :key="hyperlinkObj.href">
                  <router-link class="body2 color-action underline" :to="hyperlinkObj.href" target="_blank">{{
                    hyperlinkObj.text
                  }}</router-link>
                </span>
              </span>
              <span v-else-if="row[column.prop] != null" class="body2" :class="rowConditionalCssClass(row)"> {{
                row[column.prop] }} </span>
              <span v-else class="body2"></span>

            </slot>
          </td>

          <td :id="`fly-out-link-cell${index}`" class="text-right"
            v-if="moreOptionsColumn && (row.role && row.role !== 'Commissioner')">
            <a data-testid="show-options" class="fly-out-link" @click.prevent="showMoreOptions(index)"><i
                class="fly-out-link fa fa-ellipsis"></i></a>
          </td>
          <td class="text-right" v-else></td>
        </tr>

        <tr v-if="tableData.length === 0 && !loading">
          <td :colspan="columns.length">
            <span> No Data </span>
          </td>
        </tr>
      </tbody>
    </table>

    <pagination-component v-if="pagingOptions.usePaging && sortedTableData.length > pagingOptions.perPage"
      :total-pages="sortedTableData.length ? Math.ceil(sortedTableData.length / pagingOptions.perPage) : 0"
      :per-page="pagingOptions.perPage" :max-visible-buttons="pagingOptions.maxVisibleButtons"
      :current-page="pagingOptions.currentPage" @pagechanged="(page) => $emit('pagechanged', page)" />
  </div>
  <ol v-if="rowIndexWithMoreOptionsShowing >= 0" class="fly-out body2" :style="moreOptionsStyles">
    <li>
      <a class="" @touchstart.prevent="assignLeagueCommissioner" @click.prevent="assignLeagueCommissioner">Assign league
        commissioner</a>
    </li>
    <li v-if="allowRemoveFromLeague">
      <a class="color-danger" @touchstart.prevent="removeFromLeague" @click.prevent="removeFromLeague">Remove from
        league</a>
    </li>
  </ol>
</template>

<script>
import emitEvents from '../utils/emitEvents'
import PaginationComponent from '@/components/PaginationComponent.vue'

export default {
  emits: [emitEvents.CONFIRM_ASSIGN_LEAGUE_COMMISSIONER, emitEvents.CONFIRM_REMOVE_LEAGUE_MEMBER, 'pagechanged'],

  components: {
    PaginationComponent
  },

  created() {
    if (this.sortable && this.columns.length) {
      this.sortColumn(this.columns[0])
    }
  },

  data() {
    return {
      areActionsShowing: false,
      rowIndexWithMoreOptionsShowing: -1
    }
  },

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    rowKey: {
      type: String,
      default: 'id'
    },
    columns: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    },
    moreOptionsColumn: {
      type: Boolean,
      default: true
    },
    sortable: {
      type: Boolean,
      default: true
    },
    pagingOptions: {
      type: Object,
      default: () => {
        return {
          usePaging: false,

          perPage: 10,
          currentPage: 1,
          maxVisibleButtons: 3
        }
      }
    },
    rowConditionalCssClass: {
      type: Function,
      required: false,
      default: () => { }
    },
    allowRemoveFromLeague: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  computed: {
    columnToSort() {
      return this.columns.find((c) => c.sortDirection)
    },

    moreOptionsRow() {
      if (this.rowIndexWithMoreOptionsShowing < 0 || this.rowIndexWithMoreOptionsShowing > this.sortedTableData.length) {
        return null
      }

      return this.sortedTableData[this.rowIndexWithMoreOptionsShowing]
    },

    moreOptionsStyles() {
      const flyOutLinkElem = document.getElementById('fly-out-link-cell' + this.rowIndexWithMoreOptionsShowing)

      let offsetTop = flyOutLinkElem?.offsetTop + flyOutLinkElem?.offsetHeight

      return {
        top: offsetTop + 'px' || 0
      }
    },

    sortedTableData() {
      if (!this.columnToSort) return this.tableData

      const tableDataClone = this.tableData.map((x) => x)

      tableDataClone.sort((a, b) => {
        // sortProp allows for choosing another prop to sort by
        // this is convenient if the way the data is displayed is not sortable
        // e.g. '100%', '87%', etc are sorted lexicographically
        // and are better sorted numerically with a prop that holds 100, 87, etc
        let aColumnData = a[this.columnToSort.sortProp || this.columnToSort.prop]
        let bColumnData = b[this.columnToSort.sortProp || this.columnToSort.prop]

        if (this.columnToSort.isHyperlink) {
          aColumnData = aColumnData?.text
          bColumnData = bColumnData?.text
        }

        if (typeof aColumnData === 'string' && typeof bColumnData === 'string') {
          aColumnData = aColumnData.toLowerCase()
          bColumnData = bColumnData.toLowerCase()
        }

        if (this.columnToSort.sortDirection === 'asc') {
          return aColumnData === bColumnData ? 0 : bColumnData == null || aColumnData > bColumnData ? 1 : -1
        }

        return aColumnData === bColumnData ? 0 : aColumnData == null || bColumnData > aColumnData ? 1 : -1
      })

      return tableDataClone
    },

    sortedTableDataCurrentPage() {
      if (!this.pagingOptions.usePaging) return this.sortedTableData

      // only show what should be seen on the current page
      return this.sortedTableData.filter((item, index) => {

        const withinLowerBound = ((this.pagingOptions.currentPage - 1) * this.pagingOptions.perPage) <= index
        const withinUpperBound = index < this.pagingOptions.currentPage * this.pagingOptions.perPage

        if (withinLowerBound && withinUpperBound) {
          return true
        }
        return false
      })
    }
  },

  methods: {
    async assignLeagueCommissioner() {
      this.$emit(emitEvents.CONFIRM_ASSIGN_LEAGUE_COMMISSIONER, this.moreOptionsRow)
    },
    closeFlyout(event) {
      if (!event.target.classList.contains('fly-out-link')) {
        this.rowIndexWithMoreOptionsShowing = -1
      }
    },
    removeFromLeague() {
      this.$emit(emitEvents.CONFIRM_REMOVE_LEAGUE_MEMBER, this.moreOptionsRow)
    },

    showMoreOptions(rowIndex) {
      // hide if clicked again
      if (this.rowIndexWithMoreOptionsShowing === rowIndex) {
        this.rowIndexWithMoreOptionsShowing = -1
        return
      }

      this.rowIndexWithMoreOptionsShowing = rowIndex
    },

    sortColumn(column) {
      // reset other columns that may be sorted
      this.columns.forEach((c) => {
        if (c.label != column.label) {
          c.sortDirection = ''
        }
      })

      if (!column.sortDirection || column.sortDirection === 'desc') {
        column.sortDirection = 'asc'
      } else {
        column.sortDirection = 'desc'
      }
    }
  },

  mounted() {
    window.addEventListener('click', this.closeFlyout)
    window.addEventListener('touchstart', this.closeFlyout)
  },

  unmounted() {
    window.removeEventListener('click', this.closeFlyout)
    window.removeEventListener('touchstart', this.closeFlyout)
  }
}
</script>

<style scoped>
.rebel-table td {
  text-align: left;
}

.rebel-table td.text-center {
  text-align: center;
}
.rebel-table td.text-right {
  text-align: right;
}

.column-heading-content {
  display: flex;
  align-items: center;
  user-select: none;
}

.chevrons {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 0.5rem;
  margin-left: 0.5rem;
  color: var(--color-gray3);
}

.asc,
.desc {
  color: var(--color-action);
}

table {
  width: 100%;
  table-layout: auto;
}

table th {
  text-align: left;
}

.clickable {
  cursor: pointer;
}

table th,
table td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 2rem;
  white-space: nowrap;
}

table th:first-child,
table td:first-child {
  padding-left: 0.5rem;
}

table th:last-child,
table td:last-child {
  padding-right: 0.5rem;
}

table thead tr {
  border-top: 1px solid var(--color-gray2);
  border-bottom: 1px solid var(--color-gray2);
}

table tbody tr:nth-child(odd) {
  background-color: var(--color-gray0-5);
}

table th:last-child,
table td:last-child {
  width: 100%;
}

.overflow-x-auto {
  overflow-x: auto;
}
</style>
