<template>
  <div id="login-component" class="layout-form-page">
    <h1>Reset Password</h1>

    <form class="layout-form-list" @submit.prevent="submit">
      <div class="form-input">
        <label for="password">Password</label>
        <input
          autofocus
          id="password"
          :type="showingPassword ? 'text' : 'password'"
          placeholder="Password"
          v-model="resetPasswordForm.password"
          :required="dataConstraints.user.password.required"
          :minlength="dataConstraints.user.password.minlength"
          :maxlength="dataConstraints.user.password.maxlength"
        />
        <i class="fa fa-eye-slash" v-show="!showingPassword" @click="showingPassword = true"></i>
        <i class="fa fa-eye" v-show="showingPassword" @click="showingPassword = false"></i>
      </div>
      <div class="form-input">
        <label for="confirm-password">Confirm Password</label>
        <input
          id="confirm-password"
          :type="showingPassword ? 'text' : 'password'"
          placeholder="Confirm Password"
          v-model="resetPasswordForm.confirmPassword"
          :required="dataConstraints.user.password.required"
          :minlength="dataConstraints.user.password.minlength"
          :maxlength="dataConstraints.user.password.maxlength"
        />
        <i class="fa fa-eye-slash" v-show="!showingPassword" @click="showingPassword = true"></i>
        <i class="fa fa-eye" v-show="showingPassword" @click="showingPassword = false"></i>
      </div>

      <div class="mt-1">
        <rebel-button button-type="submit" type="primary" color="default" :is-loading="submitInProgress" text="Reset Password"></rebel-button>
      </div>
    </form>

    <reset-password-confirmation-modal v-if="showingResetPasswordConfirmationModal" />
  </div>
</template>

<script>
import dataConstraints from '@/utils/dataConstraints.js'
import authenticationService from '@/services/authenticationService.js'
import RebelButton from '@/components/RebelButton.vue'
import ResetPasswordConfirmationModal from '@/components/ResetPasswordConfirmationModal.vue'

export default {
  setup() {
    return {
      dataConstraints
    }
  },

  async created() {
    if (!this.$route.query || !this.$route.query.token) {
      await this.$router.push({ name: 'login', query: { 'invalid-reset-password-token': true } })
    }

    const isValidToken = await authenticationService.verifyResetPasswordToken(this.$route.query.token)

    if (!isValidToken.data) {
      await this.$router.push({ name: 'login', query: { 'invalid-reset-password-token': true } })
    }
  },

  components: {
    RebelButton,
    ResetPasswordConfirmationModal
  },

  data() {
    return {
      showingResetPasswordConfirmationModal: false,
      showingPassword: false,
      submitInProgress: false,
      resetPasswordForm: {
        password: '',
        confirmPassword: ''
      }
    }
  },

  methods: {
    async submit() {
      if (this.resetPasswordForm.password != this.resetPasswordForm.confirmPassword) {
        alert('Passwords do not match')
        return
      }

      this.submitInProgress = true

      try {
        await authenticationService.resetPassword(this.$route.query.token, this.resetPasswordForm.password)
        this.showingResetPasswordConfirmationModal = true
      } catch (error) {
        if (error && error.response && error.response.status == 403) {
          await this.$router.push({ name: 'login', query: { 'invalid-reset-password-token': true } })
        }
        this.showingResetPasswordConfirmationModal = false
        alert('An error occurred')
      } finally {
        this.submitInProgress = false
      }
    }
  }
}
</script>

