<template>
  <event-text :event="event" :size="1" :dark-background="darkBackground" :is-center-justified="true"
    :exempt-from-payment="exemptFromPayment" :is-paid-if-necessary="isPaidIfNecessary" :is-user-specific-view="isUserSpecificView" />
  <div v-if="resultsPending" class="body2" :class="{ 'color-danger': !darkBackground, 'gray1-5': darkBackground }">
    Additional results pending
  </div>
</template>

<script>
import EventText from '@/components/events/EventText.vue'
import LabelAdmin from '@/components/event-admin/LabelAdmin.vue'

export default {
  components: {
    EventText,
    LabelAdmin,
  },

  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    event: {
      type: Object,
      required: true
    },

    exemptFromPayment: {
      type: Boolean,
      default: false
    },

    isPaidIfNecessary: {
      type: Boolean,
      default: true
    },

    isUserSpecificView: {
      type: Boolean,
      default: true
    },

    resultsPending: {
      type: Boolean,
      required: false
    },

    subText: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
