<template>
  <div class="outer-container" :class="{ 'bg-gray0-5': !darkBackground && grayBackground, 'bg-gray3': darkBackground }">
    <nav-bar :key="authStore.loggedInUser?.user?.id"></nav-bar>
    <div class="flex col grow" :class="{ 'with-bg-image': useBackgroundImage }" :style="assignStyleVariables">
      <div class="flex col grow inner-container">
        <main class="grow">
          <router-view :key="$route.path" />
          <section id="shared-modals">
            <pig-modal v-if="sharedModals.pigModal.isShowing" @close="sharedModals.setPigModalShowing(false)" />
            <text-message-notifications-modal 
              v-if="textMessageNotifications.modal.isShowing"
              @close="textMessageNotifications.setModalShowing(false)" />
          </section>
        </main>
        <footer class="layout-padding-x">
          <section class="flex justify-center footer-section" :class="styles.getLightDarkClasses(darkBackground)">
            <a class="caption" href="/privacy-policy">Privacy Policy</a>
            <span class="caption ml-0-5 mr-0-5"> | </span>
            <a class="caption" href="/terms-of-service">Terms of Service</a>
            <span class="caption ml-0-5 mr-0-5"> | </span>
            <a class="caption" href="/refund-policy">Refund Policy</a>
          </section>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import { useEventAdminStore } from '@/stores/eventAdmin'
import { useSharedModalsStore } from '@/stores/sharedModals'
import { useStylingStore } from '@/stores/styling'
import { useTextMessageNotificationsStore } from '@/stores/textMessageNotifications'

import NavBar from '@/components/NavBar.vue'
import PigModal from '@/components/leagues/PigModal.vue'
import styles from '@/utils/styles.js'
import TextMessageNotificationsModal from '@/components/user-profile/TextMessageNotificationsModal.vue'


export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      eventAdminStore: useEventAdminStore(),
      sharedModals: useSharedModalsStore(),
      stylingStore: useStylingStore(),
      styles,
      textMessageNotifications: useTextMessageNotificationsStore()
    }
  },

  components: {
    NavBar,
    PigModal,
    TextMessageNotificationsModal
  },

  computed: {
    assignStyleVariables() {
      if (this.useBackgroundImage) {
        let styles = `--event-background-image-url: url('${this.eventAdminStore.backgroundImage}');`

        styles += `--event-background-image-gaussian-blur: blur(${this.eventAdminStore.gaussianBlur}px);`
        styles += `--event-background-image-opacity: ${this.eventAdminStore.opacity / 100};`
        styles += `--event-background-image-saturation: saturate(${this.eventAdminStore.saturation / 100});`

        if (this.eventAdminStore.blendMode) {
          styles += `--event-background-image-blend-mode: multiply;`
        } else {
          styles += `--event-background-image-blend-mode: normal;`
        }

        return styles;
      }

      return ''
    },

    hasBackgroundImage() {
      return Boolean(this.eventAdminStore.backgroundImage)
    },

    useBackgroundImage() {
      return (this.$route.meta.showEventBackgroundImage || this.stylingStore.showEventBackgroundImage) && this.hasBackgroundImage
    },

    darkBackground() {
      return this.$route.meta.darkBackground || this.stylingStore.darkBackground
    },

    grayBackground() {
      return this.$route.meta.grayBackground
    },
  },
}
</script>

<style>
#app .outer-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.with-bg-image {
  position: relative;
  /* necessary for background image */
}

.with-bg-image:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: var(--event-background-image-opacity);
  background-image: var(--event-background-image-url);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  filter: var(--event-background-image-saturation);
  mix-blend-mode: var(--event-background-image-blend-mode);
}

#app .with-bg-image .inner-container {
  position: relative;
}

#app .with-bg-image .inner-container::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: var(--event-background-image-gaussian-blur);
  mix-blend-mode: multiply;
}

.inner-container>main {
  z-index: 1;
}

footer {
  box-sizing: border-box;
  padding-bottom: 0.75rem;
  width: 100%;
}

.footer-section>* {
  padding-top: 0.25rem;
}

.footer-section>a,
.footer-section>span {
  color: var(--color-gray2);
}

.footer-section.dark-background>a,
.footer-section.dark-background>span {
  color: var(--color-gray1-5);
  filter: drop-shadow(2px 4px 6px #00000090);
}

main:has(.filter-fly-out.visible) {
  width: calc(100% - 384px);
}
</style>
