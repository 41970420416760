
    const SOCKET_URL = import.meta.env.VITE_SOCKET_URL
    import PubSub from 'pubsub-js'
    import { Client } from '@stomp/stompjs';

    function debounce(callbackFn, delay = 1000) {
      let timeout

      return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => callbackFn(args), delay);
      }
    }

    const client = new Client({
      brokerURL: SOCKET_URL,
      onConnect: () => {
        client.subscribe('/topic/bracket-reseeded', message => {
          PubSub.publish('bracket-reseeded', JSON.parse(message.body))
        });

        client.subscribe('/topic/event-results-published', message => {
          const callbackFn = () => {
            PubSub.publish('event-results-published', JSON.parse(message.body))
          }
          debounce(callbackFn, 1000)()
        });

        client.subscribe('/topic/event-brackets-published', message => {
          PubSub.publish('event-brackets-published', JSON.parse(message.body))
        });

        client.subscribe('/topic/league-members-changed', message => {
          PubSub.publish('league-members-changed', JSON.parse(message.body))
        });

        client.subscribe('/topic/payment-status-changed', message => {
          PubSub.publish('payment-status-changed', JSON.parse(message.body))
        });

        client.subscribe('/topic/user-bracket-updated', message => {
          const callbackFn = () => {
            PubSub.publish('user-bracket-updated', JSON.parse(message.body))
          }
          debounce(callbackFn, 3000)()
        });

        client.subscribe('/topic/league-name-changed', message => {
          PubSub.publish('league-name-changed', JSON.parse(message.body))
        })
      },
    });

    client.activate();
  