import axios from 'axios'

export default { 
  async sendOtp(to) {
    return axios.post(`/phone-verification/send-otp`, { to })
  },

  async checkOtp(to, otp) {
    return axios.post(`/phone-verification/check-otp`, { to, code: otp })
  }
}