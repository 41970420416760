<template>
  <div class="flex col gap-2">
    <p class="body2 bold">Last updated March 9, 2025</p>

    <h1>Terms of Use</h1>

    <p>
      Welcome to Wrestle MATNESS! By accessing or using our website, products, or services, you agree to the following terms:
    </p>

    <ol>
      <li>
        <h3>Use of Services:</h3>
        <ul>
          <li>You acknowledge Wrestle MATNESS is not a gambling site.</li>
          <li>You agree to use our services for lawful purposes only.</li>
        </ul>
      </li>
      <li>
        <h3>Intellectual Property:</h3>
        <ul>
          <li>Original artwork and the Wrestle MATNESS logo are the property of Wrestle MATNESS.</li>
          <li>You may not reproduce or exploit any original content without explicit permission.</li>
        </ul>
      </li>
      <li>
        <h3>User Accounts:</h3>
        <ul>
          <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
          <li>We reserve the right to terminate accounts for violations of these terms.</li>
        </ul>
      </li>
      <li>
        <h3>Liability:</h3>
        <ul>
          <li>Wrestle MATNESS or MATNESS LLC are not liable for any damages resulting from improper use of our products or services.</li>
        </ul>
      </li>
      <li>
        <h3>Changes to Terms:</h3>
        <ul>
          <li>We reserve the right to modify these terms at any time. Continued use of our services constitutes acceptance of the revised terms.</li>
        </ul>
      </li>
    </ol>

    <p>For further details, please contact us at {{ supportEmail }}.</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supportEmail: 'info@wrestlematness.com'
    }
  }
}
</script>

<style scoped>
ol {
  list-style-type: decimal;
  margin-left: 1.5rem;
}

ul {
  list-style-type: circle;
  margin-left: 1.5rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

ol li:last-child ul {
  margin-bottom: 0rem;
}
</style>