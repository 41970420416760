<template>
  <div v-if="isComponentShowing">
    <ol ref="weightClassSelector" class="box-selector"
      :class="`${styles.getLightDarkClasses(darkBackground)} ${getBackgroundBlurClass}`">
      <li :style="{ width: itemWidth }" class="box-item" v-if="showOverall" :class="{ active: selection === '-1' }"
        @click="setSelected('-1')">
        <span>Overall</span>
      </li>
      <li :style="{ width: itemWidth }" class="box-item" v-for="(w, index) in weightClassesShown" :key="index"
        :class="{ active: selection === w }" @click="setSelected(w)">
        <span class="nowrap">
          {{ w }} <span class="caption">{{ units }}</span>
          <rebel-notification-count-bubble class="ml-0-25" v-if="showPredictionsRemaining"
            :value="predictionsRemainingByWeightClass.get(w) || 0" :darkBackground="darkBackground"
            primaryColor="danger" />
        </span>
      </li>
      <li :style="{ width: itemWidth }" id="more-selector" v-show="isMoreVisible" @click="showMore">
        <span class="flex gap-0-25 align-center nowrap">More<i class="fa fa-chevron-down"></i></span>
      </li>
    </ol>
    <ol id="more-selections" class="fly-out box-selector col" :class="`${styles.getLightDarkClasses(darkBackground)} ${getBackgroundBlurClass}`" v-show="isMoreSelected">
      <li :class="{ active: selection === w }" :style="{ width: itemWidth }" v-for="(w, index) in moreData" :key="index"
        @click="setSelected(w)">
        <span class="nowrap">
          {{ w }} <span class="caption">{{ units }}</span>
          <rebel-notification-count-bubble class="ml-0-25" v-if="showPredictionsRemaining"
            :value="predictionsRemainingByWeightClass.get(w) || 0" :darkBackground="darkBackground"
            primaryColor="danger" />
        </span>
      </li>
    </ol>
  </div>
</template>

<script>
import defaults from '../utils/defaults'
import emitEvents from '../utils/emitEvents'
import styles from '@/utils/styles.js'

import RebelNotificationCountBubble from '@/components/RebelNotificationCountBubble.vue'

import { useStylingStore } from '@/stores/styling'

export default {
  setup() {
    return {
      styles,
      stylingStore: useStylingStore()
    }
  },

  components: {
    RebelNotificationCountBubble
  },

  created() {
    if (!this.isComponentShowing) return;

    if (this.selectedValue === '-1' || !this.weightClasses || !this.weightClasses.length) {
      this.selection = '-1'
      return
    }

    const selectedWeightClass = this.weightClasses.find((w) => w === this.selectedValue)

    if (selectedWeightClass) {
      this.selection = selectedWeightClass
    } else {
      this.selection = this.showOverall ? '-1' : this.weightClasses[0]
    }
  },
  mounted() {
    if (!this.isComponentShowing) return;

    if (this.theContainingElement == null) {
      this.theContainingElement = this.$refs.weightClassSelector.parentElement
    }

    // delay it by a few milliseconds so that the screen can render prior to capturing widths
    setTimeout(() => {
      // gather initial item widths
      this.gatherItemWidths()

      // resize to fit
      this.windowResized()
    }, 10)

    this.isMoreVisible = true
    this.moreItemWidth = document.getElementById('more-selector')?.offsetWidth || 0
    this.isMoreVisible = false

    // set up resize event
    window.addEventListener('resize', this.windowResizeThrottle)
  },
  unmounted() {
    if (!this.isComponentShowing) return;

    window.removeEventListener('resize', this.windowResizeThrottle)
  },

  props: {
    containingElement: {
      type: HTMLElement,
      required: false,
      default: undefined
    },
    weightClasses: {
      type: Array,
      default: () => defaults.WEIGHT_CLASSES
    },
    units: {
      type: String,
      default: defaults.WEIGHT_CLASS_UNITS
    },
    showOverall: {
      type: Boolean,
      default: true
    },
    selectedValue: {
      type: String,
      default: (rawProps) => {
        if (rawProps.showOverall === false && rawProps.weightClasses != null && rawProps.weightClasses.length > 0) {
          return rawProps.weightClasses[0]
        }

        return '-1'
      }
    },
    showPredictionsRemaining: {
      type: Boolean,
      default: false
    },
    predictionsRemainingByWeightClass: {
      type: Map,
      default: (rawProps) => {
        let defaultWeightClasses = defaults.WEIGHT_CLASSES

        if (rawProps.weightClasses != null && rawProps.weightClasses != null && rawProps.weightClasses.length > 0) {
          defaultWeightClasses = rawProps.weightClasses
        }

        return new Map(defaultWeightClasses.map(wc => [wc, 0]))
      }
    }
  },

  data() {
    return {
      theContainingElement: this.containingElement,

      windowResizeMetaData: {
        timeout: false,
        delay: 10
      },

      selection: '-1',

      indexToBeginRemove: '-1',
      isMoreVisible: true,
      isMoreSelected: false,

      moreItemWidth: 0,

      itemWidth: 'initial',

      itemWidths: []
    }
  },

  emits: [emitEvents.SELECTED_WEIGHT_CLASS_CHANGED],

  computed: {
    getBackgroundBlurClass() {
      return this.darkBackground ? 'bg-white-opacity-blur' : ''
    },

    darkBackground() {
      return this.$route.meta.darkBackground || this.stylingStore.darkBackground
    },

    isComponentShowing() {
      return this.weightClasses.length > 1
    },

    maxItemWidth() {
      return this.itemWidths.reduce((max, currItem) => (currItem > max ? currItem : max), 0)
    },

    totalWidthNeeded() {
      return this.maxItemWidth * this.itemWidths.length
    },

    weightClassesShown() {
      if (this.indexToBeginRemove === '-1') {
        return this.weightClasses
      }

      return this.weightClasses.filter((w, ix) => ix < this.indexToBeginRemove)
    },

    moreData() {
      if (!this.isMoreVisible) {
        return []
      }

      return this.weightClasses.filter((w, ix) => ix >= this.indexToBeginRemove)
    }
  },

  methods: {
    gatherItemWidths() {
      this.itemWidths = []

      const selectors = this.$refs.weightClassSelector.querySelectorAll('.box-item')
      for (let i = 0; i < selectors.length - 1; i++) {
        this.itemWidths.push(selectors[i].offsetWidth)
      }
    },

    windowResizeThrottle() {
      // clear the timeout
      window.clearTimeout(this.windowResizeMetaData.timeout)
      // start timing for event "completion"
      this.windowResizeMetaData.timeout = setTimeout(this.windowResized, this.windowResizeMetaData.delay)
    },

    windowResized() {
      const widthAvailable = this.theContainingElement.getBoundingClientRect().width

      if (widthAvailable > this.totalWidthNeeded) {
        this.indexToBeginRemove = '-1'
        this.isMoreVisible = false
        this.isMoreSelected = false

        const totalItems = this.itemWidths.length + 1
        this.itemWidth = widthAvailable / totalItems + 'px'

        return
      }

      this.isMoreVisible = true
      this.isMoreSelected = false

      let totalItems = Number.parseInt((widthAvailable - this.moreItemWidth) / this.maxItemWidth)

      let widthRemaining = widthAvailable - (totalItems + 1) * this.maxItemWidth
      this.itemWidth = this.maxItemWidth + widthRemaining / (totalItems + 1) + 'px'

      if (this.showOverall) {
        // must include overall item
        totalItems = Number.parseInt((widthAvailable - this.moreItemWidth - this.maxItemWidth) / this.maxItemWidth)

        let widthRemaining = widthAvailable - (totalItems + 2) * this.maxItemWidth
        this.itemWidth = this.maxItemWidth + widthRemaining / (totalItems + 2) + 'px'
      }

      this.indexToBeginRemove = totalItems
    },

    showMore() {
      this.isMoreSelected = !this.isMoreSelected

      const moreSelector = document.getElementById('more-selector')
      const moreSelections = document.getElementById('more-selections')

      if (this.isMoreSelected) {
        moreSelections.style.position = 'absolute'
        moreSelections.style.top = moreSelector.offsetTop + moreSelector.offsetHeight + 'px'
      }
    },

    setSelected(value) {
      this.selection = value
      this.$emit(emitEvents.SELECTED_WEIGHT_CLASS_CHANGED, value)
      this.isMoreSelected = false
    }
  }
}
</script>

<style>
i {
  font-size: 0.625rem;
  margin-right: 0.25rem;
}

.box-selector {
  display: flex;
}

.box-selector.light-background {
  border-top: 0.5px solid var(--color-gray2);
  border-bottom: 0.5px solid var(--color-gray2);
  width: fit-content;
}

.box-selector.dark-background {
  border-radius: 0.5rem;
  padding: 0.25rem;
}

.box-selector>li {
  padding: 0.75rem 0.5rem 1rem 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
}

.box-selector.dark-background>li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 0.5rem 0.6rem 0.5rem;
}

#more-selections>li {
  padding: 0.75rem 0.5rem;
}

.box-selector.light-background>li.active {
  border-bottom: 0.25rem solid var(--color-action);
  padding-bottom: 0;
  border-radius: 0;
}

.box-selector>li.active {
  background-color: var(--color-white);
  border-radius: 0.25rem;
}

#more-selections>li.active {
  border-bottom: 0;
}

.box-selector.light-background>li.active>span,
.box-selector.light-background>li.active>span>span {
  color: var(--color-action);
}

.box-selector.dark-background>li.active>span,
.box-selector.dark-background>li.active>span>span {
  color: var(--color-action-dark-background);
}

#more-selections {
  z-index: 2;
  min-width: auto;
}</style>