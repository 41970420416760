<template>
  <modal-dialog ref="myModal" @submit.prevent="tryCreateOrEditBracket">
    <template v-if="reSeedWeightClassModal.isShowing" v-slot:modal-title>Re-Seed This Weight Class?</template>
    <template v-else v-slot:modal-title>{{ isEditCompetitorsMode ? 'Edit' : 'Add' }} Competitors</template>

    <template v-if="reSeedWeightClassModal.isShowing" v-slot:modal-contents>
      <p>
        Are you sure you want to re-seed the <span class="bold">{{ weightClass }} weight class</span> for the event
        <span class="bold">{{ eventName }}</span>?
      </p>
      <p>
        This cannot be undone. All members' bracket selections for this weight class will be cleared. All users will
        receive
        a notification email to make new selections.
      </p>
      <p>
        If you are only making small edits, you may save without re-seeding. These changes will be published
        immediately,
        and users will <span class="bold">not</span> be notified.
      </p>
    </template>
    <template v-else v-slot:modal-contents>
      <div class="form-input gap-1-5">

        <section class="form-input gap-1">
          <div class="form-labeled-value">
            <h4>Event</h4>
            <p data-testid="event-name">{{ eventName }}</p>
          </div>

          <div class="form-labeled-value">
            <h4>Event Type</h4>
            <p data-testid="event-type">{{ eventTypeLabel }}</p>
          </div>

          <div class="form-labeled-value">
            <h4>{{ eventDateTimeLabel }}</h4>
            <p data-testid="start-date-time">{{ eventStartDateTime }}</p>
          </div>

          <div class="form-labeled-value">
            <h4>Weight Class</h4>
            <p data-testid="weight-class">{{ weightClass }}</p>
          </div>
        </section>


        <section class="form-input gap-1">
          <header class="flex space-between align-center">
            <h2 class="heading2">Round 1 Competitors</h2>
            <div class="rebelToolbarButtonContainer">
              <rebel-toolbar-button data-testid="edit-competitors-individual" :isSelected="round1CompetitorsEditMode === EDIT_MODE.INDIVIDUAL" :isWithinButtonBar="true" @click="individualEditRound1Participants">
                <template v-slot:icon-leading>
                  <user-icon class="icon-20" />
                </template>
              </rebel-toolbar-button>
              <rebel-toolbar-button data-testid="edit-competitors-bulk" :isSelected="round1CompetitorsEditMode === EDIT_MODE.BULK" :isWithinButtonBar="true" @click="bulkEditRound1Participants">
                <template v-slot:icon-leading>
                  <users-icon class="icon-20" />
                </template>
              </rebel-toolbar-button>
            </div>
            <information-circle-icon class="icon-20 color-action pointer"
              @click="round1CompetitorInfoModal.isShowing = true" />
          </header>
          <div v-if="round1CompetitorsList.length > 0 || trimmedRound1CompetitorsList.length > 0" class="form-input">
            <p class="heading4" v-if="round1CompetitorsList.length > 0 || trimmedRound1CompetitorsList.length > 0"
              data-testid="round1-competitor-count">Number of
              Round 1 Competitors: {{
              round1CompetitorsCount }}</p>
            <p class="caption color-danger" v-show="showPowerOf2ErrorMessage" data-testid="power-of-2-error">
              {{ powerOf2ErrorMessage }}
            </p>
            <p class="caption color-danger" v-show="showOneAsteriskPerPlayInMatch"
              data-testid="one-asterisk-per-play-in-match-error">
              There must be one asterisk for each play-in match winner.
            </p>
            <p class="caption color-danger" v-show="showPlayInCompetitorsCannotMatchUpError"
              data-testid="play-in-competitors-cannot-match-up-error">
              Play-in winners ({{ PLAY_IN_WINNER_PLACEHOLDER }}) cannot play against each other in Round 1. The app does
              not
              yet support this.
            </p>
          </div>

          <draggable v-if="round1CompetitorsEditMode === EDIT_MODE.INDIVIDUAL && round1CompetitorsList.length > 0"
            class="flex col gap-0-125" handle=".draggable" :list="round1CompetitorsList">
            <div class="competitor-wrapper" v-for="(r1Competitor, r1CompetitorIndex) in round1CompetitorsList"
              :key="r1CompetitorIndex">

              <div class="competitor flex grow gap-0-5">
                <div class="flex col justify-center">
                  <bars2-icon class="icon-20 gray2-5 draggable" />
                </div>
                <div class="flex grow gap-0-25">
                  <input class="rank-input" :class="{ invisible: isByeOrTbdOrPig(r1Competitor.name, false) }"
                    type="number" placeholder="Rank" maxlength="3" max="999" v-model="r1Competitor.rank">

                  <input type="text" placeholder="Enter name" maxlength="100" v-model="r1Competitor.name" required>
                </div>
                <div class="color-action flex col justify-center">
                  <x-mark-icon class="icon-20 pointer color-action"
                    @click="removeRound1CompetitorAtIndex(r1CompetitorIndex)" />
                </div>
              </div>

            </div>
          </draggable>

          <div v-if="round1CompetitorsEditMode === EDIT_MODE.INDIVIDUAL" class="flex">
            <div class="flex pointer color-action" @click="addRound1Competitor">
              <plus-icon class="icon-20" />
              <h3 class="heading3 color-action">
                Add {{ round1CompetitorsList.length > 0 ? 'Another' : '' }} Round 1 Competitor
              </h3>
            </div>
          </div>

          <div v-if="round1CompetitorsEditMode === EDIT_MODE.BULK">
            <textarea data-testid="round1-competitors-bulk" :rows="Math.max(round1CompetitorsListForBulkEdit.split('\n').length + 1, 2)"
              v-model="round1CompetitorsListForBulkEdit">
  </textarea>
          </div>
        </section>

        <section v-if="usePigTail" class="form-input gap-1">
          <header class="flex space-between align-center">
            <h2 class="heading2">Play-In Competitors</h2>
            <div class="rebelToolbarButtonContainer">
              <rebel-toolbar-button :isSelected="playInCompetitorsEditMode === EDIT_MODE.INDIVIDUAL"
                :isWithinButtonBar="true" @click="individualEditPlayInParticipants">
                <template v-slot:icon-leading>
                  <user-icon class="icon-20" />
                </template>
              </rebel-toolbar-button>
              <rebel-toolbar-button :isSelected="playInCompetitorsEditMode === EDIT_MODE.BULK" :isWithinButtonBar="true"
                @click="bulkEditPlayInParticipants">
                <template v-slot:icon-leading>
                  <users-icon class="icon-20" />
                </template>
              </rebel-toolbar-button>
            </div>
            <div style="width:20px;"></div>
          </header>

          <div v-if="playInCompetitorsList.length > 0 || trimmedPlayInCompetitorsList.length > 0" class="form-input">
            <p class="heading4" v-if="playInCompetitorsList.length > 0 || trimmedPlayInCompetitorsList.length > 0">
              Number of
              Play-In Competitors: {{
              playInCompetitorsCount
              }}</p>
            <p class="caption color-danger" v-show="showPlayInEvenCompetitorCountError">
              Play-In round must have an even number of competitors.
            </p>
          </div>

          <draggable v-if="playInCompetitorsEditMode === EDIT_MODE.INDIVIDUAL && playInCompetitorsList.length > 0"
            class="flex col gap-0-125" handle=".draggable" :list="playInCompetitorsList">
            <div class="competitor-wrapper" v-for="(playInCompetitor, playInCompetitorIndex) in playInCompetitorsList"
              :key="playInCompetitorIndex">

              <div class="competitor flex grow gap-0-5">
                <div class="flex col justify-center">
                  <bars2-icon class="icon-20 gray2-5 draggable" />
                </div>
                <div class="flex grow gap-0-25">
                  <input class="rank-input" type="number" placeholder="Rank" maxlength="3" max="999"
                    v-model="playInCompetitor.rank">
                  <input type="text" placeholder="Enter name" maxlength="100" v-model="playInCompetitor.name" required>
                </div>
                <div class="color-action flex col justify-center">
                  <x-mark-icon class="icon-20 pointer color-action"
                    @click="removePlayInCompetitorAtIndex(playInCompetitorIndex)" />
                </div>
              </div>
            </div>
          </draggable>

          <div v-if="playInCompetitorsEditMode === EDIT_MODE.INDIVIDUAL" class="flex">
            <div class="flex pointer color-action" @click="addPlayInCompetitor">
              <plus-icon class="icon-20" />
              <h3 class="heading3 color-action">
                Add {{ playInCompetitorsList.length > 0 ? 'Another' : '' }} Play-In Competitor
              </h3>
            </div>
          </div>

          <div v-if="playInCompetitorsEditMode === EDIT_MODE.BULK">
            <textarea :rows="Math.max(playInCompetitorsListForBulkEdit.split('\n').length + 1, 2)"
              v-model="playInCompetitorsListForBulkEdit">
    </textarea>
          </div>
        </section>

        <section class="form-input gap-1">
          <header class="flex">
            <h2 class="heading2">Incumbent Competitor</h2>
          </header>

          <div v-if="showIncumbentSection">
            <label for="incumbent-competitor">Incumbent Competitor</label>
            <div class="flex gap-0-5 align-center">
              <div class="flex grow gap-0-25">
                <input class="rank-input" type="number" placeholder="Rank" maxlength="3" max="999"
                  v-model="incumbentCompetitor.rank">
                <input id="incumbent-competitor" type="text" placeholder="Enter name" v-model="incumbentCompetitor.name">
              </div>
              <div class="color-action flex col justify-center">
                <x-mark-icon class="icon-20 pointer color-action"
                  @click="incumbentCompetitor = {}; showIncumbentSection = false" />
              </div>
            </div>
          </div>

          <div class="flex" v-if="!showIncumbentSection">
            <div class="flex pointer color-action" @click="showIncumbentSection = true">
              <plus-icon class="icon-20" />
              <h3 class="heading3 color-action">
                Add Incumbent Competitor
              </h3>
            </div>
          </div>
        </section>
      </div>

      <round1-competitors-info-modal v-if="round1CompetitorInfoModal.isShowing"
        :playInAndByeHelperMessage="playInAndByeHelperMessage" @close="round1CompetitorInfoModal.isShowing = false" />
    </template>

    <template v-if="reSeedWeightClassModal.isShowing" v-slot:modal-actions>
      <rebel-button type="primary" color="danger" text="Re-Seed Weight Class" :is-loading="isSavingCompetitors"
        @click="confirmReSeed"></rebel-button>
      <rebel-button v-if="editResult == EDIT_COMPETITORS_RESULT.SAVE_WITHOUT_RE_SEED" type="ghosted" color="default"
        text="Save without re-seeding" :is-loading="isSavingCompetitors" @click="confirmSaveWithoutReSeed"></rebel-button>
      <a @click.prevent="cancelReSeed">Cancel</a>
      <p class="color-danger" v-if="errorMessages.length">
        {{ errorMessages }}
      </p>
    </template>
    <template v-else v-slot:modal-actions>
      <p class="caption color-danger" v-show="hasDuplicateCompetitors" data-testid="all-competitors-must-be-unique-error">
        All competitors (excluding BYEs, Play-in winners, and 🐷 PIG) must be unique
      </p>
      <rebel-button button-type="submit" type="primary" color="default"
        :text="isEditCompetitorsMode ? 'Edit Bracket' : 'Create Bracket'" :is-loading="isSavingCompetitors"
        :disabled="!dataIsDirty || !isValidBracket"></rebel-button>
      <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
      <p class="color-danger" v-if="errorMessages.length">
        {{ errorMessages }}
      </p>
    </template>
  </modal-dialog>
</template>

<script>
import APP_SETTINGS from '@/appSettings.js'
import emitEvents from '@/utils/emitEvents'
import eventBracketService from '@/services/EventBracketService.js'
import eventTypes from '@/utils/eventTypes'

import { Bars2Icon, InformationCircleIcon, PlusIcon, UserIcon, UsersIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'
import RebelToolbarButton from '@/components/RebelToolbarButton.vue'
import Round1CompetitorsInfoModal from './Round1CompetitorsInfoModal.vue'
import { VueDraggableNext } from 'vue-draggable-next'

const EDIT_COMPETITORS_RESULT = {
  RE_SEED: 1,
  SAVE_QUIETLY: 2,
  SAVE_WITHOUT_RE_SEED: 3
}

const EDIT_MODE = {
  BULK: 1,
  INDIVIDUAL: 2
}

export default {
  setup() {
    return {
      EDIT_COMPETITORS_RESULT,
      EDIT_MODE
    }
  },

  components: {
    Bars2Icon,
    InformationCircleIcon,
    ModalDialog,
    PlusIcon,
    RebelButton,
    RebelToolbarButton,
    Round1CompetitorsInfoModal,
    UserIcon,
    UsersIcon,
    XMarkIcon,
    draggable: VueDraggableNext
  },

  computed: {
    dataIsDirty() {
      const playInCompetitorsEdited = JSON.stringify(this.trimmedPlayInCompetitorsList)
      const round1CompetitorsEdited = JSON.stringify(this.trimmedRound1CompetitorsList)

      return (
        this.initialPlayInCompetitorsListJSON != playInCompetitorsEdited ||
        this.initialRound1CompetitorsListJSON != round1CompetitorsEdited ||
        this.initialIncumbentCompetitorJSON != this.makeCompetitorJSON(this.incumbentCompetitor)
      )
    },

    eventTypeLabel() {
      return eventTypes.getEventTypeLabel(this.eventTypeId)
    },

    eventDateTimeLabel() {
      if (eventTypes.isPopularVoteTournament(this.eventTypeId)) return 'Voting End Date and Time'

      return 'Start Date and Time'
    },

    hasDuplicateCompetitors() {
      const allCompetitors = [this.trimmedPlayInCompetitorsList, this.trimmedRound1CompetitorsList, this.incumbentCompetitor]

      const mapCompetitorToString = (competitor) => {
        if (competitor == null) return ''

        return (competitor.rank ?? '') + (competitor.name ?? '')
      }

      const allCompetitorStrings = allCompetitors.flat().map(mapCompetitorToString).filter(competitor => !this.isByeOrTbdOrPig(competitor, true))

      const competitorSet = new Set()
      allCompetitorStrings.forEach(competitor => competitorSet.add(competitor))

      return competitorSet.size < allCompetitorStrings.length
    },

    isEditCompetitorsMode() {
      return this.bracketId != null
    },

    isValidBracket() {
      const errorsArray = [
        this.showPlayInEvenCompetitorCountError,
        this.showPowerOf2ErrorMessage,
        this.showOneAsteriskPerPlayInMatch,
        this.showPlayInCompetitorsCannotMatchUpError,
        this.hasDuplicateCompetitors
      ]

      return errorsArray.every(isErrorShowing => isErrorShowing === false)
    },

    playInAndByeHelperMessage() {
      if (this.usePigTail) {
        return 'Use an asterisk (' + this.PLAY_IN_WINNER_PLACEHOLDER + ') to indicate the position of each play-in match winner. Use ' + this.BYE_PLACEHOLDER + ' in place of an opponent\'s name when a competitor has a bye.'
      }

      return 'Use ' + this.BYE_PLACEHOLDER + ' in place of an opponent\'s name when a competitor has a bye.'
    },

    powerOf2ErrorMessage() {
      if (this.usePigTail) {
        return 'The total number of Round 1 competitors (plus BYEs and Play-in winners) must be an exact power of 2.'
      }

      return 'The total number of Round 1 competitors (plus BYEs) must be an exact power of 2.'
    },

    showOneAsteriskPerPlayInMatch() {
      if (!this.usePigTail) return false

      return this.totalPlayInWinnersCount !== this.playInCompetitorsCount / 2
    },

    showPlayInCompetitorsCannotMatchUpError() {
      if (!this.usePigTail) return false

      const arr = this.trimmedRound1CompetitorsList

      for (let i = 1; i < arr.length; i += 2) {
        const competitor1 = arr[i - 1]
        const competitor2 = arr[i]
        if (competitor1.name === this.PLAY_IN_WINNER_PLACEHOLDER && competitor2.name === this.PLAY_IN_WINNER_PLACEHOLDER) {
          return true
        }
      }

      return false
    },

    showPlayInEvenCompetitorCountError() {
      return this.playInCompetitorsCount % 2 !== 0
    },

    showPowerOf2ErrorMessage() {
      const totalRound1Competitors = this.round1CompetitorsCount + this.totalPlayInWinnersCount
      if (totalRound1Competitors === 1) return true

      return !this.isPowerOfTwo(totalRound1Competitors)
    },

    playInCompetitorsCount() {
      return this.trimmedPlayInCompetitorsList.length
    },

    round1CompetitorsCount() {
      return this.trimmedRound1CompetitorsList.filter(
        (competitor) => !this.usePigTail || competitor.name !== this.PLAY_IN_WINNER_PLACEHOLDER
      ).length
    },

    totalCompetitorsCount() {
      return this.playInCompetitorsCount + this.round1CompetitorsCount
    },

    totalPlayInWinnersCount() {
      return this.trimmedRound1CompetitorsList.filter(
        (competitor) => this.usePigTail && competitor.name === this.PLAY_IN_WINNER_PLACEHOLDER
      ).length
    },

    trimmedRound1CompetitorsList() {
      let competitorList = this.round1CompetitorsList

      if (this.round1CompetitorsEditMode === EDIT_MODE.BULK) {
        competitorList = this.getListForIndividualEdit(this.round1CompetitorsListForBulkEdit)
      }

      return this.trimCompetitorList(competitorList)
    },

    trimmedPlayInCompetitorsList() {
      let competitorList = this.playInCompetitorsList

      if (this.playInCompetitorsEditMode === EDIT_MODE.BULK) {
        competitorList = this.getListForIndividualEdit(this.playInCompetitorsListForBulkEdit)
      }

      return this.trimCompetitorList(competitorList)
    },
  },

  props: {
    bracketId: {
      required: false,
    },

    eventId: {
      required: true
    },

    eventIsPublished: {
      type: Boolean,
      required: true
    },

    eventTypeId: {
      required: true
    },

    eventName: {
      type: String,
      required: true
    },

    eventStartDateTime: {
      type: String,
      required: true
    },

    incumbentCompetitorData: {
      type: Object,
      required: false,
      default: () => { }
    },

    playInCompetitorsData: {
      type: Array,
      required: false,
      default: () => []
    },

    round1CompetitorsData: {
      type: Array,
      required: false,
      default: () => []
    },

    weightClass: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      editResult: EDIT_COMPETITORS_RESULT.SAVE_QUIETLY,

      errorMessages: '',

      initialPlayInCompetitorsListJSON: JSON.stringify(this.playInCompetitorsData),
      initialRound1CompetitorsListJSON: JSON.stringify(this.round1CompetitorsData),
      initialIncumbentCompetitorJSON: this.makeCompetitorJSON(this.incumbentCompetitorData),

      reSeedWeightClassModal: {
        isShowing: false
      },

      PLAY_IN_WINNER_PLACEHOLDER: '*',
      BYE_PLACEHOLDER: 'BYE',
      PIG_PLACEHOLDER: 'PIG',
      PIG_PLACEHOLDER_WITH_EMOJI: '🐷 PIG',
      isSavingCompetitors: false,

      incumbentCompetitor: { ...this.incumbentCompetitorData } ?? {},
      round1CompetitorsList: this.round1CompetitorsData != null ? JSON.parse(JSON.stringify(this.round1CompetitorsData)) : [],
      playInCompetitorsList: this.playInCompetitorsData != null ? JSON.parse(JSON.stringify(this.playInCompetitorsData)) : [],

      usePigTail: APP_SETTINGS.USE_PIG_TAIL,

      round1CompetitorInfoModal: {
        isShowing: false
      },

      showIncumbentSection: this.incumbentCompetitorData != null && this.incumbentCompetitorData.name?.length > 0,

      round1CompetitorsEditMode: EDIT_MODE.INDIVIDUAL,
      round1CompetitorsListForBulkEdit: '',
      playInCompetitorsEditMode: EDIT_MODE.INDIVIDUAL,
      playInCompetitorsListForBulkEdit: ''
    }
  },

  emits: [emitEvents.ADD_COMPETITORS, emitEvents.EDIT_COMPETITORS],

  methods: {
    getListForBulkEdit(listForIndividualEdit) {
      return listForIndividualEdit.map(c => {
        if (c.rank != null) {
          return `(${c.rank}) ${c.name}`
        }

        return c.name
      }).join('\n')
    },

    getListForIndividualEdit(listForBulkEdit) {
      return listForBulkEdit.split('\n')
        .filter(str => str.trim().length > 0)
        .map(str => {
          let rank = undefined
          let name = str.trim()

          const rankInParenthesesRegEx = /^\([0-9]+\)\s+/
          const rankWithoutParenthesesRegEx = /^[0-9]+\s+/
          const rankInParenthesesMatches = name.match(rankInParenthesesRegEx)
          const rankWithoutParenthesesMatches = name.match(rankWithoutParenthesesRegEx)
          if (rankInParenthesesMatches != null) {
            rank = rankInParenthesesMatches[0].trim().replaceAll(/\(|\)/g, '')
            name = name.replace(rankInParenthesesRegEx, '')
          } else if (rankWithoutParenthesesMatches != null) {
            rank = rankWithoutParenthesesMatches[0].trim()
            name = name.replace(rankWithoutParenthesesMatches, '')
          }

          return {
            rank,
            name
          }
        })
    },

    bulkEditRound1Participants() {
      if (this.round1CompetitorsEditMode === EDIT_MODE.BULK) return

      this.round1CompetitorsEditMode = EDIT_MODE.BULK
      this.round1CompetitorsListForBulkEdit = this.getListForBulkEdit(this.round1CompetitorsList)
    },

    individualEditRound1Participants() {
      if (this.round1CompetitorsEditMode === EDIT_MODE.INDIVIDUAL) return

      this.round1CompetitorsEditMode = EDIT_MODE.INDIVIDUAL
      this.round1CompetitorsList = this.getListForIndividualEdit(this.round1CompetitorsListForBulkEdit)
    },

    bulkEditPlayInParticipants() {
      if (this.playInCompetitorsEditMode === EDIT_MODE.BULK) return

      this.playInCompetitorsEditMode = EDIT_MODE.BULK
      this.playInCompetitorsListForBulkEdit = this.getListForBulkEdit(this.playInCompetitorsList)
    },

    individualEditPlayInParticipants() {
      if (this.playInCompetitorsEditMode === EDIT_MODE.INDIVIDUAL) return

      this.playInCompetitorsEditMode = EDIT_MODE.INDIVIDUAL
      this.playInCompetitorsList = this.getListForIndividualEdit(this.playInCompetitorsListForBulkEdit)
    },

    isByeOrTbdOrPig(competitorName, includeBlank = false) {
      if (competitorName == null) return includeBlank

      const validValues = [this.PLAY_IN_WINNER_PLACEHOLDER, this.BYE_PLACEHOLDER, this.PIG_PLACEHOLDER, this.PIG_PLACEHOLDER_WITH_EMOJI]

      if (includeBlank) {
        validValues.push('')
      }

      return validValues.map(entry => entry.toLowerCase()).includes(competitorName.toLowerCase())
    },

    addRound1Competitor() {
      this.round1CompetitorsList.push({})
    },
    removeRound1CompetitorAtIndex(index) {
      this.round1CompetitorsList.splice(index, 1)
    },

    addPlayInCompetitor() {
      this.playInCompetitorsList.push({})
    },
    removePlayInCompetitorAtIndex(index) {
      this.playInCompetitorsList.splice(index, 1)
    },

    cancel() {
      this.errorMessages = ''
      this.closeModal()
    },

    cancelReSeed() {
      this.errorMessages = ''
      this.reSeedWeightClassModal.isShowing = false
    },

    closeModal() {
      this.errorMessages = ''
      this.$refs.myModal.close()
    },

    confirmReSeed() {
      this.createOrEditBracket(false)
    },

    confirmSaveWithoutReSeed() {
      this.createOrEditBracket(true)
    },

    async createOrEditBracket(forceUpdateWithoutReSeed = false) {
      this.errorMessages = ''

      if (!this.isValidBracket) return

      this.isSavingCompetitors = true
      try {
        const dto = this.createPayload(forceUpdateWithoutReSeed)

        if (this.isEditCompetitorsMode) {
          await eventBracketService.update(this.eventId, this.bracketId, dto)
          this.$emit(emitEvents.EDIT_COMPETITORS, dto.weightClass)
        } else {
          await eventBracketService.create(this.eventId, dto)
          this.$emit(emitEvents.ADD_COMPETITORS, dto.weightClass)
        }

      } catch {
        alert('Something went wrong')
      } finally {
        this.isSavingCompetitors = false
      }
    },

    createPayload(forceUpdateWithoutReSeed = false) {
      const playInCompetitors = this.trimmedPlayInCompetitorsList
      const round1Competitors = this.trimmedRound1CompetitorsList
      const participantCount = this.totalCompetitorsCount

      return {
        bracketId: this.bracketId,
        eventId: this.eventId,
        weightClass: String(this.weightClass.split(' ')[0]),
        participantCount: participantCount,
        playInCompetitors,
        round1Competitors: this.replaceWithPigEmoji(round1Competitors),
        incumbentCompetitor: this.incumbentCompetitor?.name ? this.incumbentCompetitor : null,
        updateWithoutReSeed: forceUpdateWithoutReSeed
      }
    },

    isPowerOfTwo(x) {
      return Math.log2(x) % 1 === 0
    },

    makeCompetitorJSON(competitor) {
      const obj = {
        name: competitor?.name ?? '',
        rank: competitor?.rank ?? '',
      }

      return JSON.stringify(obj)
    },

    replaceWithPigEmoji(competitorsArr) {
      return competitorsArr.map((competitor) => {
        if (competitor != null && typeof competitor.name === 'string' && competitor.name.toUpperCase() === this.PIG_PLACEHOLDER) {
          return this.PIG_PLACEHOLDER_WITH_EMOJI
        }
        return competitor
      })
    },

    trimCompetitorList(competitors) {
      return competitors.filter(c => c.name?.trim().length > 0).map(c => {
        const isCompetitorByeOrTbd = [this.PLAY_IN_WINNER_PLACEHOLDER, this.BYE_PLACEHOLDER].includes(c.name)
        if (isCompetitorByeOrTbd) {
          c.rank = undefined
        }
        return c
      })
    },

    async tryCreateOrEditBracket() {
      if (this.isEditCompetitorsMode) {
        this.editResult = EDIT_COMPETITORS_RESULT.SAVE_QUIETLY

        if (this.eventIsPublished) {
          if (!this.isValidBracket) return

          try {
            this.editResult = (await eventBracketService.characterizeEditBeforeUpdate(this.eventId, this.bracketId, this.createPayload())).data
          } catch {
            alert('Something went wrong')
            return
          }
        }


        if (this.editResult != EDIT_COMPETITORS_RESULT.SAVE_QUIETLY) {
          this.reSeedWeightClassModal.isShowing = true
          return
        }
      }

      this.createOrEditBracket(false)
    },
  }
}
</script>

<style scoped>

input[type="text"],
input[type="number"] {
  font-size: .83rem;
}

.draggable {
  cursor: grab;
}

.competitor-wrapper:nth-of-type(even) .competitor {
  margin-bottom: .5rem;
}

.rank-input {
  text-align: right;
  width: 70px;
}

.invisible {
  visibility: hidden;
}
</style>
