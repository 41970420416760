<template>
  <div v-if="isMobile" class="entry-card-layout mobile-layout" :class="getBackgroundColor"
    :data-testid="dataTestId">
    <div class="layout-event-card-flex-gap width-100">
      <div class="layout-event-card-first-col grow">
        <slot name="entry-title"></slot>
      </div>
      <slot name="avatars"></slot>
    </div>
    <slot name="entry-data"></slot>
  </div>

  <div v-else class="entry-card-layout layout-event-card-flex-gap" :class="getBackgroundColor"
    :data-testid="dataTestId">
    <div class="layout-event-card-flex-gap grow">
      <div class="layout-event-card-first-col">
        <slot name="entry-title"></slot>
      </div>
      <slot name="entry-data"></slot>
    </div>
    <slot name="avatars"></slot>
  </div>
</template>

<script>
export default {
  props: {
    dataTestId: {
      type: String,
      required: false,
      default: ''
    },

    disqualified: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    getBackgroundColor() {
      return this.disqualified ? 'bg-gray1' : 'bg-white'
    },

    getDesktopClasses() {
      return this.isWideDesktop ? 'wide-desktop-layout' : ''
    },

    isMobile() {
      return this.windowWidth <= this.mobileBreakpoint
    }
  },

  data() {
    return {
      mobileBreakpoint: 512,
      windowWidth: window.innerWidth
    }
  },

  mounted() {
    if (this.$slots.avatars) {
      this.mobileBreakpoint = 640
    }
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style scoped>
.entry-card-layout {
  border-radius: 2px;
  padding: 0.5rem 1.25rem;
}

.mobile-layout {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>