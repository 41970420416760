<template>
  <div class="mb-0-75 flex align-center" :class="{ 'gap-0-75': prefixLabel || suffixLabel }">
    <span v-if="prefixLabel != null" class="caption">{{ prefixLabel }}</span>
    <Slider class="grow" v-model="sliderModelValue" :min="min" :max="max" showTooltip="always"
      tooltipPosition="bottom" :format="format" />
    <span v-if="suffixLabel != null" class="caption">{{ suffixLabel }}</span>
  </div>
</template>

<script>
import Slider from '@vueform/slider'

export default {
  components: {
    Slider
  },

  emits: ['update:modelValue'],

  props: {
    format: {
      type: Function,
      required: false
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    modelValue: {

    },
    prefixLabel: {
      type: String,
      required: false
    },
    suffixLabel: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      sliderModelValue: this.modelValue,

      options: {}
    }
  },

  watch: {
    sliderModelValue(curr) {
      this.$emit('update:modelValue', curr)
    }
  }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style>
:root {
  --slider-bg: var(--color-gray1);
  --slider-connect-bg: var(--color-action);
  --slider-height: .375rem;

  --slider-handle-bg: var(--color-white);
  --slider-handle-ring-width: 1px;
  --slider-handle-ring-color: var(--color-gray1);
  --slider-handle-width: .675rem;
  --slider-handle-height: .675rem;

  --slider-tooltip-font-size: 0.75rem;
  --slider-tooltip-font-weight: 400;
  --slider-tooltip-bg: transparent;
  --slider-tooltip-color: var(--color-gray4);
  --slider-tooltip-py: 0px;
  --slider-tooltip-px: 0px;
  --slider-tooltip-arrow-size: 0px;
  --slider-tooltip-distance: 0px;
}

</style>
