import { defineStore } from 'pinia'

export const useSharedModalsStore = defineStore('sharedModals', {
  state: () => ({
    pigModal: {
      isShowing: false
    },
  }),

  actions: {
    setPigModalShowing(isShowing) {
      this.pigModal.isShowing = isShowing
    },
  }
})
