<template>
  <div v-if="isRedirectingToOpenTournament" :class="{ 'white': darkBackground }">
    The Open Tournament starting... Hang on for a few seconds and we will redirect you to your Mat.
  </div>
  <div class="flex col gap-1-5" v-else-if="!isLoading">
    <div v-if="adminViewingAnotherUsersData">
      <h3 class="heading3" :class="{ 'dark-background': darkBackground }">
        Viewing as: {{ myLeagueMember?.user?.displayName }}
      </h3>
    </div>

    <div>
      <header-action-bar v-if="adminViewingAnotherUsersData" :breakpointOuter="768" :breakpointMid="512"
        :breakpointInner="432" :dark-background="darkBackground">
        <template v-slot:linkBackTo>
          <link-back-to :router-link-to="{ name: 'admin-dashboard' }" page-name="Admin Dashboard"
            :dark-background="darkBackground" />
        </template>

        <template v-if="entryHistoryMetaData != null && entryHistoryMetaData.length" v-slot:primaryAction1>
          <rebel-select :key="selectedEntryHistoryOption" :dark-background="darkBackground"
            :selectedValue="selectedEntryHistoryOption" :selectorOptions="entryHistoryOptions"
            @selected-value-changed="updateSelectedEntryHistoryOption" />
        </template>

        <template v-if="league.event.infoUrl" v-slot:secondaryAction1>
          <rebel-button :dark-background="darkBackground" @click="goToEventInfo" type="ghosted" color="default"
            text="Event Info" class="nowrap">
            <template v-slot:icon-leading>
              <arrow-top-right-on-square-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>

        <template v-if="entryHistoryMetaData != null && entryHistoryMetaData.length" v-slot:secondaryAction2>
          <rebel-button :dark-background="darkBackground" @click="entryVersionHistoryModal.isShowing = true"
            type="ghosted" color="default" text="View All Version History" class="nowrap" />
        </template>
      </header-action-bar>
      <header-action-bar v-else :breakpointOuter="768" :breakpointMid="512" :breakpointInner="432"
        :dark-background="darkBackground">
        <template v-slot:linkBackTo>
          <link-back-to :darkBackground="darkBackground"
            :router-link-to="{ name: 'league-details', params: { leagueId: league.leagueId }, query: { userId: $route.query?.userId } }"
            :page-name="league.name" />
        </template>

        <template v-slot:primaryAction1>
          <rebel-button :is-loading="isSaving" :dark-background="darkBackground" :disabled="!hasUnsavedChanges"
            @click="save" type="primary" color="default" text="Save Changes" class="nowrap">
            <template v-if="hasUnsavedChanges" v-slot:icon-trailing>
              <rebel-notification-count-bubble :value="countOfUnsavedChanges" :dark-background="darkBackground"
                primaryColor="white" />
            </template>
          </rebel-button>
        </template>

        <template v-if="league.event.infoUrl" v-slot:secondaryAction1>
          <rebel-button :dark-background="darkBackground" @click="goToEventInfo" type="ghosted" color="default"
            text="Event Info" class="nowrap">
            <template v-slot:icon-leading>
              <arrow-top-right-on-square-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>

        <template v-if="canCopyPicks" v-slot:secondaryAction2>
          <rebel-button :dark-background="darkBackground" @click="copyPicksModal.isShowing = true" type="ghosted"
            color="default" text="Copy Picks" class="nowrap">
            <template v-slot:icon-leading>
              <clipboard-document-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>
      </header-action-bar>
    </div>

    <pick-em-header :dark-background="darkBackground" :exempt-from-payment="myLeagueMember.exemptFromPayment" :is-paid-if-necessary="myLeagueMember.paidIfNecessary"
      :sub-text="eventSubText" :show-admin-data="false" :show-league-data="true" :event="league.event"
      :league-data="pickEmHeaderLeagueData" :leagues="allLeagueOptions" :league-id="league.leagueId"
      @league-changed="leagueChanged" @time-expires="countdownTimeExpires" @time-running-out="countdownTimerRunningOut"
      :key="`header-${lastLoadedTime}`" />

    <payment-warning v-if="league.event.acceptingPayments && !myLeagueMember.paidIfNecessary"
      :dark-background="darkBackground" :event-id="league.event.eventId" :league-id="league.leagueId"
      :payment-data="league.event.paymentData" :user-has-entry="myLeagueMember.paidForAtLeastOneEntry"
      :buy-entry-callback="buyEntryClicked" />

    <pick-em-matches :dark-background="darkBackground" who-is-editing="user" :event-data="league.event"
      :user-projections="myLeagueMember.pickEmUserMatchups" @matchup-changed="matchupChanged"
      :key="`matches-${lastLoadedTime}`" />

    <pick-em-reseeded-modal v-if="pickEmReseededModal.isShowing" @close="pickEmReseededModalClosed" />

    <copy-picks-modal v-if="copyPicksModal.isShowing" :leagues="allLeagueOptions"
      :weightClasses="league.event.weightClasses" @copy-picks-complete="copyPicksComplete"
      @close="copyPicksModal.isShowing = false" />

    <event-starting-soon-modal v-if="eventStartingSoonModal.isShowing" :has-unsaved-changes="hasUnsavedChanges"
      @save-changes="saveChanges" @discard-changes="discardChanges" @close="eventStartingSoonModal.isShowing = false" />

    <save-changes-modal v-if="saveChangesModal.isShowing" @save-changes="saveChanges" @discard-changes="discardChanges"
      @close="closeSaveChangesModal" />

    <entry-version-history v-if="entryVersionHistoryModal.isShowing" :event-id="league.event.eventId"
      :league-id="league.leagueId" :user-id="userId" @close="entryVersionHistoryModal.isShowing = false"
      @view-history-entry="updateSelectedEntryHistoryOption" />
  </div>
</template>

<script>
import { ArrowTopRightOnSquareIcon, ClipboardDocumentIcon } from '@heroicons/vue/20/solid'
import CopyPicksModal from '@/components/leagues/CopyPicksModal.vue'
import EntryVersionHistory from '@/components/EntryVersionHistory.vue'
import EventStartingSoonModal from '@/components/leagues/EventStartingSoonModal.vue'
import HeaderActionBar from '@/components/HeaderActionBar.vue'
import LinkBackTo from '@/components/LinkBackTo.vue'
import PaymentWarning from '@/components/PaymentWarning.vue'
import PickEmHeader from '@/components/feature/pick-em/header/PickEmHeader.vue'
import PickEmMatches from '@/components/feature/pick-em/PickEmMatches.vue'
import PickEmReseededModal from '@/components/feature/pick-em/PickEmReseededModal.vue'
import RebelButton from '@/components/RebelButton.vue'
import RebelSelect from '@/components/RebelSelect.vue'
import RebelNotificationCountBubble from '@/components/RebelNotificationCountBubble.vue'
import SaveChangesModal from '@/components/leagues/SaveChangesModal.vue'

import leagueService from '@/services/LeagueService'
import entryHistoryService from '@/services/EntryHistoryService'
import pickEmService from '@/services/PickEmService'

import { useAuthenticationStore } from '@/stores/authentication'
import { useEventAdminStore } from '@/stores/eventAdmin'
import DateUtils from '@/utils/dateUtils'
import PubSub from 'pubsub-js'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      eventAdminStore: useEventAdminStore()
    }
  },

  components: {
    ArrowTopRightOnSquareIcon,
    ClipboardDocumentIcon,
    CopyPicksModal,
    EntryVersionHistory,
    EventStartingSoonModal,
    HeaderActionBar,
    LinkBackTo,
    PaymentWarning,
    PickEmHeader,
    PickEmMatches,
    PickEmReseededModal,
    RebelButton,
    RebelSelect,
    RebelNotificationCountBubble,
    SaveChangesModal
  },

  computed: {
    viewingHistoricalEntry() {
      if (this.entryHistoryOptions.length === 0) return false

      // if we are not viewing the top-most entry, we are viewing a historical entry
      return this.entryHistoryOptions[0].value != this.selectedEntryHistoryOption
    },

    entryHistoryOptions() {
      return this.entryHistoryMetaData.map(metaData => {
        return {
          text: this.formatDateTimeWithSeconds(metaData.createdAt),
          value: metaData.entryId
        }
      })
    },

    adminViewingAnotherUsersData() {
      return this.$route.query?.userId != null && this.authStore.isDataAdmin
    },

    userId() {
      if (this.adminViewingAnotherUsersData) {
        return this.$route.query.userId
      }

      return this.authStore.loggedInUser?.user?.id
    },

    canCopyPicks() {
      return this.allLeagueOptions.length > 1
    },

    countOfUnsavedChanges() {
      if (!this.myLeagueMember || !this.pickEmUserMatchupsInitialState) return 0

      let count = 0;

      for (let matchupIndex = 0; matchupIndex < this.myLeagueMember.pickEmUserMatchups.length; matchupIndex++) {
        const initialMatchup = this.pickEmUserMatchupsInitialState[matchupIndex]
        const currMatchup = this.myLeagueMember.pickEmUserMatchups[matchupIndex]

        if (initialMatchup.projectedWinnerParticipantId != currMatchup.projectedWinnerParticipantId) count++
        if (initialMatchup.projectedMatchOutcomeOptionId != currMatchup.projectedMatchOutcomeOptionId) count++
      }

      return count
    },

    eventSubText() {
      return this.formatDateTime(this.league.event.startDateTime)
    },

    hasUnsavedChanges() {
      if (this.adminViewingAnotherUsersData) return false

      return this.countOfUnsavedChanges > 0
    },

    leagueId() {
      return this.$route.params.leagueId
    },

    myLeagueMember() {
      return this.league?.members?.find(member => member.user.id == this.userId)
    },

    pickEmHeaderLeagueData() {
      if (this.viewingHistoricalEntry) {
        return {
          leagueOptions: this.allLeagueOptions,
          pointsWon: '--',
          pointsPossible: '--',
          leagueRank: '-',
          leagueMemberCount: this.league?.members?.filter(m => m.hasAtLeastOnePrediction).length ?? 0,
          matnessRank: '-',
          matnessMemberCount: this.league?.event?.leagueMemberCount ?? 0,
          predictedTeam1Score: '--',
          predictedTeam2Score: '--',
        }
      }

      return {
        leagueOptions: this.allLeagueOptions,
        pointsWon: this.myLeagueMember?.leagueScore,
        pointsPossible: this.myLeagueMember?.pointsPossible,
        leagueRank: this.myLeagueMember?.leagueRankDisplay,
        leagueMemberCount: this.league.members.filter(m => m.hasAtLeastOnePrediction).length,
        matnessRank: this.myLeagueMember?.overallRankDisplay,
        matnessMemberCount: this.league.event.leagueMemberCount,
        predictedTeam1Score: this.myLeagueMember?.predictedTeam1Score,
        predictedTeam2Score: this.myLeagueMember?.predictedTeam2Score,
      }
    }
  },

  async created() {
    await this.loadData()
    this.isLoading = false
  },

  data() {
    return {
      entryVersionHistoryModal: {
        isShowing: false
      },
      selectedEntryHistoryOption: -1,
      entryHistoryMetaData: [],

      copyPicksModal: {
        isShowing: false
      },
      eventStartingSoonModal: {
        isShowing: false
      },
      pickEmReseededModal: {
        isShowing: false
      },
      saveChangesModal: {
        isShowing: false
      },

      isLoading: true,
      isRedirectingToOpenTournament: false,
      isSaving: false,

      allLeagueOptions: [],
      league: {},

      pickEmUserMatchupsInitialState: null,

      darkBackground: this.$route.meta.darkBackground,

      lastLoadedTime: new Date().getMilliseconds(),
    }
  },

  methods: {
    async buyEntryClicked() {
      if (this.hasUnsavedChanges) await this.save()
    },

    async getEntryHistoryMetaData() {
      if (!this.adminViewingAnotherUsersData) return

      this.entryHistoryMetaData = []
      const currentUserEntryHistoryMetaData = await entryHistoryService.get(this.league.event.eventId, this.league.leagueId, this.userId)
      this.entryHistoryMetaData = currentUserEntryHistoryMetaData.data.entryHistoryMetaData

      if (this.entryHistoryMetaData == null || this.entryHistoryMetaData.length == 0) return

      this.selectedEntryHistoryOption = this.entryHistoryMetaData[0].entryId
      this.entryHistoryMetaData[0].pickEmUserMatchups = this.myLeagueMember.pickEmUserMatchups
      this.entryHistoryMetaData[0].groups = this.league.event.groups
    },

    async updateSelectedEntryHistoryOption(entryId) {
      this.selectedEntryHistoryOption = entryId

      const metaDataEntry = this.entryHistoryMetaData.find(md => md.entryId == entryId)
      // load data if we don't already have it 
      if (metaDataEntry.pickEmUserMatchups == null) {
        const getPickEmUserMatchupsRes = await entryHistoryService.getForPickEm(entryId)
        metaDataEntry.pickEmUserMatchups = getPickEmUserMatchupsRes.data.pickEmUserMatchups
        if (getPickEmUserMatchupsRes.data.groups != null && getPickEmUserMatchupsRes.data.groups.length) {
          metaDataEntry.groups = getPickEmUserMatchupsRes.data.groups
        } else {
          metaDataEntry.groups = this.entryHistoryMetaData[0].groups // if there are no groups then use the most recent ones
        }
      }

      this.myLeagueMember.pickEmUserMatchups = metaDataEntry.pickEmUserMatchups
      this.league.event.groups = metaDataEntry.groups
      this.lastLoadedTime = new Date().getMilliseconds()
    },

    pickEmReseeded(topicName, message) {
      const isMyEvent = message.eventId != null && message.eventId == this.league.event.eventId
      if (!isMyEvent) return

      this.pickEmReseededModal.isShowing = true
    },

    pickEmReseededModalClosed() {
      location.reload()
    },

    changeLeagueName(topic, message) {
      const isMyLeague = message.leagueId != null && this.leagueId == message.leagueId

      if (isMyLeague && message.name != null) {
        this.league.name = message.name
      }
    },

    async copyPicksComplete() {
      await this.loadData()
    },

    countdownTimerRunningOut() {
      this.eventStartingSoonModal.isShowing = true
    },

    countdownTimeExpires() {
      this.timeHasExpired = true

      if (!this.league.openTournamentLeague) {
        setTimeout(async () => {
          await this.$router.push({ name: 'league-pick-em-view', params: { leagueId: this.leagueId }, query: this.$route.query })
        }, 3000)

        return
      }

      if (this.league.openTournamentLeague) {
        this.isRedirectingToOpenTournament = true

        setTimeout(async () => {
          await this.redirectToOpenTournament()
        }, 10000) // wait for a little while because the server needs to finish creating the league
      }
    },

    goToEventInfo() {
      window.open(this.league.event.infoUrl, '_blank')
    },

    async goToPickEmViewPage(leagueIdOverride) {
      await this.$router.push({ name: 'league-pick-em-view', params: { leagueId: leagueIdOverride ?? this.leagueId }, query: this.$route.query })
    },

    formatDateTime(date) {
      return DateUtils.formatDateTime(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },
    formatDateTimeWithSeconds(date) {
      return DateUtils.formatDateTimeWithSeconds(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },

    async leagueChanged(leagueIdToView) {
      await this.$router.push({ name: 'league-pick-em-edit', params: { leagueId: leagueIdToView }, query: this.$route.query })
    },

    async redirectToOpenTournament() {
      const res = await leagueService.getMyOpenTournamentLeagueIdForEvent(this.league.event.eventId)
      const myOpenTournamentLeagueId = res.data

      // for whatever reason I cannot come up with my open tournament league id
      if (myOpenTournamentLeagueId == null) {
        await this.$router.push({ name: 'events' })
      }

      await this.goToPickEmViewPage(myOpenTournamentLeagueId)
    },

    async loadData() {
      const getLeaguePromise = leagueService.getById(this.leagueId)
      const getAllLeagueOptionsPromise = leagueService.getAllOptionsWithLeagueId(this.leagueId, this.userId)
      const res = await Promise.all([getLeaguePromise, getAllLeagueOptionsPromise])

      this.league = res[0].data
      this.eventAdminStore.initialize(this.league.event)
      this.allLeagueOptions = res[1].data

      if (this.myLeagueMember == null) return
      this.pickEmUserMatchupsInitialState = JSON.parse(JSON.stringify(this.myLeagueMember.pickEmUserMatchups))

      await this.getEntryHistoryMetaData()

      this.lastLoadedTime = new Date().getMilliseconds()

      if (this.league == null) {
        await this.$router.push({ name: 'permission-denied' })
      }

      if (!this.league.event.published) {
        alert('The matchups for this event are not yet available.')
        await this.$router.push({ name: 'events' })
      }

      if (this.league.event.participationEnded) {
        await this.goToPickEmViewPage()
      }
    },

    async matchupChanged(data) {
      const myUserMatchup = this.myLeagueMember.pickEmUserMatchups.find(m => m.matchupId === data.matchupId)

      myUserMatchup.projectedWinnerParticipantId = data.projectedWinnerParticipantId
      myUserMatchup.projectedMatchOutcomeOptionId = data.projectedMatchOutcomeOptionId
    },

    async showSaveChangesModal() {
      return new Promise((resolve, reject) => {
        this.saveChangesModal.resolveFn = resolve
        this.saveChangesModal.rejectFn = reject
        this.saveChangesModal.isShowing = true
      })
    },
    async saveChanges() {
      this.saveChangesModal.isShowing = false
      await this.save()
      if (this.saveChangesModal.resolveFn != null) {
        await this.saveChangesModal.resolveFn()
      }
    },
    async discardChanges() {
      this.saveChangesModal.isShowing = false
      if (this.saveChangesModal.resolveFn != null) {
        await this.saveChangesModal.resolveFn()
      }
    },
    async closeSaveChangesModal() {
      this.saveChangesModal.isShowing = false
      if (this.saveChangesModal.rejectFn != null) {
        await this.saveChangesModal.rejectFn()
      }
    },

    preventDefaultBehavior(e) {
      e.preventDefault()
    },

    async save() {
      this.isSaving = true
      try {
        const dto = {
          eventId: this.league.event.eventId,

          projectedMatchupWinners: this.myLeagueMember.pickEmUserMatchups.map(m => {
            return {
              matchupId: m.matchupId,
              winnerId: m.projectedWinnerParticipantId,
              matchOutcomeOptionId: m.projectedMatchOutcomeOptionId
            }
          })
        }

        await pickEmService.saveUserPicks(this.leagueId, dto)
        const response = await leagueService.getById(this.leagueId)
        this.league = response.data
        this.pickEmUserMatchupsInitialState = JSON.parse(JSON.stringify(this.myLeagueMember.pickEmUserMatchups))
      } catch {
        alert('Something went wrong')
      } finally {
        this.isSaving = false
      }

    }
  },

  mounted() {
    PubSub.subscribe('bracket-reseeded', this.pickEmReseeded)
    PubSub.subscribe('league-name-changed', this.changeLeagueName)
  },

  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventDefaultBehavior)
  },

  unmounted() {
    try {
      PubSub.unsubscribe('bracket-reseeded', this.pickEmReseeded)
      PubSub.unsubscribe('league-name-changed', this.changeLeagueName)
    } catch {
      /* empty */
    }
  },

  // TODO: add something similar for event details page
  async beforeRouteLeave() {
    if (!this.hasUnsavedChanges || this.timeHasExpired) {
      return true
    }

    try {
      await this.showSaveChangesModal()
      // Resolved
      return true
    } catch (err) {
      // Rejected
    }

    return false
  },

  watch: {
    hasUnsavedChanges(curr) {
      if (curr) {
        window.addEventListener('beforeunload', this.preventDefaultBehavior)
      } else {
        window.removeEventListener('beforeunload', this.preventDefaultBehavior)
      }
    }
  }

}
</script>