<template>
  <div id="admin-page-pick-em" class="flex col gap-1-5">

    <!-- Preview -->
    <template v-if="isPreviewMode">
      <header-action-bar title="Event Preview" :breakpointOuter="768" :breakpointMid="576" :breakpointInner="432"
        :darkBackground="darkBackground">
        <template v-slot:primaryAction1>
          <rebel-button :dark-background="darkBackground" color="default" type="primary" text="Close Preview"
            @click="isPreviewMode = false">
            <template v-slot:icon-trailing>
              <x-mark-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>

        <template v-slot:secondaryAction1>
          <rebel-button :dark-background="darkBackground" @click="eventBackgroundImageUploader.isShowing = true"
            type="ghosted" color="default" text="Background Image" class="nowrap">
            <template v-slot:icon-leading>
              <camera-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>

        <template v-slot:secondaryAction2>
          <rebel-button :dark-background="darkBackground" @click="customizeBackgroundImageModal.isShowing = true"
            type="ghosted" color="default" text="Customize Design" class="nowrap">
            <template v-slot:icon-leading>
              <paint-brush-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>
      </header-action-bar>

      <pick-em-header :is-user-specific-view="false" :show-admin-data="false" :show-league-data="false" :event="event"
        :results-pending="event?.resultsPending" :sub-text="eventSubText" :darkBackground="darkBackground" />

      <pick-em-matches :event-data="event" :darkBackground="darkBackground" who-is-editing="admin-preview" />
    </template>
    <!-- End Preview-->

    <!-- Published Event-->
    <template v-else-if="event.published && !isEditCompetitorsMode">
      <header-action-bar title="Manage Event" :breakpointOuter="512">
        <template v-slot:primaryAction1>
          <rebel-button v-if="event.published && event.participationEnded" :dark-background="false"
            :disabled="!publishResultsEnabled" :is-loading="eventAdminStore.isPublishing" color="default" type="primary"
            text="Publish Results" @click="tryPublishResults"></rebel-button>
        </template>
        <template v-slot:secondaryAction1>
          <league-shortcut-button :event-data="event" />
        </template>
        <template v-slot:gearFlyOutMenu>
          <fly-out-actions-menu iconId="event-actions-menu">
            <template v-slot:icon>
              <cog-8-tooth-icon class="icon-24" />
            </template>
            <template v-slot:list-items>
              <li>
                <a @click.prevent="editEventDetails">Edit Event Details</a>
              </li>
              <li>
                <a @click.prevent="eventBackgroundImageUploader.isShowing = true">{{ hasBackgroundImage ? 'Edit' : 'Add'
                }} Background
                  Image</a>
              </li>
              <li>
                <a @click.prevent="isEditCompetitorsMode = true">Edit Competitors</a>
              </li>
              <li v-if="useEventLogos">
                <a @click.prevent="eventArtImageUploader.isShowing = true">{{ hasEventImage ? 'Edit' : 'Add' }} Event
                  Image</a>
              </li>
              <li>
                <a @click.prevent="customizeBackgroundImageModal.isShowing = true">Customize Design</a>
              </li>
              <li v-if="event.upcoming">
                <a @click.prevent="customizeScoring">Customize Scoring</a>
              </li>
              <li>
                <a @click.prevent="customizeWeightClassUnits">Customize Weight
                  Classes</a>
              </li>
              <li>
                <a @click.prevent="isPreviewMode = true">Preview Event</a>
              </li>
            </template>
          </fly-out-actions-menu>
        </template>
      </header-action-bar>

      <pick-em-header :is-user-specific-view="false" :show-admin-data="true" :show-league-data="false" :event="event"
        :sub-text="eventSubText" />

      <pick-em-matches who-is-editing="admin" :event-data="event" @matchup-changed="matchupChanged" />
    </template>
    <!-- End Published Event-->

    <!-- Event Setup -->
    <template v-else>

      <div class="flex">
        <header-action-bar :title="isEditCompetitorsMode ? 'Edit Competitors' : 'Setup Event'" :breakpointOuter="512">
          <template v-slot:primaryAction1>
            <rebel-button v-if="!event.published" :disabled="!publishEventEnabled"
              :is-loading="eventAdminStore.isPublishing" color="default" type="primary" text="Publish Dual"
              @click="eventAdminStore.publishEvent(eventId)"></rebel-button>
            <rebel-button v-if="isEditCompetitorsMode" :disabled="!saveChangesEnabled" :is-loading="isSaving"
              color="default" type="primary" text="Save Changes" @click="confirmChanges"></rebel-button>
          </template>
          <template v-if="isEditCompetitorsMode" v-slot:secondaryAction1>
            <rebel-button color="default" type="ghosted" text="Cancel" @click="cancelChanges"></rebel-button>
          </template>
          <template v-slot:gearFlyOutMenu>
            <fly-out-actions-menu iconId="event-actions-menu">
              <template v-slot:icon>
                <cog-8-tooth-icon class="icon-24" />
              </template>
              <template v-slot:list-items>
                <li>
                  <a @click.prevent="editEventDetails">Edit Event Details</a>
                </li>
                <li>
                  <a @click.prevent="eventBackgroundImageUploader.isShowing = true">{{ hasBackgroundImage ? 'Edit' :
                    'Add'
                  }} Background
                    Image</a>
                </li>
                <li v-if="useEventLogos">
                  <a @click.prevent="eventArtImageUploader.isShowing = true">{{ hasEventImage ? 'Edit' : 'Add' }} Event
                    Image</a>
                </li>
                <li>
                  <a @click.prevent="customizeBackgroundImageModal.isShowing = true">Customize Design</a>
                </li>
                <li>
                  <a @click.prevent="customizeScoring">Customize Scoring</a>
                </li>
                <li>
                  <a @click.prevent="customizeWeightClassUnits">Customize Weight
                    Classes</a>
                </li>
                <li>
                  <a @click.prevent="isPreviewMode = true">Preview Event</a>
                </li>
              </template>
            </fly-out-actions-menu>
          </template>
        </header-action-bar>
      </div>

      <div class="flex">
        <img v-if="useEventLogos && event.logoUrl" class="mr-1" width="100" :src="event.logoUrl" alt="Event logo" />
        <div class="flex col">
          <h1 class="black mb-0-5">{{ event.name }}</h1>
          <p class="heading3 gray3 mb-0-25">{{ eventSubText }}</p>
          <label-admin data-testid="event-status-label" :event="event" />
        </div>
      </div>

      <teams-based-event v-if="showTeamsBasedEvent" ref="teamsBasedEventComponent" class="bg-white p-0-75"
        :auto-save="autoSave" :event-id="eventId" :can-toggle="event.upcoming" @teams-changed="teamsChanged" />

      <div id="competitor-groups" class="flex col gap-1 bg-white p-0-75">
        <div class="flex space-between align-center">
          <h2 class="heading2">Competition Groups</h2>
          <rebel-button v-if="event.upcoming" @click="addCompetitionGroup" type="primary" color="default"
            text="Add Group" class="nowrap">
            <template v-slot:icon-leading>
              <plus-icon class="icon-20" />
            </template>
          </rebel-button>
        </div>

        <form class="flex col gap-1 mb-1" v-for="(group, groupIndex) in event.groups" :key="`group${groupIndex}`"
          @input="competitionGroupFormChanged(groupIndex)">
          <div class="flex gap-0-5 mb-0-5" v-if="event.groups.length > 1">
            <div class="form-input grow">
              <label :for="`group${groupIndex}`">Group Name</label>
              <input :id="`group${groupIndex}`" :data-testid="`group${groupIndex}`" type="text"
                placeholder="i.e. Men's, Women's, etc." v-model="group.groupName">
            </div>
            <div class="color-action flex col justify-center">
              <span>&nbsp;</span>
              <x-mark-icon class="icon-20 pointer color-action" @click="confirmRemoveCompetitorGroup(groupIndex)" />
            </div>
          </div>

          <div class="flex gap-0-5" style="margin-bottom: -0.75rem">
            <span class="width-svg"></span>
            <span class="width-weight-class">
              <label :for="`group${groupIndex}`">Weight Class</label>
            </span>
            <span class="width-rank">
              <label :for="`group${groupIndex}`">Rank</label>
            </span>
            <span class="grow flex">
              <span class="grow min-width-135">
                <label :for="`group${groupIndex}`">Competitor 1</label>
              </span>
              <span class="width-matchup-divider"></span>
              <span class="grow min-width-135">
                <label :for="`group${groupIndex}`">Competitor 2</label>
              </span>
            </span>
            <span class="width-rank">
              <label :for="`group${groupIndex}`">Rank</label>
            </span>
            <span class="width-svg"></span>
          </div>

          <draggable class="flex col gap-0-5" :list="group.matchups" @change="updateSortOrder(group)">
            <div class="flex gap-0-5 item-to-drag" v-for="(matchup, matchupIndex) in group.matchups"
              :key="`group${groupIndex}matchup${matchupIndex}`">

              <div class="flex col justify-center">
                <bars2-icon class="icon-20 gray2-5" />
              </div>

              <div class="form-input">
                <div class="flex align-center" style="position: relative;">
                  <input class="width-weight-class" :id="`group${groupIndex}matchup${matchupIndex}weightClass`"
                    :data-testid="`group${groupIndex}matchup${matchupIndex}weightClass`" type="text"
                    placeholder="Enter value" v-model="matchup.weightClass" maxlength="20" required>
                  <span style="position: absolute; right: .5rem; color: var(--color-gray2-5)">{{ event.weightClassUnits
                    ??
                    '' }}</span>
                </div>
              </div>

              <div class="form-input">
                <input class="width-rank" :id="`group${groupIndex}matchup${matchupIndex}competitor1rank`"
                  :data-testid="`group${groupIndex}matchup${matchupIndex}competitor1rank`" type="text" placeholder="---"
                  v-model="matchup.participant1Rank" maxlength="3" required>
              </div>

              <div class="flex grow">
                <div class="form-input grow min-width-135">
                  <input :id="`group${groupIndex}matchup${matchupIndex}competitor1`"
                    :data-testid="`group${groupIndex}matchup${matchupIndex}competitor1`" type="text"
                    placeholder="Enter name" v-model="matchup.participant1Name" maxlength="100" required>
                </div>

                <div class="flex col gap-0-125 justify-center">
                  <div class="matchup-divider">
                  </div>
                </div>

                <div class="form-input grow min-width-135">
                  <input :id="`group${groupIndex}matchup${matchupIndex}competitor2`"
                    :data-testid="`group${groupIndex}matchup${matchupIndex}competitor2`" type="text"
                    placeholder="Enter name" v-model="matchup.participant2Name" maxlength="100" required>
                </div>
              </div>

              <div class="form-input">
                <input class="width-rank" :id="`group${groupIndex}matchup${matchupIndex}competitor2rank`"
                  :data-testid="`group${groupIndex}matchup${matchupIndex}competitor2rank`" type="text" placeholder="---"
                  v-model="matchup.participant2Rank" maxlength="3" required>
              </div>

              <div class="color-action flex col justify-center">
                <x-mark-icon v-if="event.upcoming || (eventMatchupCount > 1)" class="icon-20 pointer color-action"
                  @click="removeMatchup(group, matchupIndex)" />
              </div>
            </div>
          </draggable>

          <div v-if="event.upcoming" class="flex width-100">
            <div class="flex pointer color-action" @click="addAnotherMatch(groupIndex)">
              <plus-icon class="icon-20" />
              <h3 class="heading3 color-action">
                Add Another Match
              </h3>
            </div>
          </div>
        </form>
      </div>
    </template>
    <!-- End Event Setup -->
  </div>
  <customize-background-image v-if="customizeBackgroundImageModal.isShowing" :event-id="eventId"
    :curr-blend-mode="event.blendMode" :curr-gaussian-blur="event.gaussianBlur" :curr-opacity="event.opacity"
    :curr-saturation="event.saturation" @design-customized="designCustomized"
    @close="customizeBackgroundImageModal.isShowing = false" />
  <customize-weight-class-units-modal v-if="customizeWeightClassUnitsModal.isShowing" :event-id="eventId"
    :units="event.weightClassUnits" @close="customizeWeightClassUnitsModal.isShowing = false"
    @event-weight-class-units-changed="weightClassUnitsChanged" />
  <customize-scoring-modal v-if="customizeScoringModal.isShowing" :event-id="eventId"
    :is-team-event="event.teams?.teamEvent" :disable-add-remove="event.published"
    @scoring-customized="scoringCustomized" @close="customizeScoringModal.isShowing = false" />
  <edit-event-modal v-if="editEventDetailsModal.isShowing" :event-data="event" @delete-event="deleteEvent"
    @close="editEventDetailsModal.isShowing = false" />
  <delete-event-modal v-if="deleteEventModal.isShowing" :event-id="event.eventId" :event-name="event.name"
    @close="deleteEventModal.isShowing = false" />
  <event-art-image-uploader v-if="useEventLogos && eventArtImageUploader.isShowing" :event-id="eventId"
    :uploaderImageSrc="event.logoUrl" @close="eventArtImageUploader.isShowing = false"
    @image-uploader-deleted="event.logoUrl = ''" @image-uploader-saved="(imageSrc) => event.logoUrl = imageSrc" />
  <event-background-image-uploader v-if="eventBackgroundImageUploader.isShowing" :event-id="eventId"
    :uploaderImageSrc="event.backgroundImage" @close="eventBackgroundImageUploader.isShowing = false"
    @image-uploader-deleted="backgroundImageUpdated('')"
    @image-uploader-saved="(imageSrc) => backgroundImageUpdated(imageSrc)" />
  <remove-competitor-group-modal v-if="removeCompetitorGroupModal.isShowing"
    :group-index="removeCompetitorGroupModal.groupIndex" :group-name="removeCompetitorGroupModal.groupName"
    @remove-competitor-group="removeCompetitorGroup" @close="removeCompetitorGroupModal.isShowing = false" />
  <re-seed-pick-em-event-modal v-if="reSeedPickEmEventModal.isShowing"
    :canSaveWithoutReSeeding="canSaveWithoutReSeeding" :event-name="event.name" @reseed="reseed"
    @save-without-reseed="saveWithoutReseed" @close="reSeedPickEmEventModal.isShowing = false" />
  <save-changes-modal v-if="saveChangesModal.isShowing"
    :action-button-text="isEditCompetitorsMode ? 'Save Changes' : 'Publish Results'" @save-changes="confirmChanges"
    @discard-changes="discardChanges" @close="closeSaveChangesModal" />
</template>

<script>
/*
  TODO:

  maxlength and required should be stored in data constraints
  textbox with lbs or kgs to the right should be built generically as a component
    - example: https://stackoverflow.com/questions/71018026/how-to-put-icon-inside-an-input-box
  x clicked on last matchup should clear row contents rather than remove row
*/

import CustomizeBackgroundImage from '../CustomizeBackgroundImage.vue'
import CustomizeScoringModal from './CustomizeScoringModal.vue'
import CustomizeWeightClassUnitsModal from './CustomizeWeightClassUnitsModal.vue'
import EditEventModal from '../EditEventModal.vue'
import DeleteEventModal from '../DeleteEventModal.vue'
import EventArtImageUploader from '../EventArtImageUploader.vue'
import EventBackgroundImageUploader from '../EventBackgroundImageUploader.vue'
import FlyOutActionsMenu from '../../FlyOutActionsMenu.vue'
import HeaderActionBar from '@/components/HeaderActionBar.vue'
import LabelAdmin from '../LabelAdmin.vue'
import LeagueShortcutButton from '@/components/event-admin/LeagueShortcutButton.vue'
import PickEmHeader from '@/components/feature/pick-em/header/PickEmHeader.vue'
import PickEmMatches from '@/components/feature/pick-em/PickEmMatches.vue'
import RebelButton from '../../RebelButton.vue'
import RemoveCompetitorGroupModal from './RemoveCompetitorGroupModal.vue'
import ReSeedPickEmEventModal from './ReSeedPickEmEventModal.vue'
import SaveChangesModal from './SaveChangesModal.vue'
import TeamsBasedEvent from './TeamsBasedEvent.vue'
import { Bars2Icon, CameraIcon, PaintBrushIcon, PlusIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { Cog8ToothIcon } from '@heroicons/vue/24/solid'

import APP_SETTINGS from '@/appSettings.js'
import DateUtils from '@/utils/dateUtils.js'
import eventService from '@/services/EventService'
import pickEmService from '@/services/PickEmService'
import debounceUtil from '@/utils/debounce'
import PQueue from 'p-queue'
import { VueDraggableNext } from 'vue-draggable-next'

import { useEventAdminStore } from '@/stores/eventAdmin'
import { usePickEmStore } from '@/stores/pickem'
import { useStylingStore } from '@/stores/styling'

export default {
  setup() {
    const savingQueue = new PQueue({ concurrency: 1 })
    const sortingQueue = new PQueue({ concurrency: 1 })

    return {
      eventAdminStore: useEventAdminStore(),
      pickEmStore: usePickEmStore(),
      stylingStore: useStylingStore(),
      savingQueue,
      sortingQueue
    }
  },

  async created() {
    this.eventInitialState = JSON.parse(JSON.stringify(this.event))
  },

  components: {
    Bars2Icon,
    CameraIcon,
    Cog8ToothIcon,
    CustomizeBackgroundImage,
    CustomizeScoringModal,
    CustomizeWeightClassUnitsModal,
    EditEventModal,
    DeleteEventModal,
    EventArtImageUploader,
    EventBackgroundImageUploader,
    FlyOutActionsMenu,
    HeaderActionBar,
    LabelAdmin,
    LeagueShortcutButton,
    PaintBrushIcon,
    PickEmHeader,
    PickEmMatches,
    PlusIcon,
    RebelButton,
    RemoveCompetitorGroupModal,
    ReSeedPickEmEventModal,
    SaveChangesModal,
    TeamsBasedEvent,
    XMarkIcon,
    draggable: VueDraggableNext
  },

  computed: {
    autoSave() {
      return !this.isEditCompetitorsMode
    },

    canSaveWithoutReSeeding() {
      // return true if there are no new matchups i.e. matchups without an id
      return this.event.groups.flatMap(g => g.matchups).every(m => m.matchupId != null)
    },

    countdownTimerDate() {
      return DateUtils.dateInUsersTimezone(this.event.startDateTime, this.event.timeZoneName)
    },

    eventId() {
      return this.event.eventId
    },

    eventMatchupCount() {
      return this.event.groups.flatMap(g => g.matchups).length
    },

    eventSubText() {
      if (this.event.popularVoteTournament) {
        if (!this.event.published) return 'Voting begins when brackets are published'
        if (!this.event.participationEnded) return 'Voting ends ' + this.formattedStartDateTime
        return 'Voting ended ' + this.formattedStartDateTime
      }

      return this.formattedStartDateTime

    },

    formattedStartDateTime() {
      return DateUtils.formatDateTime(this.event.startDateTime, this.event.timeZoneName)
    },

    hasAnythingChanged() {
      if (!this.isEditCompetitorsMode) return false

      return this.hasTeamsChanged || this.hasCompetitorsChanged
    },

    hasCompetitorsChanged() {
      if (this.eventInitialState == null) return false
      if (this.event.groups.length != this.eventInitialState.groups.length) return true

      for (let groupIndex = 0; groupIndex < this.event.groups.length; groupIndex++) {
        const initialGroup = this.eventInitialState.groups[groupIndex]
        const currGroup = this.event.groups[groupIndex]

        if (initialGroup.groupName != currGroup.groupName) return true
        if (initialGroup.matchups.length != currGroup.matchups.length) return true

        for (let matchupIndex = 0; matchupIndex < currGroup.matchups.length; matchupIndex++) {
          const initialMatchup = initialGroup.matchups[matchupIndex]
          const currMatchup = currGroup.matchups[matchupIndex]

          if (initialMatchup.weightClass != currMatchup.weightClass) return true
          if (initialMatchup.participant1Name != currMatchup.participant1Name) return true
          if (initialMatchup.participant2Name != currMatchup.participant2Name) return true
          if (initialMatchup.participant1Rank != currMatchup.participant1Rank) return true
          if (initialMatchup.participant2Rank != currMatchup.participant2Rank) return true
        }
      }

      return false
    },

    hasTeamsChanged() {
      return this.eventAdminStore.teamsChanged
    },

    hasBackgroundImage() {
      return Boolean(this.event.backgroundImage)
    },

    hasEventImage() {
      return !!this.event.logoUrl
    },

    isAllDataValid() {
      const isTeamsComponentValid = !this.showTeamsBasedEvent || this.pickEmStore.teamsBasedEvent.isValid

      /*
        Valid Competitors Check
      */
      const hasGroupName = group => !!group.groupName
      const hasMatchData = matchup => !!matchup.weightClass && !!matchup.participant1Name && !!matchup.participant2Name

      const isCompetitorsComponentValid = this.event.groups.length >= 1 && this.event.groups.every(g => {
        let isValid = g.matchups.length >= 1 && g.matchups.every(m => hasMatchData(m))

        if (this.event.groups.length > 1) {
          isValid &= hasGroupName(g)
        }

        return isValid
      })

      return isTeamsComponentValid && isCompetitorsComponentValid
    },

    isCandidateForReSeed() {
      return this.isEditCompetitorsMode && this.hasCompetitorsChanged
    },

    publishEventEnabled() {
      return this.isAllDataValid
    },

    publishResultsEnabled() {
      if (!this.event.published) return false
      if (!this.event.participationEnded) return false
      if (!this.event.groups) return false
      if (!this.eventInitialState) return false

      // check for difference between initial state of projected winners and current
      // if they are at all different, then a result must have changed.
      if (this.event.groups.length != this.eventInitialState.groups.length) {
        return true;
      }

      for (let groupIndex = 0; groupIndex < this.event.groups.length; groupIndex++) {
        const initialGroup = this.eventInitialState.groups[groupIndex]
        const currGroup = this.event.groups[groupIndex]

        for (let matchupIndex = 0; matchupIndex < currGroup.matchups.length; matchupIndex++) {
          const initialMatchup = initialGroup.matchups[matchupIndex]
          const currMatchup = currGroup.matchups[matchupIndex]

          if (initialMatchup.winnerParticipantId != currMatchup.winnerParticipantId) return true
          if (initialMatchup.winnerMatchOutcomeOptionId != currMatchup.winnerMatchOutcomeOptionId) return true
        }
      }

      return false
    },

    saveChangesEnabled() {
      return this.isAllDataValid && this.hasAnythingChanged
    },

    showTeamsBasedEvent() {
      return this.event.upcoming || this.event.teams?.teamEvent
    }
  },

  props: {
    eventData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      reseedDto: {
        eventId: this.eventData.eventId,
        groupIdsToDelete: [],
        matchupIdsToDelete: [],
        groupsToAddOrUpdate: []
      },

      isPreviewMode: false,
      isEditCompetitorsMode: false,

      isPublishing: false,
      isSaving: false,

      event: { ...this.eventData },
      eventInitialState: null,

      customizeBackgroundImageModal: {
        isShowing: false
      },
      customizeScoringModal: {
        isShowing: false
      },
      customizeWeightClassUnitsModal: {
        isShowing: false
      },
      editEventDetailsModal: {
        isShowing: false
      },
      eventArtImageUploader: {
        isShowing: false
      },
      eventBackgroundImageUploader: {
        isShowing: false
      },
      darkBackground: true,
      deleteEventModal: {
        isShowing: false
      },
      removeCompetitorGroupModal: {
        isShowing: false,
        groupIndex: 0,
        groupName: ''
      },
      reSeedPickEmEventModal: {
        isShowing: false,
      },
      saveChangesModal: {
        isShowing: false
      },

      useEventLogos: APP_SETTINGS.USE_EVENT_LOGOS,

      debounceSaveGroupFns: new Map()
    }
  },

  methods: {
    backgroundImageUpdated(imageSrc) {
      this.event.backgroundImage = imageSrc
      this.eventAdminStore.setBackgroundImage(imageSrc)
    },

    designCustomized(designDto) {
      this.event.blendMode = designDto.blendMode
      this.event.gaussianBlur = designDto.gaussianBlur
      this.event.opacity = designDto.opacity
      this.event.saturation = designDto.saturation
      this.eventAdminStore.setDesign(designDto)
    },

    cancelChanges() {
      if (this.saveChangesEnabled) {
        this.saveChangesModal.isShowing = true
      }
      else {
        this.event = JSON.parse(JSON.stringify(this.eventInitialState))
        this.isEditCompetitorsMode = false
      }
    },

    async closeSaveChangesModal() {
      this.saveChangesModal.isShowing = false
      if (this.saveChangesModal.rejectFn != null) {
        await this.saveChangesModal.rejectFn()
      }
    },

    async confirmChanges() {
      if (this.isEditCompetitorsMode) {

        if (this.isCandidateForReSeed) {
          if (this.event.upcoming) {
            this.reSeedPickEmEventModal.isShowing = true
          } else {
            await this.saveChanges(false)
          }
        } else {
          await this.saveChanges()
        }

      } else {
        await this.tryPublishResults()
      }
      this.saveChangesModal.isShowing = false
    },

    customizeScoring() {
      this.customizeScoringModal.isShowing = true
    },

    customizeWeightClassUnits() {
      this.customizeWeightClassUnitsModal.isShowing = true
    },

    debounceSaveGroup(groupIndex) {

      let fn = this.debounceSaveGroupFns.get(groupIndex)

      if (!fn) {
        fn = debounceUtil.debounce((groupIndex) => {
          this.savingQueue.clear()
          this.savingQueue.add(() => this.save(groupIndex))
        }, 1000)

        this.debounceSaveGroupFns.set(groupIndex, fn)
      }

      return fn
    },

    async discardChanges() {
      this.reseedDto = {
        eventId: this.event.eventId,
        groupIdsToDelete: [],
        matchupIdsToDelete: [],
        groupsToAddOrUpdate: []
      }
      this.event = JSON.parse(JSON.stringify(this.eventInitialState))
      this.saveChangesModal.isShowing = false
      this.isEditCompetitorsMode = false
      if (this.saveChangesModal.resolveFn != null) {
        await this.saveChangesModal.resolveFn()
      }
    },

    addAnotherMatch(groupIndex) {
      this.event.groups[groupIndex].matchups.push({
        weightClass: '',
        participant1Name: '',
        participant2Name: '',
        participant1Rank: '',
        participant2Rank: ''
      })
    },

    addCompetitionGroup() {
      this.event.groups.push({
        groupName: '',
        matchups: [
          {
            weightClass: '',
            participant1Name: '',
            participant2Name: ''
          },
        ]
      })
    },

    competitionGroupFormChanged(groupIndex) {
      if (!this.autoSave) return

      const fn = this.debounceSaveGroup(groupIndex)
      fn(groupIndex)
    },

    async confirmRemoveCompetitorGroup(groupIndex) {
      if (!this.autoSave) {
        await this.removeCompetitorGroup(groupIndex)
      } else {
        this.removeCompetitorGroupModal.groupIndex = groupIndex
        this.removeCompetitorGroupModal.groupName = this.event.groups[groupIndex].groupName
        this.removeCompetitorGroupModal.isShowing = true
      }
    },

    deleteEvent() {
      this.deleteEventModal.isShowing = true
    },

    editEventDetails() {
      this.editEventDetailsModal.isShowing = true
    },

    getGroupsWithChanges() {
      const groupsWithChanges = []

      this.event.groups.forEach((g, currGroupIndex) => {
        groupsWithChanges.push({ ...g })
        if (g.groupId == null) {
          return // all matchups need added
        }

        // only add in matchups that have changed
        groupsWithChanges[currGroupIndex].matchups = []

        const initialMatchups = this.eventInitialState.groups.flatMap(eisGroups => eisGroups.matchups)
        for (let matchupIndex = 0; matchupIndex < g.matchups.length; matchupIndex++) {
          const currMatchup = g.matchups[matchupIndex]
          if (currMatchup.matchupId == null) {
            groupsWithChanges[currGroupIndex].matchups.push({ ...currMatchup })
            continue
          }

          const initialMatchup = initialMatchups.find(im => im.matchupId === currMatchup.matchupId)

          let hasDifference = false
          if (initialMatchup == null) {
            hasDifference = true
          } else {
            if (initialMatchup.weightClass != currMatchup.weightClass) hasDifference = true
            if (initialMatchup.participant1Name != currMatchup.participant1Name) hasDifference = true
            if (initialMatchup.participant1Rank != currMatchup.participant1Rank) hasDifference = true
            if (initialMatchup.participant2Name != currMatchup.participant2Name) hasDifference = true
            if (initialMatchup.participant2Rank != currMatchup.participant2Rank) hasDifference = true
          }

          if (hasDifference) {
            groupsWithChanges[currGroupIndex].matchups.push({ ...currMatchup })
          }
        }
      })

      return groupsWithChanges
    },

    matchupChanged(data) {
      const matchup = this.event.groups.flatMap(g => g.matchups).find(m => m.matchupId == data.matchupId)
      matchup.winnerParticipantId = data.winnerParticipantId
      matchup.winnerMatchOutcomeOptionId = data.winnerMatchOutcomeOptionId
    },

    async removeCompetitorGroup(groupIndex) {
      const removedGroups = this.event.groups.splice(groupIndex, 1)
      if (!removedGroups.length || !removedGroups[0].groupId) return

      const groupIdToDelete = removedGroups[0].groupId

      if (this.autoSave) {
        await pickEmService.deleteGroupById(this.eventId, groupIdToDelete)
      } else {
        this.reseedDto.groupIdsToDelete.push(groupIdToDelete)
      }
    },

    async removeMatchup(group, matchupIndex) {
      const removedMatchups = group.matchups.splice(matchupIndex, 1)
      if (!removedMatchups.length || !removedMatchups[0].matchupId) return

      const matchupIdToDelete = removedMatchups[0].matchupId

      if (this.autoSave) {
        await pickEmService.deleteMatchupById(this.eventId, matchupIdToDelete)
      } else {
        this.reseedDto.matchupIdsToDelete.push(matchupIdToDelete)
      }
    },

    async reseed() {
      await this.saveChanges(true)
    },

    async save(groupIndex) {
      const groupToSave = this.event.groups[groupIndex]
      try {
        const response = await pickEmService.saveGroup(this.eventId, groupToSave)

        // set ids
        const savedGroup = response.data
        groupToSave.groupId = savedGroup.groupId
        for (let matchupIndex = 0; savedGroup.matchups.length; matchupIndex++) {
          groupToSave.matchups[matchupIndex].matchupId = savedGroup.matchups[matchupIndex].matchupId
        }

      } catch {
        //
      }
    },

    async saveChanges(isReSeed) {
      this.isSaving = true

      try {
        if (this.showTeamsBasedEvent) {
          await this.$refs.teamsBasedEventComponent.saveTeams()
        }

        if (isReSeed) {
          this.reseedDto.groupsToAddOrUpdate = this.getGroupsWithChanges()
          await pickEmService.reseed(this.event.eventId, this.reseedDto)

          // move to next page if navigating away
          if (this.saveChangesModal.resolveFn != null) {
            await this.saveChangesModal.resolveFn()
          }

          this.event.groups = (await pickEmService.getGroups(this.event.eventId)).data
        } else {

          /* for deletions after an event has started */
          for (let matchupIdToDelete of this.reseedDto.matchupIdsToDelete) {
            await pickEmService.deleteMatchupById(this.eventId, matchupIdToDelete)
          }
          for (let groupIdToDelete of this.reseedDto.groupIdsToDelete) {
            await pickEmService.deleteGroupById(this.eventId, groupIdToDelete)
          }

          for (let groupIndex = 0; groupIndex < this.event.groups.length; groupIndex++) {
            await this.save(groupIndex)
          }

          if (this.publishResultsEnabled) {
            await this.tryPublishResults() // recalculate scores & rankings
          }
          /* end */

        }

        this.eventInitialState = JSON.parse(JSON.stringify(this.event))

        // reset reseedDto
        this.reseedDto = {
          eventId: this.event.eventId,
          groupIdsToDelete: [],
          matchupIdsToDelete: [],
          groupsToAddOrUpdate: []
        }

      } finally {
        this.isSaving = false
        this.isEditCompetitorsMode = false
      }

      if (this.saveChangesModal.resolveFn != null) {
        await this.saveChangesModal.resolveFn()
      }

    },

    async saveWithoutReseed() {
      await this.saveChanges(false)
    },

    scoringCustomized(customizeScoringDto) {
      this.event.customizeScoringDto = customizeScoringDto
    },

    async showSaveChangesModal() {
      return new Promise((resolve, reject) => {
        this.saveChangesModal.resolveFn = resolve
        this.saveChangesModal.rejectFn = reject
        this.saveChangesModal.isShowing = true
      })
    },

    teamsChanged(teamsModel) {
      this.event.teams = teamsModel
    },

    async tryPublishResults() {
      this.isPublishing = true

      const projectedMatchupWinners = this.event.groups.flatMap(g =>
        g.matchups.map(m => {
          return {
            matchupId: m.matchupId,
            winnerId: m.winnerParticipantId,
            matchOutcomeOptionId: m.winnerMatchOutcomeOptionId
          }
        }))

      const dto = {
        eventId: this.event.eventId,
        projectedMatchupWinners: projectedMatchupWinners
      }

      try {
        await pickEmService.publishResults(dto)

        const response = await eventService.getById(this.eventId)
        this.event = { ...response.data }

        this.eventInitialState = JSON.parse(JSON.stringify(this.event))
      } finally {
        this.isPublishing = false
      }

      if (this.saveChangesModal.resolveFn != null) {
        await this.saveChangesModal.resolveFn()
      }
    },

    updateSortOrder(groupToSave) {
      const list = groupToSave.matchups.map((matchup, matchupIndex) => {
        return {
          id: matchup.matchupId,
          sortOrder: matchupIndex + 1
        }
      })

      this.sortingQueue.clear()
      this.sortingQueue.add(() => {
        try {
          pickEmService.updateSortOrder(this.eventId, groupToSave.groupId, { list })
        } catch {
          //            
        }
      })
    },

    weightClassUnitsChanged(updatedUnits) {
      this.event.weightClassUnits = updatedUnits
    },

    preventDefaultBehavior(e) {
      e.preventDefault()
    },

    async beforeRouteLeaveImpl() {
      if (!this.saveChangesEnabled && !this.publishResultsEnabled) {
        return true
      }

      try {
        await this.showSaveChangesModal()
        // Resolved
        return true
      } catch (err) {
        // Rejected
      }

      return false
    },
  },

  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventDefaultBehavior)
  },

  watch: {
    isPreviewMode(curr) {
      this.stylingStore.setDarkBackground(curr)
      this.stylingStore.setShowEventBackgroundImage(curr)
    },

    publishResultsEnabled(curr) {
      if (curr) {
        window.addEventListener('beforeunload', this.preventDefaultBehavior)
      } else {
        window.removeEventListener('beforeunload', this.preventDefaultBehavior)
      }
    },

    saveChangesEnabled(curr) {
      if (curr) {
        window.addEventListener('beforeunload', this.preventDefaultBehavior)
      } else {
        window.removeEventListener('beforeunload', this.preventDefaultBehavior)
      }
    }
  }
}
</script>

<style scoped>
#competitor-groups {
  --matchup-divider-width: 30px;
  --svg-width-height: 20px;

  overflow-x: auto;
}

.draggable {
  cursor: grab;
}

.matchup-divider {
  border-top: 1px solid var(--color-gray1-5);
  width: var(--matchup-divider-width);
}

.min-width-135 {
  min-width: 135px;
}

.width-weight-class {
  min-width: 135px;
  width: 135px;
}

.width-rank {
  min-width: 50px;
  width: 50px;
}

.width-matchup-divider {
  width: var(--matchup-divider-width);
}

.width-svg {
  min-width: var(--svg-width-height);
  width: var(--svg-width-height);
}
</style>