<template>
  <entry-card :data-testid="`league-card-${league.leagueId}`"
    :entry-paid-in-full="league.paidInFull" :entry-paid-if-necessary="league.paidIfNecessary"
    :entry-exempt-from-payment="league.exemptFromPayment" :entry-predictions-remaining="league.predictionsRemaining"
    :entry-progress-percentage="league.progressPercentage" :entry-title="league.name" :event="event"
    event-status="eventStatus">

    <template v-slot:avatars>
      <avatar-grouping :member-count="league.memberCount" :avatar-sources="league.userAvatarsRandomlyOrdered" />
    </template>

    <template v-slot:entry-scores>
      <entry-card-scores v-if="event.participationEnded && event.resultsPending" :entry-points="league.points"
        :entry-points-possible="league.pointsPossible" :league-rank="league.overallLeagueRank"
        :overall-event-rank="league.overallEventRank" />
      <entry-card-scores v-else-if="event.participationEnded && !event.resultsPending" :entry-points="league.points"
        :league-rank="league.overallLeagueRank" :overall-event-rank="league.overallEventRank" />
    </template>
  </entry-card>
</template>

<script>
import AvatarGrouping from '@/components/AvatarGrouping.vue'
import EntryCard from '@/components/events/entryCard/EntryCard.vue'
import EntryCardScores from '@/components/events/entryCard/EntryCardScores.vue'

export default {
  components: {
    AvatarGrouping,
    EntryCard,
    EntryCardScores
  },

  props: {
    event: {
      type: Object,
      required: true
    },

    eventStatus: {
      type: String,
      required: true
    },

    league: {
      type: Object,
      required: true
    }
  }
}
</script>