<template>
  <section class="flex col gap-1-5">
    <div class="flex col gap-0-75" v-for="(group, groupIndex) in groups" :key="groupIndex">

      <h3 class="heading3" :class="{ 'underline': groups.length > 1, 'dark-background': darkBackground }">{{
        group.groupName }}</h3>

      <div class="flex col gap-0-5">
        <template v-for="(matchup, matchupIndex) in group.matchups" :key="matchupIndex">
          <pick-em-match-single :darkBackground="darkBackground" :isEditable="isEditable" :matchup="matchup"
            :outcomeOptions="scoring.matchOutcomeOptions" :showWaysToWin="showWaysToWin"
            :userMatchupProjection="getUserProjectionForMatchup(matchup)" :weightClassUnits="eventData.weightClassUnits"
            :who-is-editing="whoIsEditing" @matchup-changed="(data) => matchupChanged(data)" />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import emitEvents from '../../../utils/emitEvents';
import { validWhoIsEditing } from '@/utils/eventResultStates.js'
import PickEmMatchSingle from './PickEmMatchSingle.vue';

export default {
  components: {
    PickEmMatchSingle
  },

  props: {
    darkBackground: {
      type: Boolean,
      required: false,
      default: false
    },

    eventData: {
      type: Object,
      required: true
    },

    userProjections: {
      type: Array,
      required: false
    },

    whoIsEditing: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validWhoIsEditing.includes(value)
      }
    }
  },

  created() {
    this.scoring = { ...this.eventData.customizeScoringDto }

    this.groups = JSON.parse(JSON.stringify(this.eventData.groups))
  },

  data() {
    return {
      scoring: null,
      groups: null
    }
  },

  computed: {
    isEditable() {
      if (this.whoIsEditing == 'user' || this.whoIsEditing == 'admin-preview') {
        return this.eventData.upcoming
      } else if (this.whoIsEditing == 'admin') {
        return !this.eventData.upcoming
      }
      return false
    },

    showWaysToWin() {
      // Classification of victory has not been added to scoring for this event
      if (!this.scoring.addClassificationOfVictory) return false

      // When the admin is viewing an upcoming event, show VS regardless since not editable
      if (this.whoIsEditing == 'admin' && this.eventData.upcoming) return false

      return true
    }
  },

  methods: {
    matchupChanged(data) {
      if (!this.isEditable) return

      this.$emit(emitEvents.MATCHUP_CHANGED, data)
    },

    getUserProjectionForMatchup(matchup) {
      if (this.whoIsEditing === 'admin-preview') {
        return {}
      }
      
      if (this.userProjections == null) return null

      return this.userProjections.find(up => up.matchupId === matchup.matchupId)
    }
  }
}
</script>