import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

export default {
  formatDateFromDateTime(dateTime, userTimezone) {
    if (!dateTime) return ''

    if(!userTimezone) {
      userTimezone = moment.tz.guess(true)
    }

    return moment(dateTime).tz(userTimezone).format('MMMM D, YYYY')
  },

  formatTimeFromDateTime(dateTime, userTimezone) {
    if (!dateTime) return ''
    
    if(!userTimezone) {
      userTimezone = moment.tz.guess(true)
    }

    return moment(dateTime).tz(userTimezone).format('h:mm A z')
  },

  formatDateTime(dateTime, userTimezone) {

    if(!userTimezone) {
      userTimezone = moment.tz.guess(true)
    }

    return moment(dateTime).tz(userTimezone).format('MMMM D, YYYY, h:mmA z')
  },

  formatDateTimeWithSeconds(dateTime, userTimezone) {

    if(!userTimezone) {
      userTimezone = moment.tz.guess(true)
    }

    return moment(dateTime).tz(userTimezone).format('MMMM D, YYYY, h:mm:ssA z')
  },

  dateInUsersTimezone(dateTime, userTimezone) {
    if(!userTimezone) {
      userTimezone = moment.tz.guess(true)
    }

    return new Date(moment(dateTime).tz(userTimezone).format('YYYY/MM/DD HH:mm:ss z')) 
  }
}