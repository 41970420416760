<template>
  <ul class="pagination flex space-between gap-0-5">
    <li>
      <rebel-button color="default" type="borderless" text="prev" class="nowrap" :disabled="isInFirstPage"
        @click="onClickPreviousPage">
        <template v-slot:icon-leading>
          <chevron-left-icon class="icon-20" />
        </template>
      </rebel-button>
    </li>

    <!-- Visible Buttons Start -->

    <ul class="grow flex justify-center">
      <li v-for="page in pages" :key="page.name">
        <rebel-button color="default" type="borderless" :text="String(page.name)" :disabled="page.isDisabled"
          @click="onClickPage(page.name)" />
      </li>
    </ul>


    <!-- Visible Buttons End -->
    <li>
      <rebel-button color="default" type="borderless" text="next" class="nowrap" :disabled="isInLastPage"
        @click="onClickNextPage">
        <template v-slot:icon-trailing>
          <chevron-right-icon class="icon-20" />
        </template>
      </rebel-button>
    </li>
  </ul>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import RebelButton from '@/components/RebelButton.vue'

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    RebelButton
  },

  emits: ['pagechanged'],

  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },

  computed: {
    maxVisibleButtonsComputed() {
      if (this.maxVisibleButtons > this.totalPages) {
        return this.totalPages
      }

      return this.maxVisibleButtons
    },

    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When range includes the last page
      const numPreviousPages = 1; // number of previous pages to display in range
      const minimumStartPage = this.totalPages - this.maxVisibleButtonsComputed + numPreviousPages;

      if (this.currentPage > minimumStartPage) {
        return minimumStartPage;
      }

      // When in between
      return this.currentPage - numPreviousPages;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtonsComputed - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },

  methods: {
    isPageActive(page) {
      return this.currentPage === page;
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
  }
}
</script>

<style>
.pagination {
  list-style-type: none;
  display: flex;
}
</style>