<template>
  <entry-card-layout :data-test-id="dataTestId" :disqualified="entryIsDisqualified">
    <template v-slot:entry-title>
      <entry-card-title :entry-is-editable="!event.participationEnded"
        :predictions-remaining="entryPredictionsRemaining" :title="entryTitle" />
    </template>

    <template v-if="showEntryScores" v-slot:entry-data>
      <slot name="entry-scores"></slot>
    </template>

    <template v-else v-slot:entry-data>
      <entry-payment-completion-status v-if="!event.published" :entry-exempt-from-payment="entryExemptFromPayment" :entry-paid-in-full="entryPaidInFull" :event="event"
        :statusMessage="STRINGS.COMPETITORS_UNPUBLISHED_MESSAGE" />
      <entry-payment-completion-status v-else-if="entryIsDisqualified" :entry-exempt-from-payment="entryExemptFromPayment" :entry-paid-in-full="entryPaidInFull"
        :entry-is-disqualified="true" :event="event" :statusMessage="STRINGS.ENTRY_DISQUALIFIED_MESSAGE" />
      <entry-payment-completion-status v-else-if="lastChanceToEnter" :entry-exempt-from-payment="entryExemptFromPayment" :entry-paid-in-full="entryPaidInFull"
        :event="event" :statusMessage="STRINGS.ENTRY_LAST_CHANCE_MESSAGE" />
      <entry-payment-completion-status v-else :entry-exempt-from-payment="entryExemptFromPayment" :entry-paid-in-full="entryPaidInFull"
        :entry-progress-percentage="Math.round(entryProgressPercentage)" :event="event" />
    </template>

    <template v-if="$slots.avatars" v-slot:avatars>
      <slot name="avatars"></slot>
    </template>
  </entry-card-layout>
</template>

<script>
import STRINGS from '@/utils/strings.json'

import EntryCardLayout from '@/components/events/entryCard/EntryCardLayout.vue'
import EntryCardTitle from '@/components/events/entryCard/EntryCardTitle.vue'
import EntryPaymentCompletionStatus from '@/components/events/entryCard/EntryPaymentCompletionStatus.vue'

export default {
  setup() {
    return {
      STRINGS
    }
  },
  
  components: {
    EntryCardLayout,
    EntryCardTitle,
    EntryPaymentCompletionStatus
  },

  props: {
    dataTestId: {
      type: String,
      required: false,
      default: ''
    },

    entryExemptFromPayment: {
      type: Boolean,
      default: false
    },

    entryPaidIfNecessary: {
      type: Boolean,
      required: true,
    },

    entryPaidInFull: {
      type: Boolean,
      required: true,
    },

    entryPredictionsRemaining: {
      type: Number,
      required: false,
    },

    entryProgressPercentage: {
      type: Number,
      required: false,
    },

    entryTitle: {
      type: String,
      required: true
    },

    event: {
      type: Object,
      required: true
    },

    eventStatus: {
      type: String,
      required: true
    }
  },

  computed: {
    entryIsDisqualified() {
      return this.event.paidEvent && !this.event.acceptingPayments && !this.entryPaidIfNecessary
    },

    lastChanceToEnter() {
      return this.event.participationEnded && this.event.acceptingPayments && !this.entryPaidIfNecessary
    },

    showEntryScores() {
      return (this.event.popularVoteTournament == false) && this.event.participationEnded && this.entryPaidIfNecessary
    }
  }
}
</script>
