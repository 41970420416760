export const validBreakpoints = [432, 512, 576, 640, 768, 896, 1024]
export const validHeroIconSizes = [16, 20, 24]

export default {
  getBackgroundBlurClass(darkBackground) {
    return darkBackground ? 'dark-opacity-blur' : ''
  },

  getDropShadowClass(darkBackground) {
    return darkBackground ? 'dark-drop-shadow' : 'light-drop-shadow'
  },

  getEditableClass(isEditable) {
    return isEditable ? 'editable' : 'not-editable'
  },

  getLightDarkClasses(darkBackground) {
    return darkBackground ? 'dark-background' : 'light-background'
  },

  getMobileDesktopClasses(isMobile) {
    return isMobile ? 'mobile' : 'desktop'
  },

  getSelectedClasses(isSelected, hasSelector = true) {
    if (!hasSelector) return ''
    if (isSelected) return 'selected'
    return 'unselected'
  }
}