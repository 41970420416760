<template>
  <div v-if="!isLoading" id="bracket-prediction-outer" class="flex col gap-1-5"
    :class="{ 'dark-background': darkBackground, 'light-background': !darkBackground }">
    <div class="flex space-between align-center">
      <header-action-bar :dark-background="darkBackground">
        <template v-slot:linkBackTo>
          <link-back-to :router-link-to="{ name: 'events' }" page-name="Events" :dark-background="darkBackground" />
        </template>
        <template v-slot:secondaryAction1>
          <rebel-button v-if="event.infoUrl" :dark-background="darkBackground" @click="goToEventInfo" type="ghosted"
            color="default" text="Event Info" class="nowrap">
            <template v-slot:icon-leading>
              <arrow-top-right-on-square-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>
      </header-action-bar>
    </div>

    <pick-em-header :dark-background="darkBackground" :is-user-specific-view="false"
      :results-pending="event.resultsPending" :sub-text="eventSubText" :show-admin-data="false"
      :show-league-data="false" :event="event" />

    <weight-class-selector :show-overall="false" :selected-value="selectedWeightClass"
      :weight-classes="event?.weightClasses.weightClasses" :units="event?.weightClasses.units"
      @selected-weight-class-change="weightClassChange"></weight-class-selector>

    <div ref="bracketPredictionElem" id="bracket-prediction" :class="styles.getLightDarkClasses(darkBackground)">
      <div v-if="selectedBracket" class="bracket-outer">
        <div class="rounds">
          <div v-for="(round, roundIndex) in selectedBracket.rounds" :key="round.round" class="round"
            :class="{ 'play-in': round.playIn }" :style="`--round: ${round.playIn ? 0 : Math.pow(2, round.round - 1)};`">
            <h3>
              {{ round.roundName }}
            </h3>

            <div class="round-wrapper"
              :style="`height: ${selectedBracket.rounds[this.indexOfRoundWithMostMatches].matchups.length * 80}px;`">
              <div class="games" :class="styles.getLightDarkClasses(darkBackground)">
                <div class="game" :id="`game${matchup.matchupId}`"
                  :data-testid="`round${roundIndex + 1}-matchup${matchIndex + 1}`"
                  v-for="(matchup, matchIndex) in getMatchupsWithFiller(round, selectedBracket.rounds[roundIndex + 1])"
                  :key="matchup.matchupId" :class="{
                    hideFillerMatchup: matchup.isFillerForPlayInRound,
                    'first-round': round.playIn || round.round == 1,
                    'visibility-hidden': isInHiddenList(matchup.matchupId),
                  }">
                  <bracket-match-single :darkBackground="darkBackground" :isEditable="false" :matchup="matchup"
                    :matchIndex="matchIndex" :roundIndex="roundIndex" :roundNum="round.round" userPredictionState=""
                    whoIsEditing="user" />
                  <div class="connector-last-round"
                    v-if="(roundIndex == selectedBracket.rounds?.length - 1 && round?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex - 1]?.matchups?.length === 1)">
                    <div class="connector-after-last-round"></div>
                  </div>
                  <div class="connector connector-top"
                    v-else-if="(round.playIn || matchup.nextMatchupOrder == 1) && !(roundIndex + 1 == selectedBracket.rounds?.length - 1 && selectedBracket?.rounds[roundIndex + 1]?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex]?.matchups?.length === 1)">
                  </div>
                  <div class="connector connector-bottom"
                    v-else-if="matchup.nextMatchupOrder == 2 && !(roundIndex + 1 == selectedBracket.rounds?.length - 1 && selectedBracket?.rounds[roundIndex + 1]?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex]?.matchups?.length === 1)">
                  </div>
                  <div class="connector-before"
                    v-if="!(roundIndex == selectedBracket.rounds?.length - 1 && round?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex - 1]?.matchups?.length === 1) && ((matchup.prevMatchup1Id != null && !isInHiddenList(matchup.prevMatchup1Id)) || (matchup.prevMatchup2Id != null && !isInHiddenList(matchup.prevMatchup2Id)))">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedBracket.rounds.length > 0" class="round"
            :style="`--round: ${Math.pow(2, championMatchup.round - 1)};`">
            <h3>{{ championMatchup.roundName }}</h3>
            <div class="round-wrapper"
              :style="`height: ${selectedBracket.rounds[this.indexOfRoundWithMostMatches].matchups.length * 80}px;`">
              <div class="games">
                <div class="game" data-testid="champion-matchup">
                  <div class="connector-last-round">
                    <div class="connector-after-last-round"></div>
                  </div>
                  <match-participant-container :dark-background="darkBackground" :isStackedLayout="true">
                    <template v-slot:content>
                      <match-participant
                        :actualResultState="eventResultStates.getActualResultStateForBracket(championMatchup, championMatchup.participant1Id)"
                        :darkBackground="darkBackground" :isFixedHeight="true" :hasSelector="false" :iconSize="20"
                        :isEditable="false" :isSelected="false" :isStackedLayout="true"
                        :participantName="abbreviateAsNeeded(championMatchup.round, championMatchup.participant1Name)"
                        :participantRank="championMatchup.participant1Ranking" />
                    </template>
                  </match-participant-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <pig-explanation v-if="bracketHasPig" :darkBackground="darkBackground"></pig-explanation>
  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import { useEventAdminStore } from '@/stores/eventAdmin'

import DateUtils from '@/utils/dateUtils.js'
import eventResultStates from '@/utils/eventResultStates.js'
import styles from '@/utils/styles.js'
import eventService from '@/services/eventService.js'
import eventBracketService from '@/services/EventBracketService.js'

import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'
import BracketMatchSingle from '@/components/event-admin/brackets/BracketMatchSingle.vue'
import HeaderActionBar from '@/components/HeaderActionBar.vue'
import LinkBackTo from '@/components/LinkBackTo.vue'
import MatchParticipant from '@/components/event-matches/MatchParticipant.vue'
import MatchParticipantContainer from '@/components/event-matches/MatchParticipantContainer.vue'
import PickEmHeader from '@/components/feature/pick-em/header/PickEmHeader.vue'
import PigExplanation from '@/components/PigExplanation.vue'
import RebelButton from '@/components/RebelButton.vue'
import WeightClassSelector from '@/components/WeightClassSelector.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      eventAdminStore: useEventAdminStore(),
      eventResultStates,
      styles
    }
  },

  components: {
    ArrowTopRightOnSquareIcon,
    BracketMatchSingle,
    HeaderActionBar,
    LinkBackTo,
    MatchParticipant,
    MatchParticipantContainer,
    PickEmHeader,
    PigExplanation,
    RebelButton,
    WeightClassSelector
  },

  async created() {
    try {
      const response = await eventService.getById(this.eventId)
      this.event = { ...response.data }
      this.eventAdminStore.initialize(this.event)
      this.selectedWeightClass = this.event.weightClasses?.weightClasses[0]
      await this.loadBrackets()

      // if the event is upcoming, redirect to not found
      if (this.event.upcoming) {
        await this.$router.push({ name: 'not-found' })
      }
    } catch {
      alert('Something went wrong fetching event data')
    } finally {
      setTimeout(this.setIsBracketScrollingHorizontally, 1)

      this.isLoading = false
    }
  },

  data() {
    return {
      brackets: [],
      darkBackground: this.$route.meta.darkBackground,
      event: {},
      isBracketScrollingHorizontally: false,
      isLoading: true,
      selectedWeightClass: undefined
    }
  },

  computed: {
    eventSubText() {
      if (this.event.bracketTournament) return this.formatDateTime(this.event.startDateTime)

      if (this.event.popularVoteTournament) {
        if (!this.event.published) return 'Voting begins when brackets are published'
        if (!this.event.participationEnded) return 'Voting ends ' + this.formatDateTime(this.event.startDateTime)
        return 'Voting ended ' + this.formatDateTime(this.event.startDateTime)
      }

      return ''
    },

    bracketHasPig() {
      if (this.selectedBracket == null) return false

      return this.selectedBracket.rounds
        .flatMap((r) => r.matchups)
        .some((m) => {
          return this.isPigParticipant(m.participant1Name) || this.isPigParticipant(m.participant2Name)
        })
    },

    championRoundIndex() {
      return this.selectedBracket.rounds.length
    },

    finalMatchup() {
      const finalRound = this.selectedBracket.rounds[this.selectedBracket.rounds.length - 1]
      if (finalRound == null || !finalRound.matchups.length) return {}
      return finalRound?.matchups[0]
    },

    championMatchup() {
      if (this.selectedBracket.rounds.length == 0) return {}

      const finalRound = this.selectedBracket.rounds[this.selectedBracket.rounds.length - 1]
      if (finalRound == null || !finalRound.matchups.length) return {}
      const finalMatchup = this.finalMatchup

      let winningParticipant = {
        participantRanking: '',
        participantName: ''
      }

      if (finalMatchup?.winner != null) {
        if (finalMatchup.participant1Id == finalMatchup.winner) {
          winningParticipant = {
            participantId: finalMatchup.participant1Id,
            participantRanking: finalMatchup.participant1Ranking,
            participantName: finalMatchup.participant1Name
          }
        } else if (finalMatchup.participant2Id == finalMatchup.winner) {
          winningParticipant = {
            participantId: finalMatchup.participant2Id,
            participantRanking: finalMatchup.participant2Ranking,
            participantName: finalMatchup.participant2Name
          }
        }
      }

      return {
        round: finalRound.round + 1,
        roundName: 'Champion',
        participant1Ranking: winningParticipant.participantRanking,
        participant1Name: winningParticipant.participantName,
        prevMatchup1Id: finalMatchup.matchupId,
        winner: finalMatchup.winner,
        participant1Id: winningParticipant.participantId
      }
    },

    eventId() {
      return this.$route.params.eventId
    },

    getBracketBorderClass() {
      return (!this.darkBackground && this.isBracketScrollingHorizontally) ? 'border' : ''
    },

    hiddenList() {
      if (this.selectedBracket == null) return []

      const allMatchups = this.selectedBracket?.rounds.flatMap(r => r.matchups)
      const allMatchupsMap = []
      allMatchups.forEach(matchup => {
        allMatchupsMap[matchup.matchupId] = matchup
      })

      return allMatchups
        .filter(m => m.participant1Name?.toUpperCase() == 'BYE' && m.participant2Name?.toUpperCase() == 'BYE')
        .concat(allMatchups.filter(m => (m.participant1Name?.toUpperCase() == 'BYE' || m.participant2Name?.toUpperCase() == 'BYE') && m.nextMatchupId != null && (allMatchupsMap[m.nextMatchupId]?.participant1Name?.toUpperCase() == m.participant1Name?.toUpperCase() || allMatchupsMap[m.nextMatchupId]?.participant2Name?.toUpperCase() == m.participant1Name?.toUpperCase()) && ((allMatchupsMap[m.nextMatchupId]?.participant1Name?.toUpperCase() == m.participant2Name?.toUpperCase() || allMatchupsMap[m.nextMatchupId]?.participant2Name?.toUpperCase() == m.participant2Name?.toUpperCase()))))
        .map(m => m.matchupId)
    },

    indexOfRoundWithMostMatches() {
      let index = -1
      let currentMax = 0

      this.selectedBracket?.rounds?.forEach((round, i) => {
        if (round.matchups.length > currentMax) {
          index = i
          currentMax = round.matchups.length
        }
      })

      return index
    },

    selectedBracket() {
      if (this.selectedWeightClass === -1 || this.event?.brackets == null || !this.event.brackets.length) {
        return null
      }

      return this.event.brackets.find((bracket) => bracket.weightClass === this.selectedWeightClass)
    }
  },

  methods: {
    goToEventInfo() {
      window.open(this.event.infoUrl, '_blank')
    },

    isInHiddenList(matchupId) {
      return this.hiddenList.includes(matchupId)
    },

    abbreviateAsNeeded(roundNumber, name) {
      if (name == null) return ''

      if (roundNumber <= 1) return name

      name = name.trim()

      const rankRegex = /^\d+|^\(\s*\d+\s*\)/
      const schoolStarterRegex = /\(|\[/

      let rank = ''

      const rankingMatches = name.match(rankRegex)
      if (rankingMatches != null) {
        // ranking is present. extract ranking and revise name
        rank = rankingMatches[0] + ' '
        name = name.substring(rank.length).trim()
      }

      if (name.match(schoolStarterRegex) != null) {
        name = name.split(schoolStarterRegex)[0].trim().replace('\t', ' ')
      }

      return rank + name
    },

    isWinner(matchup, participantId) {
      return matchup.winner != null && matchup.winner == participantId
    },

    getMatchupsWithFiller(round, nextRound) {
      if (!round.playIn || nextRound == null) return round.matchups

      // add filler matchup for the UI

      // find out which matchup is the next up and line up this matchup with it by adding filler
      // before it. then add filler after as needed

      const fakeMatchup = {
        participant1Id: null,
        participant1Name: '',
        participant1Ranking: '',
        participant2Id: null,
        participant2Name: '',
        participant2Ranking: '',
        winner: null,
        isFillerForPlayInRound: true
      }

      // go through each round 1 matchup. for each participant that does not have a prevMatchup associated
      // add a filler matchup in play in round
      const currRoundMatchupsWithFiller = []
      let playInMatchupCounter = 0
      let currPlayInMatchup = round.matchups[playInMatchupCounter]
      for (let i = 0; i < nextRound.matchups.length; i++) {
        const matchup = nextRound.matchups[i]
        if (matchup.prevMatchup1Id != null && matchup.prevMatchup2Id != null) {
          // add both
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
        } else if (matchup.prevMatchup1Id == null && matchup.prevMatchup2Id == null) {
          currRoundMatchupsWithFiller.push({ ...fakeMatchup })
        } else {
          // one matchup
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
        }
      }

      return currRoundMatchupsWithFiller
    },

    hasParticipant(matchup, participantNumber) {
      if (!matchup || !(participantNumber == 1 || participantNumber == 2)) return false

      if (participantNumber == 1) return matchup.participant1Id
      return matchup.participant2Id
    },

    hasWinner(matchup) {
      return matchup.winner != null
    },

    async loadBrackets() {
      const responseBrackets = await eventBracketService.getAll(this.eventId)
      this.event.brackets = responseBrackets.data
      setTimeout(this.updateMatchupLines, 1)
    },

    weightClassChange(weightClass) {
      this.selectedWeightClass = weightClass
      setTimeout(this.setIsBracketScrollingHorizontally, 1)
      setTimeout(this.updateMatchupLines, 1)
    },

    formatDateTime(date) {
      return DateUtils.formatDateTime(date, this.authStore.loggedInUser?.user?.timeZoneSetting)
    },

    setIsBracketScrollingHorizontally() {
      const bracketPredictionElement = this.$refs.bracketPredictionElem

      if (bracketPredictionElement != null) {
        this.isBracketScrollingHorizontally = bracketPredictionElement.scrollWidth > bracketPredictionElement.clientWidth
      } else {
        this.isBracketScrollingHorizontally = false
      }
    },

    isPigParticipant(name) {
      if (name == null) return false
      return name.includes('🐷 PIG')
    },

    updateMatchupLines() {
      if (this.selectedBracket == null) return

      const allMatchups = this.selectedBracket.rounds.flatMap((round) => round.matchups)
      const gameElements = document.querySelectorAll('.game')
      const idPrefix = 'game'

      for (const gameElement of gameElements) {
        const gameMatchupId = gameElement.id.substring(idPrefix.length)
        const gameMatchup = allMatchups.find(m => m.matchupId == gameMatchupId)

        if (gameMatchup && gameMatchup.nextMatchupId != null) {
          const nextGameElement = document.getElementById(`${idPrefix}${gameMatchup.nextMatchupId}`)
          const matchupYPos = gameElement.getBoundingClientRect().y
          const nextMatchupYPos = nextGameElement.getBoundingClientRect().y
          const deltaHeight = Math.abs(nextMatchupYPos - matchupYPos)
          const connectorElement = gameElement.querySelector('.connector')

          if (connectorElement == null) continue

          connectorElement.style.height = deltaHeight + 'px'

          if (nextMatchupYPos > matchupYPos) {
            connectorElement.classList.add('connector-top')
            connectorElement.classList.remove('connector-bottom')
          } else {
            connectorElement.classList.add('connector-bottom')
            connectorElement.classList.remove('connector-top')
          }
        }
      }
    },
  },

  mounted() {
    window.addEventListener('resize', this.updateMatchupLines)
    window.addEventListener('resize', this.setIsBracketScrollingHorizontally)
  },

  unmounted() {
    try {
      window.removeEventListener('resize', this.updateMatchupLines)
      window.removeEventListener('resize', this.setIsBracketScrollingHorizontally)
    } catch {
      /* empty */
    }
  }
}
</script>


<style scoped>
#bracket-prediction {
  --first-game-width: 290px;
  --game-width: 210px;
  --round-spacing: 12px;
  --player-height: 24px;
  --game-vertical-spacing: 32px;
  --border-width: 1px;
  overflow-x: auto;
}

@media screen and (max-width: 512px) {
  #bracket-prediction {
    --first-game-width: 260px;
    --game-width: 180px;
  }
}

.bracket-outer {
  width: fit-content;
}

#bracket-prediction.light-background {
  border: 1px solid var(--color-gray2);
}

#bracket-prediction.dark-background {
  background-color: var(--color-gray4-opacity50);
  border: 1px solid var(--color-gray4);
  border-radius: 8px;
  box-shadow: inset 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
}

.rounds {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: var(--color-gray1);
}

.dark-background .rounds {
  background-color: transparent;
}

.round h3 {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  border-bottom: 1px solid var(--color-gray2);
  background-color: var(--color-gray0-5);
}

.dark-background .round h3 {
  border-bottom: 1px solid var(--color-black);
  background-color: var(--color-gray4-opacity50);
  backdrop-filter: blur(1rem);
  color: var(--color-white);
}

.games {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
}

.round-wrapper {
  display: flex;
  padding: calc(var(--round-spacing) / 2);
}

.round:first-of-type .round-wrapper {
  padding-left: var(--round-spacing);
}

.round:last-of-type .round-wrapper {
  padding-right: var(--round-spacing);
}

.game {
  position: relative;
  width: var(--game-width);
}

.light-background .game {
  border: 0.5px solid var(--color-gray1);
  box-shadow: 2px 4px 6px 0px #00000066;
  background-color: var(--color-white);
}

.dark-background .game {
  border-color: var(--color-gray4-opacity50);
  border-radius: 8px;
}

.game.first-round {
  width: var(--first-game-width);
}

.connector {
  border: var(--border-width) solid var(--color-gray3);
  border-left: none;

  height: 60px;
  width: calc(var(--round-spacing) / 2 - 1px);

  position: absolute;
  z-index: 1;
  left: calc(var(--game-width) + 2px);
}

.connector-top {
  border-bottom: none;
  top: 50%;
}

.connector-bottom {
  border-top: none;
  bottom: 50%;
}

.play-in .connector {
  border-right: 0;
}

.connector-before {
  position: absolute;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: var(--border-width) solid var(--color-gray3);
  width: calc(var(--round-spacing) / 2 - 1px);
  left: calc(var(--round-spacing) * -.5 + 1px);
  top: calc(50% - var(--border-width) / 2);
}

.connector-top>.connector-before {
  top: 100%;
}

.connector-bottom>.connector-before {
  top: 0;
}

.first-round .connector {
  left: calc(var(--first-game-width) + 2px);
}

.hideFillerMatchup {
  visibility: hidden;
}

/* last round (Champion) specific styles */

.connector-last-round {
  width: 0;
  height: 0;
  border-left: none;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.connector-after-last-round {
  position: absolute;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: var(--border-width) solid var(--color-gray3);
  width: calc(var(--round-spacing));
  left: calc(var(--round-spacing) * -1);
}

.dark-background .connector,
.dark-background .connector-before,
.dark-background .connector-after,
.dark-background .connector-after-last-round {
  border-color: var(--color-dark-orange);
}

.visibility-hidden {
  visibility: hidden;
}
</style>