<template>
  <div class="pill small-caps-1 flex space-between align-end gap-0-5">
    {{ text }}
    <x-mark-icon class="pointer icon-14 gray2" @click="close" />
  </div>
</template>

<script>
import emitEvents from '../utils/emitEvents.js'
import { XMarkIcon } from '@heroicons/vue/20/solid';


export default {
  props: ['text'],

  components: {
    XMarkIcon
  },

  emits: [emitEvents.CLOSE],

  methods: {
    close() {
      this.$emit(emitEvents.CLOSE)
    }
  }
}
</script>

<style scoped>
.pill {
  background-color: var(--color-gray1);
  border-radius: 8px;
  color: var(--color-black);
  white-space: nowrap;
  width: fit-content;
  box-sizing: border-box;
  padding: 0.25rem 0.5rem;
}
</style>