<template>
  <div v-if="!isLoading" id="bracket-prediction-outer" class="flex col gap-1-5"
    :class="{ 'dark-background': darkBackground, 'light-background': !darkBackground }">
    <div v-if="adminViewingAnotherUsersData">
      <h3 class="heading3" :class="{ 'dark-background': darkBackground }">Viewing as: {{ myLeagueMember?.user?.displayName
      }}</h3>
    </div>
    
    <div>
      <header-action-bar v-if="adminViewingAnotherUsersData" :breakpointOuter="768" :breakpointMid="512" :breakpointInner="432" :dark-background="darkBackground">
        <template v-slot:linkBackTo>
          <link-back-to :router-link-to="{ name: 'admin-dashboard' }" page-name="Admin Dashboard" :dark-background="darkBackground" />
        </template>
        <template v-if="entryHistoryMetaData != null && entryHistoryMetaData.length" v-slot:primaryAction1 >
          <rebel-select :key="selectedEntryHistoryOption" :dark-background="darkBackground"
            :selectedValue="selectedEntryHistoryOption" :selectorOptions="entryHistoryOptions"
            @selected-value-changed="updateSelectedEntryHistoryOption" />
        </template>
        <template v-if="league.event.infoUrl" v-slot:secondaryAction1>
          <rebel-button :dark-background="darkBackground" @click="goToEventInfo" type="ghosted" color="default"
            text="Event Info" class="nowrap">
            <template v-slot:icon-leading>
              <arrow-top-right-on-square-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>
        <template v-if="entryHistoryMetaData != null && entryHistoryMetaData.length" v-slot:secondaryAction2>
          <rebel-button :dark-background="darkBackground" @click="entryVersionHistoryModal.isShowing = true"
            type="ghosted" color="default" text="View All Version History" class="nowrap" />
        </template>
      </header-action-bar>
      <header-action-bar v-else :breakpointOuter="512" :breakpointInner="432" :dark-background="darkBackground">
        <template v-slot:linkBackTo>
          <link-back-to v-if="adminViewingAnotherUsersData" :router-link-to="{ name: 'admin-dashboard' }"
            page-name="Admin Dashboard" :dark-background="darkBackground" />
          <link-back-to v-else
            :router-link-to="{ name: 'league-details', params: { leagueId: league.leagueId }, query: { userId: $route.query?.userId } }"
            :page-name="league.name" :dark-background="darkBackground" />
        </template>
        <template v-slot:primaryAction1>
          <rebel-select :dark-background="darkBackground" :selectedValue="selectedBracketViewing"
            :selectorOptions="bracketSelectorOptions"
            @selected-value-changed="updateBracketDisplaying" />
        </template>
        <template v-if="league.event.infoUrl" v-slot:secondaryAction1>
          <rebel-button :dark-background="darkBackground" @click="goToEventInfo" type="ghosted" color="default"
            text="Event Info" class="nowrap">
            <template v-slot:icon-leading>
              <arrow-top-right-on-square-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>
      </header-action-bar>
    </div>

    <pick-em-header :dark-background="darkBackground" :results-pending="league?.event?.resultsPending" :sub-text="eventSubText" :show-admin-data="false"
      :show-league-data="viewingActualResults === false" :event="league?.event" 
      :league-data="pickEmHeaderLeagueData" :leagues="allLeagueOptions" :league-id="league.leagueId" 
      @league-changed="leagueChanged" :key="`header-${lastLoadedTime}`" />

    <weight-class-selector :show-overall="false" :selected-value="selectedWeightClass"
      @selected-weight-class-change="weightClassChange" :weightClasses="league?.event?.weightClasses.weightClasses"
      :units="league?.event?.weightClasses.units"></weight-class-selector>

    <div ref="bracketPredictionElem" id="bracket-prediction" :class="styles.getLightDarkClasses(darkBackground)">
      <div v-if="selectedBracket" class="bracket-outer">
        <div class="rounds">
          <div v-for="(round, roundIndex) in selectedBracket.rounds" :key="round.round" class="round"
            :class="{ 'play-in': round.playIn }" :style="`--round: ${round.playIn ? 0 : Math.pow(2, round.round - 1)};`">
            <h3>
              {{ round.roundName }}
            </h3>

            <div class="round-wrapper"
              :style="`height: ${selectedBracket.rounds[this.indexOfRoundWithMostMatches].matchups.length * 80}px;`">
              <div class="games" :class="styles.getLightDarkClasses(darkBackground)">
                <div class="game" :id="`game${matchup.matchupId}`"
                  v-for="(matchup, matchIndex) in getMatchupsWithFiller(round, selectedBracket.rounds[roundIndex + 1])"
                  :key="matchup.matchupId" :class="{
                    hideFillerMatchup: matchup.isFillerForPlayInRound,
                    'first-round': round.playIn || round.round == 1,
                    'visibility-hidden': isInHiddenList(matchup.matchupId),
                    'shift-up':
                      !viewingActualResults &&
                      !isMatchupABye(matchup.prevMatchup1Id) &&
                      matchup.prevMatchup1Id && !matchup.prevMatchup2Id,
                    'shift-down':
                      !viewingActualResults &&
                      !isMatchupABye(matchup.prevMatchup1Id) &&
                      matchup.prevMatchup2Id && !matchup.prevMatchup1Id,
                  }">
                  <bracket-match-status-bar
                    v-if="matchup.prevMatchup1Id && !viewingActualResults && !isMatchupABye(matchup.prevMatchup1Id)"
                    :darkBackground="darkBackground" :isEliminated="isEliminatedByRound(roundIndex - 1, matchup, 1)"
                    :isPredictedToWin="getPredictionForMatchupId(matchup.matchupId) == getPredictedParticipant(matchup, 1).participantId"
                    :participantName="abbreviateAsNeeded(round.round, getPredictedParticipant(matchup, 1).participantName)"
                    :userPredictionState="getUserPredictionState(matchup, isEliminatedByRound(roundIndex - 1, matchup, 1))" />
                  <bracket-match-single :darkBackground="darkBackground" :isEditable="false" :matchup="matchup"
                    :matchIndex="matchIndex" :roundIndex="roundIndex" :roundNum="round.round"
                    :predictedWinnerId="getPredictionForMatchupId(matchup.matchupId)"
                    :userPredictionState="getUserPredictionState(matchup)" whoIsEditing="user" />
                  <bracket-match-status-bar
                    v-if="matchup.prevMatchup2Id && !viewingActualResults && !isMatchupABye(matchup.prevMatchup2Id)"
                    :darkBackground="darkBackground" :isEliminated="isEliminatedByRound(roundIndex - 1, matchup, 2)"
                    :isPredictedToWin="getPredictionForMatchupId(matchup.matchupId) == getPredictedParticipant(matchup, 2).participantId"
                    :participantName="abbreviateAsNeeded(round.round, getPredictedParticipant(matchup, 2).participantName)"
                    :userPredictionState="getUserPredictionState(matchup, isEliminatedByRound(roundIndex - 1, matchup, 2))" />
                  <div class="connector-last-round two-participants"
                    v-if="(roundIndex == selectedBracket.rounds?.length - 1 && round?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex - 1]?.matchups?.length === 1)">
                    <div class="connector-after-last-round"></div>
                  </div>
                  <div class="connector connector-top"
                    v-else-if="(round.playIn || matchup.nextMatchupOrder == 1) && !(roundIndex + 1 == selectedBracket.rounds?.length - 1 && selectedBracket?.rounds[roundIndex + 1]?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex]?.matchups?.length === 1)">
                  </div>
                  <div class="connector connector-bottom"
                    v-else-if="matchup.nextMatchupOrder == 2 && !(roundIndex + 1 == selectedBracket.rounds?.length - 1 && selectedBracket?.rounds[roundIndex + 1]?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex]?.matchups?.length === 1)">
                  </div>
                  <div class="connector-before"
                    v-if="!(roundIndex == selectedBracket.rounds?.length - 1 && round?.matchups?.length === 1 && selectedBracket?.rounds[roundIndex - 1]?.matchups?.length === 1) && ((matchup.prevMatchup1Id != null && !isInHiddenList(matchup.prevMatchup1Id)) || (matchup.prevMatchup2Id != null && !isInHiddenList(matchup.prevMatchup2Id)))">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedBracket.rounds.length > 0" class="round"
            :style="`--round: ${Math.pow(2, championMatchup.round - 1)};`">
            <h3>{{ championMatchup.roundName }}</h3>
            <div class="round-wrapper"
              :style="`height: ${selectedBracket.rounds[this.indexOfRoundWithMostMatches].matchups.length * 80}px;`">
              <div class="games">
                <div class="game" :class="{
                  'shift-up':
                    !viewingActualResults &&
                    !isMatchupABye(championMatchup.prevMatchup1Id) &&
                    predictedToWin(finalMatchup, 1, true),
                  'shift-down':
                    !viewingActualResults &&
                    !isMatchupABye(championMatchup.prevMatchup1Id) &&
                    predictedToWin(finalMatchup, 2, true)
                }">
                  <div class="connector-last-round">
                    <div class="connector-after-last-round"></div>
                  </div>
                  <bracket-match-status-bar
                    v-if="predictedToWin(finalMatchup, 1, true) && !viewingActualResults && !isMatchupABye(championMatchup.prevMatchup1Id)"
                    :darkBackground="darkBackground"
                    :isEliminated="isEliminatedByRound(championRoundIndex - 1, championMatchup, 1)"
                    :isPredictedToWin="getPredictionForMatchupId(finalMatchup.matchupId) == getPredictedParticipant(finalMatchup, 1).participantId"
                    :participantName="abbreviateAsNeeded(championMatchup.round, getPredictedParticipant(championMatchup, 1).participantName)"
                    :userPredictionState="getUserPredictionState(finalMatchup)" />
                  <match-participant-container :dark-background="darkBackground" :isStackedLayout="true">
                    <template v-slot:content>
                      <match-participant
                        :actualResultState="eventResultStates.getActualResultStateForBracket(championMatchup, championMatchup.participant1Id)"
                        :darkBackground="darkBackground" :isFixedHeight="true"
                        :hasSelector="getMatchupSelectionState(championMatchup, championMatchup.participant1Id)"
                        :iconSize="20" :isEditable="false"
                        :isSelected="getMatchupSelectionState(championMatchup, championMatchup.participant1Id)"
                        :isStackedLayout="true"
                        :participantName="abbreviateAsNeeded(championMatchup.round, championMatchup.participant1Name)"
                        :participantRank="championMatchup.participant1Ranking"
                        :userPredictionState="getUserPredictionState(finalMatchup)" />
                    </template>
                  </match-participant-container>
                  <bracket-match-status-bar
                    v-if="predictedToWin(finalMatchup, 2, true) && !viewingActualResults && !isMatchupABye(championMatchup.prevMatchup1Id)"
                    :darkBackground="darkBackground"
                    :isEliminated="isEliminatedByRound(championRoundIndex - 1, championMatchup, 2)"
                    :isPredictedToWin="getPredictionForMatchupId(finalMatchup.matchupId) == getPredictedParticipant(finalMatchup, 2).participantId"
                    :participantName="abbreviateAsNeeded(championMatchup.round, getPredictedParticipant(championMatchup, 1).participantName)"
                    :userPredictionState="getUserPredictionState(finalMatchup)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <pig-explanation v-if="bracketHasPig" :darkBackground="darkBackground"></pig-explanation>

    <round-scoring-summary v-if="selectedBracket && !viewingActualResults && !league.event.popularVoteTournament"
      :is-past-event="!league.event.resultsPending" :selected-bracket="selectedBracket" />

    <entry-version-history v-if="entryVersionHistoryModal.isShowing" :event-id="league.event.eventId"
      :league-id="league.leagueId" :user-id="userId" @close="entryVersionHistoryModal.isShowing = false"
      @view-history-entry="updateSelectedEntryHistoryOption" />
  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import { useEventAdminStore } from '@/stores/eventAdmin'

import DateUtils from '@/utils/dateUtils.js'
import eventResultStates from '@/utils/eventResultStates.js'
import styles from '@/utils/styles.js'
import entryHistoryService from '@/services/EntryHistoryService'
import leagueService from '@/services/leagueService.js'
import PubSub from 'pubsub-js'

import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'
import BracketMatchSingle from '@/components/event-admin/brackets/BracketMatchSingle.vue'
import BracketMatchStatusBar from '@/components/event-admin/brackets/BracketMatchStatusBar.vue'
import EntryVersionHistory from '@/components/EntryVersionHistory.vue'
import HeaderActionBar from '@/components/HeaderActionBar.vue'
import LinkBackTo from '@/components/LinkBackTo.vue'
import MatchParticipant from '@/components/event-matches/MatchParticipant.vue'
import MatchParticipantContainer from '@/components/event-matches/MatchParticipantContainer.vue'
import PickEmHeader from '@/components/feature/pick-em/header/PickEmHeader.vue'
import PigExplanation from '@/components/PigExplanation.vue'
import RebelButton from '@/components/RebelButton.vue'
import RebelSelect from '@/components/RebelSelect.vue'
import RoundScoringSummary from '@/components/leagues/RoundScoringSummary.vue'
import WeightClassSelector from '@/components/WeightClassSelector.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      eventAdminStore: useEventAdminStore(),
      eventResultStates,
      styles
    }
  },

  components: {
    ArrowTopRightOnSquareIcon,
    BracketMatchSingle,
    BracketMatchStatusBar,
    EntryVersionHistory,
    HeaderActionBar,
    LinkBackTo,
    MatchParticipant,
    MatchParticipantContainer,
    PickEmHeader,
    PigExplanation,
    RebelButton,
    RebelSelect,
    RoundScoringSummary,
    WeightClassSelector
  },

  async created() {
    const getLeaguePromise = leagueService.getById(this.leagueId)
    const getAllLeagueOptionsPromise = leagueService.getAllOptionsWithLeagueId(this.leagueId, this.userId)
    const res = await Promise.all([getLeaguePromise, getAllLeagueOptionsPromise])

    this.league = res[0].data
    this.eventAdminStore.initialize(this.league.event)
    this.allLeagueOptions = res[1].data

    // if the event is upcoming, redirect to edit page
    if (this.league.event.upcoming) {
      await this.$router.push({ name: 'league-brackets-edit', params: { leagueId: this.leagueId }, query: this.$route.query })
    }

    this.selectedWeightClass = this.league?.event?.weightClasses?.weightClasses[0] ?? '125'

    // get all of my brackets for this league
    // store them in the data of this component
    // const bracketsResponse = await leagueService.getMyBrackets(this.leagueId)

    const myMember = this.league.members.find((member) => member.user.id == this.userId)

    // if I haven't paid and I needed to, redirect me to league details page
    if (!myMember.paidIfNecessary) {
      this.goToLeagueDetailsPage()
    }

    console.log(myMember);
    this.brackets = myMember.brackets
    this.league.members.forEach((m) => (m.bracketsLoaded = true)) // leagueService.getById is loading all brackets at the moment
    this.setBracketsLoadedForMember(myMember, myMember.brackets)
    setTimeout(this.setIsBracketScrollingHorizontally, 1)
    setTimeout(this.updateMatchupLines, 1)

    // if I switched from another league, try to maintain viewing the same user
    const leagueUserViewing = this.bracketSelectorOptions.find(rso => rso.value === this.$route.query.viewingUserId)
    if (leagueUserViewing != null) {
      this.brackets = this.league.members.find(member => member.user.id == this.$route.query.viewingUserId)?.brackets
      this.selectedBracketViewing = leagueUserViewing.value;
    }

    // if I don't have predictions, default to actual results
    if (!myMember.hasAtLeastOnePrediction) {
      this.selectedBracketViewing = this.ACTUAL_RESULTS_VALUE
    }

    this.getEntryHistoryMetaData()

    this.lastLoadedTime = new Date().getMilliseconds()
    this.isLoading = false
  },

  data() {
    return {
      entryVersionHistoryModal: {
        isShowing: false
      },
      selectedEntryHistoryOption: -1,
      entryHistoryMetaData: [],

      allLeagueOptions: [],

      darkBackground: this.$route.meta.darkBackground,
      lastLoadedTime: new Date().getMilliseconds(),

      isBracketScrollingHorizontally: false,

      ACTUAL_RESULTS_VALUE: -1,
      selectedBracketViewing: this.$route.query.userId || this.authStore.loggedInUser.user.id,
      selectedWeightClass: undefined,
      brackets: [],
      isLoading: true,

      pigModal: {
        isShowing: false
      }
    }
  },

  computed: {
    viewingHistoricalEntry() {
      if (this.entryHistoryOptions.length === 0) return false

      // if we are not viewing the top-most entry, we are viewing a historical entry
      return this.entryHistoryOptions[0].value != this.selectedEntryHistoryOption
    },

    entryHistoryOptions() {
      return this.entryHistoryMetaData.map(metaData => {
        return {
          text: this.formatDateTimeWithSeconds(metaData.createdAt),
          value: metaData.entryId
        }
      })
    },

    adminViewingAnotherUsersData() {
      return this.$route.query?.userId != null && this.authStore.isDataAdmin
    },

    userId() {
      if (this.adminViewingAnotherUsersData) {
        return this.$route.query.userId
      }

      return this.authStore.loggedInUser?.user?.id
    },

    eventSubText() {
      if (this.league.event.bracketTournament) return this.formatDateTime(this.league.event.startDateTime)

      if (this.league.event.popularVoteTournament) {
        if (!this.league.event.published) return 'Voting begins when brackets are published'
        if (!this.league.event.participationEnded) return 'Voting ends ' + this.formatDateTime(this.league.event.startDateTime)
        return 'Voting ended ' + this.formatDateTime(this.league.event.startDateTime)
      }

      return ''
    },

    championRoundIndex() {
      return this.selectedBracket.rounds.length
    },

    finalMatchup() {
      const finalRound = this.selectedBracket.rounds[this.selectedBracket.rounds.length - 1]
      if (finalRound == null || !finalRound.matchups.length) return {}
      return finalRound?.matchups[0]
    },

    championMatchup() {
      if (this.selectedBracket.rounds.length == 0) return {}

      const finalRound = this.selectedBracket.rounds[this.selectedBracket.rounds.length - 1]
      if (finalRound == null || !finalRound.matchups.length) return {}
      const finalMatchup = this.finalMatchup

      const winningParticipant = this.getParticipantById(this.finalMatchup.winner)

      return {
        round: finalRound.round + 1,
        roundName: 'Champion',
        participant1Ranking: winningParticipant.participantRanking,
        participant1Name: winningParticipant.participantName,
        prevMatchup1Id: finalMatchup.matchupId,
        winner: finalMatchup.winner,
        participant1Id: winningParticipant.participantId
      }
    },

    bracketHasPig() {
      if (this.selectedBracket == null) return false

      return this.selectedBracket.rounds
        .flatMap((r) => r.matchups)
        .some((m) => {
          return this.isPigParticipant(m.participant1Name) || this.isPigParticipant(m.participant2Name)
        })
    },

    indexOfRoundWithMostMatches() {
      let index = -1
      let currentMax = 0

      this.selectedBracket?.rounds?.forEach((round, i) => {
        if (round.matchups.length > currentMax) {
          index = i
          currentMax = round.matchups.length
        }
      })

      return index
    },

    bracketSelectorOptions() {
      const options = []
      options.push({ text: 'Actual Results', value: this.ACTUAL_RESULTS_VALUE })

      if (this.authStore.loggedInUser == null) {
        return options
      }

      const myMember = this.league.members.find((member) => member.user.id == this.userId)

      if (myMember.hasAtLeastOnePrediction) {
        options.push({ text: myMember.user.displayName, value: this.userId })
      }

      const remainingLeagueMemberOptions = this.league.members
        .filter((m) => m.hasAtLeastOnePrediction)
        .flatMap((m) => m.user)
        .filter((u) => u.id != this.userId)
        .map((u) => {
          return { text: u.displayName, value: u.id }
        })

      options.push(...remainingLeagueMemberOptions)

      return options
    },

    hiddenList() {
      if (this.selectedBracket == null) return []

      const allMatchups = this.selectedBracket?.rounds.flatMap(r => r.matchups)
      const allMatchupsMap = []
      allMatchups.forEach(matchup => {
        allMatchupsMap[matchup.matchupId] = matchup
      })
      return allMatchups
        .filter(m => m.participant1Name?.toUpperCase() == 'BYE' && m.participant2Name?.toUpperCase() == 'BYE')
        .concat(allMatchups.filter(m => (m.participant1Name?.toUpperCase() == 'BYE' || m.participant2Name?.toUpperCase() == 'BYE') && m.nextMatchupId != null && (allMatchupsMap[m.nextMatchupId]?.participant1Name?.toUpperCase() == m.participant1Name?.toUpperCase() || allMatchupsMap[m.nextMatchupId]?.participant2Name?.toUpperCase() == m.participant1Name?.toUpperCase()) && ((allMatchupsMap[m.nextMatchupId]?.participant1Name?.toUpperCase() == m.participant2Name?.toUpperCase() || allMatchupsMap[m.nextMatchupId]?.participant2Name?.toUpperCase() == m.participant2Name?.toUpperCase()))))
        .map(m => m.matchupId)
    },

    leagueId() {
      return this.$route.params.leagueId
    },

    myLeagueMember() {
      return this.league?.members?.find(member => member.user.id == this.userId)
    },

    pickEmHeaderLeagueData() {
      const lastLoadedTime = this.lastLoadedTime // needed so that this computed refreshes
      const selectedLeagueMember = this.league?.members?.find(member => member.user.id == this.selectedBracketViewing)

      if (this.viewingHistoricalEntry) {
        return {
          leagueOptions: this.allLeagueOptions,
          pointsWon: '--',
          pointsPossible: '--',
          leagueRank: '-',
          leagueMemberCount: this.league?.members?.filter(m => m.hasAtLeastOnePrediction).length ?? 0,
          matnessRank: '-',
          matnessMemberCount: this.league?.event?.leagueMemberCount ?? 0,
          predictedTeam1Score: '--',
          predictedTeam2Score: '--',
        }
      }
      
      return {
        leagueOptions: this.allLeagueOptions,
        pointsWon: selectedLeagueMember?.leagueScore ?? 0,
        pointsPossible: selectedLeagueMember?.pointsPossible ?? 0,
        leagueRank: selectedLeagueMember?.leagueRankDisplay ?? '-',
        leagueMemberCount: this.league?.members?.filter(m => m.hasAtLeastOnePrediction).length ?? 0,
        matnessRank: selectedLeagueMember?.overallRankDisplay ?? '-',
        matnessMemberCount: this.league?.event?.leagueMemberCount ?? 0,
        lastLoadedTime // needed so that this computed refreshes
      }
    },

    selectedBracket() {
      if (this.selectedWeightClass === -1 || !this.brackets.length) {
        console.log('returning null')
        console.log(this.brackets)
        return null
      }

      const b = this.brackets.find((bracket) => bracket.weightClass == this.selectedWeightClass)
      console.log('return b')
      console.log(b);
      return b;
    },

    selectedLeagueMember() {
      return this.league?.members?.find(member => member.user.id == this.selectedBracketViewing)
    },

    viewingActualResults() {
      return this.selectedBracketViewing === this.ACTUAL_RESULTS_VALUE
    }
  },

  methods: {
    async getEntryHistoryMetaData() {
      if (!this.adminViewingAnotherUsersData) return 

      this.entryHistoryMetaData = []
      const currentUserEntryHistoryMetaData = await entryHistoryService.get(this.league.event.eventId, this.league.leagueId, this.userId)
      this.entryHistoryMetaData = currentUserEntryHistoryMetaData.data.entryHistoryMetaData

      if (this.entryHistoryMetaData == null || this.entryHistoryMetaData.length == 0) return

      this.selectedEntryHistoryOption = this.entryHistoryMetaData[0].entryId
      this.entryHistoryMetaData[0].brackets = this.brackets
    },
    async updateSelectedEntryHistoryOption(entryId) {
      this.selectedEntryHistoryOption = entryId

      const metaDataEntry = this.entryHistoryMetaData.find(md => md.entryId == entryId)
      // load data if we don't already have it 
      if (metaDataEntry.brackets == null) {
        const getPickEmUserMatchupsRes = await entryHistoryService.getForBracketEvent(entryId)
        metaDataEntry.brackets = getPickEmUserMatchupsRes.data.brackets
      }

      this.brackets = metaDataEntry.brackets
      this.lastLoadedTime = new Date().getMilliseconds()
      setTimeout(this.setIsBracketScrollingHorizontally, 1)
      setTimeout(this.updateMatchupLines, 1)
    },

    goToEventInfo() {
      window.open(this.league.event.infoUrl, '_blank')
    },

    goToLeagueDetailsPage() {
      this.$router.replace({ name: 'league-details', params: { leagueId: this.leagueId }, query: this.$route.query})
    },

    async leagueChanged(leagueIdToView) {
      await this.$router.push({ name: 'league-brackets-view', params: { leagueId: leagueIdToView }, query: { ...this.$route.query, viewingUserId: this.selectedLeagueUserViewing } })
    },

    isInHiddenList(matchupId) {
      return this.hiddenList.includes(matchupId)
    },

    changeLeagueName(topic, message) {
      const isMyLeague = message.leagueId != null && this.leagueId == message.leagueId

      if (isMyLeague && message.name != null) {
        this.league.name = message.name
      }
    },

    async reloadPage(topic, message) {
      try {
        const isEventMessage = message.eventId != null
        const isMyEvent = this.league.event.eventId == message.eventId
        const isMyLeague = this.leagueId == message.leagueId
        const isALeagueMessage = message.leagueId != null

        if (isEventMessage && !isMyEvent) return
        if (isALeagueMessage && !isMyLeague) return

        const response = await leagueService.getById(this.leagueId)
        if (response?.data?.members != null) {
          const userLeftLeague = this.league.members.length < response.data.members

          this.league = response.data

          const selectedMember = this.league.members.find((member) => member.user.id == this.selectedBracketViewing)
          const viewingBracketForUserThatLeftLeague = selectedMember == null

          if (this.viewingActualResults || (userLeftLeague && viewingBracketForUserThatLeftLeague)) {
            this.updateBracketDisplaying(this.ACTUAL_RESULTS_VALUE)
            // doesn't matter whose brackets we view when viewing actual results. just pick the first
            if (response.data.members.length) this.brackets = response.data.members[0]?.brackets
          } else {
            this.setBracketsLoadedForMember(selectedMember, selectedMember.brackets)
            this.brackets = selectedMember.brackets
          }

          this.lastLoadedTime = new Date().getMilliseconds()
        }
      } catch {
        // 
      }
    },

    abbreviateAsNeeded(roundNumber, name) {
      if (name == null) return ''

      if (roundNumber <= 1) return name

      name = name.trim()

      const rankRegex = /^\d+|^\(\s*\d+\s*\)/
      const schoolStarterRegex = /\(|\[/

      let rank = ''

      const rankingMatches = name.match(rankRegex)
      if (rankingMatches != null) {
        // ranking is present. extract ranking and revise name
        rank = rankingMatches[0] + ' '
        name = name.substring(rank.length).trim()
      }

      if (name.match(schoolStarterRegex) != null) {
        name = name.split(schoolStarterRegex)[0].trim().replace('\t', ' ')
      }

      return rank + name
    },

    getParticipantById(participantId) {
      const EMPTY_PARTICIPANT = {
        participantId: null,
        participantName: null,
        participantRanking: null
      }

      // TODO: create participants map on the backend or in created()
      const participants = new Map()

      const allMatchups = this.selectedBracket.rounds
        .flatMap((r) => r.matchups)

      for (const initialMatchup of allMatchups) {
        if (initialMatchup.participant1Id != null) {
          participants.set(initialMatchup.participant1Id, {
            participantId: initialMatchup.participant1Id,
            participantName: initialMatchup.participant1Name,
            participantRanking: initialMatchup.participant1Ranking
          })
        }
        if (initialMatchup.participant2Id != null) {
          participants.set(initialMatchup.participant2Id, {
            participantId: initialMatchup.participant2Id,
            participantName: initialMatchup.participant2Name,
            participantRanking: initialMatchup.participant2Ranking
          })
        }
      }

      if (!participants.has(participantId)) {
        return EMPTY_PARTICIPANT
      }

      return participants.get(participantId)
    },

    getPredictionForMatchupId(matchupId) {
      if (this.viewingActualResults) return undefined

      return this.selectedBracket.userMatchupMap[matchupId]?.projectedWinnerParticipantId
    },

    isMatchupABye(matchupId) {
      return this.selectedBracket.rounds.flatMap((r) => r.matchups).find((m) => m.matchupId == matchupId)?.bye
    },

    getPredictedParticipant(matchup, participantNumber) {
      let matchupPredictionParticipantId = null
      if (!this.hasPreviousMatchup(matchup)) {
        matchupPredictionParticipantId = this.getPredictionForMatchupId(matchup.matchupId)
      } else if (participantNumber == 1) {
        matchupPredictionParticipantId = this.getPredictionForMatchupId(matchup.prevMatchup1Id)
      } else if (participantNumber == 2) {
        matchupPredictionParticipantId = this.getPredictionForMatchupId(matchup.prevMatchup2Id)
      }

      return this.getParticipantById(matchupPredictionParticipantId)
    },

    hasPreviousMatchup(matchup) {
      return matchup.prevMatchup1Id != null || matchup.prevMatchup2Id != null
    },

    hasWinner(matchup) {
      return matchup.winner != null
    },

    isEliminatedByRound(roundIndex, matchup, participantNumber) {
      const predictionParticipant = this.getPredictedParticipant(matchup, participantNumber)
      if (predictionParticipant?.participantId == null) return false

      // find if a matchup that I participated in for this round and prior exists where there is a winner and I didn't win
      return this.selectedBracket.rounds
        .filter((r, ix) => ix <= roundIndex)
        .flatMap((r) => r.matchups)
        .some(
          (m) =>
            m.winner != null &&
            m.winner != predictionParticipant.participantId &&
            (m.participant1Id == predictionParticipant.participantId ||
              m.participant2Id == predictionParticipant.participantId)
        )
    },

    // Looks back at the previous matchup(s) to determine if I predicted this particpant advance from previous match into this one
    // determine if I have a prediction for this matchup and my prediction is or would have been this participant number (1 or 2)
    predictedToWin(matchup, participantNumber, isChampionMatchup = false) {
      if (isChampionMatchup) {
        const myPrediction = this.getPredictionForMatchupId(matchup.matchupId)
        if (participantNumber == 1 && myPrediction == matchup.participant1Id) return true
        if (participantNumber == 2 && myPrediction == matchup.participant2Id) return true
        if (participantNumber == 2 && myPrediction == matchup.participant1Id) return false
        if (participantNumber == 1 && myPrediction == matchup.participant2Id) return false
      }

      const myPrediction = this.getPredictionForMatchupId(matchup.matchupId)
      if (myPrediction == null) return false

      let roundIndex = 0
      const firstMatchupWithMyPrediction = this.selectedBracket.rounds[roundIndex].matchups.find(
        (m) => m.participant1Id == myPrediction || m.participant2Id == myPrediction
      )

      let currMatchup = firstMatchupWithMyPrediction

      // for first round, just check if I predicted the participant
      if (currMatchup != null && currMatchup.matchupId == matchup.matchupId) {
        if (participantNumber == 1) return myPrediction == currMatchup.participant1Id
        if (participantNumber == 2) return myPrediction == currMatchup.participant2Id
      }

      // try going from the beginning to determine if i can find the match with my prediction
      while (
        currMatchup != null &&
        currMatchup.nextMatchupId != null &&
        currMatchup.nextMatchupId != matchup.matchupId &&
        roundIndex < this.selectedBracket.rounds.length
      ) {
        roundIndex++
        currMatchup = this.selectedBracket.rounds[roundIndex].matchups.find((m) => currMatchup.nextMatchupId == m.matchupId)
      }

      if (currMatchup != null && currMatchup.nextMatchupId != null && currMatchup.nextMatchupId == matchup.matchupId) {
        return participantNumber == currMatchup.nextMatchupOrder
      }

      // find prev matchup
      const prevMatchups = this.selectedBracket
        .rounds
        .flatMap(r => r.matchups)
        .filter(m => m.matchupId == matchup.prevMatchup1Id || m.matchupId == matchup.prevMatchup2Id)
        .filter(m => m.nextMatchupOrder == participantNumber)

      for (let prevMatchup of prevMatchups) {
        const myPrediction = this.getPredictionForMatchupId(prevMatchup.matchupId)
        if (myPrediction == prevMatchup.participant1Id || myPrediction == prevMatchup.participant2Id) {
          if (participantNumber == prevMatchup.nextMatchupOrder) return true
        }
      }

      return false
    },

    // Returns true if the match has a winner and I predicted it correctly
    predictedWinner(matchup) {
      if (!this.hasWinner(matchup)) return false

      const currMatchupPrediction = this.selectedBracket.userMatchupMap[matchup.matchupId]

      if (currMatchupPrediction == null) return false

      return currMatchupPrediction.projectedWinnerParticipantId == matchup.winner
    },

    getUserPredictionState(matchup, isEliminated = false) {
      return eventResultStates.getUserPredictionStateForBracket(matchup, 'user', false, this.getPredictionForMatchupId(matchup.matchupId), isEliminated)
    },

    getMatchupSelectionState(matchup, participantId) {
      return this.getPredictionForMatchupId(matchup.matchupId) == participantId
    },

    isWinner(matchup, participantId) {
      return matchup.winner != null && matchup.winner == participantId
    },

    getMatchupsWithFiller(round, nextRound) {
      if (!round.playIn || nextRound == null) return round.matchups

      // add filler matchup for the UI

      // find out which matchup is the next up and line up this matchup with it by adding filler
      // before it. then add filler after as needed

      const fakeMatchup = {
        participant1Id: null,
        participant1Name: '',
        participant1Ranking: '',
        participant2Id: null,
        participant2Name: '',
        participant2Ranking: '',
        winner: null,
        isFillerForPlayInRound: true
      }

      // go through each round 1 matchup. for each participant that does not have a prevMatchup associated
      // add a filler matchup in play in round
      const currRoundMatchupsWithFiller = []
      let playInMatchupCounter = 0
      let currPlayInMatchup = round.matchups[playInMatchupCounter]
      for (let i = 0; i < nextRound.matchups.length; i++) {
        const matchup = nextRound.matchups[i]
        if (matchup.prevMatchup1Id != null && matchup.prevMatchup2Id != null) {
          // add both
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
        } else if (matchup.prevMatchup1Id == null && matchup.prevMatchup2Id == null) {
          currRoundMatchupsWithFiller.push({ ...fakeMatchup })
        } else {
          // one matchup
          currRoundMatchupsWithFiller.push(currPlayInMatchup)
          currPlayInMatchup = round.matchups[++playInMatchupCounter]
        }
      }

      return currRoundMatchupsWithFiller
    },

    weightClassChange(weightClass) {
      this.selectedWeightClass = weightClass
      setTimeout(this.setIsBracketScrollingHorizontally, 1)
      setTimeout(this.updateMatchupLines, 1)
    },

    formatDateTime(date) {
      return DateUtils.formatDateTime(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },
    formatDateTimeWithSeconds(date) {
      return DateUtils.formatDateTimeWithSeconds(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },

    isPigParticipant(name) {
      if (name == null) return false
      return name.includes('🐷 PIG')
    },

    async updateBracketDisplaying(val) {
      this.selectedBracketViewing = val

      if (val === this.ACTUAL_RESULTS_VALUE) {
        setTimeout(this.setIsBracketScrollingHorizontally, 1)
        setTimeout(this.updateMatchupLines, 1)
        // show event bracket
        return
      }

      const selectedMember = this.league.members.find((member) => member.user.id == val)

      if (!selectedMember.bracketsLoaded) {
        const response = await leagueService.getBracketsForUser(this.leagueId, selectedMember.user.id)
        this.setBracketsLoadedForMember(selectedMember, response.data)
      }

      this.brackets = selectedMember.brackets

      setTimeout(this.setIsBracketScrollingHorizontally, 1)
      setTimeout(this.updateMatchupLines, 1)
    },

    setBracketsLoadedForMember(member, brackets) {
      member.brackets = brackets
      member.bracketsLoaded = true
    },

    updateMatchupLines() {
      if (this.selectedBracket == null) return

      const allMatchups = this.selectedBracket.rounds.flatMap((round) => round.matchups)
      const gameElements = document.querySelectorAll('.game')
      const idPrefix = 'game'

      for (const gameElement of gameElements) {
        const gameMatchupId = gameElement.id.substring(idPrefix.length)
        const gameMatchup = allMatchups.find(m => m.matchupId == gameMatchupId)

        if (gameMatchup && gameMatchup.nextMatchupId != null) {
          const nextGameElement = document.getElementById(`${idPrefix}${gameMatchup.nextMatchupId}`)
          const matchupYPos = gameElement.getBoundingClientRect().y
          const nextMatchupYPos = nextGameElement.getBoundingClientRect().y
          const deltaHeight = Math.abs(nextMatchupYPos - matchupYPos)
          const connectorElement = gameElement.querySelector('.connector')

          if (connectorElement == null) continue

          connectorElement.style.height = deltaHeight + 'px'

          if (nextMatchupYPos > matchupYPos) {
            connectorElement.classList.add('connector-top')
            connectorElement.classList.remove('connector-bottom')
          } else {
            connectorElement.classList.add('connector-bottom')
            connectorElement.classList.remove('connector-top')
          }
        }
      }
    },

    setIsBracketScrollingHorizontally() {
      const bracketPredictionElement = this.$refs.bracketPredictionElem

      if (bracketPredictionElement != null) {
        this.isBracketScrollingHorizontally = bracketPredictionElement.scrollWidth > bracketPredictionElement.clientWidth
      } else {
        this.isBracketScrollingHorizontally = false
      }
    },
  },

  mounted() {
    PubSub.subscribe('league-members-changed', this.reloadPage)
    PubSub.subscribe('event-results-published', this.reloadPage)
    PubSub.subscribe('league-name-changed', this.changeLeagueName)
    PubSub.subscribe('payment-status-changed', this.reloadPage)

    window.addEventListener('resize', this.updateMatchupLines)
    window.addEventListener('resize', this.setIsBracketScrollingHorizontally)
  },

  unmounted() {
    try {
      PubSub.unsubscribe('league-members-changed', this.reloadPage)
      PubSub.unsubscribe('event-results-published', this.reloadPage)
      PubSub.unsubscribe('league-name-changed', this.changeLeagueName)
      PubSub.unsubscribe('payment-status-changed', this.reloadPage)

      window.removeEventListener('resize', this.updateMatchupLines)
      window.removeEventListener('resize', this.setIsBracketScrollingHorizontally)
    } catch {
      /* empty */
    }
  }
}
</script>


<style scoped>
#bracket-prediction {
  --first-game-width: 290px;
  --game-width: 210px;
  --round-spacing: 12px;
  --player-height: 24px;
  --game-vertical-spacing: 32px;
  --border-width: 1px;

  --color-correct: rgb(4, 158, 4);
  --color-incorrect: rgb(197, 24, 24);
  overflow-x: auto;
}

@media screen and (max-width: 512px) {
  #bracket-prediction {
    --first-game-width: 260px;
    --game-width: 180px;
  }
}

.bracket-outer {
  width: fit-content;
}

#bracket-prediction.light-background {
  border: 1px solid var(--color-gray2);
}

#bracket-prediction.dark-background {
  background-color: var(--color-gray4-opacity50);
  border: 1px solid var(--color-gray4);
  border-radius: 8px;
  box-shadow: inset 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
}

.rounds {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: var(--color-gray1);
}

.dark-background .rounds {
  background-color: transparent;
}

.round h3 {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  border-bottom: 1px solid var(--color-gray2);
  background-color: var(--color-gray0-5);
}

.dark-background .round h3 {
  border-bottom: 1px solid var(--color-black);
  background-color: var(--color-gray4-opacity50);
  backdrop-filter: blur(1rem);
  color: var(--color-white);
}

.games {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
}

.round-wrapper {
  display: flex;
  padding: calc(var(--round-spacing) / 2);
}

.round:first-of-type .round-wrapper {
  padding-left: var(--round-spacing);
}

.round:last-of-type .round-wrapper {
  padding-right: var(--round-spacing);
}

.game {
  position: relative;
  width: var(--game-width);
}

.light-background .game {
  border: 0.5px solid var(--color-gray1);
  box-shadow: 2px 4px 6px 0px #00000066;
  background-color: var(--color-white);
}

.dark-background .game {
  border-color: var(--color-gray4-opacity50);
  border-radius: 8px;
}

.game.first-round {
  width: var(--first-game-width);
}

.strikethrough {
  text-decoration: line-through;
  color: var(--color-gray2);
}

.strikethrough.correct {
  color: var(--color-correct);
}

.strikethrough.incorrect {
  color: var(--color-incorrect);
}

.connector {
  border: var(--border-width) solid var(--color-gray3);
  border-left: none;

  height: 60px;
  width: calc(var(--round-spacing) / 2 - 1px);

  position: absolute;
  z-index: 1;
  left: calc(var(--game-width) + 2px);
}

.connector-top {
  border-bottom: none;
  top: 50%;
}

.connector-bottom {
  border-top: none;
  bottom: 50%;
}

.play-in .connector {
  border-right: 0;
}

.connector-before {
  position: absolute;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: var(--border-width) solid var(--color-gray3);
  width: calc(var(--round-spacing) / 2 - 1px);
  left: calc(var(--round-spacing) * -.5 + 1px);
  top: calc(50% - var(--border-width) / 2);
}

.connector-top>.connector-before {
  top: 100%;
}

.connector-bottom>.connector-before {
  top: 0;
}

.hideFillerMatchup {
  visibility: hidden;
}

.first-round .connector {
  left: calc(var(--first-game-width) + 2px);
}

/* specific styles for matchups that only have one prev matchup */
.game.shift-up {
  position: relative;
  bottom: calc(0.5 * var(--player-height) + var(--border-width));
}

.game.shift-down {
  position: relative;
  top: calc(0.5 * var(--player-height) - var(--border-width));
}

.connector-last-round {
  width: 0;
  height: 0;
  border-left: none;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.game.shift-up .connector-last-round {
  top: 67%;
}

.game.shift-down .connector-last-round {
  top: 33%;
}

.game.shift-down .connector-last-round.two-participants {
  top: 42%;
}

.game.shift-up .connector-last-round.two-participants {
  top: 60%;
}

.connector-after-last-round {
  position: absolute;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: var(--border-width) solid var(--color-gray3);
  width: calc(var(--round-spacing));
  left: calc(var(--round-spacing) * -1);
}

.dark-background .connector,
.dark-background .connector-before,
.dark-background .connector-after,
.dark-background .connector-after-last-round {
  border-color: var(--color-dark-orange);
}

.visibility-hidden {
  visibility: hidden;
}
</style>