<template>
  <aside class="flex col filter-fly-out">
    <div class="flex space-between align-center filter-fly-out-box-shadow">
      <h2>Filter By</h2>
      <div style="cursor: pointer;" @click="close">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 18L18 6M6 6L18 18" stroke="#1B2A39" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <div class="flex col gap-0-5 filter-fly-out-group" v-for="(filter, filterIndex) in filters" :key="filterIndex">

      <div class="flex col gap-0-25 align-start">
        <span class="small-caps-2 grow">{{ filter.title }}</span>
        <input v-if="filter.type === 'checkbox_group' && filter.options.length >= 20" class="checkbox-filter-input" type="text"
          :placeholder="`Filter ${filter.title}`" v-model="filter.typeaheadFilter">
      </div>

      <!-- checkbox group template -->
      <template v-if="filter.type === 'checkbox_group'">
        <div class="flex align-center gap-0-5"
          v-for="option of filter.options.filter(opt => filter.typeaheadFilter == null || opt.text.toLowerCase().includes(filter.typeaheadFilter.toLowerCase()))"
          :key="option.value">
          <input type="checkbox" :id="`${id}filter${filterIndex}option${option.value}`" v-model="filter[filter.modelTo]"
            :value="option.value">
          <label :for="`${id}filter${filterIndex}option${option.value}`" class="body2">{{ option.text }}</label>
        </div>
      </template>

      <!-- slider template -->
      <template v-if="filter.type === 'slider'">
        <range-slider v-model="filter[filter.modelTo]" :format="filter.format" :min="filter.min" :max="filter.max"
          :prefix-label="filter.prefixLabel" :suffix-label="filter.suffixLabel" />
      </template>
    </div>
    <div class="flex-grow"></div>
    <div class="filter-fly-out-actions">
      <rebel-button text="Reset" type="ghosted" color="default" @click="reset" />
      <rebel-button text="Apply" type="primary" color="default" @click="apply" />
    </div>
  </aside>
</template>

<script>
import RangeSlider from '../RangeSlider.vue';
import RebelButton from '../../components/RebelButton.vue'
import emitEvents from '../../utils/emitEvents';
import { v4 as uuid } from 'uuid'

export default {
  /*
    filter has the following:
    {
      title: '',
      type: 'checkbox_group|slider',
      modelTo: boolean|array|number,
      min: number, (slider only)
      max: number, (slider only)
      options: [ { value: number, text: string }, ... ] (checkbox_group only)
    }
  */
  props: {
    initialFilters: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  mounted() {
    window.addEventListener('scroll', this.scrollUpdated)
    this.scrollUpdated()
  },
  unmounted() {
    window.removeEventListener('scroll', this.scrollUpdated)
  },

  components: {
    RangeSlider,
    RebelButton
  },

  data() {
    return {
      id: uuid(),

      filters: this.initialFilters.map(x => ({ ...x })), // copy of initial

      scrollPositionData: {
        lastKnownScrollPosition: 0,
        ticking: false
      }
    }
  },

  methods: {
    apply() {
      this.$emit(emitEvents.FILTERS_APPLY, this.filters)
      this.close()
    },

    close() {
      this.$emit(emitEvents.CLOSE)
    },

    reset() {
      this.filters.forEach(filter => {
        const initialFilter = this.initialFilters.find(initialFilter => initialFilter.title === filter.title)
        filter[filter.modelTo] = JSON.parse(JSON.stringify(initialFilter[initialFilter.modelTo])) // reset to copy of initial
      })

      this.$emit(emitEvents.FILTERS_RESET)
      this.close()
    },

    scrollUpdated() {
      this.scrollPositionData.lastKnownScrollPosition = window.scrollY;

      if (!this.scrollPositionData.ticking) {
        window.requestAnimationFrame(() => {
          this.updateMargin(this.scrollPositionData.lastKnownScrollPosition);
          this.scrollPositionData.ticking = false;
        });

        this.scrollPositionData.ticking = true;
      }
    },

    updateMargin(scrollPos) {
      const ONE_REM_PX = 16 // TODO put this into a utility script with all CSS constants necessary for JavaScript

      let marginTopInPx = 3.875 * ONE_REM_PX - scrollPos
      if (marginTopInPx < 0) marginTopInPx = 0

      document.querySelectorAll('.filter-fly-out').forEach(el => {
        el.style.marginTop = `${marginTopInPx}px`
        el.style.height = `calc(100vh - ${marginTopInPx}px + .125rem)`
      })
    }
  }
}

</script>

<style scoped>
.filter-fly-out {
  position: fixed;
  right: 0;
  top: 0;
  margin: 3.875rem 0 0 0;
  z-index: 1;
  background-color: white;
  width: 384px;
  padding: 0;
  height: calc(100vh - 4rem);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);
  overflow: auto;
}

.filter-fly-out>* {
  padding: 1rem;
}

.filter-fly-out-group {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

:nth-last-child(1 of .filter-fly-out-group) {
  border-bottom: 0;
}

.filter-fly-out-box-shadow {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.filter-fly-out-actions {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  gap: 0.75rem
}

.filter-fly-out-actions>* {
  flex-grow: 1;
}

.filter-fly-out .checkbox-filter-input {
  font-size: .75rem; 
  padding: .375rem .375rem .25rem .375rem;
}
</style>