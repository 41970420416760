import { defineStore } from 'pinia'
import UserRoles from '../utils/userRoles'
import NOTIFICATION_METHODS from '../utils/notificationMethods'
import axios from 'axios'
import authenticationService from '../services/AuthenticationService'

function addAuthorizationHeader(token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

function removeAuthorizationHeader() {
  delete axios.defaults.headers.common['Authorization']
}

export const useAuthenticationStore = defineStore('authentication', {
  state: () => ({
    loggedInUser: null
  }),

  getters: {
    avatarSource: (state) => {
      if (state.loggedInUser == null || state.loggedInUser.user == null || state.loggedInUser.user.imageSrc == null) {
        return null
      }

      return state.loggedInUser.user.imageSrc
    },

    isAdmin: (state) => {
      return state.loggedInUser != null &&
        state.loggedInUser.roles &&
        state.loggedInUser.roles.some((authority) => UserRoles.ADMIN_ROLES.some((role) => role === authority))
    },

    isDataAdmin: (state) => {
      return state.loggedInUser != null &&
        state.loggedInUser.roles &&
        state.loggedInUser.roles.some((authority) => UserRoles.DATA_ADMIN_ROLES.some((role) => role === authority))
    },

    isLoggedIn: (state) => {
      return state.loggedInUser != null
    },

  },

  actions: {
    setPhoneNumber(phoneNumber) {
      this.loggedInUser.user.phone = phoneNumber
    },

    async checkTokenValidity() {
      try {
        return (await authenticationService.checkTokenValidity()).data
      } catch (error) {
        return Promise.resolve(false)
      }
    },

    async isLeagueCommissionerOf(leagueId) {
      try {
        const commissionerResponse = await authenticationService.isLeagueCommissionerOf(leagueId)

        return commissionerResponse.data
      } catch (error) {
        return false
      }
    },

    async isLeagueMemberOf(leagueId) {
      try {
        if (this.isDataAdmin) return true

        const memberResponse = await authenticationService.isLeagueMemberOf(leagueId)

        return memberResponse.data
      } catch (error) {
        return false
      }
    },

    async login(loginDetails) {
      try {
        const loginResponse = await authenticationService.login(loginDetails)

        if (loginResponse.data.user && loginResponse.data.user.emailVerified === false) {
          return { success: true, user: loginResponse.data.user }
        }

        this.loggedInUser = loginResponse.data

        if (this.loggedInUser && this.loggedInUser.token) {
          addAuthorizationHeader(this.loggedInUser.token)
        }

        return { success: true, user: this.loggedInUser.user }
      } catch (error) {
        return { success: false, error }
      }
    },

    userHasAnyRole(roles = []) {
      return (
        this.loggedInUser != null &&
        this.loggedInUser.roles &&
        this.loggedInUser.roles.some((authority) => roles.some((role) => role === authority))
        // TODO: check on server-side
      )
    },

    hasNotificationOn(notificationType, notificationMethod, user = null) {
      if (user == null) user = this.loggedInUser?.user

      return user != null &&
        user.notificationSettings != null &&
        user.notificationSettings.some(setting => setting.notificationMethod == notificationMethod && setting.notificationType == notificationType)
    },
    
    hasNotificationOnForAnyMethod(notificationType, user = null) {
      for (const notificationMethod of Object.values(NOTIFICATION_METHODS)) {
        if (this.hasNotificationOn(notificationType, notificationMethod, user)) {
          return true
        }
      }
      return false
    },

    logout() {
      this.loggedInUser = null
      localStorage.removeItem('authentication')
      removeAuthorizationHeader()
    },

    async register(newUser) {
      try {
        await authenticationService.register(newUser)

        return { success: true }
      } catch (error) {
        return { success: false, error }
      }
    },

    async updateDisplayName(newDisplayName) {
      try {
        const response = await authenticationService.updateDisplayName(newDisplayName)

        const updatedDisplayName = response.data

        this.loggedInUser.user.displayName = updatedDisplayName

        return { success: true }
      } catch (error) {
        return { success: false, error }
      }
    },

    async updateMyAvatar(fileName) {
      try {
        await authenticationService.updateMyAvatar(fileName)
        this.loggedInUser.user.imageSrc = fileName

        return { success: true }
      } catch (error) {
        return { success: false, error }
      }
    },

    async updatePassword(oldPassword, newPassword) {
      try {
        await authenticationService.updatePassword(oldPassword, newPassword)

        return { success: true }
      } catch (error) {
        return { success: false, error }
      }
    },

    async updateAddMeToOpenTournamentPreference(newPreference) {
      try {
        this.loggedInUser.user.addMeToOpenTournament = newPreference
        await authenticationService.updateAddMeToOpenTournamentPreference(newPreference)
        return { success: true }
      } catch (error) {
        this.loggedInUser.user.addMeToOpenTournament = !newPreference
        return { success: false, error }
      }
    },

    async updateUserNotificationSetting(dto) {
      try {
        await authenticationService.updateUserNotificationSetting(dto)

        // update notification setting locally
        const hasNotificationOn = this.hasNotificationOn(dto.notificationType, dto.notificationMethod)
        const settings = this.loggedInUser.user.notificationSettings
        if (dto.notificationOn && !hasNotificationOn) {
          settings.push({
            notificationMethod: dto.notificationMethod,
            notificationType: dto.notificationType
          })
        } else if (!dto.notificationOn && hasNotificationOn) {
          const indexToDelete = settings.findIndex(setting => setting.notificationMethod == dto.notificationMethod && setting.notificationType == dto.notificationType)
          settings.splice(indexToDelete, 1)
        }
        return { success: true }
      } catch (error) {
        return { success: false, error }
      }
    },

    async updateTimeZoneSetting(newTimeZone) {
      const currTimeZone = this.loggedInUser.user.timeZoneSetting
      try {
        this.loggedInUser.user.timeZoneSetting = newTimeZone
        await authenticationService.updateTimeZone({ timeZoneSetting: newTimeZone })
        return { success: true }
      } catch (error) {
        this.loggedInUser.user.timeZoneSetting = currTimeZone
        return { success: false, error }
      }
    }
  },

  persist: {
    enabled: true,

    strategies: [
      {
        storage: localStorage // TODO: update to secure cookie for token
      }
    ],

    // this doesn't work. this is how I'd rather restore the user details instead of the hack below
    afterRestore: (ctx) => {
      if (ctx.store.isLoggedIn) {
        addAuthorizationHeader(ctx.store.loggedInUser.token)
      }
    }
  }
})

// TODO: improve this hack
const authJSON = localStorage.getItem('authentication')
if (authJSON) {
  const authObj = JSON.parse(authJSON)
  if (authObj.loggedInUser) {
    addAuthorizationHeader(authObj.loggedInUser.token)
  }
}
