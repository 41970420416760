<template>
  <modal-dialog ref="myModal" @submit.prevent="closeModal">
    <template v-slot:modal-title>Email Sent to User</template>
    <template v-slot:modal-contents>
      <div v-if="emailLogId == null">
        Missing required email identifier.
      </div>

      <div v-else-if="!isLoading" class="width-100 layout-form-list view-email">
        <div class="form-input">
          <label>To</label>
          <p class="body2">{{ emailLog.toUser.email }}</p>
        </div>

        <div class="form-input">
          <label>Sent</label>
          <p class="body2">{{ formatDateTime(emailLog.createdAt) }}</p>
        </div>

        <div class="form-input">
          <label>Read</label>
          <p class="body2">{{ emailLog.read ? 'Yes' : 'No' }}</p>
        </div>

        <div class="form-input">
          <label>Subject</label>
          <p class="body2">{{ emailLog.subjectLine }}</p>
        </div>

        <iframe v-if="iFrameUrl != null" id="view-email-iframe" :src="iFrameUrl" frameborder="0" width="100%">
        </iframe>
      </div>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Close" />
    </template>
  </modal-dialog>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import DateUtils from '@/utils/dateUtils.js'
import emailService from '@/services/EmailService.js'

import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
    }
  },

  components: {
    ModalDialog,
    RebelButton
  },

  data() {
    return {
      API_URL: import.meta.env.VITE_API_URL,
      emailLog: {},
      isLoading: true
    }
  },

  props: {
    emailLogId: {
      type: [Number, String],
      required: true
    }
  },

  computed: {
    authToken() {
      return this.authStore.loggedInUser?.token
    },

    iFrameUrl() {
      if (this.API_URL == null || this.emailLogId == null || this.authToken == null) return null

      return `${this.API_URL}/email-log/${this.emailLogId}/html-view?auth_token=${this.authToken}`
    }
  },

  methods: {
    closeModal() {
      this.$refs.myModal.close()
    },

    formatDateTime(dateTime) {
      return DateUtils.formatDateTime(dateTime, this.authStore.loggedInUser.user.timeZoneSetting)
    },

    resizeIframe(e) {
      const iframe = document.getElementById("view-email-iframe");
      if (!iframe == null) return

      const eventName = e.data[0];
      const data = e.data[1];
      if (data == 0) return
      switch (eventName) {
        case 'setHeight':
          iframe.style.height = data + 'px';
          break;
      }
    }
  },

  async created() {
    const response = await emailService.getEmailLog(this.emailLogId);
    this.emailLog = response.data
    this.isLoading = false
  },

  mounted() {
    // resize the iframe based on a message passed from the iframe itself
    // the message includes the height of the content within the iframe
    // ref: https://stackoverflow.com/a/23020025
    window.addEventListener('message', this.resizeIframe)
  },
  beforeUnmount() {
    window.removeEventListener('message', this.resizeIframe)
  }
}
</script>

<style>
.layout-form-page:has(.view-email) {
  max-width: unset;
}

.modal-backdrop>.modal:has(.view-email) {
  width: min(90vw, 950px);
}
</style>