<template>
  <div class="agg-section mb-2">
    <div class="agg-section-part">
      <h3>Total # Leagues</h3>
      <div class="agg-section-part-value">
        <span class="color-action heading3">{{ numberWithCommas(results.leagueCountTotal) }}</span>
      </div>
    </div>
    <div class="agg-section-part">
      <h3># New Leagues</h3>
      <div class="agg-section-part-value">
        <span class="color-action heading3">{{ numberWithCommas(results.leagueCountNew) }}</span>
        <span class="gray2 caption">in past 30 days</span>
      </div>
    </div>
  </div>

  <h2>Leagues</h2>
  <div class="flex gap-1 mt-0-5 mb-0-5 align-center">
    <input autofocus id="admin-dashboard-leagues-search-input" class="grow" type="text"
      placeholder="Search league name or commissioner email" v-model="searchTerm" @keyup.enter="search">
    <rebel-button color="default" type="primary" text="Search" @click="search" />
    <rebel-button color="default" type="ghosted" text="Filter" class="nowrap" @click="filterByFlyOut.isShowing = true">
      <template v-slot:icon-leading>
        <adjustments-horizontal-icon class="icon-20" />
      </template>
      <template v-if="countOfAppliedFilters > 0" v-slot:icon-trailing>
        <rebel-notification-count-bubble :value="countOfAppliedFilters" primaryColor="action" />
      </template>
    </rebel-button>
  </div>
  <div v-if="appliedFilters != null && appliedFilters != {}" class="flex wrap gap-0-25 mt-0-5 mb-1 align-center">
    <filter-pill v-for="eventId in appliedFilters.eventIds" :key="eventId" :text="`${getEventNameById(eventId)}`"
      @close="removeEventIdFilter(eventId)" />
    <filter-pill v-for="eventTypeId in appliedFilters.eventTypeIds" :key="eventTypeId"
      :text="`${eventTypes.getEventTypeLabel(eventTypeId)}`" @close="removeEventTypeFilter(eventTypeId)" />
    <filter-pill v-if="appliedFilters.memberCount?.length"
      :text="`# Members: ${getSliderFilterLabel(filters.find(f => f.title === '# Members'), appliedFilters.memberCount)}`"
      @close="resetMemberCountFilter" />
    <filter-pill v-if="appliedFilters.paidMemberCount?.length"
      :text="`# Paid Members: ${getSliderFilterLabel(filters.find(f => f.title === '# Paid Members'), appliedFilters.paidMemberCount)}`"
      @close="resetPaidMemberCountFilter" />
  </div>

  <div v-if="isSearching" class="flex justify-center">
    <icon-loading colorVariable="color-action" heightCss="100px" />
  </div>
  <rebel-table v-else-if="tableData.length" :table-data="tableData" row-key="leagueId" :columns="table.columns"
    :sortable="true" :more-options-column="false" :paging-options="paging"
    @pagechanged="(page) => paging.currentPage = page" />

  <filter-by-fly-out v-if="filterByFlyOut.isShowing && filtersLoaded" v-show="isVisible" :class="{ visible: isVisible }"
    :initial-filters="filters" @close="filterByFlyOut.isShowing = false" @filters-apply="filtersApply"
    @filters-reset="filtersReset" />
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import adminDashboardService from '../../services/AdminDashboardService'
import DateUtils from '@/utils/dateUtils.js'
import eventTypes from '../../utils/eventTypes'
import numberUtils from '../../utils/numberUtils'

import { AdjustmentsHorizontalIcon } from '@heroicons/vue/20/solid'
import FilterByFlyOut from './FilterByFlyOut.vue'
import FilterPill from '../FilterPill.vue'
import IconLoading from '../IconLoading.vue'
import RebelButton from '../../components/RebelButton.vue'
import RebelNotificationCountBubble from '../RebelNotificationCountBubble.vue'
import RebelTable from '../RebelTable.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      eventTypes
    }
  },

  async created() {
    // set event options
    const eventOptions = (await adminDashboardService.getEventOptions()).data
    this.filters.find(f => f.title === 'Events').options = eventOptions

    this.filtersLoaded = true
    this.search()
  },

  components: {
    AdjustmentsHorizontalIcon,
    FilterByFlyOut,
    FilterPill,
    IconLoading,
    RebelButton,
    RebelNotificationCountBubble,
    RebelTable,
  },

  props: ['isVisible'],

  data() {
    return {
      isSearching: false,

      paging: {
        usePaging: true,

        perPage: 20,
        currentPage: 1,
        maxVisibleButtons: 6
      },

      filtersLoaded: false,

      filters: [
        {
          type: 'checkbox_group',
          selectedEvents: [],
          title: 'Events',
          modelTo: 'selectedEvents',
          options: [],
        },
        {
          type: 'checkbox_group',
          selectedEventTypes: [],
          title: 'Event Type',
          modelTo: 'selectedEventTypes',
          options: eventTypes.getEventTypeOptions()
        },
        {
          type: 'slider',
          selectedMemberCount: [1, 13],
          title: '# Members',
          modelTo: 'selectedMemberCount',
          min: 1,
          max: 13,
          prefixLabel: '1',
          suffixLabel: '12+',
          format: (value) => value == 13 ? '12+' : parseInt(value)
        },
        {
          type: 'slider',
          selectedPaidMemberCount: [0, 7],
          title: '# Paid Members',
          modelTo: 'selectedPaidMemberCount',
          min: 0,
          max: 7,
          prefixLabel: '0',
          suffixLabel: '6+',
          format: (value) => value == 7 ? '6+' : parseInt(value)
        }
      ],

      results: {
        leagueCountTotal: 0,
        leagueCountNew: 0,
        leagueSearchResults: []
      },

      appliedFilters: {
        eventIds: [],
        eventTypeIds: [],
        memberCount: [],
        paidMemberCount: []
      },
      searchTerm: '',

      table: {
        columns: [
          {
            label: 'LEAGUE NAME',
            prop: 'linkToLeague',
            sortDirection: 'desc',
            isHyperlink: true
          },
          {
            label: 'COMMISSIONER EMAIL',
            prop: 'linkToCommissionerUserProfile',
            sortDirection: '',
            isHyperlink: true
          },
          {
            label: '# MEMBERS',
            prop: 'memberCount',
            sortDirection: ''
          },
          {
            label: '# PAID MEMBERS',
            prop: 'paidMemberCount',
            sortDirection: ''
          },
          {
            label: '# PAID MEMBERS W PICKS',
            prop: 'paidMemberWithPicksCount',
            sortDirection: ''
          },
          {
            label: '# UNPAID MEMBERS',
            prop: 'unpaidMemberCount',
            sortDirection: ''
          },

          {
            label: 'LEAGUE CREATED',
            prop: 'createdAtDisplay',
            sortDirection: '',
            sortProp: 'createdAt'
          }
        ],
      },

      filterByFlyOut: {
        isShowing: false
      },
    }
  },
  computed: {
    countOfAppliedFilters() {
      const eventsApplied = this.appliedFilters.eventIds?.length || 0
      const eventTypesApplied = this.appliedFilters.eventTypeIds?.length || 0
      const memberCountApplied = this.appliedFilters.memberCount?.length ? 1 : 0
      const paidMemberCountApplied = this.appliedFilters.paidMemberCount?.length ? 1 : 0
      return eventsApplied + eventTypesApplied + memberCountApplied + paidMemberCountApplied
    },

    tableData() {
      return this.results.leagueSearchResults.map(res => {
        const createdAtDisplay = this.formatDateTime(res.createdAt)

        return { ...res, createdAtDisplay }
      })
    }
  },

  methods: {
    async filtersApply(appliedFilters) {
      // TODO: find in a more statically typed way
      const eventFilter = this.filters.find(filter => filter.title === 'Events')
      const appliedEventFilter = appliedFilters.find(appliedFilter => appliedFilter.title === 'Events')

      eventFilter.selectedEvents = appliedEventFilter.selectedEvents
      this.appliedFilters.eventIds = appliedEventFilter.selectedEvents

      const eventTypeFilter = this.filters.find(filter => filter.title === 'Event Type')
      const appliedEventTypeFilter = appliedFilters.find(appliedFilter => appliedFilter.title === 'Event Type')

      eventTypeFilter.selectedEventTypes = appliedEventTypeFilter.selectedEventTypes
      this.appliedFilters.eventTypeIds = appliedEventTypeFilter.selectedEventTypes

      const memberCountFilter = this.filters.find(filter => filter.title === '# Members')
      const appliedMemberCountFilter = appliedFilters.find(appliedFilter => appliedFilter.title === '# Members')

      memberCountFilter.selectedMemberCount = appliedMemberCountFilter.selectedMemberCount
      this.appliedFilters.memberCount = appliedMemberCountFilter.selectedMemberCount

      const paidMemberCountFilter = this.filters.find(filter => filter.title === '# Paid Members')
      const appliedPaidMemberCountFilter = appliedFilters.find(appliedFilter => appliedFilter.title === '# Paid Members')

      paidMemberCountFilter.selectedPaidMemberCount = appliedPaidMemberCountFilter.selectedPaidMemberCount
      this.appliedFilters.paidMemberCount = appliedPaidMemberCountFilter.selectedPaidMemberCount


      await this.search()
    },

    async filtersReset() {
      const eventFilter = this.filters.find(filter => filter.title === 'Events')
      eventFilter.selectedEvents = []

      const eventTypeFilter = this.filters.find(filter => filter.title === 'Event Type')
      eventTypeFilter.selectedEventTypes = []

      const memberCountFilter = this.filters.find(filter => filter.title === '# Members')
      memberCountFilter.selectedMemberCount = [1, 13]

      const paidMemberCountFilter = this.filters.find(filter => filter.title === '# Paid Members')
      paidMemberCountFilter.selectedPaidMemberCount = [0, 7]

      this.appliedFilters = {}

      await this.search()
    },

    removeEventIdFilter(eventId) {
      const eventFilter = this.filters.find(filter => filter.title === 'Events')
      eventFilter.selectedEvents.splice(eventFilter.selectedEvents.findIndex(eId => eId == eventId), 1)
      this.search()
    },

    removeEventTypeFilter(eventTypeId) {
      const eventTypeFilter = this.filters.find(filter => filter.title === 'Event Type')
      eventTypeFilter.selectedEventTypes.splice(eventTypeFilter.selectedEventTypes.findIndex(eTypeId => eTypeId == eventTypeId), 1)
      this.search()
    },

    resetMemberCountFilter() {
      const memberCountFilter = this.filters.find(filter => filter.title === '# Members')
      memberCountFilter.selectedMemberCount = [1, 13]
      this.appliedFilters.memberCount = []
      this.search()
    },

    resetPaidMemberCountFilter() {
      const paidMemberCountFilter = this.filters.find(filter => filter.title === '# Paid Members')
      paidMemberCountFilter.selectedPaidMemberCount = [0, 7]
      this.appliedFilters.paidMemberCount = []
      this.search()
    },

    formatDateTime(dateTime) {
      return DateUtils.formatDateTime(dateTime, this.authStore.loggedInUser.user.timeZoneSetting)
    },

    getDataForCsv() {
      const fields = this.table.columns.map(c => c.label.replaceAll('# ', 'NUMBER OF '))
      const data = this.tableData.map(row => {
        return {
          leagueName: row.linkToLeague.text,
          commissionerEmail: row.linkToCommissionerUserProfile.text,
          memberCount: row.memberCount,
          paidMemberCount: row.paidMemberCount,
          paidMemberWithPicksCount: row.paidMemberWithPicksCount,
          unpaidMemberCount: row.unpaidMemberCount,
          createdAt: row.createdAt
        }
      })

      return { fields, data }
    },

    getEventNameById(eventId) {
      return this.filters.find(f => f.title === 'Events').options.find(eo => eo.value == eventId).text
    },

    getSliderFilterLabel(filter, array) {
      if (array[0] === array[1]) return filter.format(array[0])

      return `${filter.format(array[0])} - ${filter.format(array[1])}`
    },

    async search() {
      this.isSearching = true
      try {
        this.results = (await adminDashboardService.leagueTab(this.searchTerm, this.appliedFilters)).data
        this.paging.currentPage = 1
        this.table.columns.forEach(c => c.sortDirection = '') // reset sorting
        this.table.columns[0].sortDirection = 'desc' // reset sorting
        this.isSearching = false
      } catch (error) {
        if (error.name === 'AbortError' || error.name === 'CanceledError') {
          // do nothing
        } else {
          this.isSearching = false
          throw error
        }
      }
    },

    numberWithCommas(x) {
      return numberUtils.numberWithCommas(x)
    }
  }

}

</script>

<style scoped>
#admin-dashboard-leagues-search-input {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" className="size-5"><path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z" clipRule="evenodd" fill="%23748FA8" /></svg>');
  background-position: .5rem .65rem;
  background-size: 20px;
  background-repeat: no-repeat;
  padding-left: 2rem;
}
</style>