<template>
  <div class="width-100">
    <span class="heading3 black entry-title">
      {{ title }}
    </span>
    <span class="bubble-container">
      <rebel-notification-count-bubble v-if="showPredictionsRemaining" :value="predictionsRemaining"
        :darkBackground="false" primaryColor="danger" />
    </span>
  </div>
</template>

<script>
import RebelNotificationCountBubble from '@/components/RebelNotificationCountBubble.vue'


export default {
  components: {
    RebelNotificationCountBubble
  },

  computed: {
    showPredictionsRemaining() {
      return this.entryIsEditable && this.predictionsRemaining != null && this.predictionsRemaining > 0
    }
  },

  props: {
    entryIsEditable: {
      type: Boolean,
      required: true,
    },

    predictionsRemaining: {
      type: Number,
      required: false
    },

    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.bubble-container {
  line-height: 1rem;
  padding-left: 0.5rem;
  vertical-align: top;
}
</style>