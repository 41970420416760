<template>
  <div class="flex col grow gap-0-25" :class="`${styles.getLightDarkClasses(darkBackground)} ${getAlignmentClasses}`">

    <div v-if="size == 1" class="text-container gap-0-25" :class="getTextJustificationClass">
      <h1 class="text1">{{ event.name }}</h1>
      <p class="text2 body2">{{ eventSubText }}</p>
      <p v-if="!isPaidIfNecessary" class="text3 caption">{{ eventEntryPrice }}</p>
    </div>

    <div v-if="size == 2" class="text-container gap-0-25" :class="getTextJustificationClass">
      <h2 class="text1">{{ event.name }}</h2>
      <p class="text2 body2">{{ eventSubText }}</p>
      <p v-if="!isPaidIfNecessary" class="text3 caption">{{ eventEntryPrice }}</p>
    </div>

    <div v-if="size == 3" class="text-container" :class="getTextJustificationClass">
      <h3 class="text1">{{ event.name }}</h3>
      <p class="text2 body2">{{ eventSubText }}</p>
      <p v-if="!isPaidIfNecessary" class="text3 caption">{{ eventEntryPrice }}</p>
    </div>

    <div v-if="size == 3" class="text-container" :class="getTextJustificationClass">
      <h3 class="text1">{{ event.name }}</h3>
      <p class="text2 body2">{{ eventSubText }}</p>
      <p v-if="!isPaidIfNecessary" class="text3 caption mt-0-25">{{ eventEntryPrice }}</p>
    </div>

    <div v-if="isAdmin || showPaymentStatus" class="flex gap-0-5 pt-0-25" :class="getResponsiveFlexClass">
      <label-admin v-if="isAdmin" :event="event" :darkBackground="darkBackground" />
      <payment-status-tag v-if="showPaymentStatus" :is-disqualified="entryIsDisqualified" :is-paid-for="isPaidIfNecessary" />
    </div>

  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'

import DateUtils from '@/utils/dateUtils.js'
import styles from '@/utils/styles.js';
import LabelAdmin from '@/components/event-admin/LabelAdmin.vue'
import PaymentStatusTag from '@/components/PaymentStatusTag.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      styles
    }
  },

  components: {
    LabelAdmin,
    PaymentStatusTag
  },

  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    event: {
      type: Object,
      required: true
    },

    exemptFromPayment: {
      type: Boolean,
      default: false
    },

    isCenterJustified: {
      type: Boolean,
      default: false
    },

    isPaidIfNecessary: {
      type: Boolean,
      default: true
    },

    isUserSpecificView: {
      type: Boolean,
      default: true
    },

    size: {
      type: Number,
      default: 1
    }
  },

  computed: {
    entryIsDisqualified() {
      return this.event.paidEvent && !this.event.acceptingPayments && !this.isPaidIfNecessary
    },

    eventEntryPrice() {
      if (!this.event?.paidEvent) return ''

      if (this.event.paymentData.differentPriceForAdditionalEntry) {
        // Event has discounted additional entry price
        return `${this.event.paymentData.priceFirstEntryDisplay} for 1st entry / ${this.event.paymentData.priceAdditionalEntryDisplay} for each additional entry`
      } else if (this.event.popularVoteTournament) {
        // Event has single entry price, and is popular vote
        return `${this.event.paymentData.priceFirstEntryDisplay} to submit your vote`
      } else {
        // Event has single entry price
        return `${this.event.paymentData.priceFirstEntryDisplay} per entry`
      }
    },

    eventSubText() {
      if (this.event.popularVoteTournament) {
        if (!this.event.published) return 'Brackets to be announced soon'
        if (!this.event.participationEnded) return 'Voting ends ' + this.formatDateTime(this.event.startDateTime)
        return 'Voting ended ' + this.formatDateTime(this.event.startDateTime)
      }

      return this.formatDateTime(this.event.startDateTime)
    },

    getAlignmentClasses() {
      return this.isCenterJustified ? 'align-center' : ''
    },

    getResponsiveFlexClass() {
      return this.entryIsDisqualified ? 'row-to-col-512' : ''
    },

    getTextJustificationClass() {
      return this.isCenterJustified ? 'text-center' : ''
    },

    isAdmin() {
      return this.authStore?.isAdmin
    },

    showPaymentStatus() {
      return this.event.paidEvent && this.isUserSpecificView && !this.exemptFromPayment
    },
  },

  methods: {
    formatDateTime(date) {
      return DateUtils.formatDateTime(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    }
  }

}
</script>

<style scoped>
.text-container {
  display: flex;
  flex-direction: column;
}

.light-background .text1 {
  color: var(--color-black);
}

.light-background .text2 {
  color: var(--color-gray3);
}

.light-background .text3 {
  color: var(--color-gray2);
}

.dark-background .text1 {
  color: var(--color-white);
}

.dark-background .text2 {
  color: var(--color-gray1);
}

.dark-background .text3 {
  color: var(--color-gray1-5);
}
</style>
