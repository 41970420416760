<template>
  <div v-if="isLoaded">
    <div class="flex space-between align-center mb-2">
      <h1>Events</h1>
      <div v-if="authStore.isAdmin">
        <rebel-button color="default" type="primary" text="Create New Event" @click="createEvent"></rebel-button>
      </div>
    </div>
    <event-list-section class="mb-2" event-status="ongoing" :events="ongoingEvents" @view-event-results="viewEventResults"
      @view-popular-event-results="viewPopularEventResults" @vote-now="voteNow" />
    <event-list-section class="mb-2" event-status="upcoming" :events="upcomingEvents" @create-league="createNewLeague"
      @join-open-tournament="joinOpenTournament" @view-event-results="viewEventResults"
      @view-popular-event-results="viewPopularEventResults" @vote-now="voteNow" />
    <event-list-section event-status="past" :events="pastEvents" @create-league="createNewLeague"
      @view-event-results="viewEventResults" @view-popular-event-results="viewPopularEventResults" @vote-now="voteNow" />

    <accept-league-invite-modal v-if="acceptInviteModal.isShowing" :inviteToken="acceptInviteModal.inviteToken"
      :leagueId="acceptInviteModal.leagueId" @close="acceptInviteModal.isShowing = false" />

    <create-new-league-modal v-if="createNewLeagueModal.isShowing" @close="createNewLeagueClosed"
      :event="createNewLeagueModal.selectedEvent"></create-new-league-modal>

    <join-open-tournament-modal v-if="joinOpenTournamentModal.isShowing" @close="joinOpenTournamentClosed"
      :event="joinOpenTournamentModal.selectedEvent" />

    <create-event-modal v-if="createEventModal.isShowing" @close="createEventModal.isShowing = false" />
  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'

import eventCardService from '@/services/eventCardService'
import PubSub from 'pubsub-js'

import AcceptLeagueInviteModal from '@/components/events/AcceptLeagueInviteModal.vue'
import CreateEventModal from '@/components/event-admin/CreateEventModal.vue'
import CreateNewLeagueModal from '@/components/events/CreateNewLeagueModal.vue'
import JoinOpenTournamentModal from '@/components/events/JoinOpenTournamentModal.vue'
import EventListSection from '@/components/events/EventListSection.vue'
import RebelButton from '@/components/RebelButton.vue'

import eventTypes from '@/utils/eventTypes'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  async created() {
    await this.loadData()

    this.setupAcceptInviteModal()
    this.setInitialEventToCreateLeagueFor()

    this.isLoaded = true
  },

  data() {
    return {
      acceptInviteModal: {
        leagueId: null,
        inviteToken: null,
        isShowing: false
      },

      initialEventToCreateLeagueFor: -1,

      unpublishedEvents: [],
      ongoingEvents: [],
      upcomingEvents: [],
      pastEvents: [],

      isLoaded: false,

      createEventModal: {
        isShowing: false
      },

      createNewLeagueModal: {
        isShowing: false,
        selectedEvent: null
      },

      joinOpenTournamentModal: {
        isShowing: false,
        selectedEvent: null
      }
    }
  },

  mounted() {
    // TODO: limit this. only call loadData if the changes
    // apply to an event or league on my page
    PubSub.subscribe('bracket-reseeded', this.loadData)
    PubSub.subscribe('event-results-published', this.loadData)
    PubSub.subscribe('event-brackets-published', this.loadData)
    PubSub.subscribe('league-name-changed', this.changeLeagueName)
    PubSub.subscribe('payment-status-changed', this.loadData)

    window.addEventListener('hashchange', this.setInitialEventToCreateLeagueFor)
  },

  unmounted() {
    try {
      PubSub.unsubscribe('bracket-reseeded', this.loadData)
      PubSub.unsubscribe('event-results-published', this.loadData)
      PubSub.unsubscribe('event-brackets-published', this.loadData)
      PubSub.unsubscribe('league-name-changed', this.changeLeagueName)
      PubSub.unsubscribe('payment-status-changed', this.loadData)

      window.removeEventListener('hashchange', this.setInitialEventToCreateLeagueFor)
    } catch {
      //
    }
  },

  components: {
    AcceptLeagueInviteModal,
    CreateEventModal,
    CreateNewLeagueModal,
    EventListSection,
    JoinOpenTournamentModal,
    RebelButton
  },

  methods: {
    changeLeagueName(topic, message) {
      if (message.leagueId != null && message.name != null) {
        const leagueMatch = this.getLeagueFromPage(message.leagueId)
        if (leagueMatch != null) {
          leagueMatch.name = message.name
        }
      }
    },

    getLeagueFromPage(leagueId) {
      const allEvents = [this.unpublishedEvents, this.ongoingEvents, this.upcomingEvents, this.pastEvents]

      for (const eventList of allEvents) {
        let league = eventList.flatMap(e => e.leagues).find(l => l.leagueId == leagueId)
        if (league != null) return league
      }

      return null
    },

    async loadData() {
      const response = await eventCardService.getAll()
      this.unpublishedEvents = response.data.unpublishedEvents
      this.ongoingEvents = response.data.ongoingEvents
      this.upcomingEvents = response.data.upcomingEvents
      this.pastEvents = response.data.pastEvents
    },

    getUtcTime(event) {
      return Date.parse(`${event.startDate} ${event.startTime}+0`)
    },

    createEvent() {
      this.createEventModal.isShowing = true
    },

    createNewLeague(event) {
      if (event == null) {
        this.createNewLeagueModal.event = null
        this.createNewLeagueModal.isShowing = false
        return
      }

      if (!window.location.hash) {
        if (history) {
          history.pushState({ createLeagueFor: event.eventId }, '', `#createLeagueFor=${event.eventId}`)
        }
      }

      this.createNewLeagueModal.selectedEvent = event
      this.createNewLeagueModal.isShowing = true
    },

    removeHash() {
      let scrollV,
        scrollH,
        loc = window.location
      if ('pushState' in history) history.pushState('', document.title, loc.pathname + loc.search)
      else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop
        scrollH = document.body.scrollLeft

        loc.hash = ''

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV
        document.body.scrollLeft = scrollH
      }
    },

    createNewLeagueClosed() {
      this.removeHash()

      this.createNewLeagueModal.isShowing = false
      this.createNewLeagueModal.selectedEvent = null
    },

    joinOpenTournament(event) {
      if (event == null) {
        this.joinOpenTournamentModal.event = null
        this.joinOpenTournamentModal.isShowing = false
        return
      }

      this.joinOpenTournamentModal.selectedEvent = event
      this.joinOpenTournamentModal.isShowing = true
    },

    joinOpenTournamentClosed() {
      this.joinOpenTournamentModal.isShowing = false
      this.joinOpenTournamentModal.selectedEvent = null
    },

    setInitialEventToCreateLeagueFor() {
      const PARAM_NAME = 'createLeagueFor='
      const h = window.location.hash

      if (!h) {
        if (!this.initialEventToCreateLeagueFor) {
          this.initialEventToCreateLeagueFor = -1
        } else if (this.initialEventToCreateLeagueFor <= 0) {
          this.initialEventToCreateLeagueFor--
        } else {
          this.initialEventToCreateLeagueFor *= -1
        }
      }

      const indexOfCreateLeagueFor = h.indexOf(PARAM_NAME)

      if (indexOfCreateLeagueFor === -1) {
        if (!this.initialEventToCreateLeagueFor) {
          this.initialEventToCreateLeagueFor = -1
        } else if (this.initialEventToCreateLeagueFor <= 0) {
          this.initialEventToCreateLeagueFor--
        } else {
          this.initialEventToCreateLeagueFor *= -1
        }
      }

      const PARAM_SEPARATOR = '&'

      let i = indexOfCreateLeagueFor + PARAM_NAME.length
      let eventId = ''
      while (h.charAt(i) != PARAM_SEPARATOR && i < h.length) {
        eventId += h.charAt(i)
        i++
      }

      this.initialEventToCreateLeagueFor = this.upcomingEvents.find((e) => e.eventId == Number(eventId))
      this.createNewLeague(this.initialEventToCreateLeagueFor)
    },

    setupAcceptInviteModal() {
      const hasParametersRequiredForAcceptingInvite =
        this.$route.query?.inviteToken != null && this.$route.query?.leagueId != null

      if (hasParametersRequiredForAcceptingInvite) {
        this.acceptInviteModal.leagueId = this.$route.query?.leagueId
        this.acceptInviteModal.inviteToken = this.$route.query?.inviteToken
        this.acceptInviteModal.isShowing = true
      }
    },

    async viewEventResults(event) {
      if (eventTypes.isPickEm(event.typeId)) {
        await this.$router.push({ name: 'event-results-pick-em', params: { eventId: event.eventId } })
      } else {
        await this.$router.push({ name: 'event-results', params: { eventId: event.eventId } })
      }
    },

    async viewPopularEventResults(event) {
      if (!event.eventParticipant) {
        await this.viewEventResults(event)
        return
      }

      await this.$router.push({ name: 'user-brackets-view', params: { eventId: event.eventId } })
    },

    async voteNow(event) {
      await this.$router.push({ name: 'user-brackets-edit', params: { eventId: event.eventId } })
    }
  }
}
</script>