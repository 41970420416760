<template>
  <modal-dialog ref="myModal" @submit.prevent="closeModal">
    <template v-slot:modal-title>Entry Version History</template>
    <template v-slot:modal-contents>
      <div v-if="!isLoading" class="layout-form-list view-entry-history">
        <div class="form-input">
          <label>Event</label>
          <p class="body2">{{ modalData.eventName }}</p>
        </div>

        <div class="form-input">
          <label>Event Start Date and Time</label>
          <p class="body2">{{ formatDateTime(modalData.eventStartDateTime) }}</p>
        </div>

        <div v-if="leagueId != null" class="form-input">
          <label>League Name</label>
          <p class="body2">{{ modalData.leagueName }}</p>
        </div>

        <div class="form-input">
          <label>User Account</label>
          <p class="body2">{{ modalData.userEmail }}</p>
        </div>

        <div class="width-100">
          <rebel-table v-if="tableData.length" :table-data="tableData" row-key="createdAt" :columns="columns"
            :sortable="false" :more-options-column="false">
            <template v-slot:linkToEntry="{ row }">
              <a class="body2 color-action underline" @click.prevent="viewPicks(row.entryId)">View picks</a>
            </template>
          </rebel-table>
        </div>
      </div>
    </template>
    <template v-slot:modal-actions>
      <rebel-button button-type="submit" type="primary" color="default" text="Close" />
    </template>
  </modal-dialog>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import DateUtils from '@/utils/dateUtils.js'
import entryHistoryReasons from '@/utils/entryHistoryReasons'
import entryHistoryService from '@/services/EntryHistoryService.js'

import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'
import RebelTable from '@/components/RebelTable.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
    }
  },

  async created() {
    const response = await entryHistoryService.get(this.eventId, this.leagueId, this.userId);
    this.modalData = response.data

    this.isLoading = false
  },

  components: {
    ModalDialog,
    RebelButton,
    RebelTable
  },

  data() {
    return {
      isLoading: true,
      modalData: {
        eventName: '',
        eventStartDateTime: '',
        leagueName: '',
        userEmail: '',
        entryHistoryMetaData: []
      },
      columns: [
        {
          label: 'DATE/TIME',
          prop: 'createdAtDisplay',
          sortDirection: 'desc',
          sortProp: 'createdAt'
        },
        {
          label: 'TYPE OF EDIT',
          prop: 'entryHistoryReasonDisplay',
          sortDirection: ''
        },
        {
          label: '# PICKS ADDED',
          prop: 'numberAdded',
          sortDirection: '',
          cssClasses: 'text-center'
        },
        {
          label: '# PICKS REMOVED',
          prop: 'numberDeleted',
          sortDirection: '',
          cssClasses: 'text-center'
        },
        {
          label: '# PICKS CHANGED',
          prop: 'numberChanged',
          sortDirection: '',
          cssClasses: 'text-center'
        },
        {
          label: 'VIEW ENTRY',
          prop: 'linkToEntry',
          sortDirection: ''
        },
      ]
    }
  },

  props: {
    eventId: {
      required: true
    },
    leagueId: {
      required: false
    },
    userId: {
      required: true
    },
  },

  computed: {
    tableData() {
      return this.modalData.entryHistoryMetaData.map(entry => {

        return {
          ...entry,
          createdAtDisplay: this.formatDateTimeWithSeconds(entry.createdAt),
          entryHistoryReasonDisplay: entryHistoryReasons.getLabel(entry.entryHistoryReason),
        }
      })
    }
  },

  methods: {
    closeModal() {
      this.$refs.myModal.close()
    },
    formatDateTime(date) {
      return DateUtils.formatDateTime(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },
    formatDateTimeWithSeconds(date) {
      return DateUtils.formatDateTimeWithSeconds(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },
    viewPicks(entryId) {
      this.$emit('view-history-entry', entryId)
      this.closeModal()
    }
  }
}
</script>

<style>
.layout-form-page:has(.view-entry-history) {
  max-width: unset;
}

.modal-backdrop>.modal:has(.view-entry-history) {
  width: min(90vw, 1100px);
}
</style>