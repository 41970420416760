<template>
  <info-modal ref="myModal">
    <template v-slot:modal-title>Round 1 Competitors</template>
    <template v-slot:modal-contents>
      <div>
        Copy and paste the list of Round 1 competitor names in the order that they should appear in the bracket. Each
        wrestler should be listed on a separate line.
      </div>
      <div v-if="playInAndByeHelperMessage">
        {{ playInAndByeHelperMessage }}
      </div>
    </template>
  </info-modal>
</template>

<script>
import InfoModal from '@/components/InfoModal.vue';

export default {
  components: {
    InfoModal
  },

  props: ['playInAndByeHelperMessage']
}
</script>