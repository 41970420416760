<template>
  <section class="agg-section mb-2">
    <div class="agg-section-part">
      <h3>Total # Users</h3>
      <div class="agg-section-part-value">
        <span class="color-action heading3">{{ numberWithCommas(results.userCountTotal) }}</span>
      </div>
    </div>
    <div class="agg-section-part">
      <h3># Verified Users</h3>
      <div class="agg-section-part-value">
        <span class="color-action heading3">{{ numberWithCommas(results.userCountVerified) }}</span>
        <span class="gray2 caption">out of {{ numberWithCommas(results.userCountTotal) }}</span>
      </div>
    </div>
    <div class="agg-section-part">
      <h3># New Users</h3>
      <div class="agg-section-part-value">
        <span class="color-action heading3">{{ numberWithCommas(results.userCountNew) }}</span>
        <span class="gray2 caption">in past 30 days</span>
      </div>
    </div>
  </section>

  <h2>User Accounts</h2>
  <div class="flex gap-1 mt-0-5 mb-0-5 align-center">
    <input autofocus id="admin-dashboard-users-search-input" class="grow" type="text"
      placeholder="Search display name or user email" v-model="searchTerm" @keyup.enter="search">
    <rebel-button color="default" type="primary" text="Search" @click="search" />
    <rebel-button color="default" type="ghosted" text="Filter" class="nowrap" @click="filterByFlyOut.isShowing = true">
      <template v-slot:icon-leading>
        <adjustments-horizontal-icon class="icon-20" />
      </template>
      <template v-if="countOfAppliedFilters > 0" v-slot:icon-trailing>
        <rebel-notification-count-bubble :value="countOfAppliedFilters" primaryColor="action" />
      </template>
    </rebel-button>
  </div>
  <div v-if="appliedFilters != null && appliedFilters != {}" class="flex wrap gap-0-25 mt-0-5 mb-1 align-center">
    <filter-pill v-for="eventId in appliedFilters.eventIds" :key="eventId" :text="`${getEventNameById(eventId)}`"
      @close="removeEventIdFilter(eventId)" />
    <filter-pill v-for="eventTypeId in appliedFilters.eventTypeIds" :key="eventTypeId"
      :text="`${eventTypes.getEventTypeLabel(eventTypeId)}`" @close="removeEventTypeFilter(eventTypeId)" />
  </div>

  <div v-if="isSearching" class="flex justify-center">
    <icon-loading colorVariable="color-action" heightCss="100px" />
  </div>
  <rebel-table v-else-if="tableData.length" :table-data="tableData" row-key="email" :columns="table.columns"
    :sortable="true" :more-options-column="false" :paging-options="paging"
    @pagechanged="(page) => paging.currentPage = page" />

  <filter-by-fly-out v-if="filterByFlyOut.isShowing && filtersLoaded" v-show="isVisible" :class="{ visible: isVisible }"
    :initial-filters="filters" @close="filterByFlyOut.isShowing = false" @filters-apply="filtersApply"
    @filters-reset="filtersReset" />
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import adminDashboardService from '../../services/AdminDashboardService'
import DateUtils from '@/utils/dateUtils.js'
import eventTypes from '../../utils/eventTypes'
import numberUtils from '../../utils/numberUtils'

import { AdjustmentsHorizontalIcon } from '@heroicons/vue/20/solid'
import FilterByFlyOut from './FilterByFlyOut.vue'
import FilterPill from '../FilterPill.vue'
import IconLoading from '../IconLoading.vue'
import RebelButton from '../../components/RebelButton.vue'
import RebelNotificationCountBubble from '../RebelNotificationCountBubble.vue'
import RebelTable from '../RebelTable.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      eventTypes
    }
  },

  async created() {
    // set event options
    const eventOptions = (await adminDashboardService.getEventOptions()).data
    this.filters.find(f => f.title === 'Events').options = eventOptions

    this.filtersLoaded = true
    this.search()
  },

  components: {
    AdjustmentsHorizontalIcon,
    FilterByFlyOut,
    FilterPill,
    IconLoading,
    RebelButton,
    RebelNotificationCountBubble,
    RebelTable
  },

  props: ['isVisible'],

  data() {
    return {
      isSearching: false,

      paging: {
        usePaging: true,

        perPage: 20,
        currentPage: 1,
        maxVisibleButtons: 6
      },

      filtersLoaded: false,

      filters: [
        {
          type: 'checkbox_group',
          selectedEvents: [],
          title: 'Events',
          modelTo: 'selectedEvents',
          options: [
            {
              value: 1,
              text: 'Event 1'
            },
            {
              value: 2,
              text: 'Event 2'
            },
            {
              value: 3,
              text: 'Event 3'
            }
          ]
        },
        {
          type: 'checkbox_group',
          selectedEventTypes: [],
          title: 'Event Type',
          modelTo: 'selectedEventTypes',
          options: eventTypes.getEventTypeOptions()
        }
      ],

      results: {
        userCountTotal: 0,
        userCountVerified: 0,
        userCountNew: 0,
        userSearchResults: []
      },

      appliedFilters: {
        eventIds: [],
        eventTypeIds: []
      },
      searchTerm: '',

      table: {
        columns: [
          {
            label: 'DISPLAY NAME',
            prop: 'displayName',
            sortDirection: 'desc'
          },
          {
            label: 'EMAIL',
            prop: 'linkToUserProfile',
            sortDirection: '',
            isHyperlink: true
          },
          {
            label: 'ACCOUNT CREATED',
            prop: 'createdAtDisplay',
            sortDirection: '',
            sortProp: 'createdAt'
          },
          {
            label: 'EMAIL VERIFIED',
            prop: 'emailVerifiedDisplay',
            sortDirection: ''
          },
          {
            label: 'MOST RECENT EVENT',
            prop: 'linkToMostRecentEvent',
            sortDirection: '',
            isHyperlink: true
          },
          {
            label: 'MOST RECENT LEAGUES',
            prop: 'linksToMostRecentLeagues',
            sortDirection: '',
            isListOfHyperlinks: true
          },
        ],
      },

      filterByFlyOut: {
        isShowing: false
      },
    }
  },

  computed: {
    countOfAppliedFilters() {
      const eventsApplied = this.appliedFilters.eventIds?.length || 0
      const eventTypesApplied = this.appliedFilters.eventTypeIds?.length || 0
      return eventsApplied + eventTypesApplied
    },

    tableData() {
      return this.results.userSearchResults.map(usr => {
        const emailVerifiedDisplay = usr.emailVerified ? 'Yes' : 'No'
        const createdAtDisplay = this.formatDateTime(usr.createdAt)

        return { ...usr, emailVerifiedDisplay, createdAtDisplay }
      })
    },
  },

  methods: {
    async filtersApply(appliedFilters) {
      // TODO: find in a more statically typed way
      const eventFilter = this.filters.find(filter => filter.title === 'Events')
      const appliedEventFilter = appliedFilters.find(appliedFilter => appliedFilter.title === 'Events')

      eventFilter.selectedEvents = appliedEventFilter.selectedEvents
      this.appliedFilters.eventIds = appliedEventFilter.selectedEvents

      const eventTypeFilter = this.filters.find(filter => filter.title === 'Event Type')
      const appliedEventTypeFilter = appliedFilters.find(appliedFilter => appliedFilter.title === 'Event Type')

      eventTypeFilter.selectedEventTypes = appliedEventTypeFilter.selectedEventTypes
      this.appliedFilters.eventTypeIds = appliedEventTypeFilter.selectedEventTypes

      await this.search()
    },

    async filtersReset() {
      const eventFilter = this.filters.find(filter => filter.title === 'Events')
      eventFilter.selectedEvents = []

      const eventTypeFilter = this.filters.find(filter => filter.title === 'Event Type')
      eventTypeFilter.selectedEventTypes = []

      this.appliedFilters = {}

      await this.search()
    },

    removeEventIdFilter(eventId) {
      const eventFilter = this.filters.find(filter => filter.title === 'Events')
      eventFilter.selectedEvents.splice(eventFilter.selectedEvents.findIndex(eId => eId == eventId), 1)
      this.search()
    },

    removeEventTypeFilter(eventTypeId) {
      const eventTypeFilter = this.filters.find(filter => filter.title === 'Event Type')
      eventTypeFilter.selectedEventTypes.splice(eventTypeFilter.selectedEventTypes.findIndex(eTypeId => eTypeId == eventTypeId), 1)
      this.search()
    },

    formatDateTime(dateTime) {
      return DateUtils.formatDateTime(dateTime, this.authStore.loggedInUser.user.timeZoneSetting)
    },

    getDataForCsv() {
      const fields = this.table.columns.map(c => c.label)
      const data = this.tableData.map(row => {
        return {
          displayName: row.displayName,
          linkToUserProfileText: row.linkToUserProfile.text,
          createdAt: row.createdAt,
          emailVerifiedDisplay: row.emailVerifiedDisplay,
          linkToMostRecentEventText: row.linkToMostRecentEvent?.text || '',
          linksToMostRecentLeagues: row.linksToMostRecentLeagues?.map(l => l.text).join(' ') || ''
        }
      })

      return { fields, data }
    },

    getEventNameById(eventId) {
      return this.filters.find(f => f.title === 'Events').options.find(eo => eo.value == eventId).text
    },

    async search() {
      this.isSearching = true
      try {
        this.results = (await adminDashboardService.userTab(this.searchTerm, this.appliedFilters)).data
        this.paging.currentPage = 1
        this.table.columns.forEach(c => c.sortDirection = '') // reset sorting
        this.table.columns[0].sortDirection = 'desc' // reset sorting
        this.isSearching = false
      } catch (error) {
        if (error.name === 'AbortError' || error.name === 'CanceledError') {
          // do nothing
        } else {
          this.isSearching = false
          throw error
        }
      }

    },

    numberWithCommas(x) {
      return numberUtils.numberWithCommas(x)
    }
  }

}

</script>

<style scoped>
#admin-dashboard-users-search-input {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" className="size-5"><path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z" clipRule="evenodd" fill="%23748FA8" /></svg>');
  background-position: .5rem .65rem;
  background-size: 20px;
  background-repeat: no-repeat;
  padding-left: 2rem;
}
</style>