import { createRouter, createWebHistory } from 'vue-router'
import { useAuthenticationStore } from '@/stores/authentication'
import { useStylingStore } from '@/stores/styling'
import { useTextMessageNotificationsStore } from '@/stores/textMessageNotifications'
import ROUTES from './routes'

import AdminDashboardView from '../views/AdminDashboardView.vue'
import CookiePolicyView from '../views/CookiePolicyView.vue'
import EmailVerificationView from '../views/EmailVerificationView.vue'
import EventDetailsView from '../views/EventDetailsView.vue'
import EventPickEmResultsView from '../views/EventPickEmResultsView.vue'
import EventResultsView from '../views/EventResultsView.vue'
import EventsView from '../views/EventsView.vue'
import ForgotPasswordVerifyView from '../views/ForgotPasswordVerifyView.vue'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import LeagueBracketsEditView from '../views/LeagueBracketsEditView.vue'
import LeaguePickEmEditView from '../views/LeaguePickEmEditView.vue'
import LeagueBracketsView from '../views/LeagueBracketsView.vue'
import LeaguePickEmView from '../views/LeaguePickEmView.vue'
import LeagueDetailsView from '../views/LeagueDetailsView.vue'
import NotFoundView from '../views/error/NotFoundView.vue'
import PermissionDeniedView from '../views/error/PermissionDeniedView.vue'
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'
import RefundPolicyView from '../views/RefundPolicyView.vue'
import RegisterView from '../views/RegisterView.vue'
import StripeCheckoutSessionCompleteView from '../views/StripeCheckoutSessionCompleteView.vue'
import TermsOfServiceView from '../views/TermsOfServiceView.vue'
import UserBracketsEditView from '../views/UserBracketsEditView.vue'
import UserBracketsView from '../views/UserBracketsView.vue'
import UserProfileView from '../views/UserProfileView.vue'
import UserProfileSettingsView from '../views/UserProfileSettingsView.vue'
import UnsubscribeFromNotificationsView from '../views/UnsubscribeFromNotificationsView.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: ROUTES.HOME.path,
      name: ROUTES.HOME.name,
      component: HomeView,
      meta: {
        requiresAuth: false,
        pageTitle: 'Wrestle MATNESS'
      },
    },
    {
      path: ROUTES.ADMIN_DASHBOARD.path,
      name: ROUTES.ADMIN_DASHBOARD.name,
      component: AdminDashboardView,
      meta: {
        requiresAuth: true,
        pageTitle: ROUTES.ADMIN_DASHBOARD.title,
        requiresAdminRole: true
      }
    },
    {
      path: '/cookie-policy',
      name: 'cookie-policy',
      component: CookiePolicyView,
      meta: {
        requiresAuth: false,
        pageTitle: 'Cookie Policy - Wrestle MATNESS'
      }
    },
    {
      path: ROUTES.EVENTS.path,
      name: ROUTES.EVENTS.name,
      component: EventsView,
      meta: {
        requiresAuth: true,
        pageTitle: ROUTES.EVENTS.title,
        grayBackground: true
      }
    },
    {
      path: ROUTES.EVENT_DETAILS.path,
      name: ROUTES.EVENT_DETAILS.name,
      component: EventDetailsView,
      meta: {
        requiresAuth: true,
        pageTitle: ROUTES.EVENT_DETAILS.title,
        requiresAdminRole: true,
        grayBackground: true
      }
    },
    {
      path: ROUTES.EVENT_PICK_EM_RESULTS.path,
      name: ROUTES.EVENT_PICK_EM_RESULTS.name,
      component: EventPickEmResultsView,
      meta: {
        requiresAuth: false,
        pageTitle: ROUTES.EVENT_PICK_EM_RESULTS.title,
        showEventBackgroundImage: true,
        darkBackground: true
      }
    },
    {
      path: ROUTES.EVENT_RESULTS.path,
      name: ROUTES.EVENT_RESULTS.name,
      component: EventResultsView,
      meta: {
        requiresAuth: false,
        pageTitle: ROUTES.EVENT_RESULTS.title,
        showEventBackgroundImage: true,
        darkBackground: true,
      }
    },
    {
      path: '/forgot-password/verify',
      name: 'forgot-password-verify',
      component: ForgotPasswordVerifyView,
      meta: {
        requiresAuth: false,
        pageTitle: 'Reset your Password - Wrestle MATNESS'
      }
    },
    {
      path: ROUTES.LEAGUE_DETAILS.path,
      name: ROUTES.LEAGUE_DETAILS.name,
      component: LeagueDetailsView,
      meta: {
        requiresAuth: true,
        requiresLeagueMembership: true,
        requiresLeagueCommissioner: false,
        pageTitle: ROUTES.LEAGUE_DETAILS.title
      }
    },
    {
      ...ROUTES.LEAGUE_BRACKETS_EDIT,
      component: LeagueBracketsEditView,
    },
    {
      ...ROUTES.LEAGUE_PICK_EM_EDIT,  
      component: LeaguePickEmEditView,
    },
    {
      path: '/leagues/:leagueId/pick-em/view',
      name: 'league-pick-em-view',
      component: LeaguePickEmView,
      meta: {
        requiresAuth: true,
        requiresLeagueMembership: true,
        requiresLeagueCommissioner: false,
        pageTitle: 'View Pick Em for League - Wrestle MATNESS',
        showEventBackgroundImage: true,
        darkBackground: true
      }
    },
    {
      path: '/leagues/:leagueId/brackets/view',
      name: 'league-brackets-view',
      component: LeagueBracketsView,
      meta: {
        requiresAuth: true,
        requiresLeagueMembership: true,
        requiresLeagueCommissioner: false,
        pageTitle: 'View Brackets For League - Wrestle MATNESS',
        showEventBackgroundImage: true,
        darkBackground: true
      }
    },
    {
      path: ROUTES.LOGIN.path,
      name: ROUTES.LOGIN.name,
      component: LoginView,
      meta: {
        requiresAuth: false,
        pageTitle: 'Log In - Wrestle MATNESS'
      }
    },
    {
      path: '/permission-denied',
      name: 'permission-denied',
      component: PermissionDeniedView,
      meta: {
        requiresAuth: false,
        pageTitle: 'Permission Denied - Wrestle MATNESS'
      }
    },
    {
      path: ROUTES.PRIVACY_POLICY.path,
      name: ROUTES.PRIVACY_POLICY.name,
      component: PrivacyPolicyView,
      meta: {
        requiresAuth: false,
        pageTitle: ROUTES.PRIVACY_POLICY.title
      }
    },
    {
      path: '/refund-policy',
      name: 'refund-policy',
      component: RefundPolicyView,
      meta: {
        requiresAuth: false,
        pageTitle: 'Refund Policy - Wrestle MATNESS'
      }
    },
    {
      path: ROUTES.REGISTER.path,
      name: ROUTES.REGISTER.name,
      component: RegisterView,
      meta: {
        requiresAuth: false,
        pageTitle: ROUTES.REGISTER.title
      }
    },
    {
      ...ROUTES.STRIPE_CHECKOUT_SESSION_COMPLETE,
      component: StripeCheckoutSessionCompleteView
    },
    {
      path: ROUTES.TERMS_OF_SERVICE.path,
      name: ROUTES.TERMS_OF_SERVICE.name,
      component: TermsOfServiceView,
      meta: {
        requiresAuth: false,
        pageTitle: ROUTES.TERMS_OF_SERVICE.title
      }
    },
    {
      path: '/notifications/:notificationType/unsubscribe',
      name: 'notification-unsubscribe',
      component: UnsubscribeFromNotificationsView,
      meta: {
        requiresAuth: true,
        pageTitle: 'Unsubscribe - Wrestle MATNESS'
      }
    },
    {
      path: ROUTES.USER_BRACKETS_EDIT_VIEW.path,
      name: ROUTES.USER_BRACKETS_EDIT_VIEW.name,
      component: UserBracketsEditView,
      meta: {
        requiresAuth: true,
        pageTitle: ROUTES.USER_BRACKETS_EDIT_VIEW.title,
        showEventBackgroundImage: true,
        darkBackground: true
      }
    },
    {
      path: ROUTES.USER_BRACKETS_VIEW.path,
      name: ROUTES.USER_BRACKETS_VIEW.name,
      component: UserBracketsView,
      meta: {
        requiresAuth: true,
        pageTitle: ROUTES.USER_BRACKETS_VIEW.title,
        showEventBackgroundImage: true,
        darkBackground: true,
      }
    },
    {
      path: ROUTES.USER_PROFILE.path,
      name: ROUTES.USER_PROFILE.name,
      component: UserProfileView,
      meta: {
        requiresAuth: true,
        pageTitle: ROUTES.USER_PROFILE.title
      }
    },
    {
      path: ROUTES.USER_PROFILE_SETTINGS.path,
      name: ROUTES.USER_PROFILE_SETTINGS.name,
      component: UserProfileSettingsView,
      meta: {
        requiresAuth: true,
        pageTitle: ROUTES.USER_PROFILE_SETTINGS.title
      }
    },
    {
      path: '/verify-email',
      name: 'verify-email',
      component: EmailVerificationView,
      meta: {
        requiresAuth: false,
        pageTitle: 'Verify your email address - Wrestle MATNESS'
      }
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFoundView,
      name: ROUTES.NOT_FOUND.name,
      meta: {
        requiresAuth: false,
        pageTitle: ROUTES.NOT_FOUND.title
      }
    }
  ]
})

router.beforeEach(async (to, from) => {
  const DEFAULT_TITLE = 'Wrestle MATNESS'

  if (to.meta?.pageTitle != null) {
    document.title = to.meta.pageTitle
  } else {
    document.title = DEFAULT_TITLE
  }

  const authStore = useAuthenticationStore()
  const stylingStore = useStylingStore()
  stylingStore.setDarkBackground(false)
  stylingStore.setShowEventBackgroundImage(false)

  let tokenWasPresent = false
  // check if our auth token has expired and log the user out if it has
  // todo: if this was a network error, then we should redirect with session expired
  if (authStore.isLoggedIn) {
    tokenWasPresent = true
    const isTokenValid = await authStore.checkTokenValidity()
    if (!isTokenValid) {
      authStore.logout()

      // special case: if the user was logged in and viewing events page, but then their token expires, redirect to login
      if (to.name == 'events') {
        return {
          name: 'login',
          // save the location we were at to come back later
          query: { 'session-expired': true }
        }
      }
    }
  }

  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if ((to.meta.requiresAuth || to.meta.requiresLeagueCommissioner || to.meta.requiresAnyRole) && !authStore.isLoggedIn) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    if (tokenWasPresent) {
      return {
        name: 'login',
        // save the location we were at to come back later
        query: { redirect: to.fullPath, 'session-expired': true }
      }
    } else {
      return {
        name: 'login',
        // save the location we were at to come back later
        query: { redirect: to.fullPath }
      }
    }


  }

  if (to.meta.requiresLeagueCommissioner && !(await authStore.isLeagueCommissionerOf(to.params.leagueId))) {
    return {
      name: 'permission-denied'
    }
  }

  if (to.meta.requiresLeagueMembership && !(await authStore.isLeagueMemberOf(to.params.leagueId))) {
    return {
      name: 'permission-denied',
      query: {
        notAMember: true
      }
    }
  }


  if (to.meta.requiresAdminRole && !authStore.isAdmin) {
    return {
      name: 'permission-denied'
    }
  }

  // persist redirect when user switches from login to register page or vice-versa
  if (from && from.query.redirect && !to.query.redirect &&
    ((from.name == 'login' && to.name == 'register') || from.name == 'register' && to.name === 'login')) {
    to.query.redirect = from.query.redirect
    return to
  }

  const textMessageNotificationsStore = useTextMessageNotificationsStore()
  textMessageNotificationsStore.askIfNecessary()
})

export default router