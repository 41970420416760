<template>
  <modal-dialog ref="myModal" @submit.prevent="confirm">
    <template v-slot:modal-title>Delete This Event?</template>
    <template v-slot:modal-contents>
      <p>
        Are you sure you want to permanently delete the event <span class="bold">{{ eventName }}</span
        >?
      </p>
      <p>This cannot be undone. All leagues will be removed and all member's picks will be lost.</p>
    </template>
    <template v-slot:modal-actions>
      <rebel-button
        button-type="submit"
        type="primary"
        color="danger"
        text="Delete Event"
        :is-loading="deleteLoading"
      ></rebel-button>
      <a @click.prevent="cancel">Cancel</a>
    </template>
  </modal-dialog>
</template>

<script>
import eventService from '@/services/EventService.js'

import RebelButton from '@/components/RebelButton.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  components: {
    RebelButton,
    ModalDialog
  },

  props: {
    eventId: {
      required: true
    },

    eventName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      deleteLoading: false
    }
  },

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async confirm() {
      this.deleteLoading = true

      try {
        await eventService.delete(this.eventId)

        await this.$router.push({ name: 'events' })
      } catch {
        alert('Something went wrong')
      } finally {
        this.deleteLoading = false
      }
    }
  }
}
</script>
