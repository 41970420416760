import axios from 'axios'
import CommonRoutes from './CommonRoutes'

export default {
  forgotPassword(email) {
    return axios.post('/forgot-password', { email })
  },
  verifyResetPasswordToken(token) {
    return axios.get(`/forgot-password/verify?token=${token}`)
  },
  resetPassword(token, newPassword) {
    const dto = { token, newPassword }
    return axios.post(`/reset-password`, dto)
  },

  isLeagueCommissionerOf(leagueId) {
    return axios.get(`/leagues/${leagueId}/commissioner`)
  },

  isLeagueMemberOf(leagueId) {
    return axios.get(`/leagues/${leagueId}/member`)
  },

  login(loginDetails) {
    return axios.post(CommonRoutes.LOGIN, loginDetails)
  },

  register(registrationDetails) {
    return axios.post('/register', registrationDetails)
  },

  updateEmail(newEmail) {
    return axios.patch(`/email`, { email: newEmail })
  },

  updateAddMeToOpenTournamentPreference(newPreference) {
    return axios.patch(`/preferences/add-me-to-open-tournament`, { isPreferenceOn: newPreference })
  },

  updateUserNotificationSetting(newPreferenceDto) {
    return axios.patch(`/notifications`, newPreferenceDto)
  },

  updateDisplayName(newDisplayName) {
    return axios.patch('/displayName', { displayName: newDisplayName })
  },

  updateMyAvatar(fileName) {
    return axios.patch('/avatar', { imageSrc: fileName })
  },

  updatePassword(currentPassword, newPassword) {
    return axios.patch(CommonRoutes.CHANGE_PASSWORD, { currentPassword, newPassword })
  },

  updateTimeZone(newTimeZone) {
    return axios.patch(`/time-zone`, newTimeZone)
  },

  verifyEmail(token) {
    return axios.get(`/verify-email?token=${token}`)
  },

  checkTokenValidity() {
    return axios.get('/user/is-authenticated')
  }
}
