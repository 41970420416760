<template>
  <div class="participant-container grow flex" :class="`${getLayoutClasses} ${styles.getLightDarkClasses(darkBackground)}`">
    <slot name="content"></slot>
  </div>
</template>

<script>
import styles from '@/utils/styles.js'

export default {
  setup() {
    return {
      styles
    }
  },
  
  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    isStackedLayout: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getLayoutClasses() {
      return this.isStackedLayout ? 'stacked-layout' : 'row-layout'
    }
  }
}
</script>

<style scoped>
/* Participant container */
.participant-container {
  display: flex;
  flex-basis: 185px;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
}

.participant-container.stacked-layout {
  flex-basis: auto;
}

.participant-container.light-background {
  background-color: var(--color-gray1-5);
}

.participant-container.dark-background {
  background: linear-gradient(90deg, rgba(249, 205, 165, 0.8) 0%, rgba(141, 189, 235, 0.8) 100%);
  box-shadow: 2px 4px 6px 0px #00000066;
  backdrop-filter: blur(40px);
}
</style>