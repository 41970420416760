<template>
  <rebel-pill :text="getText" :color="getColor" :dark-background="true"/>
</template>

<script>
import RebelPill from '@/components/RebelPill.vue'
import STRINGS from '@/utils/strings.json'

export default {
  components: {
    RebelPill
  },

  computed: {
    getColor() {
      if (this.isDisqualified) {
        return STRINGS.COLOR_INACTIVE
      } else if (this.isPaidFor) {
        return STRINGS.COLOR_SUCCESS
      }
      return STRINGS.COLOR_DANGER
    },

    getText() {
      if (this.isDisqualified) {
        return STRINGS.ENTRY_DISQUALIFIED
      } else if (this.isPaidFor) {
        return STRINGS.ENTRY_PAID
      }
      return STRINGS.ENTRY_UNPAID
    }
  },

  props: {
    isPaidFor: {
      type: Boolean,
      required: true,
      default: false
    },

    isDisqualified: {
      type: Boolean,
      default: false
    }
  }
}
</script>