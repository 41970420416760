<template>
  <section class="agg-section mb-2">
    <div class="agg-section-part">
      <h3>Total # Entries</h3>
      <div class="agg-section-part-value">
        <span class="color-action heading3">{{ numberWithCommas(results.entryCountTotal) }}</span>
      </div>
    </div>
    <div class="agg-section-part">
      <h3>% Complete</h3>
      <div class="agg-section-part-value">
        <span class="color-action heading3">{{ numberWithCommas(results.entryCompletionPercentage) }}%</span>
        <span class="gray2 caption">average for all users</span>
      </div>
    </div>
    <div class="agg-section-part">
      <h3># Paid Entries</h3>
      <div class="agg-section-part-value">
        <span class="color-action heading3">{{ numberWithCommas(results.entryCountPaid) }}</span>
        <span class="gray2 caption">out of {{ numberWithCommas(results.entryCountTotal) }}</span>
      </div>
    </div>
  </section>

  <h2>Entries</h2>
  <div class="flex gap-1 mt-0-5 mb-0-5 align-center">
    <input autofocus id="admin-dashboard-entries-search-input" class="grow" type="text"
      placeholder="Search league name or user email" v-model="searchTerm" @keyup.enter="search">
    <rebel-button color="default" type="primary" text="Search" @click="search" />
    <rebel-button color="default" type="ghosted" text="Filter" class="nowrap" @click="filterByFlyOut.isShowing = true">
      <template v-slot:icon-leading>
        <adjustments-horizontal-icon class="icon-20" />
      </template>
      <template v-if="countOfAppliedFilters > 0" v-slot:icon-trailing>
        <rebel-notification-count-bubble :value="countOfAppliedFilters" primaryColor="action" />
      </template>
    </rebel-button>
  </div> <!-- TODO refactor so the search bar and filter flyout are combined into a component -->
  <div v-if="appliedFilters != null && appliedFilters != {}" class="flex wrap gap-0-25 mt-0-5 mb-1 align-center">
    <filter-pill v-for="eventId in appliedFilters.eventIds" :key="eventId" :text="`${getEventNameById(eventId)}`"
      @close="removeEventIdFilter(eventId)" />
    <filter-pill v-for="eventTypeId in appliedFilters.eventTypeIds" :key="eventTypeId"
      :text="`${eventTypes.getEventTypeLabel(eventTypeId)}`" @close="removeEventTypeFilter(eventTypeId)" />
    <filter-pill v-for="paymentStatus in appliedFilters.paymentStatuses" :key="paymentStatus"
      :text="`${paymentStatus ? 'Paid' : 'Unpaid'}`" @close="removePaymentStatusFilter(paymentStatus)" />
    <filter-pill v-if="appliedFilters.completionPercentages?.length"
      :text="`% Completion: ${getSliderFilterLabel(filters.find(f => f.title === '% Completion'), appliedFilters.completionPercentages)}`"
      @close="removeCompletionPercentageFilter()" />

  </div>

  <div v-if="isSearching" class="flex justify-center">
    <icon-loading colorVariable="color-action" heightCss="100px" />
  </div>
  <rebel-table v-else-if="tableData.length" :table-data="tableData" row-key="email" :columns="table.columns"
    :sortable="true" :more-options-column="false" :paging-options="paging"
    @pagechanged="(page) => paging.currentPage = page" />

  <filter-by-fly-out v-if="filterByFlyOut.isShowing && filtersLoaded" v-show="isVisible" :class="{ visible: isVisible }"
    :initial-filters="filters" @close="filterByFlyOut.isShowing = false" @filters-apply="filtersApply"
    @filters-reset="filtersReset" />
</template>

<script>
import adminDashboardService from '../../services/AdminDashboardService'
import eventTypes from '../../utils/eventTypes'
import numberUtils from '../../utils/numberUtils'

import { AdjustmentsHorizontalIcon } from '@heroicons/vue/20/solid'
import FilterByFlyOut from './FilterByFlyOut.vue'
import FilterPill from '../FilterPill.vue'
import IconLoading from '../IconLoading.vue'
import RebelButton from '../../components/RebelButton.vue'
import RebelNotificationCountBubble from '../RebelNotificationCountBubble.vue'
import RebelTable from '../RebelTable.vue'

export default {
  setup() {
    return {
      eventTypes
    }
  },

  async created() {
    // set event options
    const eventOptions = (await adminDashboardService.getEventOptions()).data
    this.filters.find(f => f.title === 'Events').options = eventOptions

    this.filtersLoaded = true
    this.search()
  },

  components: {
    AdjustmentsHorizontalIcon,
    FilterByFlyOut,
    FilterPill,
    IconLoading,
    RebelButton,
    RebelNotificationCountBubble,
    RebelTable
  },

  props: ['isVisible'],

  data() {
    return {
      isSearching: false,

      paging: {
        usePaging: true,

        perPage: 20,
        currentPage: 1,
        maxVisibleButtons: 6
      },

      filtersLoaded: false,

      filters: [
        {
          type: 'checkbox_group',
          selectedEvents: [],
          title: 'Events',
          modelTo: 'selectedEvents',
          options: []
        },
        {
          type: 'checkbox_group',
          selectedEventTypes: [],
          title: 'Event Type',
          modelTo: 'selectedEventTypes',
          options: eventTypes.getEventTypeOptions()
        },
        {
          type: 'checkbox_group',
          selectedPaymentStatuses: [],
          title: 'Payment Status',
          modelTo: 'selectedPaymentStatuses',
          options: [{ text: 'Paid', value: true }, { text: 'Unpaid', value: false },]
        },
        {
          type: 'slider',
          selectedCompletionPercentages: [0, 100],
          title: '% Completion',
          modelTo: 'selectedCompletionPercentages',
          min: 0,
          max: 100,
          prefixLabel: '0',
          suffixLabel: '100',
        },
      ],

      results: {
        entryCountTotal: 0,
        entryCompletionPercentage: 0,
        entryCountPaid: 0,
        entrySearchResults: []
      },

      appliedFilters: {
        eventIds: [],
        eventTypeIds: [],
        paymentStatuses: [],
        completionPercentages: []
      },
      searchTerm: '',

      table: {
        columns: [
          {
            label: 'USER EMAIL',
            prop: 'emailLinkToUserProfile',
            sortDirection: 'desc',
            isHyperlink: true
          },
          {
            label: 'DISPLAY NAME',
            prop: 'displayName',
            sortDirection: '',
          },
          {
            label: 'EVENT',
            prop: 'linkToEvent',
            sortDirection: '',
            isHyperlink: true
          },
          {
            label: 'LEAGUE NAME',
            prop: 'linkToLeague',
            sortDirection: '',
            isHyperlink: true
          },
          {
            label: '% COMPLETE',
            prop: 'percentageCompleteDisplay',
            sortDirection: '',
            sortProp: 'percentageComplete'
          },
          {
            label: 'SCORE',
            prop: 'score',
            sortDirection: '',
          },
          {
            label: 'OVERALL RANK',
            prop: 'overallRank',
            sortDirection: '',
          },
          {
            label: 'PAYMENT STATUS',
            prop: 'paymentStatus',
            sortDirection: ''
          },
          {
            label: 'VIEW ENTRY',
            prop: 'linkToEntry',
            sortDirection: '',
            isHyperlink: true
          }
        ],
      },

      filterByFlyOut: {
        isShowing: false
      },
    }
  },

  computed: {
    countOfAppliedFilters() {
      const eventsApplied = this.appliedFilters.eventIds?.length || 0
      const eventTypesApplied = this.appliedFilters.eventTypeIds?.length || 0
      const paymentStatusesApplied = this.appliedFilters.paymentStatuses?.length || 0
      const completionPercentagesApplied = this.appliedFilters.completionPercentages?.length ? 1 : 0
      return eventsApplied + eventTypesApplied + paymentStatusesApplied + completionPercentagesApplied
    },

    tableData() {
      return this.results.entrySearchResults.map(entry => {
        const percentageCompleteDisplay = entry.percentageComplete + "%"

        return { ...entry, percentageCompleteDisplay }
      })
    }
  },

  methods: {
    async filtersApply(appliedFilters) {
      // TODO: find in a more statically typed way
      const eventFilter = this.filters.find(filter => filter.title === 'Events')
      const appliedEventFilter = appliedFilters.find(appliedFilter => appliedFilter.title === 'Events')

      eventFilter.selectedEvents = appliedEventFilter.selectedEvents
      this.appliedFilters.eventIds = appliedEventFilter.selectedEvents

      const eventTypeFilter = this.filters.find(filter => filter.title === 'Event Type')
      const appliedEventTypeFilter = appliedFilters.find(appliedFilter => appliedFilter.title === 'Event Type')

      eventTypeFilter.selectedEventTypes = appliedEventTypeFilter.selectedEventTypes
      this.appliedFilters.eventTypeIds = appliedEventTypeFilter.selectedEventTypes

      const paymentStatusFilter = this.filters.find(filter => filter.title === 'Payment Status')
      const appliedPaymentStatusFilter = appliedFilters.find(appliedFilter => appliedFilter.title === 'Payment Status')

      paymentStatusFilter.selectedPaymentStatuses = appliedPaymentStatusFilter.selectedPaymentStatuses
      this.appliedFilters.paymentStatuses = appliedPaymentStatusFilter.selectedPaymentStatuses

      const completionPercentageFilter = this.filters.find(filter => filter.title === '% Completion')
      const appliedCompletionPercentageFilter = appliedFilters.find(appliedFilter => appliedFilter.title === '% Completion')

      completionPercentageFilter.selectedCompletionPercentages = appliedCompletionPercentageFilter.selectedCompletionPercentages
      this.appliedFilters.completionPercentages = appliedCompletionPercentageFilter.selectedCompletionPercentages

      await this.search()
    },

    async filtersReset() {
      const eventFilter = this.filters.find(filter => filter.title === 'Events')
      eventFilter.selectedEvents = []

      const eventTypeFilter = this.filters.find(filter => filter.title === 'Event Type')
      eventTypeFilter.selectedEventTypes = []

      const paymentStatusFilter = this.filters.find(filter => filter.title === 'Payment Status')
      paymentStatusFilter.selectedPaymentStatuses = []

      const completionPercentageFilter = this.filters.find(filter => filter.title === '% Completion')
      completionPercentageFilter.selectedCompletionPercentages = [0, 100]

      this.appliedFilters = {}

      await this.search()
    },

    removeEventIdFilter(eventId) {
      const eventFilter = this.filters.find(filter => filter.title === 'Events')
      eventFilter.selectedEvents.splice(eventFilter.selectedEvents.findIndex(eId => eId == eventId), 1)
      this.search()
    },

    removeEventTypeFilter(eventTypeId) {
      const eventTypeFilter = this.filters.find(filter => filter.title === 'Event Type')
      eventTypeFilter.selectedEventTypes.splice(eventTypeFilter.selectedEventTypes.findIndex(eTypeId => eTypeId == eventTypeId), 1)
      this.search()
    },

    removeCompletionPercentageFilter() {
      const filter = this.filters.find(filter => filter.title === '% Completion')
      filter.selectedCompletionPercentages = [0, 100]
      this.appliedFilters.completionPercentages = []
      this.search()
    },

    removePaymentStatusFilter(statusToRemove) {
      const paymentStatusFilter = this.filters.find(filter => filter.title === 'Payment Status')
      paymentStatusFilter.selectedPaymentStatuses.splice(paymentStatusFilter.selectedPaymentStatuses.findIndex(status => status == statusToRemove), 1)
      this.search()
    },

    getDataForCsv() {
      const fields = this.table.columns.map(c => c.label)
      const data = this.tableData.map(row => {
        return {
          email: row.emailLinkToUserProfile.text,
          displayName: row.displayName,
          linkToEventText: row.linkToEvent.text,
          linkToLeagueText: row.linkToLeague?.text || '',
          completionPercentage: row.percentageCompleteDisplay,
          score: row.score,
          overallRank: row.overallRank,
          paymentStatus: row.paymentStatus,
          linkToEntryText: 'View picks'
        }
      })

      return { fields, data }
    },

    getEventNameById(eventId) {
      return this.filters.find(f => f.title === 'Events').options.find(eo => eo.value == eventId).text
    },

    getSliderFilterLabel(filter, array) {
      let formatFn = typeof filter.format == 'function' ? filter.format : (val) => val
      if (array[0] === array[1]) return formatFn(array[0])

      return `${formatFn(array[0])} - ${formatFn(array[1])}`
    },

    async search() {
      this.isSearching = true
      try {
        this.results = (await adminDashboardService.entryTab(this.searchTerm, this.appliedFilters)).data
        this.paging.currentPage = 1
        this.table.columns.forEach(c => c.sortDirection = '') // reset sorting
        this.table.columns[0].sortDirection = 'desc' // reset sorting
        this.isSearching = false
      } catch (error) {
        if (error.name === 'AbortError' || error.name === 'CanceledError') {
          // do nothing
        } else {
          this.isSearching = false
          throw error
        }
      }
    },

    numberWithCommas(x) {
      return numberUtils.numberWithCommas(x)
    }
  }

}

</script>

<style scoped>
#admin-dashboard-entries-search-input {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" className="size-5"><path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z" clipRule="evenodd" fill="%23748FA8" /></svg>');
  background-position: .5rem .65rem;
  background-size: 20px;
  background-repeat: no-repeat;
  padding-left: 2rem;
}
</style>