<template>
  <div class="status-bar-container flex align-center"
    :class="`${userPredictionState} ${eventResultStates.getEliminatedClass(isEliminated)} ${styles.getLightDarkClasses(darkBackground)} ${styles.getSelectedClasses(isPredictedToWin, isPredictedToWin)}`">
    <div v-if="showIndicator && !darkBackground" class="indicator-bar" :class="getPaddingClass"></div>
    <div v-if="participantName" class="status-bar-text grow" :class="getPaddingClass">{{ participantName }}</div>
    <null-participant-data v-else :darkBackground="darkBackground" />
    <prediction-radio-button v-if="showIcon" :colorLightVarints="true" :dark-background="darkBackground"
      :icon-size="20" :is-editable="false" :is-selected="isPredictedToWin"
      :userPredictionState="userPredictionState" />
  </div>
</template>

<script>
import eventResultStates, { validUserPredictionStates } from '@/utils/eventResultStates.js'
import styles from '@/utils/styles.js'
import NullParticipantData from '@/components/event-matches/NullParticipantData.vue'
import PredictionRadioButton from '@/components/icons/PredictionRadioButton.vue';

export default {
  setup() {
    return {
      eventResultStates,
      styles
    }
  },

  components: {
    NullParticipantData,
    PredictionRadioButton
  },

  props: {
    darkBackground: {
      type: Boolean,
      required: true,
    },

    isEliminated: {
      type: Boolean,
      default: false
    },

    isPredictedToWin: {
      type: Boolean,
      required: true,
    },

    participantName: {
      type: String,
      required: false
    },

    userPredictionState: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return validUserPredictionStates.includes(value)
      }
    }
  },

  computed: {
    getPaddingClass() {
      return !this.showIndicator ? 'add-padding' : ''
    },

    showIndicator() {
      return this.userPredictionState != 'user-result-tbd' && this.isPredictedToWin
    },

    showIcon() {
      return this.isPredictedToWin && (this.userPredictionState == 'incorrect')
    }
  }
}
</script>

<style>
.status-bar-container {
  height: 1.25rem;
  padding-right: 0.5rem;
}

.status-bar-container.light-background {
  background-color: var(--color-gray0-5);
}

.status-bar-container.dark-background {
  background-color: var(--color-gray4);
}

.status-bar-text {
  color: var(--color-gray2);
  font-size: 0.9rem;
  height: 100%;
  line-height: initial;
  padding-left: 0.25rem;
}

.status-bar-text.add-padding {
  padding-left: 0.5rem;
}

.correct.selected .status-bar-text {
  color: var(--color-success);
}

.correct.selected.dark-background .status-bar-text {
  color: var(--color-success-light);
}

.incorrect.selected.light-background .status-bar-text {
  color: var(--color-danger);
}

.incorrect-somebody-else.selected.dark-background .status-bar-text,
.incorrect-eliminated.selected.dark-background .status-bar-text,
.incorrect.selected.dark-background .status-bar-text {
  color: var(--color-danger-light);
}

.eliminated .status-bar-text {
  text-decoration: line-through;
}

.indicator-bar {
  width: 0.25rem;
  height: 100%;
  background-color: var(--color-gray2);
}

.correct .indicator-bar {
  background-color: var(--color-success);
}

.incorrect.light-background .indicator-bar {
  background-color: var(--color-danger);
}

.incorrect-somebody-else.dark-background .indicator-bar,
.incorrect-eliminated.dark-background .indicator-bar,
.incorrect.dark-background .indicator-bar {
  background-color: var(--color-danger-dark-background2);
}
</style>