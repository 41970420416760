<template>
  <span id="notification-count-bubble" v-if="value" class="heading4" :class="`${styles.getLightDarkClasses(darkBackground)} ${primaryColor}`">{{ value }}</span>
</template>

<script>
import styles from '@/utils/styles.js';

export default {
  setup() {
    return {
      styles
    }
  },

  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    primaryColor: {
      type: String,
      required: true,
      validator(value) {
        return ['white', 'action', 'danger'].includes(value)
      }
    },

    value: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
#notification-count-bubble {
  border-radius: 1rem;
  padding: 0 0.5rem 1px 0.5rem;
}

#notification-count-bubble.light-background.white {
  background: var(--color-white);
  color: var(--color-action);
}

#notification-count-bubble.dark-background.white {
  background: var(--color-white);
  color: var(--color-black);
}

#notification-count-bubble.light-background.action {
  background: var(--color-action);
  color: var(--color-white);
}

#notification-count-bubble.dark-background.action {
  background: var(--color-action-dark-background);
  color: var(--color-white);
}

#notification-count-bubble.light-background.danger {
  background: var(--color-danger);
  color: var(--color-white);
}

#notification-count-bubble.dark-background.danger {
  background: var(--color-danger-dark-background);
  color: var(--color-white);
}

</style>