import axios from 'axios'

export default {
  getEventHistoryForUser(userId) {
    return axios.get(`/users/${userId}/event-history`)
  },

  getTransactionHistoryForUser(userId) {
    return axios.get(`/users/${userId}/transaction-history`)
  },

  getUserById(userId) {
    return axios.get(`/users/${userId}`)
  },
}