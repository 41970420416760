<template>
  <div class="flex col gap-2">
    <p class="body2 bold">Last updated March 9, 2025</p>

    <h1>Privacy Policy</h1>

    <p>
      At Wrestle MATNESS, we are committed to protecting your privacy. This policy outlines how we collect, use, and
      protect your information: 
    </p>

    <ol>
      <li>
        <h3>Information We Collect:</h3>
        <ul>
          <li>Personal details (e.g., name, email, billing information) provided during account creation or purchases.</li>
          <li>Usage data, including IP addresses and browsing patterns.</li>
        </ul>
      </li>
      <li>
        <h3>How We Use Your Information:</h3>
        <ul>
          <li>To process orders and provide customer support.</li>
          <li>To improve our website and offerings.</li>
          <li>For marketing purposes, with your consent.</li>
        </ul>
      </li>
      <li>
        <h3>Sharing Information:</h3>
        <ul>
          <li>We do not sell or rent your personal information to third parties.</li>
          <li>Information may be shared with trusted partners to facilitate services (e.g., payment processing).</li>
        </ul>
      </li>
      <li>
        <h3>Data Security:</h3>
        <ul>
          <li>We implement security measures to protect your information but cannot guarantee absolute security.</li>
        </ul>
      </li>
      <li>
        <h3>Your Rights:</h3>
        <ul>
          <li>You may request access to, correction of, or deletion of your data by contacting us at {{ supportEmail }}.</li>
        </ul>
      </li>
      <li>
        <h3>Cookies:</h3>
        <ul>
          <li>Our website uses cookies to enhance your experience. You may disable cookies in your browser settings.</li>
        </ul>
      </li>
    </ol>

    <p>For any questions or concerns about your privacy, please contact us at {{ supportEmail }}.</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supportEmail: 'info@wrestlematness.com'
    }
  }
}
</script>

<style scoped>
ol {
  list-style-type: decimal;
  margin-left: 1.5rem;
}

ul {
  list-style-type: circle;
  margin-left: 1.5rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

ol li:last-child ul {
  margin-bottom: 0rem;
}
</style>