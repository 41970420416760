<template>
  <div id='message' :class="`${styles.getLightDarkClasses(darkBackground)} ${color}`">
    <div class="flex row gap-1 align-center">
      <exclamation-triangle-icon class="icon-24" />
      <div class="flex col gap-0-25 ">
        <h3 class="heading3">{{ textHeading }}</h3>
        <p class="caption">{{ textBody }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import styles from '@/utils/styles.js'

export default {
  setup() {
    return {
      styles
    }
  },

  components: {
    ExclamationTriangleIcon
  },

  props: {
    color: {
      type: String,
      required: true,
      validator(value) {
        return value === 'danger' || value === 'warning' || value === 'success'
      }
    },
    darkBackground: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    textHeading: {
      type: String,
      required: false
    },
    textBody: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
#message {
  border-radius: 4px;
  padding: .5rem .75rem;
}

#message.light-background {
  color: var(--color-main);
  background-color: var(--color-light);
}

#message.light-background * {
  color: var(--color-main);
}

#message.dark-background {
  color: var(--color-white);
  background-color: var(--color-actmainual);
}

#message.success {
  --color-main: var(--color-success);
  --color-light: var(--color-success-opacity10);
}

#message.danger {
  --color-main: var(--color-danger);
  --color-light: var(--color-danger-opacity10);
}

#message.warning {
  --color-main: var(--color-warning);
  --color-light: var(--color-warning-opacity10);
}
</style>