<template>
  <div v-if="isStackedLayout" class="participant border grow flex space-between gap-0-25"
    :class="`${getUpcomingAdminClasses} ${getAllResultsClasses} ${getFixedHeightClass} ${getLayoutClasses} ${styles.getSelectedClasses(isSelected, hasSelector)} ${styles.getEditableClass(isEditable)} ${styles.getLightDarkClasses(darkBackground)}`"
    @click="participantClicked">
    <match-participant-data v-if="participantName != null && participantName != ''"
      :actualResultState="actualResultState" :darkBackground="darkBackground" :hasSelector="hasSelector"
      :iconSize="iconSize" :isEditable="isEditable" :isSelected="isSelected" :numVotes="numVotes"
      :participantName="participantName" :participantRank="participantRank"
      :userPredictionState="userPredictionState" :userScore="userScore" />
    <null-participant-data v-else :darkBackground="darkBackground" />
  </div>

  <match-participant-container v-else :darkBackground="darkBackground">
    <template v-slot:content>
      <div class="participant border grow flex space-between gap-0-25"
        :class="`${getUpcomingAdminClasses} ${getAllResultsClasses} ${styles.getSelectedClasses(isSelected, hasSelector)} ${styles.getEditableClass(isEditable)} ${styles.getLightDarkClasses(darkBackground)}`"
        @click="participantClicked">
        <match-participant-data v-if="participantName != null && participantName != ''"
          :actualResultState="actualResultState" :darkBackground="darkBackground" :hasSelector="hasSelector"
          :iconSize="iconSize" :isEditable="isEditable" :numVotes="numVotes"
          :isSelected="isSelected" :participantName="participantName" :participantRank="participantRank"
          :userPredictionState="userPredictionState" :userScore="userScore" />
        <null-participant-data v-else :darkBackground="darkBackground" />
      </div>
    </template>
  </match-participant-container>

</template>

<script>
import emitEvents from '@/utils/emitEvents';
import { validWhoIsEditing, validActualResultStates, validUserPredictionStates } from '@/utils/eventResultStates.js'
import styles, { validHeroIconSizes } from '@/utils/styles.js'
import MatchParticipantContainer from './MatchParticipantContainer.vue';
import MatchParticipantData from './MatchParticipantData.vue';
import NullParticipantData from '@/components/event-matches/NullParticipantData.vue'

export default {
  setup() {
    return {
      styles
    }
  },

  components: {
    MatchParticipantContainer,
    MatchParticipantData,
    NullParticipantData
  },

  emits: [emitEvents.MATCHUP_CHANGED],

  props: {
    actualResultState: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validActualResultStates.includes(value)
      }
    },

    darkBackground: {
      type: Boolean,
      default: false
    },

    isFixedHeight: {
      type: Boolean,
      default: false
    },

    hasSelector: {
      type: Boolean,
      default: false
    },

    iconSize: {
      type: Number,
      default: 24,
      required: false,
      validator(value) {
        return validHeroIconSizes.includes(value)
      }
    },

    isEditable: {
      type: Boolean,
      default: false
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    isStackedLayout: {
      type: Boolean,
      required: true
    },

    numVotes: {
      type: Number,
      required: false,
      default: null
    },

    matchIndex: {
      type: Number,
      required: false
    },

    roundIndex: {
      type: Number,
      required: false
    },

    participantName: {
      type: String,
      required: false,
    },

    participantRank: {
      type: [String, Number],
      required: false,
    },

    userPredictionState: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return validUserPredictionStates.includes(value)
      }
    },

    userScore: {
      type: Number,
      default: null
    },

    whoIsEditing: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validWhoIsEditing.includes(value)
      }
    }
  },

  computed: {
    getAllClasses() {
      return [this.getAllResults, styles.getLightDarkClasses(this.darkBackground), this.getPropertyClasses].join(' ')
    },

    getAllResultsClasses() {
      return [this.actualResultState, this.userPredictionState].filter(str => str != null && str !== '').join(' ')
    },

    getDataTestId() {
      if (this.roundIndex == null || this.matchIndex == null) return ''

      return `round${this.roundIndex + 1}-matchup${this.matchIndex + 1}-p1${this.isSelected ? 'selected' : 'unselected'}`
    },

    getFixedHeightClass() {
      return this.isFixedHeight ? 'fixed-height' : ''
    },

    getLayoutClasses() {
      return this.isStackedLayout ? 'stacked-layout' : 'row-layout'
    },

    getUpcomingAdminClasses() {
      // For upcoming events, the match participants are not editable for the admin
      if (this.whoIsEditing == 'admin' && !this.isEditable) {
        return 'upcoming-admin'
      }

      return ''
    },

    getUserDisplayScore() {
      if (this.userScore == null || this.userScore == 0) return null

      return '+' + this.userScore
    },
  },

  data() {
    return {
      isActuallySelected: this.isSelected,
      windowWidth: window.innerWidth
    }
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },

    participantClicked() {
      if (!this.isEditable) return

      this.isActuallySelected = !this.isActuallySelected
      this.$emit(emitEvents.MATCHUP_CHANGED)
    },

    unselect() {
      this.isActuallySelected = false
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style scoped>
.editable {
  cursor: pointer;
}

.participant.fixed-height {
  height: 24px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  white-space: nowrap;
}

/* Border */
.border {
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 6px;
}

.border.editable.unselected {
  border: none;
  padding: 5.5px;
}

.border.editable.selected {
  border: 2px solid var(--color-action);
}

.border.editable.selected.dark-background {
  border: 2px solid var(--color-action-dark-background);
}

.border.winner.correct.selected {
  border: 2px solid var(--color-success);
}

.border.loser.incorrect.selected {
  border: 2px solid var(--color-danger-dark-background);
  mix-blend-mode: multiply;
}

/* Participant */
.participant {
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px;

  display: flex;
  align-items: center;
  min-height: 1rem;
  height: 100%;
  width: 100%;
}

.participant.mobile {
  flex-basis: auto;
}

.participant.upcoming-admin,
.participant.editable.selected,
.participant.winner {
  background-color: var(--color-white);
}
</style>