<template>
  <div class="flex">
    <p class="logo-title" :style="`color: ${color};`" id="logo-title-wrestle">Wrestle</p>
    <p class="logo-title" :style="`color: ${color};`" id="logo-title-matness">MATNESS</p>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '#FFFFFF' // --color-white
    }
  }
}
</script>

<style scoped>
.logo-title {
  font-size: 26px;
}

#logo-title-wrestle {
  font-weight: 700;
  letter-spacing: 0.78px;
  padding-right: 1px;
}

#logo-title-matness {
  font-weight: 300;
  letter-spacing: -0.26px;
}
</style>