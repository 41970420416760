<template>
  <modal-dialog ref="myModal" @submit.prevent.stop="">
    <template v-slot:modal-title>{{ modalTitle }}</template>
    <template v-slot:modal-contents>
      <div v-if="verificationCodeSent" class="form-input">
        <label for="phone-number-verification-code">Verification Code</label>
        <input ref="phoneNumberVerificationCodeInput" id="phone-number-verification-code" type="text" placeholder="Enter Verification Code" v-model="otp"
          @keyup.enter="checkOtp" />
        <rebel-button v-if="verificationCodeSent" button-type="button" type="borderless" color="default"
          :text="`Send another verification code to ${phoneNumber}`" @click="sendOtp(true)"></rebel-button>
      </div>
      <div v-else class="form-input wrestle-matness-phone">
        <label for="phone-number">Phone Number</label>
        <vue-tel-input id="phone-number" @on-input="onPhoneNumberInput" v-bind="vueTelInput.bindProps"
          v-model="phoneNumber" @keyup.enter="sendOtp" />
        <p v-if="showInvalidPhone" class="caption color-danger">Phone Number is invalid</p>
      </div>
      <p class="body2">
        Get notified by text message about important event updates, like new
        event matchups becoming available or competitors getting re-seeded.
      </p>
      <p class="body2">
        You may change your preferences later or opt-out at any time. Message
        and data rates may apply.
      </p>
    </template>
    <template v-slot:modal-actions>
      <div v-if="verificationCodeSent" class="flex col gap-0-5 align-center">
        <template v-if="userVerificationCompleted">
          <p v-if="twilioVerificationStatus != null && twilioVerificationStatus === 'max_attempts_reached'"
            class="caption color-danger">Too many attempts. Try
            again in a few minutes.</p>
          <p v-else-if="twilioVerificationStatus != null && (twilioVerificationStatus === 'failed' || twilioVerificationStatus === 'pending')"
            class="caption color-danger">Incorrect code. Please try
            again.</p>
          <p v-else-if="twilioVerificationStatus != null && twilioVerificationStatus === 'expired'"
            class="caption color-danger">The code has expired. Please
            try again.</p>
          <p v-else-if="twilioVerificationStatus != null && twilioVerificationStatus !== 'approved'"
            class="caption color-danger">Something went wrong. Please
            try again.</p>
        </template>

        <rebel-button button-type="button" type="primary" color="default" :is-loading="isUpdating" text="Verify"
          @click="checkOtp"></rebel-button>
      </div>
      <div v-else class="flex col gap-0-5 align-center">
        <p v-if="twilioVerificationStatus != null && twilioVerificationStatus === 'max_attempts_reached'"
          class="caption color-danger">Too many attempts. Try
          again in a few minutes.</p>
        <p v-else-if="twilioVerificationStatus != null && twilioVerificationStatus !== 'pending'"
          class="caption color-danger">Something went wrong. Please
          try again.</p>

        <rebel-button button-type="button" type="primary" color="default" :disabled="!phoneNumberHasBeenModified"
          :is-loading="isUpdating" text="Ok" @click="sendOtp"></rebel-button>
      </div>

      <slot name="phone-number-generic-additional-actions">
        <rebel-button type="borderless" button-type="button" color="default" text="Cancel" @click="cancel"></rebel-button>
      </slot>
    </template>
  </modal-dialog>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import phoneVerificationService from '@/services/PhoneVerificationService'

import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    ModalDialog,
    RebelButton
  },

  props: ['modalTitle', 'value'],

  data() {
    return {
      showInvalidPhone: false,
      phoneNumber: this.value,
      isUpdating: false,
      otp: null,
      twilioVerificationStatus: null,
      userVerificationCompleted: false,
      verificationCodeSent: false,
      vueTelInput: {
        result: {},
        bindProps: {
          mode: "international",
          defaultCountry: "US",
          disabledFetchingCountry: false,
          disabled: false,
          disabledFormatting: false,
          placeholder: "Enter a phone number",
          required: false,
          enabledCountryCode: false,
          enabledFlags: true,
          preferredCountries: ["US"],
          onlyCountries: [],
          ignoredCountries: [],
          autocomplete: "off",
          name: "telephone",
          maxLen: 25,
          // wrapperClasses: "",
          // inputClasses: "",
          dropDownOptions: {
            disabled: false,
            showDialCodeInList: true,
            showDialCodeInSelection: true,
            showFlags: true,
            showSearchBox: false
          },
          // inputOptions: {
          //   showDialCode: false
          // },
          validCharactersOnly: true
        }
      }
    }
  },

  computed: {
    phoneNumberHasBeenModified() {
      return this.phoneNumber != this.value
    }
  },

  methods: {
    setFocusOnPhoneNumberInputField() {
      try {
        document.getElementById('phone-number').querySelector('input').focus()
      } catch { }
    },
    setFocusOnVerificationCodeInputField() {
      try {
        this.$refs.phoneNumberVerificationCodeInput.focus()
      } catch { }
    },

    onPhoneNumberInput(valueWithinInput, phoneObject) {
      this.vueTelInput.result = phoneObject
      this.showInvalidPhone = false
    },

    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    async sendOtp(noUpdating) {
      if (!this.vueTelInput.result.valid) {
        this.showInvalidPhone = true;
        return
      }

      if (!noUpdating) {
        this.isUpdating = true
      }
      this.isUpdating = true
      this.twilioVerificationStatus = null

      try {
        const sendOtpResponse = await phoneVerificationService.sendOtp(this.phoneNumber);
        this.twilioVerificationStatus = sendOtpResponse.data?.toLowerCase()
        if (this.twilioVerificationStatus === 'pending') {
          this.verificationCodeSent = true
          this.$nextTick(() => {
            this.setFocusOnVerificationCodeInputField()
          })
        }
      } catch {
        this.twilioVerificationStatus = 'error'
      } finally {
        this.isUpdating = false
      }
    },

    async checkOtp() {
      this.isUpdating = true
      this.twilioVerificationStatus = null
      this.userVerificationCompleted = false

      try {
        const checkOtpResponse = await phoneVerificationService.checkOtp(this.phoneNumber, this.otp)
        this.twilioVerificationStatus = checkOtpResponse.data?.toLowerCase()
        if (this.twilioVerificationStatus === 'approved') {
          this.authStore.setPhoneNumber(this.phoneNumber)
          this.closeModal()
        }
      } catch {
        this.twilioVerificationStatus = 'error'
      } finally {
        this.userVerificationCompleted = true
        this.isUpdating = false
      }
    },
  },

  mounted() {
    this.setFocusOnPhoneNumberInputField()
  }
}
</script>
<style>
.wrestle-matness-phone .vue-tel-input {
  border: none;
  border-radius: 4px;
  background: var(--color-gray0-5);
  border: 2px solid var(--color-white);
}

.wrestle-matness-phone .vue-tel-input:focus-within {
  box-shadow: none;
  border: none;

  border: 2px solid var(--color-gray4);
}
</style>