<template>
  <div>
    <div class="flex space-between align-center">
      <h1>Admin Dashboard</h1>
      <rebel-button color="default" type="ghosted" text="Download CSV" class="nowrap" @click="downloadCsv">
        <template v-slot:icon-leading>
          <arrow-down-tray-icon class="icon-20" />
        </template>
      </rebel-button>
    </div>

    <tab-selector class="flex mt-2 mb-2" :tabs="TABS" :selected="TABS.USERS" @selected-tab-changed="selectedTabChanged" />

    <div v-show="selectedTab === TABS.USERS">
      <users-tab ref="usersTab" :is-visible="selectedTab === TABS.USERS" />
    </div>
    <div v-show="selectedTab === TABS.EMAILS_SENT">
      <emails-sent-tab ref="emailsSentTab" :is-visible="selectedTab === TABS.EMAILS_SENT" />
    </div>
    <div v-show="selectedTab === TABS.LEAGUES">
      <leagues-tab ref="leaguesTab" :is-visible="selectedTab === TABS.LEAGUES" />
    </div>
    <div v-show="selectedTab === TABS.ENTRIES">
      <entries-tab ref="entriesTab" :is-visible="selectedTab === TABS.ENTRIES" />
    </div>

  </div>
</template>

<script>
import { ArrowDownTrayIcon } from '@heroicons/vue/20/solid'
import EmailsSentTab from '../components/admin-dashboard/EmailsSentTab.vue';
import EntriesTab from '../components/admin-dashboard/EntriesTab.vue';
import LeaguesTab from '../components/admin-dashboard/LeaguesTab.vue';
import RebelButton from '../components/RebelButton.vue'
import TabSelector from '../components/TabSelector.vue'
import UsersTab from '../components/admin-dashboard/UsersTab.vue';

export default {
  components: {
    ArrowDownTrayIcon,
    EmailsSentTab,
    EntriesTab,
    LeaguesTab,
    RebelButton,
    TabSelector,
    UsersTab
  },

  data() {
    return {
      selectedTab: 'Users',

      TABS: {
        USERS: 'Users',
        EMAILS_SENT: 'Emails Sent',
        LEAGUES: 'Leagues',
        ENTRIES: 'Entries',
      },
    }
  },

  methods: {
    downloadCsv() {
      let csvData = { fields: [], data: [] }
      switch (this.selectedTab) {
        case this.TABS.USERS:
          csvData = this.$refs.usersTab.getDataForCsv()
          break
        case this.TABS.EMAILS_SENT:
          csvData = this.$refs.emailsSentTab.getDataForCsv()
          break
        case this.TABS.LEAGUES:
          csvData = this.$refs.leaguesTab.getDataForCsv()
          break
        case this.TABS.ENTRIES:
          csvData = this.$refs.entriesTab.getDataForCsv()
          break
      }

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += csvData.fields.join(",") + "\n";

      csvData.data.forEach(row => {
        let rowData = Object.values(row).map(value => `"${value}"`).join(",");
        csvContent += rowData + "\n";
      });

      console.log(csvContent)

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "my_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    selectedTabChanged(tab) {
      if (this.selectedTab === tab) return
      this.selectedTab = tab
    }
  }
}

</script>