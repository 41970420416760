<template>
  <nav class="layout-max-container">
    <div id="nav-container" class="layout-max-x">
      <div id="nav-header" class="layout-padding-x">
        <router-link :to="logoLinkRoute" class="flex align-center link-white">
          <img id="logo-img" src="/Logo-No-Background-header.png" alt="Wrestle MATNESS logo" />
          <wrestle-matness-logo class="gray1 logo-title" />
        </router-link>

        <div id="nav-right-content">
          <div class="desktop nav-row">
            <div class="nav-link">
              <router-link :to="{ name: 'events' }">Events</router-link>
            </div>
            <div class="nav-link" v-if="authStore.isAdmin">
              <router-link :to="{ name: 'admin-dashboard' }">Dashboard</router-link>
            </div>
            <div class="nav-link">
              <a href="http://blog.wrestlematness.com" target="blank">Blog</a>
            </div>
          </div>
          <div v-if="authStore.isLoggedIn">
            <a href="javascript:void(0)" @click.prevent="showUserFlyout = !showUserFlyout" class="avatar inline-block">
              <avatar-component css-classes="avatar" :src="authStore.avatarSource" :height="36" :width="36" />
            </a>

            <ol id="user-fly-out" v-show="showUserFlyout" class="fly-out body2">
              <li class="avatar">
                <router-link class="avatar" :to="{ name: 'user-profile' }" @click="showUserFlyout = !showUserFlyout">User
                  Profile</router-link>
              </li>
              <li class="avatar">
                <a class="avatar" href="javascript:void(0)" @click.prevent="logout">Log Out</a>
              </li>
            </ol>
          </div>
          <router-link v-else :to="{ name: 'login' }" custom v-slot="{ navigate }">
            <rebel-button button-type="button" type="ghosted" color="default" :dark-background="true" @click="navigate" role="link" text="Log In" />
          </router-link>
        </div>
      </div>

      <div class="mobile flex nav-row">
        <div class="nav-link">
          <router-link :to="{ name: 'events' }">Events</router-link>
        </div>
        <div class="nav-link" v-if="authStore.isAdmin">
          <router-link :to="{ name: 'admin-dashboard' }">Dashboard</router-link>
        </div>
        <div class="nav-link">
          <a href="http://blog.wrestlematness.com" target="blank">Blog</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'

import AvatarComponent from '@/components/AvatarComponent.vue'
import RebelButton from '@/components/RebelButton.vue'
import WrestleMatnessLogo from '@/components/WrestleMatnessLogo.vue'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    AvatarComponent,
    RebelButton,
    WrestleMatnessLogo
  },

  computed: {
    logoLinkRoute() {
      if (this.authStore.isLoggedIn) {
        return { name: 'events' }
      }
      return { name: 'home' }
    }
  },

  data() {
    return {
      showUserFlyout: false
    }
  },

  mounted() {
    window.addEventListener('click', this.close)
    window.addEventListener('touchstart', this.close)
  },

  unmounted() {
    window.removeEventListener('click', this.close)
    window.removeEventListener('touchstart', this.close)
  },

  methods: {
    close(event) {
      if (!event.target.classList.contains('avatar')) {
        this.showUserFlyout = false
      }
    },
    async logout() {
      this.authStore.logout()
      await this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style>
nav {
  background: var(--color-dark-blue);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

nav .mobile {
  display: none;
}

#nav-container {
  background: var(--color-black);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
}

#nav-header {
  background: var(--color-dark-blue);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 0.5rem;
}

#nav-right-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  align-self: stretch;
}

.logo-title {
  font-size: 1.5rem;
  color: var(--color-white);
}

#logo-img {
  height: 2rem;
  width: auto;
  padding-right: 0.5rem;
}

.nav-row {
  display: inline-flex;
  align-items: center;
  background: var(--color-dark-blue);
  align-self: stretch;
  gap: 0.5rem;
}

.nav-link {
  display: inline-flex;
  padding: min(0.75rem, 12px) min(1rem, 16px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-link a {
  color: var(--color-gray1);
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-link a.router-link-active {
  color: var(--color-white);
  border-bottom: 1px solid var(--color-white);
}

#user-fly-out {
  top: 3rem;
}

@media screen and (max-width: 832px) {
  .logo-title {
    font-size: min(1.5rem, 28px);
  }

  #logo-img {
    height: min(2rem, 32px);
    width: auto;
    padding-right: min(0.5rem, 8px);
  }
}

@media screen and (max-width: 768px) {
  nav .desktop {
    display: none;
  }

  nav .mobile.flex {
    display: flex;
  }

  nav .mobile.block {
    display: block;
  }

  .logo-title {
    font-size: 1.5rem;
  }

  #logo-img {
    height: 2rem;
    width: auto;
  }

  .nav-link {
    padding: 0.75rem 1rem;
  }
}

@media screen and (max-width: 528px) {
  .logo-title {
    font-size: min(1.5rem, 24px);
  }

  #logo-img {
    height: min(2rem, 24px);
    width: auto;
    padding-right: min(0.5rem, 8px);
  }
}
</style>