const ROLE_DATA_ADMIN = 'ROLE_DATA_ADMIN'
const ROLE_EVENT_ADMIN = 'ROLE_EVENT_ADMIN'
const ROLE_SITE_ADMIN = 'ROLE_SITE_ADMIN'
const ROLE_USER = 'ROLE_USER'



export default {
    ROLE_DATA_ADMIN,
    ROLE_EVENT_ADMIN,
    ROLE_SITE_ADMIN,
    ROLE_USER,

    DATA_ADMIN_ROLES: [ROLE_SITE_ADMIN, ROLE_DATA_ADMIN],
    ADMIN_ROLES: [ROLE_SITE_ADMIN, ROLE_EVENT_ADMIN]
}