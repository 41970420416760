<template>
  <modal-dialog ref="myModal">
    <template v-slot:modal-title>Edit Event Details</template>
    <template v-slot:modal-contents>
      <form id="edit-event-form" @submit.prevent="updateEvent">
        <div class="layout-form-list">
          <div class="form-input">
            <label for="event-name">Event Name</label>
            <input id="event-name" type="text" placeholder="Event Name" v-model="event.name"
              :required="dataConstraints.event.name.required" :minlength="dataConstraints.event.name.minlength"
              :maxlength="dataConstraints.event.name.maxlength" />
          </div>

          <div class="form-input">
            <label for="event-type">Event Type</label>
            <span>{{ eventTypeLabel }}</span>
          </div>

          <div class="form-input">
            <label for="event-start-date">{{ eventDateLabel }}</label>
            <input id="event-start-date" type="date" v-model.lazy="event.startDate"
              :required="dataConstraints.event.startDate.required" :minlength="dataConstraints.event.startDate.minlength"
              :maxlength="dataConstraints.event.startDate.maxlength" />
          </div>

          <div class="form-input">
            <label for="event-start-time">{{ eventTimeLabel }}</label>
            <input id="event-start-time" type="time" v-model.lazy="event.startTime"
              :required="dataConstraints.event.startTime.required" :minlength="dataConstraints.event.startTime.minlength"
              :maxlength="dataConstraints.event.startTime.maxlength" />
          </div>

          <div class="form-input">
            <label for="event-time-zone">Time Zone</label>
            <select-time-zone id="event-time-zone" :required="true" :timestampForOffset="getTimestamp"
              :value="event.timeZoneName" @time-zone-updated="updateTimeZone"></select-time-zone>
          </div>

          <div class="form-input">
            <label for="event-name">Link to Event Info [Blog]</label>
            <input id="event-name" type="text" :placeholder="urlUtils.SAMPLE_BLOG_URL" v-model="event.infoUrl"
              @blur="urlUtils.setValidityForHtmlInputElementWithUrl($event.currentTarget)" />
          </div>

          <div class="form-input">
            <label for="price-first-entry">Price for {{ discountAdditionalEntry ? 'First' : '' }} Entry</label>
            <currency-text-input input-field-id="price-first-entry" placeholder="Enter an amount"
              v-model="event.priceFirstEntry" />
            <p class="caption color-danger" v-show="showFirstPriceMustBeGreaterThanZero">
              This amount must be greater than $0
            </p>
          </div>
          <div v-if="canHaveAdditionalEntries" class="form-input">
            <div class="flex space-between gap-0-5">
              <p class="body2">Discount pricing for each additional entry?</p>
              <toggle-button data-testid="add-additional-price" tabindex="0"
                :value="discountAdditionalEntry" @toggled="discountAdditionalEntry = !discountAdditionalEntry" />
            </div>
          </div>
          <div v-if="discountAdditionalEntry" class="form-input">
            <label for="price-additional-entry">Price for Each Additional Entry</label>
            <currency-text-input :is-required="true" input-field-id="price-additional-entry" placeholder="Enter an amount"
              v-model="event.priceAdditionalEntry" />
            <p class="caption color-danger" v-show="showAdditionalPriceMustBeGreaterThanZero">
              This amount must be greater than $0
            </p>
            <p class="caption gray2-5" :class="{ 'color-danger': showAdditionalPriceLessThanOrEqualToFirst }">This
              amount must be less than or equal to the price for first entry</p>
          </div>
          <div class="form-input">
            <div class="flex space-between align-center gap-0-5">
              <p class="body2">Allow users to pay to submit entries {{ eventHasStarted ? 'even though the event has already started?' : 'after the start of the event?' }}</p>
              <toggle-button data-testid="accept-payments-override" tabindex="1" :value="event.acceptPaymentsOverride"
                :disabled="event.priceFirstEntry == null || event.priceFirstEntry.length === 0"
                @toggled="event.acceptPaymentsOverride = !event.acceptPaymentsOverride" />
            </div>
            <p class="caption color-danger" v-show="showAcceptPaymentsOverrideCannotBeOnWithoutPrices">
              Cannot have this toggled on when price information is missing
            </p>
            <p class="caption gray2-5">
              Please note: Users are never allowed to edit any of their picks after the event starts, so this setting will
              not allow users to change or add any new picks.
            </p>
            <p class="caption gray2-5">
              However, this setting may affect both league and overall rankings, since only paid entries receive points.
            </p>
          </div>
        </div>

        <div class="layout-form-sections mt-2">
          <div class="actions-list">
            <rebel-button button-type="submit" type="primary" color="default" text="Update Event Details"
              :is-loading="isSavingEvent" :disabled="hasErrorsPreventingSubmission"></rebel-button>
            <a href="javascript:void(null)" @click.prevent="cancel">Cancel</a>
          </div>

          <div class="separator"></div>

          <div class="actions-list">
            <a href="javascript:void(null)" @click.prevent="deleteEvent" class="color-danger">Delete Event</a>
          </div>
        </div>
      </form>
    </template>
  </modal-dialog>
</template>

<script>
import dataConstraints from '@/utils/dataConstraints.js'
import eventService from '@/services/EventService.js'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

import CurrencyTextInput from '@/components/CurrencyTextInput.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import RebelButton from '@/components/RebelButton.vue'
import SelectTimeZone from '@/components/SelectTimeZone.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import emitEvents from '../../utils/emitEvents'

import eventTypes from '../../utils/eventTypes'
import urlUtils from '../../utils/urlUtils.js'

export default {
  setup() {
    return {
      eventTypes,
      dataConstraints,
      urlUtils
    }
  },

  components: {
    CurrencyTextInput,
    ModalDialog,
    RebelButton,
    SelectTimeZone,
    ToggleButton
  },

  computed: {
    canHaveAdditionalEntries() {
      return !this.isPopularVoteTournament
    },

    eventHasStarted() {
      if (this.event.startDate == null || this.event.startTime == null) {
        return false
      }

      const eventTimestamp = new Date(moment.tz(`${this.event.startDate} ${this.event.startTime}`, this.event.timeZoneName).format()).getTime()

      return eventTimestamp < new Date().getTime()
    },

    eventTypeLabel() {
      return eventTypes.getEventTypeLabel(this.eventData.typeId)
    },

    eventDateLabel() {
      if (this.isPopularVoteTournament) return 'Voting End Date'

      return 'Start Date'
    },

    eventTimeLabel() {
      if (this.isPopularVoteTournament) return 'Voting End Time'

      return 'Start Time'
    },

    isPopularVoteTournament() {
      return eventTypes.isPopularVoteTournament(this.eventData.typeId)
    },

    getTimestamp() {
      if (this.event.startDate == null || this.event.startTime == null) {
        return new Date().getTime()
      }

      return moment(`${this.event.startDate} ${this.event.startTime}`).toDate().getTime()
    },

    hasErrorsPreventingSubmission() {
      return this.showAdditionalPriceLessThanOrEqualToFirst || this.showAdditionalPriceMustBeGreaterThanZero || this.showFirstPriceMustBeGreaterThanZero
        || this.showAcceptPaymentsOverrideCannotBeOnWithoutPrices
    },

    showAcceptPaymentsOverrideCannotBeOnWithoutPrices() {
      return this.event.acceptPaymentsOverride && (this.event.priceFirstEntry == null || this.event.priceFirstEntry.length === 0)
    },

    showAdditionalPriceLessThanOrEqualToFirst() {
      if (this.discountAdditionalEntry) {
        if (this.event.priceAdditionalEntry != null && this.event.priceAdditionalEntry.length) {
          if (this.event.priceFirstEntry == null || this.event.priceFirstEntry.length === 0) return true

          return Number(this.event.priceAdditionalEntry) > Number(this.event.priceFirstEntry)
        }
      }

      return false
    },
    showAdditionalPriceMustBeGreaterThanZero() {
      return this.discountAdditionalEntry && this.showPriceMustBeGreaterThanZero(this.event.priceAdditionalEntry)
    },
    showFirstPriceMustBeGreaterThanZero() {
      return this.showPriceMustBeGreaterThanZero(this.event.priceFirstEntry)
    }
  },

  props: {
    eventData: {
      type: Object,
      default: () => {
        return {
          name: '',
          typeId: 1,
          startDate: null,
          startTime: null,
          logoUrl: '',
          timeZoneName: null,
          infoUrl: '',
          paymentData: {
            priceFirstEntry: null,
            priceAdditionalEntry: null
          },
          acceptPaymentsOverride: false
        }
      }
    }
  },

  data() {
    return {
      event: {
        eventId: this.eventData.eventId,
        name: this.eventData.name,
        startDate: this.eventData.startDateTime == null ? null : moment.tz(this.eventData.startDateTime, this.eventData.timeZoneName).format('yyyy-MM-DD'),
        startTime: this.eventData.startDateTime == null ? null : moment.tz(this.eventData.startDateTime, this.eventData.timeZoneName).format('HH:mm'),
        logoUrl: this.eventData.logoUrl,
        timeZoneName: this.eventData.timeZoneName,
        infoUrl: this.eventData.infoUrl,
        typeId: this.eventData.typeId,
        priceFirstEntry: this.eventData.paymentData.priceFirstEntry,
        priceAdditionalEntry: this.eventData.paymentData.priceAdditionalEntry,
        acceptPaymentsOverride: this.eventData.acceptPaymentsOverride
      },
      isSavingEvent: false,
      discountAdditionalEntry: this.eventData.paymentData.priceAdditionalEntry != this.eventData.paymentData.priceFirstEntry
    }
  },

  emits: [emitEvents.DELETE_EVENT],

  methods: {
    cancel() {
      this.closeModal()
    },

    closeModal() {
      this.$refs.myModal.close()
    },

    deleteEvent() {
      this.$emit(emitEvents.DELETE_EVENT, this.event)
      this.closeModal()
    },

    async updateEvent() {
      this.isSavingEvent = true

      try {
        const localDateTime = moment.tz(`${this.event.startDate} ${this.event.startTime}`, this.event.timeZoneName)
        const utcDateTime = localDateTime.utc()

        const eventDto = { ...this.event }

        eventDto.startDateTime = utcDateTime
        delete eventDto.startDate
        delete eventDto.startTime

        if (!this.discountAdditionalEntry) {
          eventDto.priceAdditionalEntry = eventDto.priceFirstEntry
        }

        const response = await eventService.update(eventDto)

        if (response.data?.eventId == null) {
          alert('Something went wrong')
          return
        }

        location.reload()
      } catch {
        alert('Something went wrong')
      } finally {
        this.isSavingEvent = false
      }
    },

    showPriceMustBeGreaterThanZero(price) {
      if (typeof price === 'number') return price === 0

      return price?.length && price == 0
    },

    updateTimeZone(newTimeZone) {
      this.event.timeZoneName = newTimeZone.name
    }
  }
}
</script>


