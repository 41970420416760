<template>
  <div>
    <header-action-bar>
      <template v-slot:linkBackTo>
        <link-back-to class="mb-0-5" :router-link-to="{ name: 'user-profile', query: { userId: $route.query?.userId } }"
          page-name="Profile" />
      </template>
    </header-action-bar>


    <h1 class="mb-2">{{ adminViewingAnotherUsersData ? userAdminIsViewing.displayName : '' }} Settings</h1>

    <div class="layout-form-sections">
      <section class="grid-section">
        <h2>Notifications</h2>
        <h3 class="text-center">Email</h3>
        <h3 class="text-center">Text</h3>

        <p>When event matchups become available</p>
        <toggle-button data-testid="brackets-become-available"
          :disabled="eventBracketPublishChanging || adminViewingAnotherUsersData"
          :value="eventBracketPublishEmailNotificationOn"
          @toggled="(onOrOff) => updateNotificationPreference(onOrOff, NOTIFICATION_TYPES.MATCHUPS_ANNOUNCED_ID, NOTIFICATION_METHODS.EMAIL)" />
        <toggle-button data-testid="brackets-become-available"
          :disabled="eventBracketPublishChanging || adminViewingAnotherUsersData"
          :value="eventBracketPublishSmsNotificationOn"
          @toggled="(onOrOff) => updateNotificationPreference(onOrOff, NOTIFICATION_TYPES.MATCHUPS_ANNOUNCED_ID, NOTIFICATION_METHODS.SMS)" />

        <p>When event matchups are re-seeded</p>
        <toggle-button data-testid="bracket-is-re-seeded"
          :disabled="bracketReseedingChanging || adminViewingAnotherUsersData"
          :value="bracketReSeedingEmailNotificationOn"
          @toggled="(onOrOff) => updateNotificationPreference(onOrOff, NOTIFICATION_TYPES.EVENT_RESEEDED_ID, NOTIFICATION_METHODS.EMAIL)" />
        <toggle-button data-testid="bracket-is-re-seeded"
          :disabled="bracketReseedingChanging || adminViewingAnotherUsersData" :value="bracketReSeedingSmsNotificationOn"
          @toggled="(onOrOff) => updateNotificationPreference(onOrOff, NOTIFICATION_TYPES.EVENT_RESEEDED_ID, NOTIFICATION_METHODS.SMS)" />

        <p>24 hours before the prediction / voting period ends</p>
        <toggle-button data-testid="event-is-starting-soon"
          :disabled="eventStartingSoonChanging || adminViewingAnotherUsersData"
          :value="eventStartingSoonEmailNotificationOn"
          @toggled="(onOrOff) => updateNotificationPreference(onOrOff, NOTIFICATION_TYPES.EVENT_STARTING_SOON_ID, NOTIFICATION_METHODS.EMAIL)" />
        <toggle-button data-testid="event-is-starting-soon"
          :disabled="eventStartingSoonChanging || adminViewingAnotherUsersData"
          :value="eventStartingSoonSmsNotificationOn"
          @toggled="(onOrOff) => updateNotificationPreference(onOrOff, NOTIFICATION_TYPES.EVENT_STARTING_SOON_ID, NOTIFICATION_METHODS.SMS)" />
      </section>

      <section>
        <h2>Preferences</h2>
        <div class="section-list">
          <div class="toggle-item">
            <p>For each event, add me to the Open Tournament by default</p>
            <toggle-button data-testid="add-me-to-open-tourney"
              :disabled="addMeToOpenTournamentPreferenceChanging || adminViewingAnotherUsersData"
              :value="addMeToOpenTournamentPreference" @toggled="updateAddMeToOpenTournamentPreference" />
          </div>
        </div>
      </section>

      <section>
        <label for="user-time-zone" class="heading2 noselect inline-block">Time Zone</label>
        <span v-if="adminViewingAnotherUsersData">{{ timeZoneSetting }}</span>
        <select-time-zone v-else id="user-time-zone" :disabled="zoneChanging" :value="timeZoneSetting"
          @time-zone-updated="updateTimeZone" />
      </section>
    </div>
  </div>
</template>

<script>
import HeaderActionBar from '@/components/HeaderActionBar.vue'
import LinkBackTo from '@/components/LinkBackTo.vue'
import SelectTimeZone from '@/components/SelectTimeZone.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import { useAuthenticationStore } from '@/stores/authentication'
import userService from '@/services/UserService'
import NOTIFICATION_METHODS from '@/utils/notificationMethods'
import NOTIFICATION_TYPES from '@/utils/notificationTypes'

/*
  TODO: Consider storing notification type descriptions in the
  database and then using a loop to build the template above.
*/


export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      NOTIFICATION_TYPES,
      NOTIFICATION_METHODS
    }
  },

  async created() {
    if (this.adminViewingAnotherUsersData) {
      const res = await userService.getUserById(this.userId)
      this.userAdminIsViewing = res.data
    }

    this.isLoading = false;
  },

  components: {
    HeaderActionBar,
    LinkBackTo,
    SelectTimeZone,
    ToggleButton
  },

  computed: {
    adminViewingAnotherUsersData() {
      return this.$route.query?.userId != null && this.authStore.isDataAdmin
    },

    addMeToOpenTournamentPreference() {
      return this.currentUser?.addMeToOpenTournament
    },

    bracketReSeedingEmailNotificationOn() {
      return this.authStore.hasNotificationOn(NOTIFICATION_TYPES.EVENT_RESEEDED_ID, NOTIFICATION_METHODS.EMAIL, this.currentUser)
    },

    eventBracketPublishEmailNotificationOn() {
      return this.authStore.hasNotificationOn(NOTIFICATION_TYPES.MATCHUPS_ANNOUNCED_ID, NOTIFICATION_METHODS.EMAIL, this.currentUser)
    },

    eventStartingSoonEmailNotificationOn() {
      return this.authStore.hasNotificationOn(NOTIFICATION_TYPES.EVENT_STARTING_SOON_ID, NOTIFICATION_METHODS.EMAIL, this.currentUser)
    },

    bracketReSeedingSmsNotificationOn() {
      return this.authStore.hasNotificationOn(NOTIFICATION_TYPES.EVENT_RESEEDED_ID, NOTIFICATION_METHODS.SMS, this.currentUser)
    },

    eventBracketPublishSmsNotificationOn() {
      return this.authStore.hasNotificationOn(NOTIFICATION_TYPES.MATCHUPS_ANNOUNCED_ID, NOTIFICATION_METHODS.SMS, this.currentUser)
    },

    eventStartingSoonSmsNotificationOn() {
      return this.authStore.hasNotificationOn(NOTIFICATION_TYPES.EVENT_STARTING_SOON_ID, NOTIFICATION_METHODS.SMS, this.currentUser)
    },

    timeZoneSetting() {
      return this.currentUser?.timeZoneSetting
    },

    currentUser() {
      if (this.adminViewingAnotherUsersData) {
        return this.userAdminIsViewing
      }

      return this.authStore?.loggedInUser?.user
    },

    userId() {
      if (this.adminViewingAnotherUsersData) {
        return this.$route.query.userId
      }

      return this.authStore.loggedInUser?.user?.id
    }
  },

  data() {
    return {
      addMeToOpenTournamentPreferenceChanging: false,
      bracketReseedingChanging: false,
      eventBracketPublishChanging: false,
      eventStartingSoonChanging: false,
      isLoading: true,
      userAdminIsViewing: {},
      zoneChanging: false
    }
  },

  methods: {
    async updateAddMeToOpenTournamentPreference(newPreference) {
      try {
        this.addMeToOpenTournamentPreferenceChanging = true

        const result = await this.authStore.updateAddMeToOpenTournamentPreference(newPreference)
        if (!result.success) {
          alert('Something went wrong')
        }
      } catch {
        alert('Something went wrong')
      } finally {
        this.addMeToOpenTournamentPreferenceChanging = false
      }
    },

    async updateNotificationPreference(onOrOff, notificationType, notificationMethod) {
      try {
        switch (notificationType) {
          case NOTIFICATION_TYPES.EVENT_RESEEDED_ID:
            this.bracketReseedingChanging = true
            break;
          case NOTIFICATION_TYPES.EVENT_STARTING_SOON_ID:
            this.eventStartingSoonChanging = true
            break;
          case NOTIFICATION_TYPES.MATCHUPS_ANNOUNCED_ID:
            this.eventBracketPublishChanging = true
            break;
        }

        const dto = {
          notificationOn: onOrOff,
          notificationType,
          notificationMethod
        }

        const result = await this.authStore.updateUserNotificationSetting(dto)
        if (!result.success) {
          alert('Something went wrong')
        }
      } catch {
        alert('Something went wrong')
      } finally {
        switch (notificationType) {
          case NOTIFICATION_TYPES.EVENT_RESEEDED_ID:
            this.bracketReseedingChanging = false
            break;
          case NOTIFICATION_TYPES.EVENT_STARTING_SOON_ID:
            this.eventStartingSoonChanging = false
            break;
          case NOTIFICATION_TYPES.MATCHUPS_ANNOUNCED_ID:
            this.eventBracketPublishChanging = false
            break;
        }
      }
    },

    async updateTimeZone(newTimeZone) {
      try {
        this.zoneChanging = true

        const result = await this.authStore.updateTimeZoneSetting(newTimeZone.name)
        if (!result.success) {
          alert('Something went wrong')
        }
      } catch {
        alert('Something went wrong')
      } finally {
        this.zoneChanging = false
      }
    }
  }
}
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: .5rem;
}

.section-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-self: stretch;
}

.toggle-item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  max-width: 36rem;
}

.grid-section {
  display: grid;
  grid-template-columns: 1fr 44px 44px;
  row-gap: .5rem;
  column-gap: 1rem;
}


.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>