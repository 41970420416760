<template>
  <div>
    <ol class="box-selector light-background grow"> <!-- Currently inheriting styles from WeightClassSelector.vue. Perhaps export into shared stylesheet.  -->
      <li v-for="tab in tabs" :key="tab" class="grow" :class="{ active: selectedTab === tab }" @click="tabSelected(tab)">
        <span>{{ tab }}</span>
      </li>
    </ol>
  </div>
</template>

<script>
import emitEvents from '../utils/emitEvents'

export default {
  props: ['tabs', 'selected'],
  emits: [emitEvents.SELECTED_TAB_CHANGED],

  data() {
    return {
      selectedTab: this.selected
    }
  },

  methods: {
    tabSelected(tab) {
      if (tab == this.selectedTab) return // do nothing if tab is already selected

      this.selectedTab = tab
      this.$emit(emitEvents.SELECTED_TAB_CHANGED, tab)
    }
  }
}

</script>
