<template>
  <div class="flex align-baseline gap-1">
    <league-data-item-fraction v-if="entryPointsPossible != null" label1="won" :value1=entryPoints label2="possible" :value2=entryPointsPossible />
    <league-data-item v-else :value="entryPoints" label="points" />
    <league-data-item :value="leagueRank" label="league rank" />
    <league-data-item :value="overallEventRank" label="MATNESS rank" />
  </div>
</template>

<script>
import LeagueDataItem from '@/components/events/LeagueDataItem.vue'
import LeagueDataItemFraction from '@/components/events/LeagueDataItemFraction.vue'

export default {
  components: {
    LeagueDataItem,
    LeagueDataItemFraction
  },

  props: {
    entryPoints: {
      type: Number,
      required: true
    },

    entryPointsPossible: {
      type: Number,
      required: false
    },

    leagueRank: {
      type: String,
      required: true
    },

    overallEventRank: {
      type: String,
      required: true
    }
  }
}
</script>
