<template>
  <div class="flex col gap-2">
    <p class="body2 bold">Last updated March 9, 2025</p>

    <h1>Refund Policy</h1>

    <p>
      At Wrestle MATNESS, customer satisfaction is our top priority. Since our offerings are digital products, refunds are
      not provided except in cases where there is a verifiable issue with our software. Please review our policy below:
    </p>

    <ol>
      <li>
        <h3>Eligibility for Refunds:</h3>
        <ul>
          <li>Refunds are only available if a software issue prevents the product from functioning as intended and cannot
            be resolved after troubleshooting.</li>
          <li>Refund requests must be made within 14 days of purchase.</li>
        </ul>
      </li>
      <li>
        <h3>Refund Process:</h3>
        <ul>
          <li>To initiate a refund, please contact us at {{ supportEmail }}.com with your order details and a description
            of the issue.</li>
          <li>Approved refunds will be processed within 7-10 business days to the original payment method.</li>
        </ul>
      </li>
      <li>
        <h3>Exclusions:</h3>
        <ul>
          <li>Refunds are not available for user errors, such as purchasing the wrong product or failing to meet system
            requirements.</li>
        </ul>
      </li>
    </ol>

    <p>For questions or assistance, please contact us at {{ supportEmail }}.</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supportEmail: 'info@wrestlematness.com'
    }
  }
}
</script>

<style scoped>
ol {
  list-style-type: decimal;
  margin-left: 1.5rem;
}

ul {
  list-style-type: circle;
  margin-left: 1.5rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

ol li:last-child ul {
  margin-bottom: 0rem;
}

</style>