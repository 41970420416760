<template>
  <match-participant-container :dark-background="darkBackground" :isStackedLayout="true">
    <template v-slot:content>
      <div class="flex col matchup-stacked grow width-100"
        :class="`${userPredictionState} ${styles.getLightDarkClasses(darkBackground)}`">
        <match-participant
          :actualResultState="eventResultStates.getActualResultStateForBracket(matchup, matchup.participant1Id)"
          :darkBackground="darkBackground" :isFixedHeight="true" :hasSelector="hasSelector(1)"
          :iconSize="20" :isEditable="isEditable" :isSelected="isSelected(1)" :isStackedLayout="true"
          :matchIndex="matchIndex" :roundIndex="roundIndex"
          :participantName="abbreviateAsNeeded(matchup.participant1Name)"
          :participantRank="matchup.participant1Ranking" :userPredictionState="userPredictionState"
          @click="playerClicked(matchup.participant1Id)" />
        <match-participant
          :actualResultState="eventResultStates.getActualResultStateForBracket(matchup, matchup.participant2Id)"
          :darkBackground="darkBackground" :isFixedHeight="true" :hasSelector="hasSelector(2)"
          :iconSize="20" :isEditable="isEditable" :isSelected="isSelected(2)" :isStackedLayout="true"
          :matchIndex="matchIndex" :roundIndex="roundIndex"
          :participantName="abbreviateAsNeeded(matchup.participant2Name)" :participantRank="matchup.participant2Ranking"
          :userPredictionState="userPredictionState" 
          @click="playerClicked(matchup.participant2Id)" />
      </div>
    </template>
  </match-participant-container>
</template>

<script>
import emitEvents from '@/utils/emitEvents.js';
import eventResultStates, { validUserPredictionStates, validWhoIsEditing } from '@/utils/eventResultStates.js'
import styles from '@/utils/styles.js'
import MatchParticipant from '@/components/event-matches/MatchParticipant.vue';
import MatchParticipantContainer from '@/components/event-matches/MatchParticipantContainer.vue';

export default {
  setup() {
    return {
      eventResultStates,
      styles
    }
  },

  emits: [emitEvents.MATCHUP_CHANGED, 'player-clicked'],

  components: {
    MatchParticipant,
    MatchParticipantContainer,
  },

  props: {
    darkBackground: {
      type: Boolean,
      required: false,
      default: false
    },

    isEditable: {
      type: Boolean,
      default: false,
    },

    matchup: {
      type: Object,
      required: true
    },

    matchIndex: {
      type: Number,
      required: false
    },

    roundIndex: {
      type: Number,
      required: false
    },

    roundNum: {
      type: Number,
      required: false
    },

    predictedWinnerId: {
      type: Number,
      required: false
    },

    userPredictionState: {
      type: String,
      required: true,
      validator(value) {
        return validUserPredictionStates.includes(value)
      }
    },

    whoIsEditing: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validWhoIsEditing.includes(value)
      }
    }
  },

  data() {
    return {
      lastUpdated: Date.now(),
    }
  },

  methods: {
    abbreviateAsNeeded(name) {
      if (name == null) return ''

      if (this.roundNum <= 1) return name

      name = name.trim()

      const rankRegex = /^\d+|^\(\s*\d+\s*\)/
      const schoolStarterRegex = /\(|\[/

      let rank = ''

      const rankingMatches = name.match(rankRegex)
      if (rankingMatches != null) {
        // ranking is present. extract ranking and revise name
        rank = rankingMatches[0] + ' '
        name = name.substring(rank.length).trim()
      }

      if (name.match(schoolStarterRegex) != null) {
        name = name.split(schoolStarterRegex)[0].trim().replace('\t', ' ')
      }

      return rank + name
    },

    getMatchupSelectionState(participantIndex) {
      return this.getParticipantIdByIndex(participantIndex) == this.predictedWinnerId
    },

    getParticipantIdByIndex(participantIndex) {
      if (participantIndex == 1) {
        return this.matchup.participant1Id
      } else if (participantIndex == 2) {
        return this.matchup.participant2Id
      }
      throw Error('Participant index can only be 1 or 2')
    },

    hasSelector(participantIndex) {
      if (this.isEditable) {
        return !this.matchup.bye
      } else {
        return this.getMatchupSelectionState(participantIndex)
      }
    },

    isSelected(participantIndex) {
      if (this.isEditable) {
        if (this.whoIsEditing == 'admin-preview') {
          return this.matchup.winner != null && this.matchup.winner == this.getParticipantIdByIndex(participantIndex)
        }
        
        return this.matchup.projectedWinner != null && this.matchup.projectedWinner == this.getParticipantIdByIndex(participantIndex)
      } else {
        return this.getMatchupSelectionState(participantIndex)
      }
    },

    // NOT YET
    matchupChanged() {
      if (!this.isEditable) return

      if (this.whoIsEditing == 'user' || this.whoIsEditing == 'admin-preview') {

        const data = {
          matchupId: this.matchup.matchupId,
          projectedWinnerParticipantId: this.userMatchupProjection?.projectedWinnerParticipantId,
          projectedMatchOutcomeOptionId: this.userMatchupProjection?.projectedMatchOutcomeOptionId
        }

        this.$emit(emitEvents.MATCHUP_CHANGED, data)
      } else {
        const data = {
          matchupId: this.matchup.matchupId,
          winnerParticipantId: this.matchup.winnerParticipantId,
          winnerMatchOutcomeOptionId: this.matchup.winnerMatchOutcomeOptionId
        }

        this.$emit(emitEvents.MATCHUP_CHANGED, data)
      }

      this.lastUpdated = Date.now()
    },

    participantClicked(participantNumber) {
      if (!this.isEditable) return

      if (this.whoIsEditing == 'user' || this.whoIsEditing == 'admin-preview') {
        if (this.userMatchupProjection == null) return undefined

        const userClickedOnSelectedParticipant = this.userMatchupProjection.projectedWinnerParticipantId === participantNumber

        if (userClickedOnSelectedParticipant) {
          this.userMatchupProjection.projectedWinnerParticipantId = null
        } else {
          this.userMatchupProjection.projectedWinnerParticipantId = participantNumber
        }
      } else {
        const userClickedOnSelectedParticipant = this.matchup.winnerParticipantId === participantNumber

        if (userClickedOnSelectedParticipant) {
          this.matchup.winnerParticipantId = null
        } else {
          this.matchup.winnerParticipantId = participantNumber
        }
      }

      this.matchupChanged()

      if (participantNumber === 1) {
        this.$refs.participant2.unselect()
      } else {
        this.$refs.participant1.unselect()
      }
    },

    playerClicked(participantId) {
      this.$emit('player-clicked', { matchup: this.matchup, participantId })
    }
  }
}
</script>

<style scoped>
.matchup-stacked.light-background {
  gap: 2px;
}

.matchup-stacked.incorrect {
  gap: 4px;
}
</style>