<template>
  <entry-card :entry-paid-in-full="entry.paidInFull"
    :entry-paid-if-necessary="entry.paidIfNecessary"
    :entry-exempt-from-payment="entry.exemptFromPayment"
    :entry-predictions-remaining="entry.predictionsRemaining" :entry-progress-percentage="entry.progressPercentage"
    entry-title="My Vote" :event="event" event-status="eventStatus" />
</template>

<script>
import EntryCard from '@/components/events/entryCard/EntryCard.vue'

export default {
  components: {
    EntryCard
  },

  props: {
    entry: {
      type: Object,
      required: true,
    },

    event: {
      type: Object,
      required: true
    },

    eventStatus: {
      type: String,
      required: true
    }
  }
}
</script>