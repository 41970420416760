export default {
  HOME: {
    name: 'home',
    path: '/'
  },

  ADMIN_DASHBOARD: {
    name: 'admin-dashboard',
    path: '/admin/dashboard',
    title: 'Admin Dashboard - Wrestle MATNESS'
  },

  COOKIE_POLICY: 'cookie-policy',

  EVENTS: {
    name: 'events',
    path: '/events',
    title: 'View All Events - Wrestle MATNESS'
  },

  EVENT_DETAILS: {
    name: 'event-details',
    path: '/events/:eventId',
    title: 'Manage Event - Wrestle MATNESS'
  },

  EVENT_PICK_EM_RESULTS: {
    name: 'event-results-pick-em',
    path: '/events/:eventId/pick-em/view',
    title: 'View Event Results - Wrestle MATNESS'
  },

  EVENT_RESULTS: {
    name: 'event-results',
    path: '/events/:eventId/brackets/view',
    title: 'View Event Results - Wrestle MATNESS'
  },

  FORGOT_PASSWORD_VERIFY: 'forgot-password-verify',

  LEAGUE_DETAILS: {
    name: 'league-details',
    path: '/league/:leagueId',
    title: 'League Details - Wrestle MATNESS'
  },

  LEAGUE_BRACKETS_EDIT: {
    path: '/leagues/:leagueId/brackets/edit',
    name: 'league-brackets-edit',
    meta: {
      requiresAuth: true,
      requiresLeagueMembership: true,
      requiresLeagueCommissioner: false,
      pageTitle: 'Edit Predictions for League - Wrestle MATNESS',
      showEventBackgroundImage: true,
      darkBackground: true
    }
  },
  LEAGUE_BRACKETS_VIEW: 'league-brackets-view',
  LEAGUE_PICK_EM_EDIT: {
    path: '/leagues/:leagueId/pick-em/edit',
    name: 'league-pick-em-edit',
    meta: {
      requiresAuth: true,
      requiresLeagueMembership: true,
      requiresLeagueCommissioner: false,
      pageTitle: 'Edit Pick Em for League - Wrestle MATNESS',
      showEventBackgroundImage: true,
      darkBackground: true
    }
  },

  LOGIN: {
    name: 'login',
    path: '/login'
  },

  NOT_FOUND: {
    name: 'not-found',
    title: 'Not Found - Wrestle MATNESS'
  },

  PERMISSION_DENIED: {
    name: 'permission-denied',
    path: '/permission-denied',
    title: 'Permission Denied - Wrestle MATNESS'
  },

  PRIVACY_POLICY: {
    name: 'privacy-policy',
    path: '/privacy-policy',
    title: 'Privacy Policy - Wrestle MATNESS'
  },

  REGISTER: {
    name: 'register',
    path: '/register',
    title: 'Sign Up - Wrestle MATNESS'
  },

  STRIPE_CHECKOUT_SESSION_COMPLETE: {
    path: '/order/success',
    alias: '/order/cancel',
    name: 'stripe-checkout-session-complete',
    meta: {
      requiresAuth: true,
      title: 'Order Complete - Wrestle MATNESS'
    }
  },

  TERMS_OF_SERVICE: {
    path: '/terms-of-service',
    name: 'terms-of-service',
    title: 'Terms of Service - Wrestle MATNESS'
  },

  NOTIFICATION_UNSUBSCRIBE: 'notification-unsubscribe',

  USER_BRACKETS_EDIT_VIEW: {
    name: 'user-brackets-edit',
    path: '/events/:eventId/vote-now',
    title: 'Edit Brackets - Wrestle MATNESS'
  },

  USER_BRACKETS_VIEW: {
    name: 'user-brackets-view',
    path: '/events/:eventId/vote-results',
    title: 'View Brackets - Wrestle MATNESS'
  },

  USER_PROFILE: {
    name: 'user-profile',
    path: '/user-profile',
    title: 'User Profile - Wrestle MATNESS'
  },

  USER_PROFILE_SETTINGS: {
    name: 'user-profile-settings',
    path: '/user-profile-settings',
    title: 'User Settings - Wrestle MATNESS'
  },

  VERIFY_EMAIL: 'verify-email',
}