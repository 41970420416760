<template>
  <div class="rebelToolbarButton flex align-center justify-center gap-0-5" :class="`action ${styles.getSelectedClasses(isSelected)} nowrap`">
    <slot name="icon-leading"></slot>
    <slot name="body">{{ text }}</slot>
    <slot name="icon-trailing"></slot>
  </div>
</template>

<script>
import styles from '@/utils/styles.js';

export default {
  setup() {
    return {
      styles
    }
  },

  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: false
    }
  },
}
</script>

<style>
/* Intentionally not scoped, because the container class is used to create button bar */

.rebelToolbarButtonContainer {
  background: var(--color-gray2);
  border: 1px solid var(--color-gray2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  box-shadow: 2px 4px 6px 0px #00000066;
  gap: 1px;
  overflow: hidden;
}

.rebelToolbarButton { 
  cursor: pointer;
  line-height: 1.2rem;
  padding: 0.5rem 0.75rem;
}

.rebelToolbarButton.unselected {
  background: var(--color-gray0-5);
  color: var(--color-gray2);
}

.rebelToolbarButton.selected {
  background: var(--color-white);
  border-bottom: 2px solid var(--color-action);
  color: var(--color-action);
}
</style>