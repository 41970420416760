<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" :class="CLOSE_ME_CLASS">
      <div class="modal" role="dialog" aria-labelledby="modalTitle">
        <div class="icon-container flex justify-end">
          <x-mark-icon data-testid="close-btn" class="icon icon-24" :class="CLOSE_ME_CLASS" @click="close" />
        </div>
        <form class="modal-body layout-form-page">
          <h1 id="modalTitle">
            <slot name="modal-title"></slot>
          </h1>
          <div id="modal-contents" class="layout-form-list">
            <slot name="modal-contents"></slot>
          </div>
          <div class="actions-list" v-if="$slots['modal-actions']">
            <slot name="modal-actions"></slot>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import emitEvents from '../utils/emitEvents.js'
import { useTextMessageNotificationsStore } from '../stores/textMessageNotifications'

export default {
  setup() {
    return {
      textMessageNotifications: useTextMessageNotificationsStore()
    }
  },
  
  components: {
    XMarkIcon
  },
  created() {
    this.textMessageNotifications.setAnotherModalIsShowing(true)
    window.addEventListener('click', this.tryClose)
    window.addEventListener('touchstart', this.tryClose)
  },

  unmounted() {
    this.textMessageNotifications.setAnotherModalIsShowing(false)
    window.removeEventListener('click', this.tryClose)
    window.removeEventListener('touchstart', this.tryClose)
  },

  data() {
    return {
      CLOSE_ME_CLASS: 'close-me-when-clicked'
    }
  },

  emits: [emitEvents.CLOSE, emitEvents.SUBMIT_MODAL],

  methods: {
    close() {
      window.removeEventListener('click', this.tryClose)
      this.$emit(emitEvents.CLOSE)
    },

    tryClose(event) {
      if (event.target.classList.contains(this.CLOSE_ME_CLASS)) {
        this.close()
      }
    }
  }
}
</script>

<style scoped>
@import '/modal.css';

.icon {
  cursor: pointer;
}

.icon-container {
  box-sizing: border-box;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
}
</style>