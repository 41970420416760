<template>
  <div v-if="participantName.toUpperCase() == 'BYE'" class="participant-text bye" :class="getTypographyClass">
    {{ participantName }}
  </div>
  <div v-else class="participant-text flex gap-0-25 align-baseline"
    :class="`${getTypographyClass} ${getAllResultsClasses} ${getPigStyling} ${styles.getSelectedClasses(isSelected, hasSelector)} ${styles.getEditableClass(isEditable)}`"
    @click="participantTextClicked($event)">
    <span v-if="participantRank != null" class="ranking caption" :class="styles.getLightDarkClasses(darkBackground)">{{
      participantRank }}</span>
    {{ participantName }}
  </div>
  <div class="flex gap-0-25 align-center">
    <span v-if="getUserDisplayScore != null" class="bonus heading4">
      {{ getUserDisplayScore }}
    </span>
    <span v-if="numVotes != null" class="flex votes heading4">
      {{ getVoteDisplay }}
    </span>
    <div v-if="hasSelector" class="flex align-center gap-0-125">
      <prediction-radio-button v-if="hasSelector" :data-testid="dataTestId" :dark-background="darkBackground"
        :icon-size="iconSize" :is-editable="isEditable" :is-selected="isSelected"
        :userPredictionState="userPredictionState" />
    </div>
  </div>
</template>

<script>
import { validActualResultStates, validUserPredictionStates } from '@/utils/eventResultStates.js'
import styles, { validHeroIconSizes } from '@/utils/styles.js'
import PredictionRadioButton from '@/components/icons/PredictionRadioButton.vue';
import { useSharedModalsStore } from '@/stores/sharedModals'

export default {
  setup() {
    return {
      sharedModals: useSharedModalsStore(),
      styles
    }
  },

  components: {
    PredictionRadioButton
  },

  props: {
    actualResultState: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value == null || validActualResultStates.includes(value)
      }
    },

    darkBackground: {
      type: Boolean,
      default: false
    },

    dataTestId: {
      type: String,
      required: false
    },

    hasSelector: {
      type: Boolean,
      default: false
    },

    iconSize: {
      type: Number,
      default: 24,
      required: false,
      validator(value) {
        return validHeroIconSizes.includes(value)
      }
    },

    isEditable: {
      type: Boolean,
      default: false
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    numVotes: {
      type: Number,
      required: false,
      default: null
    },

    participantName: {
      type: String,
      required: true,
    },

    participantRank: {
      type: [String, Number],
      required: false,
    },

    userPredictionState: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return validUserPredictionStates.includes(value)
      }
    },

    userScore: {
      type: Number,
      default: null
    }
  },

  computed: {
    getAllResultsClasses() {
      return [this.actualResultState, this.userPredictionState].filter(str => str != null && str !== '').join(' ')
    },

    getPigStyling() {
      if (this.isPigParticipant) {
        return 'pig'
      }
      return ''
    },

    getTypographyClass() {
      return this.windowWidth <= 512 ? 'caption' : 'body2'
    },

    getUserDisplayScore() {
      if (this.userScore == null || this.userScore == 0) return null

      return '+' + this.userScore
    },

    getVoteDisplay() {
      if (this.numVotes == null) return null

      return (this.numVotes == 1) ? `${this.numVotes} vote` : `${this.numVotes} votes`
    },

    isPigParticipant() {
      if (this.participantName == null) return false
      return this.participantName.includes('🐷 PIG')
    },
  },

  data() {
    return {
      windowWidth: window.innerWidth
    }
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },

    participantTextClicked(ev) {
      if (this.isPigParticipant) {
        ev.stopPropagation()
        this.sharedModals.setPigModalShowing(true)
      }
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style scoped>
.bonus {
  color: var(--color-success);
}

.votes {
  color: var(--color-success);
}

.ranking.light-background {
  color: var(--color-gray2-5);
}

.ranking.dark-background {
  color: var(--color-gray4);
}

/* Participant text */
.participant-text {
  overflow: hidden;
}

.participant-text.pig {
  cursor: pointer;
}

.participant-text.dark-background {
  color: var(--color-dark-blue);
}

.participant-text.editable.selected {
  color: var(--color-black);
}

.participant-text.actual-result-tbd.selected.not-editable,
.participant-text.winner.not-editable {
  color: var(--color-black);
  font-weight: 600;
}

.participant-text.bye,
.participant-text.loser,
.participant-text.loser .ranking {
  color: var(--color-gray3);
}

.participant-text.loser.incorrect {
  text-decoration: line-through;
}
</style>