<template>
  <div v-if="isLoaded" class="flex col align-center justify-center gap-2">

    <section v-if="orderSuccessful" class="flex col align-center justify-center">
      <check-circle-icon v-if="orderSuccessful" class="check-icon" />
      <h2 class="heading2">Thank you for your order</h2>
    </section>
    <section v-else>
      <h2 class="heading2">Something went wrong</h2>
    </section>

    <section v-if="orderSuccessful" id="payment-summary" class="flex col align-center gap-1-5">
      <header class="flex align-center">
        <img id="logo-img" src="/Logo-No-Background-header.png" alt="Wrestle MATNESS logo" />
        <wrestle-matness-logo class="gray1 logo-title" color="#1B2A39" />
      </header>

      <section class="flex col align-center gap-0-5">
        <p class="event-name">Entry for {{ model.event.name }}</p>
        <p class="price">{{ model.transaction.amountTotalDisplay }}</p>
      </section>

      <img v-if="model.event.logoUrl" width="120" :src="model.event.logoUrl" alt="Event logo" />
    </section>
    <section v-else>
      {{ orderCancelStatusText }}
    </section>

    <rebel-button color="default" type="primary" text="Return to Wrestle MATNESS" @click="goBackToWhereTheyCameFrom">
      <template v-slot:icon-leading>
        <arrow-left-icon class="icon-20 white" />
      </template>
    </rebel-button>
  </div>
</template>

<script>
import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/vue/20/solid';
import stripeCheckoutService from '@/services/StripeCheckoutService.js'
import routes from '@/router/routes'
import RebelButton from '@/components/RebelButton.vue'
import WrestleMatnessLogo from '@/components/WrestleMatnessLogo.vue'

export default {

  async created() {
    if (!this.orderSuccessful && this.cancelReason == null) {
      await this.goBackToWhereTheyCameFrom()
    }

    if (this.orderSuccessful) {
      this.model = (await stripeCheckoutService.getByTransactionId(this.transactionId)).data
    }
    this.isLoaded = true
  },

  data() {
    return {
      model: {},
      isLoaded: false,

      PURCHASER_TYPES: ['league member', 'event member'],
      CANCEL_REASONS: [
        'An entry has already been purchased',
        'Something unexpected went wrong.'
      ]
    }
  },
  components: {
    ArrowLeftIcon,
    CheckCircleIcon,
    RebelButton,
    WrestleMatnessLogo
  },

  computed: {
    transactionId() {
      return this.$route.query.transaction_id
    },

    orderSuccessful() {
      return this.$route.path.toLowerCase().endsWith("success")
    },

    cancelReason() {
      if (this.$route.query?.reason_code != null && this.$route.query?.reason_code != '') {
        return this.CANCEL_REASONS[Number(this.$route.query.reason_code)]
      }

      return null
    },

    orderCancelStatusText() {
      let additionalText = ''
      if (this.cancelReason != null) {
        additionalText = this.cancelReason
        if (this.purchaserType != null) additionalText += ` for this ${this.purchaserType}`
        additionalText += '.'
      }

      return 'Your order has not been completed. ' + additionalText
    },

    purchaserType() {
      if (this.$route.query?.purchaser_type != null && this.$route.query?.purchaser_type != '') {
        return this.PURCHASER_TYPES[Number(this.$route.query.purchaser_type)]
      }

      return null
    },

    pathToGoTo() {
      if (this.$route.query.redirect != null) {
        return this.$route.query.redirect
      }

      return routes.EVENTS.path
    }
  },
  methods: {
    async goBackToWhereTheyCameFrom() {
      this.$router.push(this.pathToGoTo)
    }
  }
}
</script>

<style scoped>
.check-icon {
  height: 157px;
  width: 157px;
  min-height: 157px;
  min-width: 157px;
  color: var(--color-success);
}

#payment-summary {
  border: 1px solid var(--color-gray1-5);
  border-radius: 12px;
  padding: 1.5rem 2.25rem;
  text-align: center;
  width: 350px;
}

@media screen and (max-width: 432px) {
  #payment-summary {
    width: 250px;
  }
}

.event-name {
  color: '#989898';
  font-family: 'Akatab', serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.price {
  color: #303030;
  font-family: Helvetica;
  font-size: 26px;
  font-weight: 700;
  line-height: 28px;
}
</style>