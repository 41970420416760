<template>
  <button
    ref="rebelButton"
    :type="buttonType"
    :class="`action ${type} ${color} ${styles.getLightDarkClasses(darkBackground)} ${getBackgroundBlurClass} nowrap`"
    :disabled="disabled || isLoading"
    :style="applyInlineStyles"
  >
    
    <div v-if="!isLoading" class="flex align-center justify-center gap-0-5 button-content">
      <slot name="icon-leading"></slot>
      <slot name="body">{{ text }}</slot>
      <slot name="icon-trailing"></slot>
    </div>  
    <span v-else class="flex justify-center align-center">
      <icon-loading class="loading-icon" :colorVariable="fillColorForLoadingIcon" />
    </span>
  </button>
</template>

<script>
import IconLoading from './IconLoading.vue';
import styles from '@/utils/styles.js';

export default {
  setup() {
    return {
      styles
    }
  },
  
  props: {
    buttonType: {
      type: String,
      default: null,
      validator(value) {
        return value == null || value === 'button' || value === 'submit' || value === 'reset'
      }
    },

    color: {
      type: String,
      default: 'default',
      validator(value) {
        return value == 'default' || value == 'inverse' || value == 'success' || value == 'danger'
      }
    },

    darkBackground: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    text: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: '',
      validator(value) {
        return value == 'primary' || value == 'primary-inverse' || value == 'ghosted' || value == 'borderless'
      }
    }
  },

  components: {
    IconLoading
  },

  computed: {
    applyInlineStyles() {
      if (this.initialButtonWidthCSS === 'initial') return undefined // don't apply any inline styles if they're not needed because it overrides any other CSS

      return `width: ${this.initialButtonWidthCSS}`
    },

    fillColorForLoadingIcon() {
      if (this.type === 'ghosted' || this.type === 'borderless' ) {
        if (styles.getLightDarkClasses(this.darkBackground) == 'light-background') {
          return 'color-action'
        } else {
          return 'color-action-dark-background';
        }
      } else if (this.type === 'primary-inverse') {
        return 'color-black'
      } else {
        // remaining types are primary and inverse-ghosted
        return 'color-white'
      }
    },

    getBackgroundBlurClass() {
      if (this.type == 'ghosted') {
        return styles.getBackgroundBlurClass(this.darkBackground)
      }
      return ''
    }
  },

  data() {
    return {
      initialButtonWidthCSS: 'initial'
    }
  },

  watch: {
    isLoading(prev, curr) {
      if (!curr) {
        // capture the width when the current value of isLoading is false
        this.initialButtonWidthCSS = this.$refs.rebelButton.clientWidth + 'px'
      } else {
        this.initialButtonWidthCSS = 'initial'
      }
    }
  }
}
</script>

<style scoped>
button {
  --button-height: 1.2rem;

  border-radius: 4px;
  border: 1px solid var(--color-actual);
  cursor: pointer;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.button-content {
  font-family: 'Blinker', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: var(--button-height);
  text-wrap: nowrap;
}

button.dark-background {
  filter: drop-shadow(2px 4px 6px #00000090)
}

button:disabled {
  opacity: 50%;
  cursor: default;
}

.loading-icon {
  height: var(--button-height);
}

.default {
  --color-actual: var(--color-action);
}

.default.dark-background {
  --color-actual: var(--color-action-dark-background);
}

.danger, .danger .button-content {
  --color-actual: var(--color-danger);
}

.success, .success .button-content {
  --color-actual: var(--color-success);
}

.primary {
  border: 2px solid var(--color-actual);
  background-color: var(--color-actual);
}

.primary.dark-background {
  border: 2px solid var(--color-white);
}

.primary.dark-background:disabled {
  border: 2px solid var(--color-action-dark-background);
}

.ghosted {
  border: 2px solid var(--color-actual);
  background: transparent;
}

.ghosted.dark-background {
  border: 2px solid var(--color-white);
}

.borderless {
  border: none;
  background: transparent;
}

.primary-inverse {
  border: none;
  background: var(--color-white);
}

.primary, .primary .button-content, .ghosted.dark-background, .ghosted.dark-background .button-content, .borderless.dark-background, .borderless.dark-background .button-content{
  color: var(--color-white);
}

.ghosted, .ghosted .button-content, .borderless, .borderless .button-content {
  color: var(--color-actual);
}

.primary-inverse, .primary-inverse .button-content {
  color: var(--color-black)
}

.toolbar {
  border: 1px solid var(--color-gray2);
}
</style>