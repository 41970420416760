<template>
  <section id="dont-miss-out-submit-picks" data-testid="dont-miss-out-submit-picks"
    :class="`${styles.getLightDarkClasses(darkBackground)} ${getBackgroundBlurClass}`">
    <div>
      <h2 class="heading2">Don't miss out, submit your picks!</h2>
      <h3 class="heading3">{{ pricingExplanation }}</h3>
      <p class="caption mt-0-5">
        Each <span class="caption semi-bold">LEAGUE</span> or <span class="caption semi-bold">OPEN TOURNAMENT</span> entry requires a separate
        transaction. You may edit your picks until the clock runs out at the <span class="caption semi-bold">START OF THE EVENT</span>.
        Any <span class="caption semi-bold">UNPAID</span> entries will be discarded after the start of the event.
      </p>
    </div>

    <form id="buy-entry-form" @submit="buyEntryFormSubmit" class="flex" :action="`${API_URL}/create-checkout-session`"
      method="POST">
      <input type="hidden" name="eventId" :value="eventId">
      <input type="hidden" name="leagueId" :value="leagueId">
      <input type="hidden" name="originUrlPath" :value="$route.fullPath">
      <input type="hidden" name="auth_token" :value="authToken">

      <rebel-button class="grow" button-type="submit" type="primary-inverse"
        :text="`Buy Entry ${nextEntryPriceDisplay}`" :is-loading="isBuyingEntry" :dark-background="true" />
    </form>
  </section>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import RebelButton from '@/components/RebelButton.vue'
import styles from '@/utils/styles.js'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      styles
    }
  },

  components: {
    RebelButton
  },

  props: {
    buyEntryCallback: {
      type: Function,
      required: false,
    },
    darkBackground: {
      type: Boolean,
      default: false
    },
    eventId: {
      required: true
    },
    leagueId: {
      required: false
    },
    paymentData: {
      required: true
    },
    userHasEntry: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      API_URL: import.meta.env.VITE_API_URL,

      firstEntryPriceDisplay: this.paymentData.priceFirstEntryDisplay,
      additionalEntryPriceDisplay: this.paymentData.priceAdditionalEntryDisplay,
      additionalEntriesDifferInPrice: this.paymentData.differentPriceForAdditionalEntry,

      isBuyingEntry: false
    }
  },

  computed: {
    authToken() {
      return this.authStore.loggedInUser?.token
    },

    getBackgroundBlurClass() {
      return this.darkBackground ? 'bg-gray-opacity-blur' : ''
    },

    nextEntryPriceDisplay() {
      if (this.additionalEntriesDifferInPrice) {
        return this.userHasEntry ? this.additionalEntryPriceDisplay : this.firstEntryPriceDisplay
      }

      return this.firstEntryPriceDisplay
    },

    pricingExplanation() {
      if (this.additionalEntriesDifferInPrice) {
        return `${this.firstEntryPriceDisplay} for 1st entry, ${this.additionalEntryPriceDisplay} for each additional entry`
      }

      return `${this.firstEntryPriceDisplay} per entry`
    }
  },

  methods: {
    async buyEntryFormSubmit(e) {
      try {
        this.isBuyingEntry = true
        if (this.buyEntryCallback != null && typeof this.buyEntryCallback === 'function') {
          e.preventDefault();
          await this.buyEntryCallback();
          document.getElementById('buy-entry-form').submit();
        }
      } finally {
        setTimeout(() => {
          this.isBuyingEntry = false
        }, 6000); // if the button is "loading" after 6 seconds, set "loading" status to false
      }
    }
  }
}
</script>

<style scoped>
#dont-miss-out-submit-picks {
  border-radius: 4px;
  padding: .5rem .75rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

#dont-miss-out-submit-picks > div * {
  color: var(--color-white);
}

#dont-miss-out-submit-picks.light-background {
  background-color: var(--color-dark-orange);
}

@media screen and (max-width: 512px) {
  #dont-miss-out-submit-picks {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: .75rem;
  }

  #dont-miss-out-submit-picks>* {
    width: 100%;
  }
}
</style>