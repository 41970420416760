<template>
  <router-link class="action flex align-center gap-0-125" :class="styles.getLightDarkClasses(darkBackground)" :to="routerLinkTo">
    <arrow-left-icon class="icon-20" />
    <div>Back to {{ pageName }}</div>
  </router-link>
</template>

<script>
import { ArrowLeftIcon } from '@heroicons/vue/20/solid';
import styles from '@/utils/styles.js';

export default {
  setup() {
    return {
      styles
    }
  },
  
  components: {
    ArrowLeftIcon
  },

  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    routerLinkTo: {
      type: Object || String,
      required: true
    },

    pageName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
a.dark-background {
  color: var(--color-white);
  filter: drop-shadow(2px 4px 6px #00000090);
}
</style>