<template>
  <div class="null-participant" :class="styles.getLightDarkClasses(darkBackground)"></div>
</template>

<script>
import styles from '@/utils/styles.js'

export default {
  setup() {
    return {
      styles
    }
  },

  props: {
    darkBackground: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>
.null-participant {
  margin-left: 0.5rem;
  margin-right: 1.5rem;
  width: 95%;
  height: 0.5rem;
  flex-grow: 1;
}

.null-participant.light-background {
  background: var(--color-gray1);
}

.null-participant.dark-background {
  background: var(--color-gray2-5);
}
</style>