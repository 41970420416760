<template>
  <section class="flex col gap-0-5">
    <h2>{{ capitalizeFirst(eventStatus) }}</h2>
    <p v-if="events.length == 0" class="gray3">You do not have any {{ eventStatus }} events.</p>

    <div class="flex col gap-0-75">
      <div v-for="event in events" :key="event.eventId" :data-testid="`event${event.eventId}`">
        <event-card :event="event" :event-status="eventStatus" 
        @create-league="(event) => $emit(emitEvents.CREATE_LEAGUE, event)"
        @join-open-tournament="(event) => $emit(emitEvents.JOIN_OPEN_TOURNAMENT, event)"
        @view-event-results="(event) => $emit(emitEvents.VIEW_EVENT_RESULTS, event)"
        @view-popular-event-results="(event) => $emit(emitEvents.VIEW_POPULAR_EVENT_RESULTS, event)"
        @vote-now="(event) => $emit(emitEvents.VOTE_NOW, event)"/>
      </div>
    </div>
  </section>
</template>

<script>
import emitEvents from '@/utils/emitEvents.js'
import EventCard from '@/components/events/EventCard.vue'

export default {
  setup() {
    return {
      emitEvents
    }
  },

  emits: [emitEvents.VIEW_EVENT_RESULTS, emitEvents.VIEW_POPULAR_EVENT_RESULTS, emitEvents.VOTE_NOW, emitEvents.CREATE_LEAGUE, emitEvents.JOIN_OPEN_TOURNAMENT],

  components: {
    EventCard
  },

  props: {
    eventStatus: {
      type: String,
      required: true,
      default: ''
    },

    events: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  methods: {
    capitalizeFirst(word) {
      if (word == null || word.length === 0) {
        return ''
      }

      return word.charAt(0).toUpperCase() + word.slice(1)
    }
  }
}
</script>
