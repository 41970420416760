<template>
  <template v-if="showWaysToWin">
    <select v-if="isEditable" @change="resultOptionChanged" v-model="chosenOption"
      class="ways-to-win small-caps-2 semi-bold"
      :class="`${styles.getLightDarkClasses(darkBackground)} ${getUnselectedClasses} ${styles.getDropShadowClass(darkBackground)}`">
      <option class="body2" :value="undefined">Select one</option>
      <option class="selected body2" :value="vc.id" v-for="vc in outcomeOptions" :key="vc.id">{{ vc.outcomeName }}
      </option>
    </select>
    <div v-else-if="resultOutcomeName" class="ways-to-win viewing-results flex gap-0-25 small-caps-2 semi-bold"
      :class="`${getIncorrectClass} ${styles.getLightDarkClasses(darkBackground)} ${styles.getDropShadowClass(darkBackground)}`">
      {{ resultOutcomeName }}
      <star-icon v-if="bonusWon" class="icon-14" />
    </div>
    <div v-else class="ways-to-win result-pending small-caps-2 semi-bold"
      :class="`${getIncorrectClass} ${styles.getLightDarkClasses(darkBackground)} ${styles.getDropShadowClass(darkBackground)}`">
      {{ userOutcomeName || 'None selected' }}
    </div>
  </template>
  <div v-else class="ways-to-win vs small-caps-2 semi-bold"
    :class="`${styles.getLightDarkClasses(darkBackground)} ${styles.getDropShadowClass(darkBackground)}`">
    VS
  </div>
</template>

<script>
import emitEvents from '@/utils/emitEvents';
import styles from '@/utils/styles.js';
import { StarIcon } from '@heroicons/vue/16/solid';

export default {
  setup() {
    return {
      styles
    }
  },

  emits: [emitEvents.MATCHUP_CHANGED],

  components: {
    StarIcon
  },

  props: {
    isEditable: {
      type: Boolean,
      required: false
    },

    bonusWon: {
      type: Boolean,
      required: false
    },

    darkBackground: {
      type: Boolean,
      required: false
    },

    resultOutcomeName: {
      type: String,
      required: false,
    },

    userOutcomeName: {
      type: String,
      required: false,
    },

    outcomeOptions: {
      type: Array,
      required: false,
      defaultValue: () => []
    },

    resultOptionId: {
      type: Number,
      required: false
    },

    showWaysToWin: {
      type: Boolean,
      required: true
    },
  },

  computed: {
    /* I'm expecting a merge conflict with main here. My new refactoring on main should replace this hacky method */
    getIncorrectClass() {
      if (this.resultOutcomeName && !this.bonusWon) {
        return 'incorrect'
      } else if (!this.userOutcomeName) {
        return 'incorrect'
      }
      return ''
    },

    getUnselectedClasses() {
      return this.chosenOption === undefined ? 'unselected regular-weight' : ''
    }
  },

  data() {
    return {
      chosenOption: this.resultOptionId
    }
  },

  methods: {
    resultOptionChanged() {
      this.$emit(emitEvents.MATCHUP_CHANGED, this.chosenOption)
    }
  }
}
</script>

<style scoped>
.ways-to-win {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  box-sizing: border-box;
  color: var(--color-white);
  padding: 0.275rem 0.375rem;
  text-align: center;
  text-overflow: clip;
  width: 10rem;
}

@media screen and (max-width: 512px) {
  .ways-to-win {
    text-wrap: balance;
    width: 6.5rem;
  }
}

@media screen and (max-width: 428px) {

  .ways-to-win.viewing-results,
  .ways-to-win.result-pending {
    text-wrap: balance;
    width: 5rem;
  }
}

.ways-to-win.light-background {
  background-color: var(--color-action);
  border: 2px solid var(--color-action);
}

.ways-to-win.dark-background {
  background-color: var(--color-dark-orange);
  border: 1px solid var(--color-dark-orange);
}

/* Selector */
select.ways-to-win {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" class="size-5"><path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" /></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - .25rem);
  background-position-y: 45%;
  background-size: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 1.375rem;
  text-align: center;
  user-select: none;
}

select.ways-to-win.light-background.unselected {
  /* color-action #2860F0 / blue is close enough; not sure why hex isn't workking in svg */
  color: var(--color-action);
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="blue" class="size-5"><path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" /></svg>');
}

select.ways-to-win:focus {
  outline: none;
  box-shadow: none;
}

select.ways-to-win>option {
  background-color: var(--color-white);
  color: var(--color-gray4);
}

/* Not editable: VS */
.ways-to-win.vs {
  padding: 0.25rem 0.375rem;
  width: 4rem;
}

/* Editable: Unselected state */
.ways-to-win.dark-background.unselected {
  backdrop-filter: blur(8px);
  background-color: var(--color-dark-orange-opacity50);
  border: 1px solid var(--color-white);
}

.ways-to-win.light-background.unselected {
  background-color: var(--color-white);
  border: 2px solid var(--color-action);
  color: var(--color-action);
}

/* Viewing results */
.ways-to-win.dark-background.result-pending {
  backdrop-filter: blur(8px);
  background-color: var(--color-dark-orange-opacity50);
  border: 1px solid var(--color-dark-orange-opacity50);
}

.ways-to-win.dark-background.incorrect {
  background-color: var(--color-light-blue);
  border: 1px solid var(--color-light-blue);
}
</style>