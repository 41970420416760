import axios from 'axios'

let emailTabController = new AbortController()
let entryTabController = new AbortController()
let leagueTabController = new AbortController()
let userTabController = new AbortController()

export default {
  async getEventOptions() {
    return await axios.get('/admin-dashboard/options/events')
  },

  async emailTab(searchTerm, appliedFilters) {
    const csvEventIds = appliedFilters?.eventIds?.join(',') ?? []
    const csvEventTypeIds = appliedFilters?.eventTypeIds?.join(',') ?? []
    const csvEmailTypeIds = appliedFilters?.emailTypeIds?.join(',') ?? []

    const isRead = appliedFilters?.readStatuses?.includes(true) || appliedFilters?.readStatuses == null || appliedFilters?.readStatuses?.length === 0 // true if not filtering 
    const isUnread = appliedFilters?.readStatuses?.includes(false) || appliedFilters?.readStatuses == null || appliedFilters?.readStatuses?.length === 0 // true if not filtering 

    if (emailTabController) {
      emailTabController.abort()
    }

    emailTabController = new AbortController();
    
    return await axios.get(`/admin-dashboard/email-tab?searchTerm=${searchTerm}&eventIds=${csvEventIds}&eventTypeIds=${csvEventTypeIds}&emailTypeIds=${csvEmailTypeIds}&read=${isRead}&unread=${isUnread}`, {
      signal: emailTabController.signal
    })

  },

  async entryTab(searchTerm, appliedFilters) {
    const csvEventIds = appliedFilters?.eventIds?.join(',') ?? []
    const csvEventTypeIds = appliedFilters?.eventTypeIds?.join(',') ?? []

    const isPaid = appliedFilters?.paymentStatuses?.includes(true) || appliedFilters?.paymentStatuses == null || appliedFilters?.paymentStatuses?.length === 0 // true if not filtering 
    const isUnpaid = appliedFilters?.paymentStatuses?.includes(false) || appliedFilters?.paymentStatuses == null || appliedFilters?.paymentStatuses?.length === 0 // true if not filtering 

    let completionPercentageMin = 0, completionPercentageMax = 100;
    if (appliedFilters?.completionPercentages != null && appliedFilters?.completionPercentages?.length > 0) {
      completionPercentageMin = appliedFilters?.completionPercentages[0]
      completionPercentageMax = appliedFilters?.completionPercentages[1]
    }

    if (entryTabController) {
      entryTabController.abort()
    }

    entryTabController = new AbortController();

    return await axios.get(`/admin-dashboard/entry-tab?searchTerm=${searchTerm}&eventIds=${csvEventIds}&eventTypeIds=${csvEventTypeIds}&completionPercentageMin=${completionPercentageMin}&completionPercentageMax=${completionPercentageMax}&paid=${isPaid}&unpaid=${isUnpaid}`, {
      signal: entryTabController.signal
    })
  },

  async leagueTab(searchTerm, appliedFilters) {
    const csvEventIds = appliedFilters?.eventIds?.join(',') ?? []
    const csvEventTypeIds = appliedFilters?.eventTypeIds?.join(',') ?? []

    let memberCountMin = 1, memberCountMax = 1000000000;
    if (appliedFilters?.memberCount != null && appliedFilters?.memberCount?.length > 0) {
      memberCountMin = appliedFilters?.memberCount[0]
      memberCountMax = appliedFilters?.memberCount[1]
      if (memberCountMax > 12) memberCountMax = 1000000000;
    }

    let paidMemberCountMin = 0, paidMemberCountMax = 1000000000;
    if (appliedFilters?.memberCount != null && appliedFilters?.memberCount?.length > 0) {
      paidMemberCountMin = appliedFilters?.paidMemberCount[0]
      paidMemberCountMax = appliedFilters?.paidMemberCount[1]
      if (memberCountMax > 6) memberCountMax = 1000000000;
    }

    if (leagueTabController) {
      leagueTabController.abort()
    }

    leagueTabController = new AbortController();

    return await axios.get(`/admin-dashboard/league-tab?searchTerm=${searchTerm}&eventIds=${csvEventIds}&eventTypeIds=${csvEventTypeIds}&memberCountMin=${memberCountMin}&memberCountMax=${memberCountMax}&paidMemberCountMin=${paidMemberCountMin}&paidMemberCountMax=${paidMemberCountMax}`, {
      signal: leagueTabController.signal
    })
  },

  async userTab(searchTerm, appliedFilters) {
    const csvEventIds = appliedFilters?.eventIds?.join(',') ?? []
    const csvEventTypeIds = appliedFilters?.eventTypeIds?.join(',') ?? []

    if (userTabController) {
      userTabController.abort()
    }

    userTabController = new AbortController();

    return await axios.get(`/admin-dashboard/user-tab?searchTerm=${searchTerm}&eventIds=${csvEventIds}&eventTypeIds=${csvEventTypeIds}`, {
      signal: userTabController.signal
    })
  },
}