<template>
  <section class="flex col align-center justify-center gap-1 mt-1">
    <h3 class="heading3 text-center">Permission Denied</h3>

    <div v-if="$route.query?.notAMember" class="flex col gap-0-5" style="max-width: 600px">
      <p class="body2">
        You are not a member of the league that you are attempting to access.
      </p>
      <p class="body2">
        Contact the league commissioner and ask them to use the Invite Members button
        on their league page to share an invite with you and others.
      </p>
    </div>
  </section>
</template>
