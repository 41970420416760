import { defineStore } from 'pinia'
import { useAuthenticationStore } from './authentication'
import smsFeatureNotificationService from '../services/SmsFeatureNotificationService'

export const useTextMessageNotificationsStore = defineStore('textMessageNotifications', {
  state: () => ({
    modal: {
      isShowing: false
    },
    anotherModal: {
      isShowing: false,
      lastWasShowing: null
    },
    lastAskedAbout: null,
  }),

  actions: {
    secondsSinceEpoch() {
      return Math.round(new Date().getTime() / 1000);
    },

    // if the user already has a phone number stored,
    // or if the user has been asked about text message notifications
    // within the last 24 hours, then do not ask again.
    shouldAsk() {
      // not going to ask if a modal is currently show or was ever showing
      // we ask on login/page refresh so we will either be the only modal that shows at that time
      // or we won't show until next login/page refresh
      if (this.anotherModal.isShowing || this.anotherModal.lastWasShowing != null) {
        return false
      }

      const authStore = useAuthenticationStore()

      if (authStore.loggedInUser?.user == null) return false // user not logged in
      if (authStore.loggedInUser?.user?.phone !== undefined) return false

      if (this.lastAskedAbout == null) return true

      const TWENTY_FOUR_HOURS_IN_SECONDS = 86400
      const timeSinceLastAsked = this.secondsSinceEpoch() - this.lastAskedAbout
      return timeSinceLastAsked > TWENTY_FOUR_HOURS_IN_SECONDS
    },

    askIfNecessary() {
      const ONE_SECOND = 1 * 1000
      setTimeout(this._delayedAsk, ONE_SECOND) // delay so that other modals have a chance of showing before this one
    },

    _delayedAsk() {
      if (!this.shouldAsk()) return

      smsFeatureNotificationService.shouldShowForUser()
        .then(res => {
          this.lastAskedAbout = res.data.lastAsked
          this.setModalShowing(res.data.shouldShowForUser)
        })
        .catch(() => {
          this.lastAskedAbout = this.secondsSinceEpoch()
        })
    },

    setAnotherModalIsShowing(isShowing) {
      this.anotherModal.isShowing = isShowing
      this.anotherModal.lastWasShowing = this.secondsSinceEpoch()
    },

    setModalShowing(isShowing) {
      this.modal.isShowing = isShowing
    }
  },

  persist: {
    enabled: true,

    strategies: [
      {
        storage: localStorage
      }
    ],
  }
})
