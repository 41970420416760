<template>
  <div class="grow" :class="darkBackground ? 'dark-background bg-gray4-opacity-50-blur' : 'light-background'">
    <div class="flex row gap-0-75 align-center grow">
      <img v-if="useEventLogos && event.logoUrl" width="100" :src="event.logoUrl" alt="Event logo" />
      <event-text :event="event" :size="2" :darkBackground="false" :exempt-from-payment="exemptFromPayment" :is-paid-if-necessary="isPaidIfNecessary" :is-user-specific-view="isUserSpecificView"></event-text>
    </div>
  </div>
</template>

<script>
import APP_SETTINGS from '@/appSettings.js'
import EventText from '@/components/events/EventText.vue'

export default {
  components: {
    EventText
  },

  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    event: {
      type: Object,
      required: true
    },

    exemptFromPayment: {
      type: Boolean,
      default: false
    },

    isPaidIfNecessary: {
      type: Boolean,
      default: true
    },

    isUserSpecificView: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      useEventLogos: APP_SETTINGS.USE_EVENT_LOGOS
    }
  }
}
</script>

<style scoped>
.light-background.event-card-header {
  background-color: var(--color-gray-half);
}

.dark-background.event-card-header {
  border-radius: 4px;
}
</style>
