<template>
  <div class="flex align-center gap-1">
    <payment-status-tag v-if="event.paidEvent && !entryExemptFromPayment" :is-paid-for="entryPaidInFull" :is-disqualified="entryIsDisqualified" :data-testid="paymentStatusTestId" />
    <div v-if="statusMessage" class="caption gray2">{{ statusMessage }}</div>
    <div v-else-if="showProgressBar" class="flex align-center gap-0-25">
      <progress-bar :value1="Math.round(entryProgressPercentage)" :value2="100" :max-value="100" :width="100">
        <span class="caption gray2">complete</span>
      </progress-bar>
    </div>
  </div>
</template>

<script>
import PaymentStatusTag from '@/components/PaymentStatusTag.vue'
import ProgressBar from '@/components/ProgressBar.vue'

export default {
  components: {
    PaymentStatusTag,
    ProgressBar
  },

  props: {
    entryExemptFromPayment: {
      type: Boolean,
      default: false
    },
    
    entryIsDisqualified: {
      type: Boolean,
      default: false
    },
    
    entryPaidInFull: {
      type: Boolean,
      default: false,
      required: false
    },

    entryProgressPercentage: {
      type: Number,
      required: false
    },

    event: {
      type: Object,
      required: true
    },

    paymentStatusTestId: {
      type: String,
      default: '',
      required: false
    },

    statusMessage: {
      type: String,
      required: false
    }
  },

  computed: {
    showProgressBar() {
      return !this.event.participationEnded && this.entryProgressPercentage != null
    }
  }
}
</script>
