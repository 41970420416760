<template>
  <div v-if="!isLoading" class="flex col gap-1-5">
    <div v-if="adminViewingAnotherUsersData">
      <h3 class="heading3" :class="{ 'dark-background': darkBackground }">Viewing as: {{ myLeagueMember?.user?.displayName
      }}</h3>
    </div>

    <div>
      <header-action-bar v-if="adminViewingAnotherUsersData" :breakpointOuter="768" :breakpointMid="512"
        :breakpointInner="432" :dark-background="darkBackground">
        <template v-slot:linkBackTo>
          <link-back-to :router-link-to="{ name: 'admin-dashboard' }" page-name="Admin Dashboard"
            :dark-background="darkBackground" />
        </template>

        <template v-if="entryHistoryMetaData != null && entryHistoryMetaData.length" v-slot:primaryAction1>
          <rebel-select :key="selectedEntryHistoryOption" :dark-background="darkBackground"
            :selectedValue="selectedEntryHistoryOption" :selectorOptions="entryHistoryOptions"
            @selected-value-changed="updateSelectedEntryHistoryOption" />
        </template>

        <template v-if="league.event.infoUrl" v-slot:secondaryAction1>
          <rebel-button :dark-background="darkBackground" @click="goToEventInfo" type="ghosted" color="default"
            text="Event Info" class="nowrap">
            <template v-slot:icon-leading>
              <arrow-top-right-on-square-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>

        <template v-if="entryHistoryMetaData != null && entryHistoryMetaData.length" v-slot:secondaryAction2>
          <rebel-button :dark-background="darkBackground" @click="entryVersionHistoryModal.isShowing = true"
            type="ghosted" color="default" text="View All Version History" class="nowrap" />
        </template>
      </header-action-bar>

      <header-action-bar v-else :breakpointOuter="512" :breakpointInner="432" :dark-background="darkBackground">
        <template v-slot:linkBackTo>
          <link-back-to
            :router-link-to="{ name: 'league-details', params: { leagueId: league.leagueId }, query: { userId: $route.query?.userId } }"
            :page-name="league.name" :dark-background="darkBackground" />
        </template>
        <template v-slot:primaryAction1>
          <rebel-select :dark-background="darkBackground" :selectedValue="selectedLeagueUserViewing"
            :selectorOptions="resultSelectorOptions" @selected-value-changed="updateSelectedLeagueUserViewing" />
        </template>
        <template v-if="league.event.infoUrl" v-slot:secondaryAction1>
          <rebel-button :dark-background="darkBackground" @click="goToEventInfo" type="ghosted" color="default"
            text="Event Info" class="nowrap">
            <template v-slot:icon-leading>
              <arrow-top-right-on-square-icon class="icon-20" />
            </template>
          </rebel-button>
        </template>
      </header-action-bar>
    </div>

    <pick-em-header :dark-background="darkBackground" :results-pending="league?.event?.resultsPending" :subText="eventSubText" :show-admin-data="false"
      :show-league-data="viewingActualResults === false" :event="league.event" :league-data="pickEmHeaderLeagueData"
      :leagues="allLeagueOptions" :league-id="league.leagueId" @league-changed="leagueChanged"
      :key="`header-${lastLoadedTime}`" />

    <pick-em-matches v-if="viewingActualResults" :dark-background="darkBackground" :event-data="league.event"
      :user-projections="viewingActualResults ? undefined : pickEmUserMatchups" :key="`matches1-${lastLoadedTime}`" />
    <pick-em-matches v-else :dark-background="darkBackground" :event-data="league.event" who-is-editing="user"
      :user-projections="pickEmUserMatchups" :key="`matches2-${lastLoadedTime}`" />

    <entry-version-history v-if="entryVersionHistoryModal.isShowing" :event-id="league.event.eventId"
      :league-id="league.leagueId" :user-id="userId" @close="entryVersionHistoryModal.isShowing = false"
      @view-history-entry="updateSelectedEntryHistoryOption" />
  </div>
</template>

<script>
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'
import EntryVersionHistory from '@/components/EntryVersionHistory.vue'
import HeaderActionBar from '@/components/HeaderActionBar.vue'
import LinkBackTo from '@/components/LinkBackTo.vue'
import PickEmHeader from '@/components/feature/pick-em/header/PickEmHeader.vue'
import PickEmMatches from '@/components/feature/pick-em/PickEmMatches.vue'
import RebelButton from '@/components/RebelButton.vue'
import RebelSelect from '@/components/RebelSelect.vue'

import leagueService from '@/services/LeagueService'
import entryHistoryService from '@/services/EntryHistoryService'

import { useAuthenticationStore } from '@/stores/authentication'
import { useEventAdminStore } from '@/stores/eventAdmin'
import DateUtils from '@/utils/dateUtils'
import PubSub from 'pubsub-js'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
      eventAdminStore: useEventAdminStore()
    }
  },

  components: {
    ArrowTopRightOnSquareIcon,
    EntryVersionHistory,
    LinkBackTo,
    HeaderActionBar,
    PickEmHeader,
    PickEmMatches,
    RebelButton,
    RebelSelect
  },

  computed: {
    viewingHistoricalEntry() {
      if (this.entryHistoryOptions.length === 0) return false

      // if we are not viewing the top-most entry, we are viewing a historical entry
      return this.entryHistoryOptions[0].value != this.selectedEntryHistoryOption
    },

    entryHistoryOptions() {
      return this.entryHistoryMetaData.map(metaData => {
        return {
          text: this.formatDateTimeWithSeconds(metaData.createdAt),
          value: metaData.entryId
        }
      })
    },

    myLeagueMember() {
      return this.league?.members?.find((member) => member.user.id == this.selectedLeagueUserViewing)
    },

    adminViewingAnotherUsersData() {
      return this.$route.query?.userId != null && this.authStore.isDataAdmin
    },

    userId() {
      if (this.adminViewingAnotherUsersData) {
        return this.$route.query.userId
      }

      return this.authStore.loggedInUser?.user?.id
    },

    eventSubText() {
      return this.formatDateTime(this.league.event.startDateTime)
    },

    leagueId() {
      return this.$route.params.leagueId
    },

    resultSelectorOptions() {
      const options = []
      options.push({ text: 'Actual Results', value: this.ACTUAL_RESULTS_VALUE })

      if (this.authStore.loggedInUser == null) {
        return options
      }

      const myMember = this.league.members.find((member) => member.user.id == this.userId)

      if (myMember.hasAtLeastOnePrediction) {
        options.push({ text: myMember.user.displayName, value: this.userId })
      }

      const remainingLeagueMemberOptions = this.league.members
        .filter((m) => m.hasAtLeastOnePrediction)
        .flatMap((m) => m.user)
        .filter((u) => u.id != this.userId)
        .map((u) => {
          return { text: u.displayName, value: u.id }
        })

      options.push(...remainingLeagueMemberOptions)

      return options
    },

    pickEmHeaderLeagueData() {
      const lastLoadedTime = this.lastLoadedTime // needed so that this computed refreshes

      if (this.viewingHistoricalEntry) {
        return {
          leagueOptions: this.allLeagueOptions,
          pointsWon: '--',
          pointsPossible: '--',
          leagueRank: '-',
          leagueMemberCount: this.league?.members?.filter(m => m.hasAtLeastOnePrediction).length ?? 0,
          matnessRank: '-',
          matnessMemberCount: this.league?.event?.leagueMemberCount ?? 0,
          predictedTeam1Score: '--',
          predictedTeam2Score: '--',
          lastLoadedTime
        }
      }

      return {
        leagueOptions: this.allLeagueOptions,
        pointsWon: this.myLeagueMember?.leagueScore ?? 0,
        pointsPossible: this.myLeagueMember?.pointsPossible ?? 0,
        leagueRank: this.myLeagueMember?.leagueRankDisplay ?? '-',
        leagueMemberCount: this.league?.members?.filter(m => m.hasAtLeastOnePrediction).length ?? 0,
        matnessRank: this.myLeagueMember?.overallRankDisplay ?? '-',
        matnessMemberCount: this.league?.event?.leagueMemberCount ?? 0,
        predictedTeam1Score: this.myLeagueMember?.predictedTeam1Score ?? 0,
        predictedTeam2Score: this.myLeagueMember?.predictedTeam2Score ?? 0,
        lastLoadedTime
      }
    },

    viewingActualResults() {
      return this.selectedLeagueUserViewing === this.ACTUAL_RESULTS_VALUE
    }
  },

  async created() {
    await this.loadData()

    const myMember = this.league.members.find((member) => member.user.id == this.userId)

    // if I haven't paid and I needed to, redirect me to league details page
    if (!myMember.paidIfNecessary) {
      this.$router.replace({ name: 'league-details', params: { leagueId: this.leagueId } })
      return
    }

    this.pickEmUserMatchups = myMember?.pickEmUserMatchups
    // if I switched from another league, try to maintain viewing the same user
    const leagueUserViewing = this.resultSelectorOptions.find(rso => rso.value == this.$route.query.viewingUserId)
    if (leagueUserViewing != null) {
      this.pickEmUserMatchups = myMember?.pickEmUserMatchups
      this.selectedLeagueUserViewing = leagueUserViewing.value;
    }

    // if I don't have predictions, default to actual results
    if (!myMember.hasAtLeastOnePrediction) {
      this.selectedLeagueUserViewing = this.ACTUAL_RESULTS_VALUE
    }

    this.league.members.forEach((m) => (m.pickEmUserMatchupsLoaded = true)) // leagueService.getById is loading all matchups at the moment


    this.lastLoadedTime = new Date().getMilliseconds()
    this.isLoading = false
  },

  data() {
    return {
      entryVersionHistoryModal: {
        isShowing: false
      },
      selectedEntryHistoryOption: -1,
      entryHistoryMetaData: [],

      ACTUAL_RESULTS_VALUE: -1,

      isLoading: true,

      allLeagueOptions: [],
      league: {},

      pickEmUserMatchups: undefined,

      selectedLeagueUserViewing: this.$route.query.userId || this.authStore.loggedInUser?.user?.id,

      lastLoadedTime: new Date().getMilliseconds(),

      darkBackground: this.$route.meta.darkBackground,
    }
  },

  methods: {
    async updateSelectedEntryHistoryOption(entryId) {
      this.selectedEntryHistoryOption = entryId

      const metaDataEntry = this.entryHistoryMetaData.find(md => md.entryId == entryId)
      // load data if we don't already have it 
      if (metaDataEntry.pickEmUserMatchups == null) {
        const getPickEmUserMatchupsRes = await entryHistoryService.getForPickEm(entryId)
        metaDataEntry.pickEmUserMatchups = getPickEmUserMatchupsRes.data.pickEmUserMatchups
        if (getPickEmUserMatchupsRes.data.groups != null && getPickEmUserMatchupsRes.data.groups.length) {
          metaDataEntry.groups = getPickEmUserMatchupsRes.data.groups
        } else {
          metaDataEntry.groups = this.entryHistoryMetaData[0].groups // if there are no groups then use the most recent ones
        }
      }

      this.pickEmUserMatchups = metaDataEntry.pickEmUserMatchups
      this.league.event.groups = metaDataEntry.groups
      this.lastLoadedTime = new Date().getMilliseconds()
    },

    changeLeagueName(topic, message) {
      const isMyLeague = message.leagueId != null && this.leagueId == message.leagueId

      if (isMyLeague && message.name != null) {
        this.league.name = message.name
      }
    },

    formatDateTime(date) {
      return DateUtils.formatDateTime(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },
    formatDateTimeWithSeconds(date) {
      return DateUtils.formatDateTimeWithSeconds(date, this.authStore?.loggedInUser?.user?.timeZoneSetting)
    },

    goToEventInfo() {
      window.open(this.league.event.infoUrl, '_blank')
    },

    goToLeagueDetailsPage() {
      this.$router.replace({ name: 'league-details', params: { leagueId: this.leagueId }, query: this.$route.query })
    },

    async goToPickEmEditPage() {
      await this.$router.push({ name: 'league-pick-em-edit', params: { leagueId: this.leagueId }, query: this.$route.query })
    },

    async leagueChanged(leagueIdToView) {
      await this.$router.push({ name: 'league-pick-em-view', params: { leagueId: leagueIdToView }, query: { ...this.$route.query, viewingUserId: this.selectedLeagueUserViewing } })
    },

    async loadData() {
      const getLeaguePromise = leagueService.getById(this.leagueId)
      const getAllLeagueOptionsPromise = leagueService.getAllOptionsWithLeagueId(this.leagueId, this.userId)
      const res = await Promise.all([getLeaguePromise, getAllLeagueOptionsPromise])

      this.league = res[0].data
      this.eventAdminStore.initialize(this.league.event)
      this.allLeagueOptions = res[1].data

      if (this.league == null) {
        await this.$router.push({ name: 'permission-denied' })
      }

      if (this.league.event.upcoming) {
        await this.goToPickEmEditPage()
      }

      if (!this.league.event.participationEnded) {
        await this.goToPickEmEditPage()
      }

      // if I haven't paid and I needed to, redirect me to league details page
      if (!this.myLeagueMember?.paidIfNecessary) {
        this.goToLeagueDetailsPage()
      }

      this.getEntryHistoryMetaData()
    },

    async getEntryHistoryMetaData() {
      if (!this.adminViewingAnotherUsersData) return

      this.entryHistoryMetaData = []
      const currentUserEntryHistoryMetaData = await entryHistoryService.get(this.league.event.eventId, this.league.leagueId, this.userId)
      this.entryHistoryMetaData = currentUserEntryHistoryMetaData.data.entryHistoryMetaData

      if (this.entryHistoryMetaData == null || this.entryHistoryMetaData.length == 0) return

      this.selectedEntryHistoryOption = this.entryHistoryMetaData[0].entryId
      this.entryHistoryMetaData[0].pickEmUserMatchups = this.pickEmUserMatchups
      this.entryHistoryMetaData[0].groups = this.league.event.groups
    },

    async reloadPage(topic, message) {
      try {
        const isEventMessage = message.eventId != null
        const isMyEvent = this.league.event.eventId == message.eventId
        const isMyLeague = this.leagueId == message.leagueId
        const isALeagueMessage = message.leagueId != null

        if (isEventMessage && !isMyEvent) return
        if (isALeagueMessage && !isMyLeague) return

        const response = await leagueService.getById(this.leagueId)
        if (response?.data?.members != null) {
          const userLeftLeague = this.league.members.length < response.data.members

          this.league = response.data

          const viewingBracketForUserThatLeftLeague = this.myLeagueMember == null

          if (this.viewingActualResults || (userLeftLeague && viewingBracketForUserThatLeftLeague)) {
            this.updateSelectedLeagueUserViewing(this.ACTUAL_RESULTS_VALUE)
            // doesn't matter whose brackets we view when viewing actual results. just pick the first
            if (response.data.members.length) this.pickEmUserMatchups = response.data.members[0]?.pickEmUserMatchups
          } else {
            this.setPickEmUserMatchupsLoadedForMember(this.myLeagueMember, this.myLeagueMember.pickEmUserMatchups)
            this.pickEmUserMatchups = this.myLeagueMember.pickEmUserMatchups
          }
          this.lastLoadedTime = new Date().getMilliseconds()
        }
      } catch {
        // 
      }
    },

    setPickEmUserMatchupsLoadedForMember(member, pickEmUserMatchups) {
      member.pickEmUserMatchups = pickEmUserMatchups
      member.pickEmUserMatchupsLoaded = true
    },

    updateSelectedLeagueUserViewing(val) {
      this.selectedLeagueUserViewing = val

      if (val === this.ACTUAL_RESULTS_VALUE) {
        return
      }

      const selectedMember = this.league.members.find((member) => member.user.id == val)

      this.pickEmUserMatchups = selectedMember.pickEmUserMatchups
    }
  },

  mounted() {
    PubSub.subscribe('league-members-changed', this.reloadPage)
    PubSub.subscribe('event-results-published', this.reloadPage)
    PubSub.subscribe('league-name-changed', this.changeLeagueName)
    PubSub.subscribe('payment-status-changed', this.reloadPage)
  },


  unmounted() {
    try {
      PubSub.subscribe('league-members-changed', this.reloadPage)
      PubSub.subscribe('event-results-published', this.reloadPage)
      PubSub.unsubscribe('league-name-changed', this.changeLeagueName)
      PubSub.unsubscribe('payment-status-changed', this.reloadPage)
    } catch {
      /* empty */
    }
  },

}
</script>
