<template>
  <div class="flex col justify-center align-center gap-0-125"
    :class="styles.getLightDarkClasses(darkBackground)">
    <span v-if="!isEditable && hasResults && isIncorrect && userPredictionOutcomeName" class="small-caps-3 strikethrough">{{ userPredictionOutcomeName }}</span>
    <way-to-win-selector :is-editable="isEditable" :dark-background="darkBackground" :bonus-won="bonusWon" :show-ways-to-win="showWaysToWin"
      :outcome-options="outcomeOptions" :result-outcome-name="resultOutcomeName" :user-outcome-name="userPredictionOutcomeName" :result-option-id="userPredictionOptionId || resultOptionId" 
      @matchup-changed="resultOptionChanged" />
    <span v-if="weightClass" class="weight-class small-caps-2">
      {{ weightClass }} {{ weightClassUnits }}
    </span>
  </div>
</template>

<script>
import emitEvents from '@/utils/emitEvents';
import WayToWinSelector from '@/components/feature/pick-em/WayToWinSelector.vue';
import styles from '@/utils/styles.js';

export default {
  setup() {
    return {
      styles
    }
  },
  
  emits: [emitEvents.MATCHUP_CHANGED],

  components: {
    WayToWinSelector
  },

  computed: {
    bonusWon() {
      return this.eligibleForBonus && this.resultOptionId != null && this.userPredictionOptionId != null && this.resultOptionId === this.userPredictionOptionId
    },

    hasResults() {
      return this.resultOptionId != null
    },

    isIncorrect() {
      return this.eligibleForBonus && !this.bonusWon
    },

    resultOutcomeName() {
      return this.getOutcomeName(this.resultOptionId)
    },

    userPredictionOutcomeName() {
      return this.getOutcomeName(this.userPredictionOptionId)
    }
  },

  props: {
    isEditable: {
      type: Boolean,
      required: false
    },

    darkBackground: {
      type: Boolean,
      required: false
    },

    eligibleForBonus: {
      type: Boolean,
      required: false
    },

    outcomeOptions: {
      type: Array,
      required: false,
      defaultValue: () => []
    },

    resultOptionId: {
      type: Number,
      required: false
    },

    showWaysToWin: {
      type: Boolean,
      required: true
    },

    userPredictionOptionId: {
      type: Number,
      required: false
    },

    weightClass: {
      type: String,
      required: false
    },

    weightClassUnits: {
      type: String,
      required: false
    },
  },

  methods: {
    getOutcomeName(id) {
      if (id == null ) return ''

      const outcome = this.outcomeOptions.find(oo => oo.id === id)

      if (outcome) return outcome.outcomeName

      return ''
    },

    resultOptionChanged(newResultOptionId) {
      this.$emit(emitEvents.MATCHUP_CHANGED, newResultOptionId)
    }
  }
}
</script>

<style scoped>
.dark-background * {
  color: var(--color-white);
}

.weight-class {
  letter-spacing: 0.08rem;
  padding: 0 0.125rem;
}
</style>