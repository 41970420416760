<template>
  <div class="flex col gap-0-75">
    <h2 class="heading2">Event History</h2>
    <rebel-table v-if="tableDataComputed.length" :table-data="tableDataComputed" row-key="email" :columns="table.columns" :sortable="true"
      :more-options-column="false" :paging-options="paging" @pagechanged="(page) => paging.currentPage = page" />
  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import DateUtils from '@/utils/dateUtils'
import RebelTable from '@/components/RebelTable.vue';
import userService from '@/services/UserService'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore()
    }
  },

  components: {
    RebelTable
  },

  async created() {
    if (this.userId == null) return;

    const response = await userService.getEventHistoryForUser(this.userId)
    this.tableData = response.data.records
  },

  data() {
    return {
      paging: {
        usePaging: true,

        perPage: 10,
        currentPage: 1,
        maxVisibleButtons: 6
      },

      table: {
        columns: [
          {
            label: 'EVENT',
            prop: 'linkToEvent',
            sortDirection: '',
            isHyperlink: true
          },
          {
            label: 'LEAGUE',
            prop: 'linkToLeague',
            sortDirection: '',
            isHyperlink: true
          },
          {
            label: '% COMPLETION',
            prop: 'completionPercentageDisplay',
            sortProp: 'completionPercentage',
            sortDirection: ''
          },
          {
            label: 'PAYMENT STATUS',
            prop: 'paymentStatus',
            sortDirection: ''
          },
          {
            label: 'TRANSACTION DATE/TIME',
            prop: 'paymentDateDisplay',
            sortProp: 'paymentDate',
            sortDirection: '',
          },
          {
            label: 'VIEW ENTRY',
            prop: 'linkToEntry',
            sortDirection: '',
            isHyperlink: true
          },
        ],
      },

      tableData: []
    }
  },

  props: {
    userId: {
      type: [Number, String],
      required: true
    }
  },

  computed: {
    tableDataComputed() {
      return this.tableData.map(row => {
        return {
          ...row,
          completionPercentageDisplay: row.completionPercentage + '%',
          paymentDateDisplay: this.formatDateTime(row.paymentDate)
        }
      })
    }
  },

  methods: {
    formatDateTime(dateTime) {
      if (dateTime == null) return ''
      return DateUtils.formatDateTime(dateTime, this.authStore.loggedInUser?.user.timeZoneSetting)
    }
  }
}

</script>