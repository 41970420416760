export const validActualResultStates = ['actual-result-tbd', 'winner', 'loser']
export const validUserPredictionStates = ['', 'user-result-tbd', 'correct', 'incorrect', 'incorrect-eliminated', 'incorrect-blank', 'incorrect-somebody-else']
export const validWhoIsEditing = ['admin', 'admin-preview', 'user']

export default {
  getActualResultStateForPickEm(matchup, participantId) {
    if (matchup.winnerParticipantId == null) return 'actual-result-tbd'
    if (matchup.winnerParticipantId == participantId) return 'winner'
    return 'loser'
  },

  getUserPredictionState(matchup, whoIsEditing, isEditable, projectedWinnerId = null) {
    if (whoIsEditing == 'user') {
      if (matchup.winnerParticipantId != null && projectedWinnerId == matchup.winnerParticipantId) {
        return 'correct'
      } else if (matchup.winnerParticipantId != null || (!isEditable && projectedWinnerId == null)) {
        return 'incorrect'
      } else {
        return 'user-result-tbd'
      }
    }
    return ''
  },

  getActualResultStateForBracket(matchup, participantId) {
    if (matchup.winner == null) return 'actual-result-tbd'
    if (matchup.winner == participantId) return 'winner'
    return 'loser'
  },

  getUserPredictionStateForBracket(matchup, whoIsEditing, isEditable, projectedWinnerId = null, isEliminated = false) {
    if (whoIsEditing == 'user') {
      if (matchup.winner != null && projectedWinnerId == matchup.winner) {
        return 'correct'
      } else if (matchup.winner != null || (!isEditable && projectedWinnerId == null) || isEliminated) {
        if (matchup.winner != null ) {
          return 'incorrect-somebody-else'
        } else if (!isEditable && projectedWinnerId == null) {
          return 'incorrect-blank'
        } else if(isEliminated){
          return 'incorrect-eliminated'
        }
        return 'incorrect'
      } else {
        return 'user-result-tbd'
      }
    }
    return ''
  },

  getEliminatedClass(isEliminated) {
    return isEliminated ? 'eliminated' : ''
  }
}