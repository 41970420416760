import axios from 'axios'

export default {
  get(eventId, leagueId, userId) {
    let url = `/entry-history?eventId=${eventId}&userId=${userId}`
    if (leagueId != null) {
      url += `&leagueId=${leagueId}`
    }

    return axios.get(url)
  },

  getForPickEm(entryId) {
    return axios.get(`/pick-em/entry-history/${entryId}`)
  },
  getForBracketEvent(entryId) {
    return axios.get(`/bracket-event/entry-history/${entryId}`)
  }
}