<template>
  <div class="currency-text-container">
    <span class="currency-symbol-container">$</span>
    <input :required="isRequired" :id="inputFieldId" class="currency-text-input" type="text" :placeholder="placeholder" :value="modelValue"
      @input="inputChanged($event.target)">
  </div>
</template>

<script>
export default {
  props: ['modelValue', 'placeholder', 'inputFieldId', 'isRequired'],
  emits: ['update:modelValue'],

  methods: {
    isDigit(char) {
      const charCode = char.charCodeAt(0);
      return charCode >= 48 && charCode <= 57;
    },

    inputChanged(el) {
      if (el.value != null && el.value.length > 0) {

        // prevent anything other than digits
        const mostRecentlyTypedChar = el.value[el.value.length - 1]
        if (!this.isDigit(mostRecentlyTypedChar) && mostRecentlyTypedChar != '.') {
          el.value = el.value.substring(0, el.value.length - 1);
        }

        // prevent many decimal points
        if (el.value.indexOf('.') != el.value.lastIndexOf('.')) {
          el.value = el.value.substring(0, el.value.length - 1);
        }

        // prevent many leading zeros
        if (el.value.startsWith('0') && el.value.length > 1 && !el.value.startsWith('0.')) {
          el.value = 0
        }

        // prevent more than 2 places after the decimal
        const decimalPointIndex = el.value.indexOf('.')
        if (decimalPointIndex >= 0) {
          if (el.value.length - decimalPointIndex > 3) {
            el.value = el.value.substring(0, decimalPointIndex + 3)
          }
        }
      }

      if (!el.value.endsWith('.')) {
        this.$emit('update:modelValue', el.value)
      }
    }
  }
}
</script>

<style scoped>
.currency-text-container {
  position: relative;
}

.currency-symbol-container {
  position: absolute;
  color: var(--color-gray3);
  left: 12px;
  top: 9px;
}

.currency-text-input {
  padding-left: 25px;
}
</style>