<template>
  <div class="layout-form-page">
    <h1>Success!</h1>

    <p>Your email has been verified.</p>

    <rebel-button class="mb-0-5" type="primary" color="default" text="Go to Log In" @click="confirm"></rebel-button>
  </div>
</template>

<script>
import authenticationService from '@/services/authenticationService.js'
import RebelButton from '@/components/RebelButton.vue'

export default {
  async created() {
    if (!this.$route.query || !this.$route.query.token) {
      await this.$router.push({ name: 'login', query: { 'invalid-verify-email-token': true } })
    }

    try {
      await authenticationService.verifyEmail(this.$route.query.token)
    } catch (e) {
      if (e?.response?.status == 403) {
        await this.$router.push({ name: 'login', query: { 'invalid-verify-email-token': true } })
        return
      }

      alert('An error occurred')

      this.goToLogin()
    }
  },

  components: {
    RebelButton
  },

  methods: {
    async confirm() {
      await this.goToLogin()
    },

    async goToLogin() {
      if (this.$route.query.redirect) {
        await this.$router.push({ name: 'login', query: { redirect: this.$route.query.redirect } })
      } else {
        await this.$router.push({ name: 'login' })
      }
    }
  }
}
</script>

