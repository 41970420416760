<template>
  <select class="rebel-select" :class="{ 'dark-background': darkBackground }" v-model="mySelectedValue"
    @change="selectedValueChanged">
    <option v-for="opt in selectorOptions" :key="opt.value" :value="opt.value">
      {{ opt.text }}
    </option>
  </select>
</template>

<script>
import emitEvents from '@/utils/emitEvents';

export default {
  emits: [emitEvents.SELECTED_VALUE_CHANGED],

  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },

    selectedValue: {
      type: [Number, String],
      required: true
    },

    selectorOptions: {
      type: Array,
      defaultValue: () => [],
    }
  },

  data() {
    return {
      mySelectedValue: this.selectedValue
    }
  },

  methods: {
    selectedValueChanged() {
      this.$emit(emitEvents.SELECTED_VALUE_CHANGED, this.mySelectedValue)
    }
  }
}
</script>

<style scoped>
.rebel-select {
  background-color: var(--color-action);
  background-image: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" class="size-5"><path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" /></svg>');
  background-position-x: calc(100% - .5rem);
  background-position-y: 50%;
  background-size: 1.25rem;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 600;
  padding-top: .625rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  padding-right: 1.75rem;
  text-align: center;
  width: fit-content;
}

.rebel-select>option {
  background-color: var(--color-white);
  color: var(--color-gray4);
}

.rebel-select.dark-background {
  background-color: var(--color-dark-orange);
  border: 2px solid var(--color-white);
}
</style>