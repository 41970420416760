<template>
  <phone-number-generic-modal modal-title="Edit Phone Number" :value="value" />
</template>

<script>
import PhoneNumberGenericModal from './PhoneNumberGenericModal.vue';

export default {
  props: ['value'],

  components: {
    PhoneNumberGenericModal
  }
}
</script>