import axios from 'axios'

export default {
  shouldShowForUser() {
    return axios.get('/sms-feature-notification')
  },

  ack() {
    return axios.post('/sms-feature-notification')
  }
}