<template>
  <div class="max-width-48">
    <span class="caption" :class="getTextClasses">* </span>
    <span class="caption bold nowrap" :class="getBoldTextClasses">🐷 PIG</span>
    <span class="caption" :class="getTextClasses">
      represents the WINNER of the pigtail or previous round.
    </span>
    <span class="caption bold nowrap" :class="getBoldTextClasses">🐷 PIG</span>
    <span class="caption" :class="getTextClasses">
      can be advanced to score points in all rounds.
    </span>
  </div>
</template>

<script>
export default{
  props: {
    darkBackground: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getTextClasses() {
      return this.darkBackground ? 'gray1' : 'gray2-5'
    },

    getBoldTextClasses() {
      return this.darkBackground ? 'white' : 'gray4'
    }
  }
}
</script>
