<template>
  <div class="flex col gap-0-75">
    <h2 class="heading2">Transaction History</h2>
    <rebel-table v-if="tableData.length" :table-data="tableData" row-key="eventPaymentTransactionId"
      :columns="table.columns" :sortable="true" :more-options-column="false" :paging-options="paging"
      :rowConditionalCssClass="rowConditionalCssClass" @pagechanged="(page) => paging.currentPage = page">

      <template v-slot:receiptUrl="{ row }">
        <a v-if="row.receiptUrl" class="body2 color-action underline" :href="row.receiptUrl" target="_blank">View
          receipt</a>
      </template>
    </rebel-table>
    <p v-else>No transactions to show</p>
  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication'
import DateUtils from '@/utils/dateUtils.js'
import RebelTable from '@/components/RebelTable.vue';
import userService from '@/services/UserService'

export default {
  setup() {
    return {
      authStore: useAuthenticationStore(),
    }
  },

  components: {
    RebelTable
  },

  async created() {
    if (this.userId == null) return;

    const response = await userService.getTransactionHistoryForUser(this.userId)
    this.tableData = response.data.records.map(result => {

      const createdAtDisplay = this.formatDateTime(result.transactionDetails.createdAt)

      // flatten object
      const obj = {
        ...result,
        ...result.transactionDetails,
        createdAtDisplay
      }

      delete obj.transactionDetails // remove prop that was flattened

      return obj
    })

    let newArray = []

    this.tableData.forEach((td) => {
      newArray.push(JSON.parse(JSON.stringify(td)))
      if (td.refunds.length === 0) {
        return
      }

      const refundsForTransaction = td.refunds.flatMap(tdr => {
        delete td.refunds

        td.amountTotal = tdr.amount // replace charge amount with refund's amount
        td.amountTotalDisplay = tdr.amountTotalDisplay // replace charge amount with refund's amount
        td.eventPaymentTransactionId = td.eventPaymentTransactionId + '_' + tdr.refundId
        td.createdAt = tdr.createdAt // replace charge created date with refund created date
        td.createdAtDisplay = this.formatDateTime(tdr.createdAt)

        return {
          ...td,
          ...tdr
        }
      })

      newArray = newArray.concat(refundsForTransaction);
    })

    this.tableData = newArray;
  },

  data() {
    return {
      paging: {
        usePaging: true,

        perPage: 10,
        currentPage: 1,
        maxVisibleButtons: 6
      },

      table: {
        columns: [
          {
            label: 'DATE/TIME',
            prop: 'createdAtDisplay',
            sortProp: 'createdAt',
            sortDirection: 'asc'
          },
          {
            label: 'AMOUNT PAID',
            prop: 'amountTotalDisplay',
            sortProp: 'amountTotal',
            sortDirection: '',
          },
          {
            label: 'EVENT',
            prop: 'eventName',
            sortDirection: ''
          },
          {
            label: 'LEAGUE',
            prop: 'leagueName',
            sortDirection: ''
          },
          {
            label: 'RECEIPT',
            prop: 'receiptUrl',
            sortDirection: ''
          }
        ],
      },

      tableData: []
    }
  },

  props: {
    userId: {
      type: [Number, String],
      required: true
    }
  },

  methods: {
    formatDateTime(dateTime) {
      return DateUtils.formatDateTime(dateTime, this.authStore.loggedInUser.user.timeZoneSetting)
    },

    rowConditionalCssClass(row) {
      return {
        'color-danger': row.refundId != null
      }
    }
  },
}


</script>