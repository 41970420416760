export default {

  MATCHUPS_ANNOUNCED_ID: 1,
  MATCHUPS_ANNOUNCED_NAME: 'Matchups announced',

  EVENT_STARTING_SOON_ID: 2,
  EVENT_STARTING_SOON_NAME: 'Event starting soon',

  EVENT_RESEEDED_ID: 3,
  EVENT_RESEEDED_NAME: 'Event re-seeded',

  RESET_PASSWORD_ID: 4,
  RESET_PASSWORD_NAME: 'Reset Password',

  PASSWORD_CHANGED_ID: 5,
  PASSWORD_CHANGED_NAME: 'Password Changed',

  NEW_USER_VERIFICATION_ID: 6,
  NEW_USER_VERIFICATION_NAME: 'New User Verification',

  getNotificationTypeLabel(typeId) {
    const notificationTypeOption = this.getNotificationTypeOptions().find(eto => eto.value == typeId)

    if (!notificationTypeOption) throw Error('Email Type not found')

    return notificationTypeOption.text
  },

  getNotificationTypeOptions() {
    return [
      {
        value: this.MATCHUPS_ANNOUNCED_ID,
        text: this.MATCHUPS_ANNOUNCED_NAME
      },
      {
        value: this.EVENT_STARTING_SOON_ID,
        text: this.EVENT_STARTING_SOON_NAME
      },
      {
        value: this.EVENT_RESEEDED_ID,
        text: this.EVENT_RESEEDED_NAME
      },
      {
        value: this.RESET_PASSWORD_ID,
        text: this.RESET_PASSWORD_NAME
      },
      {
        value: this.PASSWORD_CHANGED_ID,
        text: this.PASSWORD_CHANGED_NAME
      },
      {
        value: this.NEW_USER_VERIFICATION_ID,
        text: this.NEW_USER_VERIFICATION_NAME
      },
    ]
  },
  
}