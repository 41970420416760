<template>
  <phone-number-generic-modal ref="phoneNumberModal" modal-title="Receive Text Message Notifications"
    @phone-number-saved="phoneNumberSaved"
    @close="$emit(emitEvents.CLOSE)">
    <template v-slot:phone-number-generic-additional-actions>
      <rebel-button type="ghosted" button-type="button" color="default" text="Remind me later"
        @click="remindMeLater"></rebel-button>
      <rebel-button type="borderless" button-type="button" color="default" text="No thanks, don't show me this again"
        @click="noThanks"></rebel-button>
    </template>
  </phone-number-generic-modal>
</template>

<script>
import emitEvents from '../../utils/emitEvents';
import PhoneNumberGenericModal from './PhoneNumberGenericModal.vue';
import RebelButton from '../RebelButton.vue';
import smsFeatureNotificationService from '../../services/SmsFeatureNotificationService';

export default {
  setup() {
    return {
      emitEvents
    }
  },
  emits: [emitEvents.CLOSE],

  components: {
    PhoneNumberGenericModal,
    RebelButton
  },

  methods: {
    noThanks() {
      smsFeatureNotificationService.ack();
      this.$refs.phoneNumberModal.closeModal()
    },

    async phoneNumberSaved() {

    },

    remindMeLater() {
      // do nothing 
      this.$refs.phoneNumberModal.closeModal()
    },
  }
}
</script>