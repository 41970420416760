export default {

  MATCHUPS_ANNOUNCED_ID: 1,
  MATCHUPS_ANNOUNCED_NAME: 'Matchups announced',

  EVENT_RESEEDED_ID: 2,
  EVENT_RESEEDED_NAME: 'Event Reseeded',

  USER_SAVE_CHANGES_ID: 3,
  USER_SAVE_CHANGES_NAME: 'User Save Changes',

  USER_COPY_PICKS_ID: 4,
  USER_COPY_PICKS_NAME: 'User Copy Picks',

  USER_JOIN_LEAGUE_ID: 5,
  USER_JOIN_LEAGUE_NAME: 'User Join League',


  getLabel(id) {
    const option = this.getOptions().find(eto => eto.value == id)

    if (!option) throw Error('Entry History Reason not found')

    return option.text
  },

  getOptions() {
    return [
      {
        value: this.MATCHUPS_ANNOUNCED_ID,
        text: this.MATCHUPS_ANNOUNCED_NAME
      },
      {
        value: this.EVENT_RESEEDED_ID,
        text: this.EVENT_RESEEDED_NAME
      },
      {
        value: this.USER_SAVE_CHANGES_ID,
        text: this.USER_SAVE_CHANGES_NAME
      },
      {
        value: this.USER_COPY_PICKS_ID,
        text: this.USER_COPY_PICKS_NAME
      },
      {
        value: this.USER_JOIN_LEAGUE_ID,
        text: this.USER_JOIN_LEAGUE_NAME
      },
    ]
  },
  
}